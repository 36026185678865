export default {
  mount: '.icon-twitter-native',
  attrs: {
    viewBox: '0 0 120 120',
    fill: 'currentColor'
  },
  path: `
  <path d="M89.8334655,0 L30.0713719,0 C13.5130849,0 0,13.5238095 0,30.0952381 L0,90 C0,106.47619 13.5130849,120 30.0713719,120 L89.9286281,120 C106.486915,120 120,106.47619 120,89.9047619 L120,30.0952381 C119.904837,13.5238095 106.391753,0 89.8334655,0 Z" fill="#1DA1F2"/>
  <path d="M46.086,90.477 C74.388,90.477 89.868,67.029 89.868,46.695 C89.868,46.029 89.868,45.366 89.823,44.706 C92.8344942,42.5277327 95.4340665,39.8306382 97.5,36.741 C94.6916373,37.9854054 91.7124879,38.8014858 88.662,39.162 C91.874232,37.2389463 94.2783705,34.214316 95.427,30.651 C92.4064725,32.4433659 89.1019383,33.706536 85.656,34.386 C80.8862484,29.3141828 73.3071582,28.0728377 67.1686479,31.3580397 C61.0301376,34.6432419 57.858825,41.6379771 59.433,48.42 C47.0606871,47.7997485 35.5334274,41.9559699 27.72,32.343 C23.6358683,39.3739341 25.7219636,48.3685785 32.484,52.884 C30.0352236,52.8114225 27.6398381,52.1508396 25.5,50.958 C25.5,51.021 25.5,51.087 25.5,51.153 C25.5020044,58.477779 30.6652545,64.7866056 37.845,66.237 C35.5796088,66.85482 33.2027418,66.9451329 30.897,66.501 C32.9128437,72.7692684 38.6897238,77.0633517 45.273,77.187 C39.824205,81.4692957 33.0931785,83.7939831 26.163,83.787 C24.9387107,83.7846495 23.7156122,83.7105225 22.5,83.565 C29.5369079,88.0808298 37.7247402,90.4761507 46.086,90.465" fill="#FFFFFF"/>`
}
