export default {
  mount: '.icon-logo-2',
  attrs: {
    viewBox: '0 0 205 135',
    fill: 'currentColor'
  },
  path: `
  <path d="M102.163,-2.27373675e-13 C159.485119,-2.27373675e-13 204.318,29.5860574 204.318,67.231 C204.318,104.869535 159.476221,134.462 102.163,134.462 C44.8461785,134.462 0,104.871159 0,67.231 C0,29.5890219 44.8409348,-2.27373675e-13 102.163,-2.27373675e-13 Z M102.163,3 C46.2994625,3 3,31.5718563 3,67.231 C3,102.888278 46.3046927,131.462 102.163,131.462 C158.017669,131.462 201.318,102.886784 201.318,67.231 C201.318,31.5688239 158.026572,3 102.163,3 Z M98.12,97.376 C107.593,97.659 147.314,99.962 166.54,109.948 C149.821,120.128 127.139,126.386 102.163,126.386 C75.714,126.386 51.837,119.371 34.897,108.112 C54.02,98.42 76.627,96.732 98.12,97.376 Z M189.638,46.986 C193.251847,53.1227188 195.179927,60.1054139 195.227,67.227 C195.227,80.188 188.669,92.172 177.554,101.921 C158.739,89.689 118.261,85.968 102.679,85.115 L111.993,67.135 C156.999,66.852 180.543,53.693 189.638,46.986 Z M16.09,44.681 C29.69161,53.294 52.3042594,64.7218472 96.7504495,66.8105763 L98.103,66.87 L88.812,84.812 C66.18,84.961 43.612,88.346 24.34,99.69 C14.701,90.369 9.09,79.216 9.09,67.228 C9.16768873,59.1894251 11.6014819,51.3501774 16.09,44.681 Z M150.437,16.646 C163.522,21.701 174.487,28.746 182.294,37.131 C164.551,51.351 126.545,54.857 108.271,54.68 C106.035667,54.6553333 103.854333,54.6106667 101.727,54.546 L114.127,31.111 C129.776,29.321 142.939,23.937 150.434,16.647 L150.437,16.646 Z M54.67,16.342 C63.375,25.042 80.086,31.103 99.531,31.705 L88.101,53.778 C53.82,50.93 35.426,42.013 24.101,35.005 L24.097,35.005 C31.89,27.393 42.364,20.994 54.67,16.342 Z"></path>
  `
}
