// import Validate from 'validate.js'
import DataModel from './data-model'
import { definePropertySet } from '../../library'
import '../validate'
import { axiosInstance } from '../../api'

// RequestPayloadModel
export default class ListModel {
  constructor (args) {
    // console.log('listmodel')
    const entity = (() => {
      if (args) {
        return typeof args === 'string' ? JSON.parse(args) : args
      }
      return {}
    })()
    const Model = entity.model || DataModel
    const api = entity.api || {}
    this.data = (entity.data && entity.data.map(p => new Model(p))) || []
    this.listErrors = entity.listErrors || []
    this.loaded = entity.loaded || false
    this.current_page = entity.current_page || 0
    this.from = entity.from || 0
    this.last_page = entity.last_page || 0
    this.per_page = entity.per_page || 0
    this.to = entity.to || 0
    this.total = entity.total || 0
    this.path = entity.path || ''
    definePropertySet.call(this,
      {
        entity,
        modelType: Model,
        api
      },
      {
        enumerable: false,
        writable: true
      }
    )
  }

  /**
   * 設定 model property 值
   * @param {*} entity
   */
  set (entity) {
    const Model = this.modelType
    Object.keys(entity).forEach(key => {
      if (key === 'data') {
        this[key] = entity[key].map(p => new Model(p))
      } else {
        this[key] = entity[key]
      }
    })
  }

  setPages (args) {
    const entity = args || {}
    this.current_page = entity.current_page || 0
    this.from = entity.from || 0
    this.last_page = entity.last_page || 0
    this.per_page = entity.per_page || 0
    this.to = entity.to || 0
    this.total = entity.total || 0
    this.path = entity.path || ''
  }

  /**
   * 取得該 api 的 Pagination 資料
   * @param {Number} page
   */
  updatePaginationData (page) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        baseURL: ''
      }).get(this.path + '?page=' + page).then(res => {
        const Model = this.modelType
        this.data = res.data.map(p => new Model(p))
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getPagination (page) {
    return axiosInstance({
      baseURL: ''
    }).get(this.path + '?page=' + page)
  }
}
