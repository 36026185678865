<template lang="pug">
  div(class="col d-flex align-items-center justify-content-center pb-4 flex-wrap")
    div(class="text-center py-4 w-100" v-if="!draw")
      h4(class="font-weight-bold mb-4" v-if="cardSuccess") 感謝您的訂購！
      h4(class="font-weight-bold mb-4 text-danger" v-else) {{ $parent.stepText[0] }}
      div(v-if="cardSuccess")
        div(class="py-2" v-if="ShoppingResultViews.data && ShoppingResultViews.data[0]")
          div(class="" v-html="ShoppingResultViews.data[0].content")
      div(v-else)
        div(class="py-2" v-if="ShoppingResultViews.data && ShoppingResultViews.data[1]")
          div(class="" v-html="ShoppingResultViews.data[1].content")
    div(class="bg-contain container-fluid w-100" style="background-repeat: no-repeat;background-size: cover;background-position: bottom;" v-else :style="{backgroundImage: `url(${images.DrawBg})`}")
      div(class="mx-auto pt-4" style="max-width: 320px;")
        img(class="w-100" :src="images.DrawSubject")
        div(class="draw-text text-center py-3")
          span 您共有
          span {{ opportunity }}
          span 次抽獎機會，剩下
          span {{ leftCount }}
          span 次
      div(class="row py-3")
        div(class="col-auto py-3 mx-auto red-package" :class="{open: state}" v-for="(state, index) in redPackages")
          button(class="btn btn-icon outline-none" type="button" v-if="state === 2")
            img(class="" :src="images.RedPackageOpen" width="60")
          button(class="btn btn-icon outline-none" type="button" :disabled="!leftCount" v-else @click="openPackage(index)")
            img(class="event-none" :src="images.RedPackage" width="60")
      div(class="row py-3")
        div(class="col-auto py-3 mx-auto" v-for="item in drawProductList")
          div(class="d-flex")
            div(class="col-auto mx-auto")
              div(class="text-center")
                img(class="" v-if="item.images && item.images[0] && item.images[0].image" :src="item.images[0].image" width="50")
                img(class="" v-else :src="defaultImage" width="50")
              div(class="py-2")
                span(class="ml-2") {{ item.name }}
                span(class="ml-2") X1
      div(class="d-flex justify-content-between align-items-end py-3")
        div(style="max-width: 100px;")
          img(class="w-100" :src="images.DragonLeft")
        div(style="max-width: 100px;")
          button(class="btn btn-icon outline-none" type="button" :disabled="!leftCount" @click="autoOpenPackage")
            img(class="w-100" :src="images.DrawBtn")
        div(style="max-width: 100px;")
          img(class="w-100" :src="images.DrawRight")
    div(class="col-12" v-if="!leftCount")
      div(class="d-flex flex-column align-items-center py-3")
        router-link(class="btn btn-success my-2 max-w-300 w-100" to="/member/order/list") 前往購物紀錄
        router-link(class="btn btn-outline-success my-2 max-w-300 w-100" to="/home") 回首頁
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DrawBg from '@/assets/draw/draw-bg.svg'
import DrawBtn from '@/assets/draw/draw-btn.svg'
import DrawSubject from '@/assets/draw/draw-subject.png'
import DragonLeft from '@/assets/draw/dragon-left.png'
import DrawRight from '@/assets/draw/dragon-right.png'
import RedPackage from '@/assets/draw/red-package.png'
import RedPackageOpen from '@/assets/draw/red-package-open.png'
import { getOrdersById, getActivitiesById, getProductsById, deleteShopcartAll } from '../../../api'
import defaultImage from '@/assets/images/default.png'

export default {
  data () {
    return {
      defaultImage,
      draw: false,
      images: {
        DrawBg,
        DrawBtn,
        DrawSubject,
        DragonLeft,
        DrawRight,
        RedPackage,
        RedPackageOpen
      },
      redPackages: [0, 0, 0, 0, 0],
      orderNumber: this.$route.query.order_no,
      cardSuccess: false,
      opportunity: 0,
      leftCount: 0,
      drawProductList: [],
      lotteryProductList: []
    }
  },
  created () {
    /** 台新
     * http://localhost:8080/member/shopcart/step/5?s_mid=&ret_code=00&tx_type=1&order_no=20210409000037&ret_msg=交易成功%28Approved%20or%20completed%20successfully%29
     * order_no: "20210409000037"
     * ret_code: "00"
     * ret_msg: "交易成功(Approved or completed successfully)"
     * s_mid: ""
     * tx_type: "1"
     * order_no
     * ordernumber
    */
    const orderResponse = this.$route.query
    if (orderResponse) {
      if (orderResponse.ret_code === '00' || orderResponse.retcode === '00' || orderResponse.retCode === '00') {
        localStorage.removeItem('order-response')
        this.cardSuccess = true
        this.$parent.step = 5
        // 如果為刷卡時
        const orderId = this.$route.query.order_id
        if (orderId) {
          getOrdersById(orderId).then(async res => {
            if (res.data) {
              const orderData = res.data
              const activities = await Promise.all(
                orderData.order_items.map((item) => {
                  if (item.activity_id) {
                    return getActivitiesById(item.activity_id)
                  }
                  return Promise.resolve()
                })
              )
              // 清除已結帳的購物車資訊
              await deleteShopcartAll()
              localStorage.removeItem('name')
              localStorage.removeItem('postal_code')
              localStorage.removeItem('receiver_phone')
              localStorage.removeItem('addresscity')
              localStorage.removeItem('addressdistrict')
              localStorage.removeItem('addressaddress')
              localStorage.removeItem('invoice_accept_type')
              localStorage.removeItem('invoicepostal_code')
              localStorage.removeItem('invoicecity')
              localStorage.removeItem('invoicedistrict')
              localStorage.removeItem('invoiceaddress')
              localStorage.removeItem('currentOfficesInvoiceAddress')
              localStorage.removeItem('sale_territory')
              localStorage.removeItem('payment')
              localStorage.removeItem('installment')
              localStorage.removeItem('total_price_new')

              this.$store.dispatch('getListModel', {
                name: 'ShopCarts'
              })
              // console.log('deleteShopcartAll', res)
              // const shopcartIdGroup = this.ShopCarts.data.map(p => p.id)
              // Promise.all(
              //   shopcartIdGroup.map(id => deleteShopcartById(id))
              // ).then(() => {
              //   shopcartIdGroup.forEach(id => {
              //     const index = this.ShopCarts.data.map(p => p.id).indexOf(id)
              //     this.ShopCarts.data.splice(index, 1)
              //   })
              // })
              // 取活動資料
              orderData.order_items.forEach((item, index) => {
                if (activities[index]) {
                  item.activity = activities[index].data
                }
              })
              // 對抽獎活動判斷
              const orderItems = orderData.order_items.filter(item => item && item.activity && item.activity.ruleable_type.includes('RuleRaffle'))
              if (orderItems.length) {
                const producResult = await Promise.all(orderItems.map(item => getProductsById(item.product_id)))
                const productList = producResult.map((item, index) => {
                  if (item && item.data) {
                    item.data.product_quantity = orderItems[index].quantity
                    return item.data
                  }
                  return {
                    product_quantity: 0
                  }
                })
                this.lotteryProductList = productList.reduce((acc, item) => {
                  return acc.concat(new Array(item.product_quantity).fill(item))
                }, [])
                this.lotteryProductList = this.shuffle(this.lotteryProductList)
                // console.log('this.lotteryProductList', this.lotteryProductList)
                this.opportunity = this.lotteryProductList.length
                this.leftCount = this.lotteryProductList.length
                this.draw = true
              }
            }
          }).catch(() => {
            this.$parent.step = 0
            this.cardSuccess = false
            this.$parent.stepText[0] = '交易失敗'
          })
        }
      }
      localStorage.removeItem('name')
      localStorage.removeItem('postal_code')
      localStorage.removeItem('receiver_phone')
      localStorage.removeItem('addresscity')
      localStorage.removeItem('addressdistrict')
      localStorage.removeItem('addressaddress')
      localStorage.removeItem('invoice_accept_type')
      localStorage.removeItem('invoicepostal_code')
      localStorage.removeItem('invoicecity')
      localStorage.removeItem('invoicedistrict')
      localStorage.removeItem('invoiceaddress')
      localStorage.removeItem('currentOfficesInvoiceAddress')
      localStorage.removeItem('sale_territory')
      localStorage.removeItem('payment')
      localStorage.removeItem('installment')
      localStorage.removeItem('total_price_new')
    }
    this.getListModel({ name: 'ShoppingResultViews' })
    if (this.$route.query && this.$route.query.error === '500') {
      this.$parent.stepText[0] = '訂單失敗'
    }
  },
  computed: {
    ...mapState({
      ShoppingResultViews: state => state.model.ShoppingResultViews,
      ShopCarts: state => state.model.ShopCarts
    })
  },
  methods: {
    ...mapActions(['getListModel']),
    shuffle (array) {
      let currentIndex = array.length
      let randomIndex = 0
      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]]
      }

      return array
    },
    openPackage (index) {
      this.redPackages[index] = 1
      this.leftCount--
      this.$forceUpdate()
      setTimeout(() => {
        this.redPackages[index] = 2
        this.drawProductList.push(this.lotteryProductList.pop())
        setTimeout(() => {
          this.redPackages[index] = 0
          this.$forceUpdate()
        }, 2000)
      }, 2000)
    },
    autoOpenPackage () {
      this.leftCount = 0
      for (let index = 0; index < this.lotteryProductList.length; index++) {
        if (index < 5) {
          this.redPackages[index] = 1
        }
        setTimeout(() => {
          if (index < 5) {
            this.redPackages[index] = 2
          }
          this.drawProductList.push(this.lotteryProductList.pop())
        }, index * 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.draw-text {
  color:#ae0000;
  letter-spacing: 2px;
}
.red-package{
  button {
    animation: red-packages-shake 2s infinite linear;
  }
  &:nth-of-type(1) button{
    animation-delay: 0.2s;
  }
  &:nth-of-type(2) button{
    animation-delay: 0.4s;
  }
  &:nth-of-type(3) button{
    animation-delay: 0.6s;
  }
  &:nth-of-type(4) button{
    animation-delay: 0.8s;
  }
  &:nth-of-type(5) button{
    animation-delay: 1s;
  }
  &.open {
    animation: red-packages-open 2s linear;
  }
}
@keyframes red-packages-shake {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(10px); }
  100% { transform: translateY(0); }
}

@keyframes red-packages-open {
  12%  { transform: rotate(5deg); }
  25%  { transform: rotate(0); }
  37%  { transform: rotate(-5deg); }
  50% { transform: rotate(0); }
  62%  { transform: rotate(5deg); }
  75%  { transform: rotate(0); }
  87%  { transform: rotate(-5deg); }
  100% { transform: rotate(0); }
}
</style>
