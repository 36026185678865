export default class Dialog {
  constructor () {
    this.show = false
    this.view = null
    this.onClose = null
    this.options = {
      position: {
        x: 'center',
        y: 'flex-start'
      },
      onBackgroundClick: () => this.close()
    }
  }

  open (view, options) {
    document.body.style.overflow = 'hidden'
    this.show = true
    this.view = view
    this.options = Object.assign(this.options, options)
    return new Promise(resolve => {
      this.onClose = resolve
    })
  }

  close () {
    // console.log('close', close)
    document.body.style.overflow = ''
    // this.options.attrs.close = close
    this.onClose(this.options.attrs)
    this.view = null
    this.attrs = this.options
    this.show = false
  }

  closeDialog () {
    document.body.style.overflow = ''
    this.attrs = this.options
    this.show = false
  }
}
