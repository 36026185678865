export default {
  mount: '.icon-view-agenda',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'currentColor'
  },
  path: `
    <path d="M20,13 L3,13 C2.45,13 2,13.45 2,14 L2,20 C2,20.55 2.45,21 3,21 L20,21 C20.55,21 21,20.55 21,20 L21,14 C21,13.45 20.55,13 20,13 Z M20,3 L3,3 C2.45,3 2,3.45 2,4 L2,10 C2,10.55 2.45,11 3,11 L20,11 C20.55,11 21,10.55 21,10 L21,4 C21,3.45 20.55,3 20,3 Z" fill="#424242"/>
  `
}
