<template lang="pug">
  div(class="px-2 col mt-2")
    div(class="row no-gutters mx-n2")
      div(class="col-auto px-2 mt-2" :style="titleStyle" :class="titleClass" v-if="title")
        div(class="my-2") {{ title }}
      div(class="col-12 col-lg-auto p-0")
      div(class="col px-2")
        div(class="row flex-column flex-md-row no-gutters align-items-end mx-n2 pl-4 pl-sm-5 p-md-0")
          div(class="col my-2 px-2 max-w-md-160")
            InputBox(class="" type="text" placeholder="郵遞區號" :model="model" handle="postal_code" readonly @change="submitChanges" @input="input($event, model)" inputmode="numeric")
          div(class="col my-2 px-2 max-w-md-160")
            select(class="custom-select" :class="{'is-invalid': hasError(model, 'city')}" v-model="model.city" @change="change($event, model)")
              option(v-for="city in citys" :key="city.city_eng_name" :value="city.city_name") {{ city.city_name }}
          div(class="col my-2 px-2 max-w-md-160")
            select(class="custom-select" :class="{'is-invalid': hasError(model, 'district')}" v-model="model.district" @change="change($event, model)")
              option(v-for="area in selectDistrictFilter(model)" :key="area.area_name" :value="area.area_name") {{ area.area_name }}
          div(class="col my-2 px-2")
            InputBox(class="w-100" type="text" placeholder="請輸入詳細地址" :model="model" handle="address" @change="submitChanges" @input="joinAddressString" :watch="true")
        ErrorMessage(class="mt-0 text-right text-md-left" :model="model" handle="address")
</template>

<script>
// import { v4 as uuidv4 } from 'uuid'
import { DataModel } from '../../library/proto'
import citys from './city'

export default {
  name: 'AddressEditor',
  data () {
    return {
      citys
    }
  },
  props: {
    model: {
      type: DataModel,
      default: new DataModel()
    },
    title: {
      type: String,
      default: ''
    },
    titleStyle: {
      type: Object,
      default: () => ({})
    },
    titleClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    selectDistrictFilter (model) {
      const city = this.citys.find(p => p.city_name === model.city)
      if (city) {
        return city.area_list
      }
      return []
    },
    input (e, model) {
      // console.log('input', this.citys)
      const city = this.citys.find(c => c.area_list.map(p => p.zip_code.substring(0, 1)).includes(model.postal_code.substring(0, 1)))
      if (city) {
        model.city = city.city_name
        const area = city.area_list.find(p => p.zip_code === model.postal_code.substring(0, 3))
        if (area) {
          model.district = area.area_name
        }
        this.joinAddressString()
      }
      this.submitInputs()
    },
    change (e, model) {
      // console.log('change', e)
      console.log(model)
      // console.log(this.citys)

      const city = this.citys.find(p => p.city_name === model.city)
      if (city) {
        const area = city.area_list.find(p => p.area_name === model.district)
        if (area) {
          model.postal_code = area.zip_code + String(model.postal_code).substring(3)
        }
        this.joinAddressString()
      }
      this.submitChanges(e)
    },
    submitInputs (e) {
      this.$emit('input', e)
    },
    submitChanges (e) {
      // console.log(e)
      this.$emit('change', e)
    },
    submit (e) {
      if (e instanceof KeyboardEvent && e.key === 'Enter') {
        this.$emit('submit')
      }
    },
    joinAddressString () {
      console.log('model', this.model)
      const model = this.model

      this.model.validate({
        // address: () => {
        //   // eslint-disable-next-line no-control-regex
        //   if (model.address.match(/[\u4e00-\u9fa5]/g)) {
        //     return { inclusion: { message: '^請確實填寫收貨地址，勿填入特殊符號 (限半形，中英文及數字還有括弧)\n' } }
        //   }
        //   // model.address = this.ToDBC(model.address)
        //
        //   return {
        //     address: {
        //       message: '^限半形'
        //     }
        //   }
        // }
      })
      console.log('model.address', model.address)
      // model.address = this.toASCII(model.address)
      model.addressString = (model.postal_code || '') + ' ' + (model.city || '') + (model.district || '') + (model.address || '')
    }
  }
}
</script>
<style lang="scss" scoped>
  .custom-select{
    text-align-last: left;
    max-width: none;
  }
  @media (min-width: 768px) {
    .max-w-md-160 {
      max-width: 160px;
    }
  }
</style>
