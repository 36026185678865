<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px);")
    div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="d-flex justify-content-between position-relative pt-xl-3")
        div(class="d-flex")
          h4(class="font-weight-bold text-left") 點數查詢
      div(class="d-flex justify-content-center mt-4 pt-3" v-if="false")
        div(class="text-center")
          div 您到 <span class="text-primary font-weight-bold">2021/12/31</span> 為止
          div 輕鬆ＧＯ!!渡假+研習會 累積點數是
          div(class="mt-4 pt-3") <span class="text-primary font-weight-bold point-text">{{ !UserData.pv ? 0 : commaFormat(UserData.pv.pre_tour_pv) }}</span> 點
      div(class="mt-4 pt-3 text-dark" v-if="false")
        div 說明：
        div 1. 點數計算結果以電腦運算為主
        div 2. 活動期間內，累積點數達6,900點(含)。即可免費招待1位，超過全額補助點數不再計算。
        div 3. 活動期間內，累積點數達1,000點(含)以上者，方有資格領取補助金額，每一點折合新台幣1元，不足1,000點則不予補助。
      div(class="d-flex justify-content-center mt-4 pt-3")
        div(class="text-primary font-weight-bold point-text")
          div 暫無資料
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Points',
  data () {
    return {}
  },
  created () {
    console.log('UserData', this.UserData)
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
    }
  },
  mounted () {

  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    })
  }
}
</script>

<style lang="scss" scoped>
.point-text{
  font-size: 80px;
  font-family: 'DIN Condensed', sans-serif;
}
</style>
