<template lang="pug">
  footer(class="footer border-top text-dark py-4 px-3")
    div(class="container")
      div(class="row align-items-end")
        div(class="col-12 d-flex justify-content-center py-3 py-xl-5")
          div(class="logo")
            img(v-if="SystemImages.loaded" :src="SystemImages.data[0].image" width="67" height="98")
            Icon(v-else class="btn-icon" pattern="Logo" size="67,98")
          div(class="navbar d-xl-flex d-none flex-grow-1")
            ul(class="d-flex justify-content-around flex-grow-1")
              li(class="" v-for="route in routeRender(getNodeTree)" :key="route.name")
                a(class="font-weight-bold transition-ease" v-if="route.isRender") {{ route.meta.title }}
                router-link(class="font-weight-bold transition-ease hover-opacity" :to="route.path" v-else) {{ route.meta.title }}
                ul(class="" v-if="route.children && route.children.length")
                  li(class="" v-for="child in routeRender(route.children)" :key="child.name")
                    router-link(class="transition-ease hover-opacity" :to="child.path"  v-if="route.isRender") {{ child.meta.title }}
        div(class="col-xl-4 d-flex justify-content-center justify-content-xl-end order-xl-3")
          div()
            div(class="d-flex justify-content-center justify-content-xl-end mb-3" v-if="SystemRights.loaded")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_fb" :href="SystemRights.data[0].link_fb || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Facebook" size="24")
                Icon(class="mx-2" pattern="FacebookNative" size="24")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_line" :href="SystemRights.data[0].link_line || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Line" size="24")
                Icon(class="mx-2" pattern="LineNative" size="24")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_ig" :href="SystemRights.data[0].link_ig || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Instagram" size="24")
                img(class="mx-2" src="@/assets/images/Instagram.png" width="24")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_twitter" :href="SystemRights.data[0].link_twitter || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Twitter" size="24")
                Icon(class="mx-2" pattern="TwitterNative" size="24")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_wechat" :href="SystemRights.data[0].link_wechat || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Wechat" size="24")
                Icon(class="mx-2" pattern="WechatNative" size="24")
              a(class="btn btn-icon text-secondary transition-ease" v-if="SystemRights.data[0].link_youtube" :href="SystemRights.data[0].link_youtube || 'javascript:;' ")
                //- Icon(class="mx-2" pattern="Youtube" size="24")
                Icon(class="mx-2" pattern="YoutubeNative" size="24")
            div(class="d-flex mx-1 transition-ease")
              div(class="" v-for="(count,index) in browseCount" :key="index")
                div(class="text-center mx-1 py-1 browse-number text-white") {{ count }}
        div(class="col-xl-8 text-center text-xl-left pt-4 pt-xl-0" v-if="SystemRights.loaded")
          div(class="transition-ease" v-if="SystemRights.data[0].address")
            span 地址：
            span {{ SystemRights.data[0].address }}
          div(class="d-flex flex-column flex-xl-row justify-content-center justify-content-xl-start")
            span(class="d-block py-1 transition-ease" v-if="SystemRights.data[0].telphone")
              span 電話：
              span {{ SystemRights.data[0].telphone }}
            div(class="vertical-line bg-light mx-2 transition-ease" v-if="SystemRights.data[0].fax && SystemRights.data[0].telphone")
            span(class="d-block py-1 transition-ease" v-if="SystemRights.data[0].fax")
              span 傳真：
              span {{ SystemRights.data[0].fax }}
            div(class="vertical-line bg-light mx-2 transition-ease" v-if="SystemRights.data[0].email")
            span(class="d-block py-1 transition-ease" v-if="SystemRights.data[0].email")
              span 電子信箱：
              span {{ SystemRights.data[0].email }}
          div(class="fs-12 pt-4 pt-xl-0 transition-ease") {{ SystemRights.data[0].declare }}
</template>
<script>
import getNodeTree from '../node-tree'
import { mapState } from 'vuex'
import { getBrowseCount } from '@/api'

export default {
  name: 'Footer',
  data () {
    return {
      browseData: 0
    }
  },
  computed: {
    getNodeTree () {
      const nodeTree = getNodeTree.call(this, this.global.router)
      this.Categories.data.forEach(item => {
        const route = {
          name: `ProducteList-${item.id}`,
          path: item.id <= 0 ? '/product/list' : `/product/list?category_id=${item.id}`,
          meta: {
            title: item.name
          }
        }
        const nodeTree = this.$router.options.routes.find(r => r.name === 'Product').children
        const cacheRoute = nodeTree.find(r => r.name === route.name)
        if (cacheRoute) {
          Object.keys(cacheRoute).forEach(key => {
            cacheRoute[key] = route[key]
          })
        } else {
          nodeTree.push(route)
        }
      })
      return nodeTree
    },
    browseCount () {
      const arr = new Array(9).fill('0')
      const str = String(this.browseData)
      str.split('').reverse().forEach((s, i) => {
        arr[i] = s
      })
      const count = arr.reverse()
      // console.log('this.browseData,', this.browseData)
      return count
    },
    ...mapState({
      global: state => state.global,
      Seometas: state => state.model.Seometas,
      Categories: state => state.model.Categories,
      SystemImages: state => state.model.SystemImages,
      SystemRights: state => state.model.SystemRights
    })
  },
  methods: {
    isRouteRender (route) {
      // console.log(route.children)
      return route.children && route.children.filter(p => !p.meta.hidden).length > 1
    },
    isNextPath (route) {
      return route.children && route.children.length >= 1 && !/^:/.test(route.children[0].path)
    },
    routeRender (routes) {
      // console.log('UserData,', this.UserData.id)
      return routes.filter(p => {
        if (!p.meta) {
          return true
        }
        return !p.meta.hidden
      }).map(route => {
        return {
          ...route,
          isRender: this.isRouteRender(route),
          path: this.isNextPath(route) ? route.children[0].path : route.path
        }
      })
    }
  },
  updated () {
    getBrowseCount().then(res => {
      this.browseData = res.data
    })
  },
  mounted () {
    getBrowseCount().then(res => {
      this.browseData = res.data
    })
  }
}
</script>
<style lang="scss" scoped>
  .navbar{
    > ul{
      list-style:  none;
      > li{
        > a{
          color: #979797;
          padding: 0.5rem 0;
          display: block;
        }
        > ul{
          padding: 0;
          list-style:  none;
          > li{
            > a{
                color: #979797;
                padding: 0.5rem 0;
                display: block;
            }
          }
        }
      }
    }
  }
  .browse-number{
    background-color: #979797;
    width: 20px;
    font-size: 16px;
    @media (min-width: 576px){
      width: 28px;
      font-size: 20px;
    }
  }
  .hover-opacity{
    &:hover {
      font-weight: bold;
      color: #424242;
    }
  }
</style>
