<template lang="pug">
  div(class="pt-5")
    div(class="px-xl-5 mx-xl-3" v-for='(item,index) in model.web_children')
      div(class='border rounded border-success px-4 mb-2')
        button(class="btn-panel py-2 stretched-link position-relative" :class="{active: item.active}"  type="button" @click="toggleSlide(index)")
          h5(class="mb-0" :style="{fontSize: global.fontSize + 'px'}") {{ item.title }}
          Icon( class="text-dark arrow" pattern="ArrowRight" size="24")
        div(class='border-top' ref="title" :style="{fontSize: global.fontSize + 'px'}")
          div(class='pt-2 articel-content' v-html='item.content')
</template>

<script>
import { MultilevelArticleModel } from '../../models'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Third',
  data () {
    return {
      model: new MultilevelArticleModel(),
      sliding: false
    }
  },
  watch: {
    $route (to, from) {
      this.getModel().then(this.setGroup)
    }
  },
  computed: {
    ...mapState({
      global: state => state.global
    }),
    getModelById () {
      return this.$route.meta.api
    }
  },
  mounted () {
    this.getModel().then(this.setGroup)
  },
  methods: {
    ...mapMutations(['setFontSize', 'setAnimationGroup']),
    setGroup (model) {
      // 深拷貝出 this.model.web_children，防止選單開合記憶
      const slideGroup = model.web_children.map(item => {
        return { ...item }
      })
      this.setAnimationGroup(slideGroup)
      let i = 0
      const callback = doms => {
        slideGroup.forEach((item, index) => {
          item.element = doms[index]
        })
        this.global.animationGroup.forEach((el, index) => {
          this.$store.dispatch('toggleAnimationTarget', {
            index,
            element: doms[index]
          })
        })
      }
      const renderCheck = () => {
        if (this.$refs.title && i < 60) {
          const titles = this.$refs.title
          callback(titles)
          return
        }
        i++
        requestAnimationFrame(renderCheck)
      }
      renderCheck()
    },
    getModel () {
      return new Promise(resolve => {
        const model = this.$route.meta.model
        if (model && model.content) {
          this.model = new MultilevelArticleModel(model)
          return resolve(this.model)
        }
        this.getModelById(this.$route.meta.articleId).then(res => {
          this.$route.meta.model = this.model = new MultilevelArticleModel(res.data)
          resolve(this.model)
        })
      })
    },
    toggleSlide (index) {
      if (this.sliding) {
        return
      }
      this.sliding = true
      this.$store.dispatch('toggleAnimationTarget', {
        index,
        animation: {
          type: 'slide'
        },
        element: this.$refs.title[index],
        callback: () => {
          this.sliding = false
        }
      })
    }
  }
}
</script>
