<template lang="pug">
  div(class="py-2")
    div(class="container-fluid")
      div(class="row ro2222")
        div(class="col-12 col-lg-auto py-2" v-if="model.ruleable_type.includes('RuleProduct') && model.ruleable.selected_gift && model.ruleable.selected_gift - activityItemTotle >= 0 && edit === true")
          button(class="btn btn-success" style="width: 180px;" @click="openGiftDialog")
            span(v-if="model.ruleable.selected_gift - activityItemTotle")
              span 可選擇
              span {{ model.ruleable.selected_gift - activityItemTotle }}
              span 個贈品
            span(v-else) 重新選擇贈品
        div(class="col-12 col-lg py-2" v-show='showText')
          div(v-html="model.description")
          div(class="text-danger small" v-if="model.ruleable.remaining_quota <= 0 && ruleable_type == 'RuleRaffle'") 本次活動已結束
          div(class="text-danger small" v-else-if="model.ruleable.remaining_quota <= 0 && ruleable_type != 'RuleRaffle'") 活動贈品已贈送完畢
          div(class="text-danger small" v-else-if="!hasLimit")
          div(v-else)
            div(class="text-danger small" v-if="iterative  > maxIterative && remaining_quota >= maxIterative && ruleable_type == 'RuleRaffle'") 提醒您！消費積分已達活動單張訂單最多可抽獎次數，建議您可拆單訂購
            div(class="text-danger small" v-else-if="iterative > remaining_quota && ruleable_type == 'RuleRaffle'") 提醒您！目前剩餘抽獎次數不足，僅能獲得部分贈品
            div(class="text-danger small" v-if="iterative > maxIterative && remaining_quota >= giftQuantity && ruleable_type == 'RuleFixedPv'") 提醒您！消費積分已達活動單張訂單最多贈品數量，建議您可拆單訂購
            div(class="text-danger small" v-else-if="giftQuantity > remaining_quota && ruleable_type == 'RuleFixedPv'") 提醒您！目前贈品數量不足，僅能獲得部分贈品
            div(class="text-danger small" v-if="giftQuantity > remaining_quota && ruleable_type == 'RuleProduct'") 提醒您！目前贈品數量不足，僅能獲得部分贈品
            div(class="text-danger small" v-if="giftQuantity > remaining_quota && ruleable_type == 'RuleRangePv'") 提醒您！目前贈品數量不足，僅能獲得部分贈品
        div()
    div(class="")
      //ActivitieProducts(v-for="item in model.activity_items" :model="item" :key="item.id")
</template>

<script>
import { mapState } from 'vuex'
import { ActivitieModel } from '../../models'
import ActivitieProducts from './ActivitieProducts.vue'
import GiftProduct from '../../views/dialog/GiftProduct.vue'
import { Toast } from '@/utils/helper'

export default {
  name: 'ActivitieGrid',
  props: {
    model: {
      type: ActivitieModel,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    hasLimit: {
      type: Boolean,
      default: false
    },
    showText: {
      type: Boolean,
      default: false
    },
    updateView: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      iterative: 0,
      dialogIsShow: 0,
      maxIterative: 0,
      remaining_quota: 0,
      giftQuantity: 0,
      ruleable_type: ''
    }
  },
  updated () {
    // console.log('updated ActivitieModel', this.model)
    this.init()
  },
  created () {
    // console.log('created ActivitieModel', this.model.activity_items)
    this.init()
  },
  computed: {
    ...mapState({
      ShopCarts: state => state.model.ShopCarts
    }),
    activityItemTotle () {
      return this.model.activity_items.reduce((a, item) => Number(a) + Number(item.gift_quantity), 0)
    }
  },
  methods: {
    init () {
      if (this.model.ruleable_type.includes('RuleFixedPv')) {
        this.ruleable_type = 'RuleFixedPv'
        return this.mathRuleFixedPv(this.model)
      }
      if (this.model.ruleable_type.includes('RuleRangePv')) {
        this.ruleable_type = 'RuleRangePv'
        return this.mathRuleRangePv(this.model)
      }
      if (this.model.ruleable_type.includes('RuleProduct')) {
        this.ruleable_type = 'RuleProduct'
        return this.mathRuleProduct(this.model)
      }
      if (this.model.ruleable_type.includes('RuleRaffle')) {
        this.ruleable_type = 'RuleRaffle'
        return this.mathRuleRaffle(this.model)
      }
    },
    mathRuleProduct () {
      const activitie = this.model
      this.remaining_quota = activitie.ruleable.remaining_quota || 0 // 還剩下數量
      const perBuyQuantity = activitie.ruleable.per_buy_quantity// 買幾個
      const quantity = activitie.ruleable.per_freebie_quantity// 送幾個
      const totalQuantity = this.inShopCartProduct(activitie).reduce((quantity, shopcart) => quantity + shopcart.product_quantity, 0)
      this.giftQuantity = Math.floor(totalQuantity / perBuyQuantity) * quantity// 可以送幾個

      if (this.dialogIsShow === 1) return
      const seg = window.location.pathname.split('/')
      if (seg[seg.length - 1] !== '1') return

      if (this.model.ruleable.remaining_quota <= 0 && this.ruleable_type === 'RuleProduct') {
        Toast.fire({
          icon: 'warning',
          title: '贈品已全數贈送完畢'
        })
        return
      }

      if (this.giftQuantity > this.remaining_quota && this.ruleable_type === 'RuleProduct') {
        Toast.fire({
          icon: 'warning',
          title: '提醒您！目前贈品數量不足，僅能獲得部分贈品'
        })
      }
    },
    mathRuleFixedPv () {
      const activitie = this.model
      this.remaining_quota = activitie.ruleable.remaining_quota || 0 // 還剩下數量
      if (this.isActivityLimit(activitie)) {
        this.maxIterative = activitie.ruleable.max_iterative || 0
        const perBuyPv = activitie.ruleable.per_buy_pv
        const quantity = activitie.ruleable.per_freebie_quantity
        const freebie = activitie.ruleable.freebie
        let pv = 0
        if (this.remaining_quota > 0) {
          this.iterative = this.inShopCartProduct(activitie).reduce((acc, shopcart) => {
            pv += shopcart.products.pv * shopcart.product_quantity
            return Math.floor(pv / perBuyPv)
          }, 0)
          // if (process.env.NODE_ENV === 'development') console.log('iterative > maxIterative', iterative, maxIterative)
          // console.log('maxIterative', this.maxIterative)
          // console.log('iterative', this.iterative)

          if (freebie) {
            if (this.iterative > this.maxIterative) {
              this.giftQuantity = this.maxIterative * quantity
            } else {
              this.giftQuantity = this.iterative * quantity
            }
            freebie.gift_quantity = this.giftQuantity
            if (this.giftQuantity > this.remaining_quota) {
              freebie.gift_quantity = this.remaining_quota
            }

            // console.log('inin giftQuantity', this.giftQuantity)
            // console.log('inin this.remaining_quota', this.remaining_quota)
            // console.log('inin ruleable_type', this.ruleable_type)
          }
        } else {
          if (this.model.ruleable.remaining_quota <= 0 && this.ruleable_type === 'RuleFixedPv') {
            Toast.fire({
              icon: 'warning',
              title: '本次活動已結束'
            })
          }
        }

        if (this.iterative > this.maxIterative && this.remaining_quota >= this.giftQuantity && this.ruleable_type === 'RuleFixedPv') {
          Toast.fire({
            icon: 'warning',
            title: '提醒您！消費積分已達活動單張訂單最多贈品數量，建議您可拆單訂購'
          })
        } else if (this.giftQuantity > this.remaining_quota && this.ruleable_type === 'RuleFixedPv') {
          Toast.fire({
            icon: 'warning',
            title: '提醒您！目前贈品數量不足，僅能獲得部分贈品'
          })
        }
      }
    },
    mathRuleRangePv () {
      const activitie = this.model
      if (this.isActivityLimit(activitie)) {
        this.remaining_quota = activitie.ruleable.remaining_quota || 0
        if (this.remaining_quota > 0) {
          const freebiesRange = activitie.ruleable.freebies_range
          // console.log('in freebiesRange', freebiesRange)
          const totalPv = this.inShopCartProduct(activitie).reduce((pv, shopcart) => {
            return pv + shopcart.products.pv * shopcart.product_quantity
          }, 0)
          const freebie = freebiesRange.find((f) => {
            // if (process.env.NODE_ENV === 'development') console.log('mathRuleRangePv:Range', `totalPv:${totalPv} >= pv_start:${f.pv_start} && totalPv:${totalPv} <= pv_end:${f.pv_end}`, totalPv >= f.pv_start && totalPv <= f.pv_end)
            return totalPv >= f.pv_start && totalPv <= f.pv_end
          })
          if (freebie) {
            if (freebie.gift_quantity > this.remaining_quota) {
              this.giftQuantity = freebie.gift_quantity
              // console.log('inin giftQuantity', this.giftQuantity)
              freebie.gift_quantity = this.remaining_quota
            }
          }
        } else {
          if (this.model.ruleable.remaining_quota <= 0 && this.ruleable_type === 'RuleRangePv') {
            Toast.fire({
              icon: 'warning',
              title: '本次活動已結束'
            })
          }
        }
        if (this.giftQuantity > this.remaining_quota && this.ruleable_type === 'RuleRangePv') {
          Toast.fire({
            icon: 'warning',
            title: '提醒您！目前贈品數量不足，僅能獲得部分贈品'
          })
        }
      }
    },
    mathRuleRaffle () {
      const activitie = this.model
      this.remaining_quota = activitie.ruleable.remaining_quota || 0 // 還剩下數量
      this.maxIterative = activitie.ruleable.max_iterative || 0
      const perBuyPv = activitie.ruleable.per_buy_pv
      if (perBuyPv > 0) {
        let pv = 0
        this.iterative = this.inShopCartProduct(activitie).reduce((acc, shopcart) => {
          pv += shopcart.products.pv * shopcart.product_quantity
          return Math.floor(pv / perBuyPv)
        }, 0)
        // console.log('iterative', this.iterative) // 訂單可抽獎次數
      }
      // console.log('remaining_quota', this.remaining_quota) // 訂單可抽獎次數

      // console.log('inin giftQuantity', this.giftQuantity)
      // console.log('maxIterative', this.maxIterative) // 訂單可抽獎次數
      if (this.iterative > this.maxIterative && this.remaining_quota >= this.maxIterative && this.ruleable_type === 'RuleRaffle') {
        Toast.fire({
          icon: 'warning',
          title: '提醒您！消費積分已達活動單張訂單最多可抽獎次數，建議您可拆單訂購'
        })
      } else if (this.model.ruleable.remaining_quota <= 0 && this.ruleable_type === 'RuleRaffle') {
        Toast.fire({
          icon: 'warning',
          title: '本次活動已結束'
        })
      } else if (this.iterative > this.remaining_quota && this.ruleable_type === 'RuleRaffle') {
        Toast.fire({
          icon: 'warning',
          title: '提醒您！目前剩餘抽獎次數不足，僅能獲得部分贈品'
        })
      }
    },
    isActivityLimit (activitie) { // 判斷是否參加活動
      if (process.env.NODE_ENV === 'development') {
        // console.log(activitie.title, 'isActivityLimit', activitie.ruleable.activity_limit_products.map(p => p.id))
        // console.log(activitie.title, 'isActivityLimit', this.ListModel.data.map(p => `${p.products.id}:${p.products.name}:${p.products.product_number}`))
      }
      return this.ShopCarts.data.some(shopcart => {
        return activitie.ruleable.activity_limit_products.some(p => Number(p.id) === Number(shopcart.product_id))
      })
    },
    inShopCartProduct (activitie) { // 能夠參與該活動的商品
      const activityProductsId = activitie.ruleable.activity_products.map(p => Number(p.id))
      if (process.env.NODE_ENV === 'development') {
        // console.log(activitie.title, 'inShopCartProduct', activityProductsId)
        // console.log(activitie.title, 'inShopCartProduct', this.ListModel.data.map(p => `${p.products.id}:${p.products.name}:${p.products.product_number}`))
      }
      return this.ShopCarts.data.filter(p => activityProductsId.includes(Number(p.products.id)))
    },
    async openGiftDialog () {
      this.dialogIsShow = 1
      await this.$dialog.open(GiftProduct, {
        size: '500px',
        onBackgroundClick: false,
        attrs: {
          model: this.model
        }
      }).then(attrs => {
        // callback function
        this.updateView()
        this.dialogIsShow = 0
        console.log('attrs', attrs)
        console.log('model', this.model.activity_items)
      })
    }
  },
  components: {
    ActivitieProducts
  }
}
</script>
