import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
*/

export default class ContentModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.sort = entity.sort || 0
    this.content = entity.content || ''
    this.started_at = entity.started_at || ''
    this.finished_at = entity.finished_at || ''
    this.updated_user = entity.updated_user || ''
    this.status = entity.status || 0
  }
}
