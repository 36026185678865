<template lang="pug">
  div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
    div(class="d-flex justify-content-between position-relative pt-xl-3")
      div(class="d-flex")
        h4(class="font-weight-bold text-left") 購物紀錄
    div(class="d-flex align-items-center flex-column flex-sm-row py-1" style="max-width: 576px;")
      //- div(class="d-flex align-items-center py-1 w-100")
        input(class="form-control" type="date" style="min-width: 0;" v-model="search._from" :min="minDate" :max="maxDate")
      DatePicker(v-model="search._from" valueType="format" :disabled-date="startDate")
      div(class="bg-success m-2 flex-shrink-0" style="width: 16px;height: 2px;")
      DatePicker(v-model="search._to" valueType="format" :disabled-date="endDate")
      //- div(class="d-flex align-items-center py-1 w-100")
        input(class="form-control" type="date" style="min-width: 0;" v-model="search._to" :min="minDate" :max="maxDate")
      button(class="btn btn-icon m-2 flex-shrink-0" @click="clickSearch")
        Icon(class="btn-icon text-dark" pattern="Search" size="24")
    div(class="py-2")
      div(class="d-grid grid-table border" v-if="ListModel.data.length" style="grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr;")
        div(class="text-center title") 訂單編號
        div(class="text-center title") 下單時間
        div(class="text-center title") 訂單金額
        div(class="text-center title") 訂單積分
        div(class="text-center title") 訂單狀態
        div(class="text-center title") 發票號碼
        div(class="text-center title") 取消訂單
        div(class="grid-span line d-none d-lg-block")
        div(class="d-grid grid-tr grid-span fs-lg-16 fs-14 position-relative" v-for="model in ListModel.data" :key="model.id")
          div(class="px-3 py-2 d-lg-none font-weight-bold") 訂單編號
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            router-link(class="py-lg-3 py-2 font-weight-bold text-success stretched-link" :to="'/member/order/' + model.id ") {{ model.order_number }}
          div(class="px-3 py-2 d-lg-none font-weight-bold") 下單時間
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            div(class="py-lg-3 py-2") {{ dayFormat(model.created_at, 'YYYY-MM-DD HH:mm:ss') }}
          div(class="px-3 py-2 d-lg-none font-weight-bold") 訂單金額
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            div(class="py-lg-3 py-2") NT {{ commaFormat(model.total_price) }}
          div(class="px-3 py-2 d-lg-none font-weight-bold") 訂單積分
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            div(class="py-lg-3 py-2") {{ commaFormat(model.total_pv) }}
          div(class="px-3 py-2 d-lg-none font-weight-bold") 訂單狀態
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            div(class="py-lg-3 py-2") {{ convOrderStateByCode(model.order_status) }}
          div(class="px-3 py-2 d-lg-none font-weight-bold") 發票號碼
          div(class="d-flex justify-content-lg-center align-items-lg-center")
            div(class="py-lg-3 py-2") {{ model.invoice_number }}
          template(v-if="isCancelOrder(model)")
            div(class="grid-span d-lg-none")
              div(class="p-3 btn-position")
                button(class="btn btn-outline-success w-100" type="button" @click="cancelOrder(model.id)") 取消訂單
            div(class="d-lg-flex d-none justify-content-lg-center align-items-lg-center")
              div(class="py-3 btn-position")
                button(class="btn btn-icon text-dark" type="button" @click="cancelOrder(model.id)")
                  Icon(class="btn-icon rotate-45" pattern="Add" size="24")
          div(class="grid-span line")
    div(class="d-flex justify-content-center py-3" v-if="this.ListModel.last_page > 1")
      PaginationList(v-bind="paginationSettings")
  //-     PaginationList(
  //- :page-count="20"
  //- :click-handler="functionName"
  //- :prev-text="'Prev'"
  //- :next-text="'Next'"
  //- :container-class="'pagination'")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { updateOrders, getOrders, getOrdersSearch } from '../../../../api'
import { OrderModel } from '../../../../models'
import { ListModel } from '../../../../library/proto'
import * as Icon from '../../../../components/icon'
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import { Toast } from '@/utils/helper'

export default {
  name: 'OrderList',
  components: {
    DatePicker
  },
  data () {
    const minDateDefault = dayjs().format(`${dayjs().format('YYYY') - 1}-01-01`)
    const maxDateDefault = dayjs().format('YYYY-MM-DD')
    return {
      ListModel: new ListModel({
        model: OrderModel
      }),
      search: {
        page: 1,
        _type: '',
        _from: '',
        _to: ''
      },
      // currentPageNum: this.search.page,
      minDateDefault,
      maxDateDefault,
      minDate: minDateDefault,
      maxDate: maxDateDefault
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
      return
    }
    // console.log('created 1', this.search)
    this.getOrders()
    // this.changePage(this.ListModel.current_page)
    // console.log('created ListModel:', this.ListModel)
    // console.log('created', this.search)
    // console.log(this.currentPageNum)
  },
  computed: {
    ...mapState({
      Orders: state => state.model.Orders,
      UserData: state => state.model.UserData
    }),
    paginationSettings () {
      // console.log('paginationSettings')
      // console.log(this.changePage)
      const arrow = JSON.parse(JSON.stringify(Icon.ArrowRight))
      const arrowAttrs = Object.keys(arrow.attrs).map(key => ` ${key}="${arrow.attrs[key]}"`).join('')
      // console.log('paginationSettings:', this.$route.query.page)
      // console.log('ListModel_current_page', this.ListModel.current_page)
      // console.log('this.Search:', this.search.page)
      return {
        // forcePage: Number(this.$route.query.page) || 1,
        // forcePage: Number(this.ListModel.current_page),
        // forcePage: Number(this.changePage),
        value: Number(this.search.page),
        // forcePage: Number(this.search.page),
        pageCount: this.ListModel.last_page,
        prevText: `<div><svg${arrowAttrs}><g transform="rotate(180 12 12)">${arrow.path}</g></svg></div>`,
        nextText: `<div><svg${arrowAttrs}>${arrow.path}</svg></div>`,
        containerClass: 'pagination',
        pageClass: 'pagination-item',
        prevClass: 'pagination-prev',
        nextClass: 'pagination-next',
        clickHandler: this.changePage
      }
    }
  },
  watch: {
    $route (to) {
      console.log('watch', to)
      this.getOrders()
    }
  },
  methods: {
    functionName (page) {
      console.log(page)
      return page
    },
    ...mapActions(['getListModel', 'getModelById']),
    startDate (date) {
      return dayjs(date).isBefore(this.minDate)
    },
    endDate (date) {
      return dayjs(date).isAfter(this.maxDate)
    },
    async getOrders () {
      // console.log('getOrders')
      // console.log('getOrders', Number(this.$route.query.page))
      this.search = Object.assign({
        page: Number(this.$route.query.page) || 1,
        _type: this.$route.query._from || this.$route.query._to ? 'orderDate' : '',
        _from: this.$route.query._from === '1900-01-01' ? '' : (this.$route.query._from || ''),
        _to: this.$route.query._to === '9999-12-31' ? '' : (this.$route.query._to || '')
      })
      // console.log('this.search', this.search)
      if (this.$route.query.page || this.$route.query._from || this.$route.query._to) {
        console.log('getOrdersIf')
        // console.log('getOrdersIf this.search.page:', this.search.page)
        const res = await getOrdersSearch({
          _from: this.$route.query._from + ' 00:00:00',
          _to: this.$route.query._to + ' 23:59:59',
          _type: this.$route.query._type,
          page: this.search.page || 1
        })
        this.ListModel.set(res.data)
      } else {
        console.log('getOrdersElse')
        const res = await getOrders()
        this.ListModel.set(res.data)
      }
      // console.log(this.ListModel.current_page)
      return this.ListModel
    },
    convOrderStateByCode (code) {
      switch (Number(code)) {
        case 1: return '已訂購未付款'
        case 2: return '信用卡處理中'
        case 3: return '已付款完成'
        case 4: return '已付款未出貨'
        case 5: return '備貨中'
        case 6: return '已出貨'
        case 7: return '黑貓配送中'
        case 8: return '已送達'
        case 9: return '取消訂單'
        case 10: return '已退貨'
        case 11: return '信用卡交易失敗'
        case 23: return '錯誤交易'
        default: return ''
      }
    },
    beforeUpdate () {
      this.getOrders()
    },
    isCancelOrder (model) {
      if (model.payment === 'ATM') {
        return model.order_status < 4
      }
      return model.order_status < 5
    },
    cancelOrder (id) {
      Toast.fire({
        title: '確定取消訂單?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          updateOrders(id, {
            order_status: 9
          }).then(() => {
            const order = this.ListModel.data.find(p => Number(p.id) === id)
            if (order) {
              order.order_status = 9
            }
          })
        }
      })
    },
    changePage (page) {
      // console.log('changePage', page)
      this.search.page = page
      this.routerLink()
    },
    searchQuery (query = {}) {
      // console.log('searchQuery-query', query)
      const search = {}
      if (query.page) {
        search.page = query.page
      }
      if (query._from === '' || query._from === undefined) {
        search._from = '1900-01-01'
      } else {
        search._from = query._from
      }
      if (query._to === '' || query._to === undefined) {
        search._to = '9999-12-31'
      } else {
        search._to = query._to
      }
      if (query._type) {
        search._type = query._type
      }
      if (query._to || query._from) {
        search._type = 'orderDate'
      }
      return search
    },
    clickSearch () {
      // console.log('clickSearch', this.$route.query)
      this.search = Object.assign({
        page: 1,
        _type: 'orderDate',
        _from: this.search._from,
        _to: this.search._to
      })

      this.routerLink()
    },
    routerLink () {
      // console.log('routerLink', this.search)
      this.$router.push({
        query: this.searchQuery(this.search)
      })
    }
  }
}
</script>

<style lang="scss">
  .grid-table{
    .title {
      padding: 12px 0;
      @media (max-width: 991.5px) {
        display: none;
      }
    }
  }
  .grid-tr{
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 991.5px) {
      grid-template-columns: auto 1fr;
    }
  }
  .btn-position {
    position: relative;
    z-index: 2;
  }
</style>
