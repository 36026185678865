<template lang="pug">
  div(class="lighbox position-relative p-4")
    div(class="rounded-lg" style="background: #ffffff;")
      div(class="px-3 py-4")
        <button class="close_button close" type="button" @click="dialogClose">
        <span>&times;</span>
        </button>
        div(v-if="showExit")
          div(class="px-5 py-4" style="font-size:28px; text-align:center;") <b>您的傳銷權資格已退出，</b><br/>需先辦理重新入會，如有任何疑問，請致電：04-22581569。
        div(v-if="showFirst")
          div(v-if="yearPv < 6000" class="px-5 py-4" style="font-size:28px; text-align:center;") 提醒您！您的有效期限至 <span style = "color: red;">{{expiry_day}}</span>，目前無法購物，<br/>請於 <span style = "color: red;">{{renewal_expiry_date}}</span>前至各營業所辦理續約，以維護個人權益。
          div(v-else class="px-5 py-4" style="font-size:28px; text-align:center;") 您的傳銷權有效期限至今年底，無需辦理續約。
        div(v-else-if="showMiddleMonth")
        div(v-else-if="showlastMonth")
          div(v-if="yearPv < 6000" class="px-5 py-4" style="font-size:24px; text-align:center;") 您今年個人消費累計為{{parseInt(yearPv).toLocaleString() }} PV <p class="mt-2" style="font-size:20px;">若是您在12月31日前補足6,000積分，屆時公司將為您的有效期限展延至明年底</p>
          div(v-else class="px-5 py-4" style="font-size:24px; text-align:center;") 您今年個人消費累計已大於6,000積分，傳銷商資格將自動展延至明年底。
        div(class="" v-if="message.type === 'video'" @click.stop)
          video(class="w-100" controls autoplay muted loop v-if="message.content.video")
            source(:src="message.content.video" type="video/mp4")
            source(:src="message.content.video" type="video/ogg")
            source(:src="message.content.video" type="video/webm")
            p {{ message.content.video_alt }}
        div(class="" style="background: #ffffff;" v-if="message.type === 'text'")
          div(class="px-5 py-4")
            div(class="" v-html="message.content")
</template>

<script>
import dayjs from 'dayjs'
import { getVideoreSources, getLoginPops } from '../../api'
import { mapState } from 'vuex'
export default {
  name: 'HomeLightbox',
  data () {
    return {
      message: {},
      yearPvAlert: '',
      date: '',
      currMonth: '',
      showFirst: false,
      showMiddleMonth: false,
      showlastMonth: false,
      yearPv: '',
      showExit: false,
      renewal_expiry_date: '',
      renewal_month: 0,
      expiry_day: '',
      expiry_year: 2022,
      renewal_expiry_date_time: 0
    }
  },
  props: {
    attrs: Object
  },
  created () {
    // const yearPvAlert = window.localStorage.getItem('yearPvAlert')
    const nowDate = new Date()
    const currMonth = nowDate.getMonth() + 1
    const renewalExpiryDate = new Date(this.UserData.renewal_expiry_date)
    const expiryDate = new Date(this.UserData.expiry_day)
    const created = new Date(this.UserData.created_at)
    console.log('this.UserData')
    console.log(JSON.stringify(this.UserData))
    console.log('currMonth', currMonth)
    console.log('renewalExpiryDate', renewalExpiryDate)

    // pv
    if (this.UserData.pv === null || this.UserData.pv.year_pv === null || this.UserData === null) {
      this.yearPv = 0
    } else {
      this.yearPv = Number(this.UserData.pv.year_pv)
    }

    // 續約截止日
    if (this.UserData.renewal_expiry_date === null) {
      this.renewal_expiry_date = 0
    } else {
      // console.log('this.UserData.renewal_expiry_date', this.UserData.renewal_expiry_date)
      this.renewal_month = renewalExpiryDate.getMonth() + 1
      this.renewal_expiry_date = renewalExpiryDate.getFullYear() + '/' + this.renewal_month + '/' + renewalExpiryDate.getDate()
      this.renewal_expiry_date_time = renewalExpiryDate.getTime()
    }

    // 到期日
    if (this.UserData.expiry_day === null) {
      this.expiry_day = 0
    } else {
      this.expiry_day = expiryDate.getFullYear() + '/' + (expiryDate.getMonth() + 1) + '/' + expiryDate.getDate()
      this.expiry_year = expiryDate.getFullYear()
    }

    // 退出
    if (this.UserData.exited_at != null && this.UserData.exited_at !== '') {
      this.showExit = true
      console.log('showExit = true')
    } else {
      // 還沒退出
      console.log('showExit = false')
      if (expiryDate < nowDate) {
        this.showFirst = true
        console.log('showFirst = true')
      } else if (currMonth === 11 || currMonth === 12) {
        // 11或12月
        if (created.getFullYear() === nowDate.getFullYear()) {
          // 今年加入
          this.showMiddleMonth = false
          this.showlastMonth = false
          console.log('not show showlastMonth = false showMiddleMonth = false ')
        } else {
          // yearPv <= 6000 您今年個人消費累計為....  yearPv > 6000 您今年個人消費累計已....
          this.showlastMonth = true
          console.log('showlastMonth = true ')
        }
      }
    }
    // 待修正優化由外部拿取資料
    getLoginPops().then(res => {
      const todayFormat = dayjs().format()
      if (res.data[0].status === 1 || (res.data[0].status === 2 && res.data[0].published_at <= todayFormat && todayFormat <= res.data[0].finished_at)) {
        this.message = res.data[0]
        // console.log('this.message', this.message)
        if (this.message.type === 'video') {
          getVideoreSources().then(res => {
            const video = res.data.find(p => p.title === this.message.content)
            if (video) {
              this.message.content = video
            }
          })
        }
      }
      // console.log('getLoginPops', this.message.content)
    })
  },
  mounted () {
    document.addEventListener('click', function (event) {
      location.reload()
    })
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Seometas: state => state.model.Seometas,
      SystemImages: state => state.model.SystemImages,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData,
      Search: state => state.global.search
    })
  },
  methods: {
    dialogClose () {
      const check = () => {
        this.$dialog.close()
      }
      check()
    }
  }
}
</script>
<style>
 .lighbox iframe {
   margin: auto;
 }
 .z-index-10{
   z-index: 10;
 }
 .lighbox img {
  width: 100% !important;
  height: auto !important;
 }
</style>
