<template lang="pug">
  div(class="d-grid grid-tr grid-span fs-lg-16 fs-14" :class="{'no-delete': !edit}")
    div(class="grid-span line")
    div(class="d-none d-lg-block grid-image px-3 py-2 p-lg-3")
      div(class="p-100 position-relative")
        router-link(class="position-fill bg-contain" :title="model.products.name" :to="'/product/' + model.product_id" v-if="model.products.images && model.products.images.length && model.products.images[0]" :style="{backgroundImage: `url(${model.products.images[0].image})`}")
    div(class="d-none d-lg-flex grid-text  align-items-center " :style="style")
      router-link(class="cursor-pointer" tag="div" :to="'/product/' + model.product_id")
        div(class="") {{ model.products.product_number }}
        div(class="") {{ model.products.name }}
        div(class="") {{ model.fortune_word }}
      div(class="col my-2 px-2 max-w-md-160" v-if="model.activity_fives")
        select(class="custom-select" :disabled="model.activity_fives_change == 1"  v-model="model.product_number" @change="changeFive($event, model)")
          option(v-for="five in activity_fives" :key="five.product_number" :value="five.product_number") {{ five.product_name }}
    div(class="grid-price d-lg-flex d-none flex-center")
      div(class="text-nowrap" v-if="!model.activity_id") NT$ {{ commaFormat(model.products.member_price) }}
    div(class="grid-pv d-lg-flex d-none flex-center")
      div(class="text-nowrap" v-if="!model.activity_id") {{ commaFormat(model.products.pv)}}
    div(class="grid-quantity d-none d-lg-flex flex-center px-3 px-lg-0")
      template(v-if="isOrderItem")
        div(class="w-100 text-lg-center") {{ model.quantity }}
      template(v-else-if="edit && !model.is_lucky")
        select(class="w-100 custom-select my-2" style="text-align-last: left;" v-model="model.product_quantity" v-if="maxLimitedBuy(model.products)" @change="changeData($event, model)")
          option(v-for="i in maxLimitedBuy(model.products)" :value="i") {{ i }}
        div(class="text-danger my-2" v-else) 已售完
      template(v-else)
        div(class="w-100 text-lg-center") {{ model.product_quantity }}
    div(class="grid-count-price d-none d-lg-flex justify-content-lg-center justify-content-end align-items-center px-3 my-1")
      div(class="text-nowrap" v-if="!model.activity_id")
        template(v-if="isOrderItem") NT$ {{ commaFormat(model.products.member_price * model.quantity) }}
        template(v-else) NT$ {{ commaFormat(model.products.member_price * model.product_quantity) }}
    div(class="gridd-count-pv d-none d-lg-flex justify-content-lg-center justify-content-end align-items-center px-3 my-1")
      div(class="text-nowrap" v-if="!model.activity_id")
        template(v-if="isOrderItem") {{ commaFormat(model.products.pv * model.quantity) }}
        template(v-else) {{ commaFormat(model.products.pv * model.product_quantity) }}
      div(v-else)
    div(class="grid-delete d-flex justify-content-lg-center justify-content-end align-items-lg-center align-items-start p-3" v-if="edit")
      button(class="btn btn-icon text-dark" type="button" @click="remove($event, model.id)")
        Icon(class="btn-icon rotate-45" pattern="Add" size="24")
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 品號
      div() {{ model.products.product_number }}
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 商品
      router-link(class="" :title="model.products.name" :to="'/product/' + model.product_id" v-if="model.products.images && model.products.images.length && model.products.images[0]" )
        img(:src="model.products.images[0].image" style="width:100px")
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 名稱
      div() {{ model.products.name }}
    div(class="d-lg-none two-col px-3 py-2" v-if="model.activity_fives")
      div(class="") 選擇贈品
      div(class="col my-2 px-2 max-w-md-160" v-if="model.activity_fives")
        select(class="custom-select" :disabled="model.activity_fives_change == 1"  v-model="model.product_number" @change="changeFive($event, model)")
          option(v-for="five in activity_fives" :key="five.product_number" :value="five.product_number") {{ five.product_name }}
    div(class="d-lg-none two-col px-3 py-2" v-if="!model.activity_id")
      div(class="") 單價
      div(class="text-nowrap" ) NT$ {{ commaFormat(model.products.member_price) }}
    div(class="d-lg-none two-col px-3 py-2" v-if="!model.activity_id")
      div(class="") 積分
      div(class="text-nowrap" ) {{ commaFormat(model.products.pv)}}
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 數量
      template(v-if="isOrderItem")
        div(class="w-100 text-lg-center") {{ model.quantity }}
      template(v-else-if="edit && !model.is_lucky")
        select(class="w-100 custom-select my-2" style="text-align-last: left;" v-model="model.product_quantity" v-if="maxLimitedBuy(model.products)" @change="changeData($event, model)")
          option(v-for="i in maxLimitedBuy(model.products)" :value="i") {{ i }}
        div(class="text-danger my-2" v-else) 已售完
      template(v-else)
        div(class="w-100 text-lg-center") {{ model.product_quantity }}
    div(class="d-lg-none two-col px-3 py-2" v-if="!model.activity_id")
      div(class="") 金額小計
      div(class="text-nowrap" v-if="!model.activity_id")
        template(v-if="isOrderItem") NT$ {{ commaFormat(model.products.member_price * model.quantity) }}
        template(v-else) NT$ {{ commaFormat(model.products.member_price * model.product_quantity) }}
    div(class="d-lg-none two-col px-3 py-2" v-if="!model.activity_id")
      div(class="") 積分小計
      div(class="text-nowrap" )
        template(v-if="isOrderItem") {{ commaFormat(model.products.pv * model.quantity) }}
        template(v-else) {{ commaFormat(model.products.pv * model.product_quantity) }}
    div(class="d-lg-none two-col px-3 py-2" v-else)
      div(class="") 積分小計
      div(class="text-nowrap" )
</template>

<script>
import { mapActions } from 'vuex'
import defaultImage from '@/assets/images/default.png'
import { ShopCartModel, OrderItemModel } from '../../models'
import { createShopcart } from '../../api'
import { Toast } from '@/utils/helper'
export default {
  props: {
    model: {
      type: [ShopCartModel, OrderItemModel],
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: () => {}
    },
    loadcart: {
      type: Function,
      default: () => {}
    },
    isRemove: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultImage,
      activity_fives: [],
      product_number: '',
      style: ''
    }
  },
  created () {
    // console.log('ProdGrid', this.model)
    const copy = Object.assign({}, this.model)
    this.product_number = copy.product_number

    if (Object.hasOwn(this.model, 'activity_fives') && this.model.activity_fives !== '' && this.model.activity_fives !== null) {
      this.style = 'flex-direction:column;align-items: baseline !important;'
      this.getFiveById(this.model.activity_fives)
    }
    if (this.isRemove) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.model.hasOwnProperty('products')) {
        // if (this.model.product_quantity > this.model.products.quantity && this.model.is_lucky !== 1) {
        //   Toast.fire({
        //     icon: 'error',
        //     title: this.model.products.name + ' 商品庫存不足, 商品會自動移除'
        //   })
        //   this.remove(this.model.id).then(res => {
        //     this.loadcart(res)
        //   })
        // }
      }
    }
  },
  computed: {
    isOrderItem () {
      return this.model instanceof OrderItemModel
    }
  },
  methods: {
    ...mapActions(['removeShopcartByShopCartId', 'getActivityFiveList', 'updateActivityFive']),
    getFiveById (activityFives) {
      return new Promise(resolve => {
        this.getActivityFiveList(activityFives).then(res => {
          // console.log(res.data)
          this.activity_fives = res.data
          resolve(res)
        }).catch(err => {
          console.log(err)
        })
      })
    },
    changeData (e, model) {
      createShopcart(
        model.toDatabase()
      ).then(res => {
        model.set(res.data)
        this.change(e)
      })
    },
    async remove (e, id) {
      await this.removeShopcartByShopCartId(id)
      this.change(e)
    },
    changeFive (e, model) {
      // console.log('changeFive', model.product_number)
      Toast.fire({
        icon: 'info',
        title: '是否確認選擇的選項？',
        showCancelButton: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false
      }).then((recall) => {
        if (recall.isConfirmed === true) {
          return new Promise(resolve => {
            // console.log('changeFive 111', model.product_number)
            this.updateActivityFive(model).then(res => {
              // console.log(res.data)
              model.activity_fives_change = 1
              model.products.product_number = model.product_number
              model.products.name = res.data.product_name
            }).catch(err => {
              console.log(err)
            })
          })
        } else {
          // console.log('changeFive false', model.product_number)
          model.product_number = this.product_number
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .grid-tr {
    grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      .two-col{
        display: grid;
        grid-template-columns:  1fr 1fr ;
      }
    }
  }
</style>
