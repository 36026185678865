<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px);")
    div(class="px-sm-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      h3(class="mb-4 font-weight-bold") 我的最愛
      div(class="d-grid grid-table border" v-if="Favorites.data.length" style="grid-template-columns: 132px 3fr 1fr 1fr 1fr 150px;")
        div(class="text-center title") 商品
        div(class="text-center title")
        div(class="text-center title") 單價
        div(class="text-center title") 積分
        div(class="text-center title") 移除
        div(class="text-center title") 加入購物車
        div(class="d-grid grid-tr grid-span fs-lg-16 fs-14" v-for="model in Favorites.data")
          div(class="grid-span line")
          div(class="grid-image px-3 py-2 p-lg-3")
            div(class="p-100 position-relative")
              router-link(class="position-fill bg-contain" :title="model.name" :to="'/product/' + model.id" v-if="model.images && model.images.length && model.images[0]" :style="{backgroundImage: `url(${model.images[0].image})`}")
              router-link(class="position-fill bg-contain" :title="model.name" :to="'/product/' + model.id" v-else :style="{backgroundImage: `url(${defaultImage})`}")
          div(class="grid-text d-flex align-items-center")
            router-link(class="cursor-pointer" tag="div" :to="'/product/' + model.id")
              div(class="") {{ model.product_number }}
              div(class="") {{ model.name }}
          div(class="grid-price d-lg-flex d-none flex-center")
            div(class="") ${{ commaFormat(model.member_price) }}
          div(class="grid-pv d-lg-flex d-none flex-center")
            div(class="") {{ commaFormat(model.pv)}}
          div(class="grid-price-pv d-flex d-lg-none align-items-end p-3")
            div(class="mr-3") 單價${{ commaFormat(model.member_price)}}
            div(class="") 積分{{ commaFormat(model.pv)}}
          div(class="grid-delete d-flex justify-content-lg-center justify-content-end align-items-lg-center align-items-start p-3")
            button(class="btn btn-icon text-dark" type="button" @click="removeFavoritesBtn(model.id)")
              Icon(class="btn-icon rotate-45" pattern="Add" size="24")
          div(class="grid-shopcart d-flex justify-content-lg-center justify-content-end align-items-lg-center align-items-start p-3" v-if="!isLuckyProduct(model.product_number)")
            div(class="" v-if="checkStock(model)") 缺貨
            button(class="btn btn-success" type="button" v-else @click="clickShopcart(model.id)")
              span(class="" v-if="inShopCart(model.id)") 移除購物車
              span(class="" v-else) 加入購物車
      div(v-else)
        h2(class="text-center py-5") 我的最愛沒有任何商品
</template>

<script>
import { mapState, mapActions } from 'vuex'
import defaultImage from '@/assets/images/default.png'
import { Toast } from '@/utils/helper'

export default {
  name: 'Favorites',
  data () {
    return {
      defaultImage
    }
  },
  computed: {
    ...mapState({
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData
    })
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
      return
    }
    this.getListModel({ name: 'Favorites' })
    this.$store.dispatch('getListModel', {
      name: 'ShopCarts'
    })
  },
  methods: {
    ...mapActions(['getListModel', 'addShopcart', 'removeShopcartByProdId', 'removeFavorites']),
    clickShopcart (id) {
      if (this.ShopCarts.data.map(p => Number(p.product_id)).includes(Number(id))) {
        this.removeShopcartByProdId(id)
      } else {
        this.addShopcart(id).then(res => {
          // console.log(res)
          if (res.out_of_stock === 1) {
            // console.log(id)
            const product = this.Favorites.data.filter(function (el) {
              return el.id === id
            })

            // this.Favorites.filter()
            Toast.fire({
              icon: 'error',
              title: product[0].name + ' 庫存不足'
            })
          } else {
            Toast.fire({
              icon: 'success',
              title: '成功加入購物車'
            })
          }
        })
      }
    },
    removeFavoritesBtn (id) {
      Toast.fire({
        title: '確定移除?',
        text: '商品將從我的最愛移除',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '確定移除',
        cancelButtonText: '取消'
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeFavorites(id)
          Toast.fire(
            '已移除',
            '商品已從我的最愛移除',
            'success'
          )
        } else {
          Toast.fire(
            '已取消',
            '商品仍保留在我的最愛中',
            'error'
          )
        }
      })
    },
    inShopCart (id) {
      return this.ShopCarts.data.map(p => p.product_id).includes(id)
    },
    checkStock (modal) {
      // console.log(modal.name)
      // console.log(modal.quantity)
      if (modal.quantity <= 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .grid-table{
    .title {
      padding: 12px 0;
      @media (max-width: 991.5px) {
        display: none;
      }
    }
  }
  .grid-tr {
    grid-template-columns: 132px 3fr 1fr 1fr 1fr 150px;
    @media (max-width: 991.5px) {
      grid-template-columns: 100px 1fr auto;
      .grid-image{
        grid-column: 1;
        grid-row: 1;
      }
      .grid-text{
        grid-column: 2 / 3;
        grid-row: 1;
      }
      .grid-price{
        display: none;
      }
      .grid-pv{
        display: none;
      }
      .grid-price-pv{
        grid-column: 1 / 3;
        grid-row: 2;
      }
      .grid-delete{
        grid-column: 3 / 4;
        grid-row: 1;
      }
      .grid-shopcart{
        grid-column: 3 / 4;
        grid-row: 2;
      }
    }
    &.no-delete{
      grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr;
      @media (max-width: 991.5px) {
        grid-template-columns: 100px 1fr 1fr;
      }
    }
  }
</style>
