export default {
  mount: '.icon-line-native',
  attrs: {
    viewBox: '0 0 120 120',
    fill: 'currentColor'
  },
  path: `
  <g>
    <path d="M94,120 L26,120 C11.6,120 0,108.4 0,94 L0,26 C0,11.6 11.6,0 26,0 L94,0 C108.4,0 120,11.6 120,26 L120,94 C120,108.4 108.4,120 94,120 Z" fill="#00B900"/>
    <g transform="translate(16.100000, 19.200000)">
        <path d="M87.4,35.5 C87.4,15.9 67.8,0 43.7,0 C19.6,0 0,15.9 0,35.5 C0,53 15.5,67.7 36.5,70.5 C37.9,70.8 39.9,71.4 40.3,72.7 C40.7,73.8 40.6,75.5 40.4,76.7 C40.4,76.7 39.9,79.8 39.8,80.4 C39.6,81.5 38.9,84.7 43.6,82.8 C48.3,80.8 68.8,68 77.9,57.4 L77.9,57.4 C84.4,50.4 87.4,43.3 87.4,35.5" fill="#FFFFFF"/>
        <g transform="translate(14.200000, 25.900000)" fill="#00B900">
            <path d="M20.6,0.2 L17.5,0.2 C17,0.2 16.6,0.6 16.6,1 L16.6,20 C16.6,20.5 17,20.8 17.5,20.8 L20.6,20.8 C21.1,20.8 21.5,20.4 21.5,20 L21.5,1 C21.5,0.6 21.1,0.2 20.6,0.2"/>
            <path d="M41.7,0.2 L38.7,0.2 C38.2,0.2 37.8,0.6 37.8,1 L37.8,12.3 L29.1,0.5 C29.1,0.5 29.1,0.4 29,0.4 C29,0.4 29,0.4 29,0.4 C29,0.4 29,0.4 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.9,0.3 C28.9,0.3 28.9,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 C28.8,0.3 28.8,0.3 28.8,0.3 L25.7,0.3 C25.2,0.3 24.8,0.7 24.8,1.1 L24.8,20.1 C24.8,20.6 25.2,20.9 25.7,20.9 L28.8,20.9 C29.3,20.9 29.7,20.5 29.7,20.1 L29.7,8.8 L38.4,20.6 C38.5,20.7 38.5,20.8 38.6,20.8 C38.6,20.8 38.6,20.8 38.6,20.8 C38.6,20.8 38.6,20.8 38.7,20.8 C38.7,20.8 38.7,20.8 38.7,20.8 C38.7,20.8 38.7,20.8 38.7,20.8 C38.7,20.8 38.7,20.8 38.7,20.8 C38.7,20.8 38.7,20.8 38.7,20.8 C38.7,20.8 38.7,20.8 38.8,20.8 C38.8,20.8 38.8,20.8 38.8,20.8 C38.9,20.8 38.9,20.8 39,20.8 L41.7,20.8 C42.2,20.8 42.6,20.4 42.6,20 L42.6,1 C42.6,0.6 42.2,0.2 41.7,0.2"/>
            <path d="M13.2,16.1 L4.9,16.1 L4.9,1 C4.9,0.5 4.5,0.1 4,0.1 L0.9,0.1 C0.4,0.1 0,0.5 0,1 L0,20 L0,20 C0,20.2 0.1,20.4 0.2,20.6 C0.2,20.6 0.2,20.6 0.2,20.6 C0.2,20.6 0.2,20.6 0.2,20.6 C0.4,20.7 0.6,20.8 0.8,20.8 L0.8,20.8 L13,20.8 C13.5,20.8 13.8,20.4 13.8,19.9 L13.8,16.8 C14.1,16.5 13.7,16.1 13.2,16.1"/>
            <path d="M58.6,4.9 C59.1,4.9 59.4,4.5 59.4,4 L59.4,0.9 C59.4,0.4 59,-7.10542736e-15 58.6,-7.10542736e-15 L46.4,-7.10542736e-15 L46.4,-7.10542736e-15 C46.2,-7.10542736e-15 46,0.1 45.8,0.2 C45.8,0.2 45.8,0.2 45.8,0.2 C45.8,0.2 45.8,0.2 45.8,0.2 C45.7,0.4 45.6,0.6 45.6,0.8 L45.6,0.8 L45.6,19.8 L45.6,19.8 C45.6,20 45.7,20.2 45.8,20.4 C45.8,20.4 45.8,20.4 45.8,20.4 C45.8,20.4 45.8,20.4 45.8,20.4 C46,20.5 46.2,20.6 46.4,20.6 L46.4,20.6 L58.6,20.6 C59.1,20.6 59.4,20.2 59.4,19.7 L59.4,16.6 C59.4,16.1 59,15.7 58.6,15.7 L50.3,15.7 L50.3,12.9 L58.6,12.9 C59.1,12.9 59.4,12.5 59.4,12 L59.4,8.9 C59.4,8.4 59,8 58.6,8 L50.3,8 L50.3,4.9 L58.6,4.9 Z"/>
        </g>
    </g>
  </g>
  `
}
