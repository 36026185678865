<template lang="pug">
  div#product-list
    div(class="overflow-hidden bg-white mx-n3")
      Slick(class="benner" v-bind="bennerSlickSettings" v-if="isProductCarousel.length")
        div(class="px-sm-1 " v-for="item in isProductCarousel")
          div(class="position-relative banner-item" :class="{'cursor-pointer': item.url}" @click="linkUrl(item.url)")
            div(class="position-fill bg-cover" :style="{backgroundImage: `url(${item.image || defaultImage})`}")
    div(class="bg-white top-fix mb-2")
      Marquee(:message="marqueesContents")
    div(class="")
      h2(class="mb-3 ") 商品系列
      div(class="row")
        router-link(class="col-md-4 mb-3" :to="{ path:'/product/list',query: { category_id: category.id }}" v-for="(category, index) in Categories.data" v-if="category.name !=='全部商品'" :key="category.id || 'all'")
          img(class="img-fluid" style="width:100%;" :src="baseUrl + category.image")

</template>

<script>

import { mapState } from 'vuex'
import * as Icon from '../../../components/icon'

export default {
  name: 'Product',
  data () {
    return {
      baseUrl: location.protocol + '//api.tocin.com.tw/storage/',
      bennerSlickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }]
      }
    }
  },
  created () {
    // console.log('created', process.env.VUE_APP_BASE_URL)
    this.$store.dispatch('getListModel', {
      name: 'ProductCarousels'
    })
    this.$store.dispatch('getListModel', {
      name: 'ProductMarquees'
    })
    this.$store.dispatch('getListModel', {
      name: 'Products'
    })
    this.$store.dispatch('getListModel', {
      name: 'ShopCarts'
    })

    // console.log('Categories', this.Categories)
  },
  computed: {
    ...mapState({
      Search: state => state.global.search,
      Carousels: state => state.model.ProductCarousels,
      Marquees: state => state.model.ProductMarquees,
      StoreProducts: state => state.model.Products,
      Categories: state => state.model.Categories,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData
    }),
    isProductCarousel () {
      return this.Carousels.data.filter(p => p.is_product_use)
    },
    marqueesContents () {
      return this.Marquees.data.filter(p => p.is_product_use).map(p => {
        return p.content
      })
    },
    paginationSettings () {
      // console.log('paginationSettings', Number(this.Search.page))
      const arrow = JSON.parse(JSON.stringify(Icon.ArrowRight))
      const arrowAttrs = Object.keys(arrow.attrs).map(key => ` ${key}="${arrow.attrs[key]}"`).join('')
      return {
        // forcePage: Number(this.Search.page),
        value: Number(this.Search.page),
        pageCount: this.ProductsList.last_page,
        prevText: `<div><svg${arrowAttrs}><g transform="rotate(180 12 12)">${arrow.path}</g></svg></div>`,
        nextText: `<div><svg${arrowAttrs}>${arrow.path}</svg></div>`,
        containerClass: 'pagination',
        pageClass: 'pagination-item',
        prevClass: 'pagination-prev',
        nextClass: 'pagination-next',
        clickHandler: this.changePage
      }
    },
    selectcategory () {
      return this.Categories.data.reduce((acc, item) => acc.concat(item.series), [])
    },
    showCartPicHandler () {
      console.log('啟動computed')
      console.log(this.ShopCarts.data[0])
      return this.ShopCarts.data[0]
    }
  },
  methods: {
    linkUrl (url) {
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>

<style lang="scss">
.suggested-price{
  font-size: .9em;
}
.text-truncate {
  line-height: 1.7;
}
.custom-select {
  text-align-last: center;
  font-size: 14px;
  max-width: 340px;
}
.hover-block{
  .hover-show{
    box-shadow: 0 0 0px rgb(0 0 0 / 0%);
    transition: all .3s ease-in-out;
    .transform-button{
      overflow: hidden;
      bottom: -120%;
      position: absolute;
      transition: all .3s ease-in-out;
    }
    .btn-favorite{
      width: 44px;
      height: 43px;
      background-color: rgba(165,38,49,0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      transition: all .3s ease-in-out;
      &:hover {
        background-color: rgba(165,38,49,1);
      }
    }
    .btn-cart{
      width: 44px;
      height: 43px;
      background-color: rgba(96, 157, 33 ,0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      transition: all .7s ease-in-out;
      &:hover {
        background-color: rgba(96, 157, 33,1);
      }
    }
  }
  &:hover{
    .hover-show{
      display: block;
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    }
    .transform-button{
      bottom: 5px;
    }
  }
}
.overflow-mix-image {
  max-width: 100%;
  max-height: 100%;
}
#product-list {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
}
.max-w-240 {
  max-width: 240px;
}
// #btn-favorite {
//   position: absolute;
// }
@media (min-width: 1200px) {
  .col-2_5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
</style>
