import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} image 圖片位址
 * @param {String} image_title 圖片說明文字
 * @param {String} image_alt 圖片替代文字
 * @param {Number} sort 排序
 * @param {Number} is_index_use 是否為首頁項目
 * @param {Number} is_product_use 是否為產品頁項目
 * @param {String} published_at 公開時間
 * @param {String} finished_at 到期時間
 * @param {String} updated_user 更新使用者
*/
export default class CarouselsModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.image = entity.image || ''
    this.image_title = entity.image_title || ''
    this.image_alt = entity.image_alt || ''
    this.sort = entity.sort || 0
    this.url = entity.url || ''
    this.is_index_use = entity.is_index_use || 0
    this.is_product_use = entity.is_product_use || 0
    this.url = entity.url || ''
    this.published_at = entity.published_at || ''
    this.finished_at = entity.finished_at || ''
    this.updated_user = entity.updated_user || ''
  }
}
