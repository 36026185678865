<template lang="pug">
  div(class="py-2")
    div(v-if="ListModel.data.length")
      div(class="d-grid grid-table border" style="grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;")
        div(class="text-center title") 商品
        div(class="text-center title")
        div(class="text-center title") 單價
        div(class="text-center title") 積分
        div(class="text-center title") 數量
        div(class="text-center title") 金額小計
        div(class="text-center title") 積分小計
        div(class="text-center title") 移除
        ProdGrid(v-for="model in ListModel.data" :model="model" :key="model.id" :edit="true" :change="changeQuantity" :loadcart="loadShoppingCart" :isRemove="true")
        ProdGrid(v-for="model in stockEmpty" :model="model" :key="model.id")
        ActivitieProdGridItem(v-for="model in activity_items" :model="model" :key="model.id")
        div(class="grid-span line ")
        div(class="grid-span row " style="padding-left:10px")
          div(class=" px-3 py-2 col-sm-12 col-md-8" style="color: #AE0000;")
            span ‧ 提醒您！
              a(href="/service/1/8") 運費收取說明 <br />
            span ‧ 本島訂貨總金額滿$5,000元即可享免運。<br />
            span ‧ 外島訂貨總金額滿$10,000元即可享免運。
          div(class="px-3 py-2 max-w-lg-250 col-sm-12 col-md-4" )
            div(class="d-flex py-2 ")
              div(class="text-dark totle-count-title") 訂單金額總計
              div(class=" text-danger font-weight-bold") NT$ {{ commaFormat(mathTotalPrice) }}
            div(class="d-flex py-2 ")
              div(class="text-dark totle-count-title") 訂單積分總計
              div(class="text-primary font-weight-bold") {{ commaFormat(mathTotalPv) }}
      div(class="py-2" v-if="Activities.data.length")
        div(class="border fs-lg-16 fs-14")
          div(class="border-bottom text-center py-2") 特惠活動
          ActivitieGrid(v-for="(model, index) in OrderModel.activities" :showText="true" :updateView="updateView" :model="model" :hasLimit="model.hasLimit" :key="model.id" :edit="true" :class="{'border-top': index}")
    div(v-else)
      h2(class="text-center py-5") 購物車沒有任何商品
    div(class="text-danger text-center py-2" v-if="isLuckyProductInShopCart.length") 購物車內含有一般商品及開運飾品，無法進行台新信用卡十二期分期。若要進行十二期分期請移除一般商品。
    div(class="row mx-n1 py-2 justify-content-end align-items-center flex-column flex-lg-row")
      router-link(class="w-100 btn btn-outline-success m-1 max-w-300 max-w-lg-180" to="/product/list") 繼續購物
      button(class="w-100 btn btn-outline-success m-1 max-w-300 max-w-lg-180" type="button" v-if="ListModel.data.length" @click="deleteShopcartAll") 清除購物車
      button(class="w-100 btn btn-success m-1 max-w-300" type="button" @click="nextStep" v-if="ListModel.data.length") 下一步
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
import ProdGrid from '../../../container/order/ProdGrid.vue'
import ActivitieGrid from '../../../container/order/ActivitieGrid.vue'
import ActivitieProdGrid from '../../../container/order/ActivitieProdGrid.vue'
import ActivitieProdGridItem from '../../../container/order/ActivitieProdGridItem.vue'
import { deleteShopcartAll } from '../../../api'
import { ProductsModel, OrderItemModel, ActivitieModel } from '../../../models'
import { Toast } from '@/utils/helper'

export default {
  name: 'step1',
  data () {
    return {
      OrderModel: this.$parent.model,
      stockEmpty: [],
      listModel: [],
      activity_items: [],
      renewal_expiry_date: 0,
      expiry_date: 0
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
      return
    }
    this.$parent.step = 1
    this.getListModel({ name: 'SettlementDays' })
    this.getListModel({ name: 'ShopSystems' })
    this.getListModel({ name: 'ShoppingRules' })
    this.getListModel({ name: 'LoveCodes' })
    this.loadShoppingCart()

    this.UserData.login().then(() => {
      // console.log('this.UserData', this.UserData)
      if (this.UserData.exited_at !== null) {
        this.$store.commit('logout')
        Toast.fire({
          icon: 'success',
          title: '已成功登出'
        }).then(() => {
          this.$router.replace('/home')
        })
      }
    })

    this.OrderModel.activities.map(a => {
      this.activity_items = this.activity_items.concat(a.activity_items)
      return a.activity_items
    })
  },
  computed: {
    ...mapState({
      ListModel: state => state.model.ShopCarts,
      Products: state => state.model.Products,
      SettlementDays: state => state.model.SettlementDays,
      ShopSystems: state => state.model.ShopSystems,
      Activities: state => state.model.Activities,
      UserData: state => state.model.UserData,
      ShopCarts: state => state.model.ShopCarts
    }),
    // ...mapActions(['removeShopcartByProdId']),
    mathTotalPrice () { // 計算總金額
      return this.ListModel.data.map(item => {
        const total = item.products.member_price * item.product_quantity
        return this.maxLimitedBuy(item.products) ? total : 0
      }).reduce((a, b) => Number(a) + Number(b), 0)
    },
    mathTotalPv () { // 計算總積分
      return this.ListModel.data.map(item => {
        const total = item.products.pv * item.product_quantity
        return this.maxLimitedBuy(item.products) ? total : 0
      }).reduce((a, b) => Number(a) + Number(b), 0)
    },
    isShopSystemClose () {
      const system = this.ShopSystems.data.length ? this.ShopSystems.data[0] : false
      if (!system) {
        return true
      }
      const isSystem = dayjs().isAfter(system.started_at) && dayjs().isBefore(system.finished_at)
      if (this.ShopSystems.data[0].status === 0) {
        if (isSystem) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isLuckyProductInShopCart () {
      return this.ListModel.data.filter(p => p.fortune_word)
    }
  },
  beforeUpdate () {
    this.ShopCarts.data.forEach(item => {
      if (item.products.status === 0) {
        // console.log(item.products.id)
        this.$store.dispatch('removeShopcartByProdId', item.products.id)
      }
    })
  },
  updated () {
    console.log('updated', this.ShopCarts)
    console.log('activity_items', this.activity_items)
  },
  methods: {
    ...mapActions(['getListModel', 'getModelById', 'removeShopcartByProdId']),
    async getShopCarts () { // 取得最新的 Shopcart 的資料
      await this.getListModel({ name: 'ShopCarts' })
      const prod = await this.getProductsByIdGroup(this.ListModel.data.map(p => {
        // console.log(p.products)
        return Number(p.product_id)
      }))
      // console.log('this.ListModel.data:', this.ListModel.data)
      this.ListModel.data.forEach((model, index) => {
        const Product = new ProductsModel(prod[index])
        // console.log('Product:', Product)
        model.products = Product
        model.product_quantity = this.isLuckyProduct(model.products.product_number) ? 1 : Math.min(this.maxLimitedBuy(Product), model.product_quantity)
      })
      console.log(this.ListModel.data)
      // this.ListModel.data.sort(function (a, b) {
      //   return Number(a.products.product_number) - Number(b.products.product_number)
      // })
    },
    loadShoppingCart () {
      this.getShopCarts().then(res => {
        // console.log('loadShoppingCart', res)
        this.getActivitiesListModel()
        this.clearStockEmpty()
      })
    },
    getProductsByIdGroup (arrId) { // 依據產品 id 取得最新的 Products 資料
      return Promise.all(
        arrId.map(id => this.$store.dispatch('getModelById', { name: 'Products', id }))
      )
    },
    changeQuantity () {
      // console.log('changeQuantity')
      this.getActivitiesListModel()
    },
    updateView () {
      console.log('updateView', this.OrderModel.activities)
      this.activity_items = []
      this.OrderModel.activities.map(a => {
        this.activity_items = this.activity_items.concat(a.activity_items)
        return a.activity_items
      })
    },
    async clearStockEmpty () { // 清除無庫存商品
      const arrCache = this.ListModel.data.filter(model => model.product_quantity <= 0 && !this.isLuckyProduct(model.products.product_number))
      // console.log('clearStockEmpty', arrCache)

      let prodName = ''
      arrCache.forEach(model => {
        // console.log('model', model)
        if (prodName === '') {
          prodName = model.products.name
        } else {
          prodName += ',' + model.products.name
        }
      })

      if (prodName !== '') {
        Toast.fire({
          icon: 'error',
          title: prodName + ' 已無庫存, 商品會自動移除'
        })
      }

      Promise.all(
        arrCache.map(model => this.removeShopcartByProdId(model.products.id))
      ).then(() => {
        arrCache.forEach(model => {
          // console.log('model', model)
          this.ListModel.data = this.ListModel.data.filter(p => p.id !== model.id)
        })
      })
    },
    async deleteShopcartAll () {
      if (confirm('是否確定要清除購物車?')) {
        await deleteShopcartAll()
        this.ListModel.data = []
        this.mathRule()
      }
    },
    nextStep () {
      // console.log('next isSettlementDay')
      const isSettlementDay = this.SettlementDays.data.some(day => dayjs().isAfter(day.started_at) && dayjs().isBefore(day.finished_at))
      // console.log('isSettlementDay', isSettlementDay, this.SettlementDays.data[0].status)

      if (this.SettlementDays.data[0].status === 1 && isSettlementDay) {
        Toast.fire({
          icon: 'warning',
          title: '親愛的傳銷商您好，本月份業績已經進行結算作業，暫停所有產品下單，造成不便，深感抱歉!'
        }).then(() => {
          this.$parent.step = 0
          this.$router.push('/')
        })
        return
      }
      if (this.isShopSystemClose) {
        console.log('close')
        this.$parent.step = 0
        this.$router.push('/member/shopcart/shop-close')
        return
      }
      const nowDate = new Date()
      const renewalExpiryDate = new Date(this.UserData.renewal_expiry_date)
      const expiryDate = new Date(this.UserData.expiry_day)

      // 續約截止日
      if (this.UserData.renewal_expiry_date === null) {
        this.renewal_expiry_date = 0
      } else {
        this.renewal_expiry_date = renewalExpiryDate.getFullYear() + '/' + (renewalExpiryDate.getMonth() + 1) + '/' + renewalExpiryDate.getDate()
      }

      // 到期日
      if (this.UserData.expiry_day === null) {
        this.expiry_date = 0
      } else {
        this.expiry_date = expiryDate.getFullYear() + '/' + (expiryDate.getMonth() + 1) + '/' + expiryDate.getDate()
      }

      if (expiryDate < nowDate && Number(this.UserData.pv.year_pv) < 6000) {
        Toast.fire({
          icon: 'warning',
          html: `提醒您！您的有效期限至 <span style = "color: red;">${this.expiry_date}</span>，請於 <span style = "color: red;">${this.renewal_expiry_date}</span>前至各營業所辦理續約，以維護個人權益。`
        }).then(() => {
          this.$parent.step = 0
          this.$router.push('/')
        })
        return
      }
      console.log('not close')
      if (!this.UserData.email) {
        if (process.env.NODE_ENV === 'development') console.log('UserData:email', this.UserData.email)
        Toast.fire({
          icon: 'warning',
          title: '如需購物請至會員中心建立電子信箱'
        }).then(() => {
          this.$parent.step = 0
          this.$router.push('/member/account')
        })
        return
      }
      if (this.handleActivity()) {
        Toast.fire({
          icon: 'warning',
          title: '請選擇活動贈品'
        })
        return
      }
      this.OrderModel.total_price = this.mathTotalPrice
      this.OrderModel.total_pv = this.mathTotalPv
      this.OrderModel.order_items = this.ListModel.data.filter(model => {
        return this.maxLimitedBuy(model.products)
      }).map(p => new OrderItemModel({
        ...p,
        fortune_birth_at: p.fortune_year && p.fortune_month && p.fortune_day ? `${p.fortune_year}-${p.fortune_month}-${p.fortune_day}` : undefined
      }))
      // console.log('step 1 this.OrderModel', this.OrderModel)
      // console.log('step 1 this.OrderModel.order_items', this.OrderModel.order_items)

      this.$parent.step = 2
      this.$router.push('2')
      this.getListModel({ name: 'Offices' })
      // this.getListModel({ name: 'GeneralAddress' })
    },
    // 活動相關
    async getActivitiesListModel () {
      this.getListModel({ name: 'Activities' }).then(() => {
        // console.log(' getActivitiesListModel', this.Activities.data)
        this.OrderModel.activities = this.Activities.data.map(p => new ActivitieModel(p))
        this.OrderModel.activities.forEach(a => {
          if (a.ruleable.freebie && a.ruleable.freebie.id) a.ruleable.freebie.getData()
          // console.log(' this.OrderModel.activities', a)
          a.ruleable.freebies.forEach(m => m.getData())
          a.ruleable.freebies_range.forEach(m => m.getData())
          a.ruleable.raffle_list.forEach(m => m.getData())
          // console.log('a.ruleable.freebies_range', a.ruleable.freebies_range)
          // console.log('a.ruleable.remaining_quota ', a.ruleable.remaining_quota, a.ruleable_type)
          if (a.ruleable.remaining_quota <= 0) {
            const found = this.OrderModel.agree_freebie_none_ids.find(element => element === a.id)
            // console.log('found', found)
            if (found === undefined) {
              this.OrderModel.agree_freebie_none_ids.push(a.id)
            }
          }
          a.show = 1
          a.hasLimit = this.isActivityLimit(a)
          // console.log('foreach activities', a)
        })
        // console.log('this.OrderModel.agree_freebie_none_ids', this.OrderModel.agree_freebie_none_ids)
        this.activity_items = []
        this.mathRule()
      })
    },
    isActivityLimit (activitie) { // 判斷是否參加活動
      if (process.env.NODE_ENV === 'development') {
        // console.log(activitie.title, 'isActivityLimit', activitie.ruleable.activity_limit_products.map(p => p.id))
        // console.log(activitie.title, 'isActivityLimit', this.ListModel.data.map(p => `${p.products.id}:${p.products.name}:${p.products.product_number}`))
      }
      return this.ListModel.data.some(shopcart => {
        return activitie.ruleable.activity_limit_products.some(p => Number(p.id) === Number(shopcart.product_id))
      })
    },
    inShopCartProduct (activitie) { // 能夠參與該活動的商品
      const activityProductsId = activitie.ruleable.activity_products.map(p => Number(p.id))
      if (process.env.NODE_ENV === 'development') {
        // console.log(activitie.title, 'inShopCartProduct', activityProductsId)
        // console.log(activitie.title, 'inShopCartProduct', this.ListModel.data.map(p => `${p.products.id}:${p.products.name}:${p.products.product_number}`))
      }
      return this.ListModel.data.filter(p => activityProductsId.includes(Number(p.products.id)))
    },
    mathRule () { // 更新活動計算結果
      console.log('?3')
      return this.OrderModel.activities.map(a => {
        if (a.ruleable_type.includes('RuleFixedPv')) {
          return this.mathRuleFixedPv(a)
        }
        if (a.ruleable_type.includes('RuleRangePv')) {
          return this.mathRuleRangePv(a)
        }
        if (a.ruleable_type.includes('RuleProduct')) {
          return this.mathRuleProduct(a)
        }
        if (a.ruleable_type.includes('RuleRaffle')) {
          return this.mathRuleRaffle(a)
        }
        return new ActivitieModel()
      })
    },
    mathRuleFixedPv (activitie) { // 固定積分運算
      // if (process.env.NODE_ENV === 'development') console.log('%cmathRuleFixedPv', 'color: red;', activitie.ruleable)
      const quota = activitie.ruleable.remaining_quota || 0
      if (this.isActivityLimit(activitie)) {
        const maxIterative = activitie.ruleable.max_iterative || 0
        const perBuyPv = activitie.ruleable.per_buy_pv
        const quantity = activitie.ruleable.per_freebie_quantity
        const freebie = activitie.ruleable.freebie
        let pv = 0
        if (quota > 0) {
          const iterative = this.inShopCartProduct(activitie).reduce((acc, shopcart) => {
            pv += shopcart.products.pv * shopcart.product_quantity
            return Math.floor(pv / perBuyPv)
          }, 0)
          // if (process.env.NODE_ENV === 'development') console.log('iterative > maxIterative', iterative, maxIterative)
          activitie.errorCondition.iterative = iterative > maxIterative
          // console.log('maxIterative', maxIterative)
          // console.log('iterative', iterative)

          if (freebie) {
            let giftQuantity = 0 // iterative * quantity
            // console.log('quota', quota)
            if (iterative > maxIterative) {
              giftQuantity = maxIterative * quantity
            } else {
              giftQuantity = iterative * quantity
            }
            freebie.gift_quantity = giftQuantity
            if (giftQuantity > quota) {
              activitie.errorCondition.quota = true
              freebie.gift_quantity = quota
            } else {
              activitie.errorCondition.quota = false
            }

            // console.log('giftQuantity', giftQuantity)
          }
          activitie.activity_items = freebie && freebie.gift_quantity ? [freebie] : []
          if (freebie && freebie.gift_quantity) {
            this.activity_items = this.activity_items.concat(freebie)
          }

          // console.log('mathRuleFixedPv activitie.activity_items', activitie.activity_items)
          // activitie.errorCondition.quota = false
          // if (process.env.NODE_ENV === 'development') console.log('mathRuleFixedPv:gift_quantity', `Math.min(iterativeCount:${iterativeCount} * quantity:${quantity} , quota:${quota} )`)
        } else {
          activitie.errorCondition.quota = true
        }
      }
      return activitie
    },
    mathRuleRangePv (activitie) { // 範圍積分運算
      // if (process.env.NODE_ENV === 'development') console.log('%cmathRuleRangePv', 'color: blue;', activitie.ruleable)
      if (this.isActivityLimit(activitie)) {
        const quota = activitie.ruleable.remaining_quota || 0
        if (quota > 0) {
          const freebiesRange = activitie.ruleable.freebies_range
          // console.log('freebiesRange', freebiesRange)
          const totalPv = this.inShopCartProduct(activitie).reduce((pv, shopcart) => {
            return pv + shopcart.products.pv * shopcart.product_quantity
          }, 0)
          const freebie = freebiesRange.find((f) => {
            // if (process.env.NODE_ENV === 'development') console.log('mathRuleRangePv:Range', `totalPv:${totalPv} >= pv_start:${f.pv_start} && totalPv:${totalPv} <= pv_end:${f.pv_end}`, totalPv >= f.pv_start && totalPv <= f.pv_end)
            return totalPv >= f.pv_start && totalPv <= f.pv_end
          })
          // console.log('freebie', freebie)
          // console.log('totalPv', totalPv)
          if (freebie) {
            if (freebie.gift_quantity > quota) {
              activitie.errorCondition.quota = true
              // freebie.gift_quantity = quota
            } else {
              activitie.errorCondition.quota = false
            }
          }
          activitie.activity_items = freebie && freebie.gift_quantity ? [freebie] : []
          // this.activity_items = freebie && freebie.gift_quantity ? [freebie] : []

          if (freebie && freebie.gift_quantity) {
            this.activity_items = this.activity_items.concat(freebie)
          }
          // console.log('mathRuleRangePv activitie.activity_items', activitie.activity_items)

          // activitie.errorCondition.quota = false
          // if (process.env.NODE_ENV === 'development') console.log('mathRuleRangePv:gift_quantity', `freebie:${freebie && freebie.name}Math.min(gift_quantity:${freebie && freebie.gift_quantity}, quota:${quota})`)
        } else {
          activitie.errorCondition.quota = true
        }
      }
      return activitie
    },
    mathRuleProduct (activitie) { // 買A送B運算
      // if (process.env.NODE_ENV === 'development') console.log('%cmathRuleProduct', 'color: purple;', activitie.ruleable)
      const quota = activitie.ruleable.remaining_quota || 0// 總數
      const perBuyQuantity = activitie.ruleable.per_buy_quantity// 買幾個
      const quantity = activitie.ruleable.per_freebie_quantity// 送幾個
      const totalQuantity = this.inShopCartProduct(activitie).reduce((quantity, shopcart) => quantity + shopcart.product_quantity, 0)
      const giftQuantity = Math.floor(totalQuantity / perBuyQuantity) * quantity// 可以送幾個
      if (giftQuantity < activitie.ruleable.selected_gift) { // 送的數量小於選擇
        activitie.activity_items = []// 更新規則就從讀
      }
      console.log('activitie', activitie)
      // this.model.activities.map(a => {
      //   console.log('inin:', a.activity_items)
      //   this.activity_items = this.activity_items.concat(a.activity_items)
      //   return a.activity_items
      // })
      // console.log('giftQuantity', giftQuantity)
      if (giftQuantity > quota) {
        activitie.errorCondition.quota = true
        activitie.ruleable.selected_gift = quota
      } else {
        activitie.errorCondition.quota = false
        activitie.ruleable.selected_gift = giftQuantity
      }

      activitie.ruleable.freebies.map(a => {
        a.gift_quantity = 0
        return a
      })
      // if (process.env.NODE_ENV === 'development') console.log('mathRuleProduct:selected_gift', `Math.min(Math.floor(totalQuantity:${totalQuantity} / perBuyQuantity:${perBuyQuantity}) * quantity:${quantity} , quota:${quota})`, activitie.ruleable.selected_gift)
      // console.log('mathRuleProduct', activitie.ruleable)
      return activitie
    },
    mathRuleRaffle (activitie) { // 抽獎運算
      // if (process.env.NODE_ENV === 'development') console.log('%cmathRuleRaffle', 'color: green;', activitie.ruleable)
      return activitie
    },
    handleActivity () { // 計算所有活動商品是否都已選擇完畢
      const ruleProducts = this.OrderModel.activities.filter(a => a.ruleable_type.includes('RuleProduct'))
      if (ruleProducts.length) {
        if (ruleProducts.every(activitie => activitie.ruleable.remaining_quota <= 0)) {
          return false
        }
        return !ruleProducts.every(activitie => activitie.ruleable.selected_gift === activitie.activity_items.reduce((a, item) => Number(a) + Number(item.gift_quantity), 0))
      }
      return false
    }
  },
  components: {
    ProdGrid,
    ActivitieGrid,
    ActivitieProdGrid,
    ActivitieProdGridItem
  }
}
</script>

<style lang="scss" scoped>
  .grid-table{
    .title {
      padding: 12px 0;
      @media (max-width: 991.5px) {
        display: none;
      }
    }
  }
</style>
