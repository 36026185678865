export function initProto (hydrating) {
  window.VueCreateElement = hydrating
  // eslint-disable-next-line no-proto
  window.VNode = hydrating('div').__proto__.constructor
}

export function nodeConvVNode (html, vm) {
  const VNode = window.VNode
  const tag = html.tagName
  const onEvent = {}
  Object.keys(html).forEach(key => {
    if (/^on/.test(key) && typeof html[key] === 'function') {
      onEvent[key] = html[key]
    }
  })
  const attrs = {
    style: html.style.cssText,
    class: html.classList.value,
    on: onEvent,
    attrs: {},
    domProps: {
      innerHTML: html.innerHTML
    }
  }
  html.attributes.forEach(attr => {
    if (!/^on/.test(attr.nodeName) || attr.nodeName !== 'class' || attr.nodeName !== 'style') {
      attrs.attrs[attr.nodeName] = attr.value
    }
  })
  return VNode(tag, attrs, undefined, undefined, undefined, vm)
}

export function createVNode (VueComponent) {
  const vm = this
  if (VueComponent instanceof window.VNode) {
    return VueComponent
  }
  if (VueComponent instanceof HTMLElement) {
    return nodeConvVNode(VueComponent, vm)
  }
  return VueComponent.render.call(vm)
}
