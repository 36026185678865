<template lang="pug">
  div(:style="{backgroundImage: `url(${urlBodyBg})`}")
    div(class="container shopcart bg-white px-lg-5 fs-lg-16 fs-14 d-flex flex-column" style="min-height: calc(100vh - 190px);")
      div(class="col-auto")
        div(class="row justify-content-between align-items-center flex-lg-row flex-column pt-3 pt-md-4 pt-xl-4 pb-3")
          div(class="col-12 col-lg-2 py-3")
              div(class="row align-items-center")
                h2(class="col-auto h3 font-weight-bold") 購物車
                div(class="col text-right text-success d-lg-none" style="font-size:1rem;") {{ stepText[step] }}
          div(class="col")
            div(class="step-bar")
              div(class="d-lg-flex d-none")
                div(class="col-20 text-center fs-12 mb-1" :class="{'d-none': index === 0}" style="font-size:1rem;" v-for="(item, index) in stepText") {{ item }}
              ul
                li(:style="{flex: `0 0 ${step * 20}%`, 'max-width': `${step * 20}%`}")
          div(class="col-2")
      router-view
</template>

<script>
import urlBodyBg from '@/assets/bg.png'
import { OrderModel } from '../../../models'

export default {
  name: 'Shopcart',
  data () {
    return {
      step: 0,
      model: new OrderModel(),
      urlBodyBg,
      stepText: ['交易失敗', '1.購物清單', '2.填入資料', '3.付款資訊', '4.訂單預覽', '5.訂單送出']
    }
  },
  created () {
    if (this.$route.path === '/member/shopcart') {
      this.$router.replace('/member/shopcart/step/1')
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/member/shopcart') {
        this.$router.replace('/member/shopcart/step/1')
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .step-bar {
    ul {
      background-color: #D8D8D8;
      height: 10px;
      padding: 2px 3px;
      list-style-type: none;
      border-radius: 99rem;
      display: flex;
      margin: 0;
      li{
        border-radius: 99rem;
        height: 100%;
        background-color: #56870F;
        transition: all 0.5s;
      }
    }
    .col-20{
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
</style>
