import DataModel from '../../library/proto/data-model'
import ImageModel from '../../library/proto/image-model'
import { getProductsById } from '../../api'

/**
 * @param {Number} id
 * @param {Number} category_id
 * @param {Number} series_id
 * @param {String} product_number 產品目錄編號
 * @param {String} name 產品名稱
 * @param {Number} price 價格
 * @param {String} unit 單位
 * @param {Number} member_price 會員價格
 * @param {Number} max_limited_buy 最大購買數量
 * @param {Number} pv 點數
 * @param {String} brief 說明
 * @param {String} feature 說明
 * @param {String} info 說明
 * @param {String} description 說明
 * @param {Number} free_shipping_quantity 免運數量
 * @param {Number} abroad_free_shipping_quantity 海外免運數量
 * @param {Number} is_recommended 是否為推薦商品
 * @param {Number} recommend_sort 推薦商品的排序
 * @param {Number} sort 排序
 * @param {String} published_at 上架時間
 * @param {String} finished_at 下架時間
 * @param {Number} quantity 庫存數量
 * @param {Number} gift_quantity 贈品數量
 * @param {Array<ImageModel>} images 圖片
*/
export default class ProductsModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.category_id = entity.category_id || 0
    this.series_id = entity.series_id || 0
    this.product_number = entity.product_number || ''
    this.name = entity.name || ''
    this.price = entity.price || 0
    this.unit = entity.unit || ''
    this.member_price = entity.member_price || 0
    this.max_limited_buy = entity.max_limited_buy || 0
    this.pv = entity.pv || 0
    this.brief = entity.brief || ''
    this.feature = entity.feature || ''
    this.info = entity.info || ''
    this.description = entity.description || ''
    this.free_shipping_quantity = entity.free_shipping_quantity || null
    this.abroad_free_shipping_quantity = entity.abroad_free_shipping_quantity || null
    this.is_recommended = entity.is_recommended || 0
    this.recommend_sort = entity.recommend_sort || 0
    this.sort = entity.sort || 0
    this.status = entity.status || 0
    this.published_at = entity.published_at || ''
    this.finished_at = entity.finished_at || ''
    this.updated_user = entity.updated_user || ''
    this.quantity = entity.quantity || 0
    this.gift_quantity = entity.gift_quantity || 0
    this.images = entity.images ? entity.images.map(image => new ImageModel(image)) : []
  }

  /**
   * 更新該 Model 的值為最新的參數
   * @returns {ProductsModel} 執行的該 Model 回傳
   */
  getData () {
    return new Promise(resolve => {
      getProductsById(this.id).then(res => {
        this.set(res.data)
        resolve(this)
      })
    })
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'images') {
        this[key] = entity[key].map(p => new ImageModel(p))
      } else {
        this[key] = entity[key]
      }
    })
  }

  toDatabase (addObject) {
    return {
      ...addObject
    }
  }
}
