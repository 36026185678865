<template lang="pug">
  div
    div(class="py-2")
      OrderView(:model="model")
    div(class="d-grid grid-table border" v-if="model.order_items.length" style="grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;;")
      div(class="text-center title") 商品
      div(class="text-center title")
      div(class="text-center title") 單價
      div(class="text-center title") 積分
      div(class="text-center title") 數量
      div(class="text-center title") 金額小計
      div(class="text-center title") 積分小計
      ProdGrid(v-for="(model, index) in model.order_items" :model="model" :key="model.id")
      ActivitieProdGridItem(v-for="model in activity_items" :model="model" :key="model.id")
      div(class="d-grid grid-tr grid-span fs-lg-16 fs-14 no-delete" v-if="model.shipping_fee !=0" )
        div(class="grid-span line")
        div(class="d-none d-lg-flex grid-image px-3 py-2 p-lg-3")
          img(:src="truck" style="width:100px")
        div(class="d-none d-lg-flex grid-text  align-items-center")
          div(class="cursor-pointer")
            div(class="") {{shippingCode}}
        div(class="d-none d-lg-flex grid-price d-lg-flex d-none flex-center")
        div(class="d-none d-lg-flex grid-pv d-lg-flex d-none flex-center")
        div(class="d-none d-lg-flex grid-price-pv d-flex d-lg-none")
        div(class="d-none d-lg-flex grid-quantity d-flex flex-center px-3 px-lg-0")
        div(class="d-none d-lg-flex grid-count-price justify-content-lg-center justify-content-end align-items-center px-3 my-1") 運費
        div(class="d-none d-lg-flex grid-count-pv  justify-content-lg-center justify-content-end align-items-center px-3 my-1") NT$ {{commaFormat(model.shipping_fee)}}
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 品號
          div() {{ shippingCode }}
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 商品
          img(:src="truck" style="width:100px")
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 運費
          div() NT$ {{commaFormat(model.shipping_fee)}}
      div(class="grid-span line d-none d-lg-block")
      div(class="grid-span")
        div(class="ml-auto px-3 py-2 max-w-lg-250")
          div(class="d-flex py-2 justify-content-between")
            div(class="text-dark totle-count-title")
              span 訂
              span 單
              span 金
              span 額
              span 總
              span 計
            div(class="text-danger font-weight-bold") NT$ {{ commaFormat(model.total_price_new) }}
          div(class="d-flex py-2 justify-content-between")
            div(class="text-dark totle-count-title")
              span 訂
              span 單
              span 積
              span 分
              span 總
              span 計
            div(class="text-primary font-weight-bold") {{ commaFormat(model.total_pv) }}

    div(class="py-2" v-if="this.model.activities.length")
      div(class="border fs-lg-16 fs-14")
        div(class="border-bottom text-center py-2") 特惠活動
        ActivitieGrid(v-for="(item, index) in model.activities" :showText="true" :model="item" :hasLimit="item.hasLimit" :key="item.id" :class="{'border-top': index}")
    div(class="form-group form-check" v-if="showTerms")
      input(type="checkbox" class="form-check-input" id="regulation" v-model="checkbox" value="同意" )
      label(class="form-check-label text-left fs-14" for="regulation")
        span(class="form-check-box")
        span 我已閱讀並同意活動辦法與注意事項
        a(@click="checkmodal" href="#") 請先點選此連結開啟閱讀後勾選
    div(class="pb-5")
      div(class="row mx-n1 py-2 justify-content-end align-items-center flex-column flex-lg-row")
        //router-link(class="w-100 btn btn-outline-success m-1 max-w-300 max-w-lg-180" to="/member/shopcart/step/3") 上一步
        button(class="w-100 btn btn-success m-1 max-w-300" @click="toStep(3)" type="button") 上一步
        button(class="w-100 btn btn-success m-1 max-w-300" @click="nextStep" type="button" :disabled="submitDisabled") 下一步
</template>

<script>
import truck from '@/assets/images/truck.png'
import dayjs from 'dayjs'
import OrderView from '../../../container/order/OrderView.vue'
import ProdGrid from '../../../container/order/ProdGrid.vue'
import ActivitieGrid from '../../../container/order/ActivitieGrid.vue'
import { mapActions, mapState } from 'vuex'
import { createOrders, deleteShopcartById, getTerms } from '../../../api'
import { NatalchartDataModel, ProductsModel } from '../../../models'
import Step4Lightbox from '@/views/dialog/Step4Lightbox'
import { Toast } from '@/utils/helper'
import ActivitieProdGridItem from '../../../container/order/ActivitieProdGridItem.vue'

export default {
  data () {
    return {
      model: this.$parent.model,
      checkbox: false,
      productsStockEmpty: [],
      submitDisabled: false,
      content: '',
      showTerms: 1,
      truck,
      shippingCode: '06004',
      activity_items: []
    }
  },
  created () {
    const step = Number(this.$parent.step)
    if (step === 4) {
      this.$parent.step = 4
    } else {
      this.$router.replace('1')
      return
    }
    this.getOrderItems()
    this.getTerms()
    // console.log('model step4', this.model.activities)
    this.model.activities.map(a => {
      this.activity_items = this.activity_items.concat(a.activity_items)
      return a.activity_items
    })
    if (parseInt(this.model.shipping_fee) === 100) {
      this.shippingCode = '06004'
    } else if (parseInt(this.model.shipping_fee) === 200) {
      this.shippingCode = '0600401'
    } else if (parseInt(this.model.shipping_fee) === 250) {
      this.shippingCode = '0600402'
    }
    if (parseInt(this.model.total_price) > 0) {
      this.model.total_price_new = this.model.shipping_fee + this.model.total_price
    }
  },
  updated () {
  },
  computed: {
    ...mapState({
      PaymentMethods: state => state.model.PaymentMethods,
      ShopCarts: state => state.model.ShopCarts,
      Products: state => state.model.Products,
      LoveCodes: state => state.model.LoveCodes,
      UserData: state => state.model.UserData
    }),
    luckyProducts () {
      return this.model.order_items.filter(p => p.is_lucky)
    }
  },
  methods: {
    ...mapActions(['getListModel', 'natalchartFormat']),
    async getTerms () {
      getTerms().then(response => {
        const data = response.data
        // console.log('getTerms', data)
        this.content = data[0].content
        this.showTerms = data[0].status
      }).catch(err => {
        console.log(err)
      })
    },
    async getOrderItems () {
      const prod = await this.getProductsByIdGroup(this.model.order_items.map(p => Number(p.product_id)))
      const result = await Promise.all(
        this.model.order_items.map(p => {
          if (p.is_lucky) {
            return this.natalchartFormat({
              ...p,
              fortune_year: dayjs(p.fortune_birth_at).format('YYYY'),
              fortune_month: dayjs(p.fortune_birth_at).format('MM'),
              fortune_day: dayjs(p.fortune_birth_at).format('DD')
            })
          }
          return Promise.resolve()
        })
      )
      this.model.order_items.forEach((model, index) => {
        const Product = new ProductsModel(prod[index])
        const NatalchartsData = new NatalchartDataModel(result[index])
        model.products = Product
        model.result = NatalchartsData
        if (model.is_lucky) {
          model.fortune_is_leap = model.result.leap
        }
        model.product_quantity = Math.min(this.maxLimitedBuy(Product), model.product_quantity)
        // order_items 要給 ProdGrid 做判斷
        model.quantity = model.product_quantity
      })
    },
    async checkmodal () {
      await this.$dialog.open(Step4Lightbox, {
        onBackgroundClick: false,
        position: {
          x: 'center',
          y: 'center'
        },
        attrs: {
          model: this.model,
          checkbox: this.checkbox,
          content: this.content
        },
        width: '1080px'
      })

      await this.$nextTick()
      this.checkbox = true
    },
    getProductsByIdGroup (arrId) { // 依據產品 id 取得最新的 Products 資料
      return Promise.all(
        arrId.map(id => this.$store.dispatch('getModelById', {
          name: 'Products',
          id
        }))
      )
    },
    checkStockEmpty () {
      this.model.order_items.forEach(item => {
        if (item.products.quantity <= 0 && item.is_lucky === false) {
          this.productsStockEmpty.push(item.name)
        }
      })
      return this.productsStockEmpty.length
    },
    toStep (step, query) {
      return new Promise(resolve => {
        this.$parent.step = Number(step)
        if (query) {
          this.$router.push({
            query,
            path: String(step)
          })
        } else {
          this.$parent.step = step
          this.$router.push(String(step))
        }
        resolve()
      })
    },
    cardStep (url) {
      return new Promise(resolve => {
        this.$parent.step = 1
        location.href = url
        console.log('set cardStep', this.$parent.step)
        this.toStep(1)
        resolve()
      })
    },
    async nextStep () {
      if (this.showTerms === 1) {
        if (this.checkbox === false) {
          Toast.fire({
            icon: 'warning',
            title: '請先同意活動辦法選項'
          })
          return
        }
        this.model.set({
          terms_agree: 1
        })
      }
      localStorage.removeItem('order-response')
      // 檢查庫存，若無庫存則導回第一頁
      const productIdGroup = this.model.order_items.map(p => p.product_id)
      await this.getProductsByIdGroup(productIdGroup)
      if (this.checkStockEmpty()) {
        alert(`${this.productsStockEmpty.join(',')}目前已無庫存，請稍後再試。`)
        this.$parent.step = 1
        this.$router.push('1')
        return
      }
      this.submitDisabled = true
      createOrders(this.model.toDatabase()).then(async res => {
        localStorage.setItem('order-response', '00')
        // this.$parent.model = new OrderModel()
        if (res.data.payment.toLowerCase() === 'atm') {
          // TODO
          /**
           * 因為 ATM 結帳後端不會給 id 帶到下一步，所以要在這一步先處理好。
           * 預計後續將從 this.model 統一帶到下一步，但需要用 order_number 更新資料
           */
          // 清除已結帳的購物車資訊
          const shopcartIdGroup = this.model.order_items.map(p => p.id)
          Promise.all(
            shopcartIdGroup.map(id => deleteShopcartById(id))
          ).then(() => {
            shopcartIdGroup.forEach(id => {
              const index = this.ShopCarts.data.map(p => p.id).indexOf(id)
              this.ShopCarts.data.splice(index, 1)
            })
          })
          // 跳轉下一頁
          this.toStep(5, {
            retCode: '00'
          })
        } else {
          if (res.data.url) {
            this.cardStep(res.data.url)
          }
        }
      }).catch(err => {
        // 各種使用者可能遇到的錯誤
        let remindFail = '登入失敗'
        if (err && err.response) {
          const statusFromBackend = Number(err.response.data.status_from_backend)
          const errorActivityId = Number(err.response.data.error_activity_id)
          // const errorType = (err.response.data.error_type)
          // const message = (err.response.data.message)
          if (statusFromBackend === 4001) {
            remindFail = '該用戶有效日期已過'
            alert(remindFail)
            this.$store.commit('logout')
            return
          } else if (statusFromBackend === 4002) {
            remindFail = '非首次登入'
          } else if (statusFromBackend === 4003) {
            remindFail = '尚未完成簡訊驗證，請至第一次登入 / 尚未開通，請至第一次登入'
            alert(remindFail)
            this.$store.commit('logout')
            return
          } else if (statusFromBackend === 4004) {
            remindFail = '登入失敗'
            alert(remindFail)
            this.$store.commit('logout')
            return
          } else if (statusFromBackend === 4005) {
            remindFail = '該用戶已退出'
            alert(remindFail)
            this.$store.commit('logout')
            return
          } else if (statusFromBackend === 4006) {
            remindFail = '購物網站未開啟'
            alert(remindFail)
            this.toStep(1)
          } else if (statusFromBackend === 4007) {
            remindFail = '商品庫存不足，請重新下訂單'
            alert(remindFail)
            this.toStep(1)
            return
          } else if (statusFromBackend === 4008) {
            remindFail = '訂單內容價格，積分，運費有誤'
            alert(remindFail)
          } else if (statusFromBackend === 4009) {
            remindFail = '訂單儲存失敗，請重新下單'
            alert(remindFail)
          } else if (statusFromBackend === 4010) {
            remindFail = '活動贈品已贈送完畢。'
            // this.toStep(1)

            const activity = this.model.activities.filter(function (value) {
              // console.log('value.id', value.id)
              return value.id === errorActivityId
            })

            // console.log(this.model.activities)
            console.log('activity', activity)
            let actName = ''
            if (activity != null && activity.length > 0) {
              actName = activity[0].title
            }
            if (activity != null && activity.length > 0 && activity[0].ruleable_type.includes('RuleProduct')) {
              console.log(' RuleProduct')
              Toast.fire({
                title: '部分贈品已被贈送完畢！',
                icon: 'warning',
                confirmButtonText: 'OK'
              }).then((result) => {
                this.toStep(1)
              })
            } else {
              console.log('not RuleProduct')
              Toast.fire({
                title: actName,
                html:
                  '<b>部分贈品已被贈送完畢，請問是否繼續此訂單?</b> ' +
                  '<br />' +
                  '您可能無法得到全部贈品',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: '是',
                cancelButtonText: '否'
              }).then((result) => {
                if (result.isConfirmed) {
                  const found = this.model.agree_freebie_none_ids.find(element => element === errorActivityId)
                  if (found === undefined) {
                    this.model.agree_freebie_none_ids.push(errorActivityId)
                    this.model.set({
                      agree_freebie_none_ids: this.model.agree_freebie_none_ids
                    })
                  }
                  console.log('model', this.model)
                  this.nextStep()
                } else {
                  this.toStep(1)
                }
              })
            }

            return
          } else if (statusFromBackend === 4011) {
            remindFail = '活動已截止'
            this.toStep(1)
            return
          } else if (statusFromBackend === 4012) {
            remindFail = '贈品選擇不符規則'
            this.toStep(1)
            return
          } else if (statusFromBackend === 4013) {
            remindFail = '活動部分贈品已贈送完畢'
            // 贈品-固定積分
            // 贈品-範圍積分
            // 抽獎
            // 加上是或否
            // console.log(remindFail)

            const activity = this.model.activities.filter(function (value) {
              // console.log('value.id', value.id)
              return value.id === errorActivityId
            })

            // console.log(this.model.activities)
            console.log('activity', activity)
            let actName = ''
            if (activity != null && activity.length > 0) {
              actName = activity[0].title
            }

            if (activity != null && activity.length > 0 && activity[0].ruleable_type.includes('RuleProduct')) {
              console.log(' RuleProduct')

              Toast.fire({
                title: '部分贈品已被贈送完畢！',
                icon: 'warning',
                confirmButtonText: 'OK'
              }).then((result) => {
                this.toStep(1)
              })
            } else {
              console.log('not RuleProduct')
              Toast.fire({
                title: actName,
                html:
                  '<b>部分贈品已被贈送完畢，請問是否繼續此訂單?</b> ' +
                  '<br />' +
                  '您可能無法得到全部贈品',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: '是',
                cancelButtonText: '否'
              }).then((result) => {
                if (result.isConfirmed) {
                  const found = this.model.agree_freebie_none_ids.find(element => element === errorActivityId)
                  if (found === undefined) {
                    this.model.agree_freebie_none_ids.push(errorActivityId)
                    this.model.set({
                      agree_freebie_none_ids: this.model.agree_freebie_none_ids
                    })
                  }
                  // console.log('model', this.model)
                  this.nextStep()
                } else {
                  this.toStep(1)
                }
              })
            }
            return
          } else if (statusFromBackend === 4014) {
            remindFail = '此活動已取消三次訂單，故暫停此網路購物功能'
            Toast.fire({
              title: remindFail,
              icon: 'warning',
              confirmButtonText: 'OK'
            }).then((result) => {
              this.toStep(1)
            })

            return
          } else {
            remindFail = '系統錯誤'
          }
          this.$parent.error = remindFail
          this.toStep(5, {
            error: '500'
          }).then(() => {
            alert(remindFail)
          })
        }
      })
    }
  },
  components: {
    ProdGrid,
    OrderView,
    ActivitieGrid,
    ActivitieProdGridItem
  }
}
</script>

<style lang="scss" scoped>
.grid-tr {
  grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    .two-col{
      display: grid;
      grid-template-columns:  1fr 1fr ;
    }
  }
}

.grid-table {
  grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;

  .title {
    padding: 12px 0;
    @media (max-width: 991.5px) {
      display: none;
    }
  }
}

.grid-span {
  grid-column: 1 / -1;

  &.line {
    background-color: rgba(216, 216, 216, 0.6);
    height: 1px;
  }
}

.information-title {
  width: 150px;
}

.table {
  border: 1px solid #D8D8D8;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.form-check {
  padding-left: 1.5rem;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-check-box {
  margin-top: 3px;
}
</style>
