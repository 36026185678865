import DataModel from './data-model'
import { v4 as uuid } from 'uuid'

/**
 * @param {Number} id 該Model的辨識索引
 */
export default class ImageModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || uuid()
    this.name = entity.name || ''
    this.sort = entity.sort || 0
    this.type = entity.type || ''
    this.status = entity.status || ''
    this.size = entity.size || 0
    this.image_alt = entity.image_alt || ''
    this.image_title = entity.image_title || ''
    this.image = entity.image || ''
    this.updated_user = entity.updated_user || ''
  }
}
