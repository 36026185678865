export default {
  mount: '.icon-tocin-food',
  attrs: {
    viewBox: '0 0 358 321',
    fill: 'currentColor'
  },
  path: `
  <g id="底層">
    <path id="交叉_1" fill="#E8F5EB" d="M127.2,68.2c31.8-18,70.7-18.2,102.5-0.3L208,106c-18.5-9.1-40.3-9-58.7,0.2L127.2,68.2z"/>
    <g id="组_762" transform="translate(168.947368, 64.241785)">
      <g id="東震icon-01">
        <path id="路径_309" d="M19.4,1.6c-1-1-2.3-1.6-3.8-1.6s-2.8,0.6-3.8,1.6L11.4,2L11,1.6C10,0.6,8.7,0,7.3,0S4.5,0.6,3.5,1.6
          c-2.1,2.2-2.1,5.6,0,7.8l7.5,7.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l7.5-7.8C21.4,7.2,21.4,3.8,19.4,1.6z M18.6,8.6
          L11.4,16L4.3,8.6c-1.6-1.7-1.6-4.4,0-6.1c0.8-0.8,1.8-1.3,3-1.3s2.2,0.5,3,1.3L11,3.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
          l0.8-0.8c0.8-0.8,1.8-1.3,3-1.3s2.2,0.5,3,1.3C20.2,4.1,20.2,6.9,18.6,8.6z"/>
        <path id="路径_310" d="M2.7,6.9H0.6C0.4,6.9,0.2,7,0.1,7.2C0,7.4,0,7.6,0.1,7.8s0.3,0.3,0.5,0.3h2.1C3,8,3.2,7.8,3.2,7.5
          C3.2,7.2,3,6.9,2.7,6.9L2.7,6.9z"/>
        <path id="路径_311" d="M20.2,7.3h-5.3L14,5.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.3,0.3l-1.7,5l-2.1-6
          C9.5,4.3,9.3,4.2,9.2,4.2C9,4.2,8.9,4.3,8.8,4.5L7.4,7.5H2.8c-0.1,0-0.2,0-0.3,0.1C2.5,7.7,2.5,7.8,2.5,7.9
          c0,0.2,0.2,0.4,0.4,0.4h4.8C7.9,8.3,8,8.2,8,8.1l1.1-2.5l2.1,6.1c0.1,0.2,0.2,0.3,0.4,0.3l0,0c0.2,0,0.3-0.1,0.4-0.3l1.7-5.1
          l0.5,1.3c0.1,0.1,0.3,0.2,0.4,0.2h5.6c0.2,0,0.3-0.2,0.3-0.4C20.5,7.5,20.4,7.3,20.2,7.3z"/>
        <path id="路径_312" d="M22,6.7h-1.7c-0.3,0-0.6,0.3-0.6,0.6s0.2,0.6,0.6,0.6H22c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0-0.6
          C22.4,6.8,22.2,6.7,22,6.7L22,6.7z"/>
      </g>
      <g id="健康" transform="translate(1.995866, 19.462488)">
        <g>
          <path id="path-3" d="M2,8.5V2l0.2-0.6c0.1-0.4,0.2-0.8,0.3-1l0,0L2.1,0.3C1.7,1.8,1.1,3,0.5,4.1l0,0l0.3,0.4
            c0.3-0.5,0.5-1,0.7-1.4l0,0v5.4H2z M6.5,7.6v-1h2V6.2h-2V5.4h1.8V4.9H6.5V4.2h1.4V2.9h0.5V2.4H7.9V1.2H6.5V0.4H6v0.8H4.9v0.5H6
            v0.8H4.4v0.4H6v0.8H5v0.4H6v0.7H4.6v0.4H6v0.8H4.4v0.5H6v1H6.5z M2.6,8.6c0.4-0.4,0.7-0.8,1-1.3C3.9,7.7,4.4,8,5.1,8.2
            c0.6,0.2,1.7,0.2,3.3,0.2l0,0L8.5,8c-1.6,0-2.7,0-3.3-0.2c-0.6-0.1-1-0.4-1.4-0.9C4.1,6,4.3,4.9,4.3,3.8l0,0H3.2
            c0.3-0.5,0.6-1.2,1-2.1l0,0V1.2H2.7v0.4h1C3.4,2.5,3,3.2,2.7,3.8l0,0v0.4h1.2C3.8,5.1,3.7,5.8,3.5,6.4C3.3,6,3.1,5.5,2.9,5l0,0
            L2.5,5.2C2.7,5.8,3,6.4,3.3,6.9C3,7.3,2.7,7.8,2.3,8.2l0,0L2.6,8.6z M7.4,2.4H6.5V1.6h0.9V2.4z M7.4,3.7H6.5V2.9h0.9V3.7z
            M10.2,8.6C10.7,7.6,11,6.1,11,4l0,0h2.8v0.7h-2.1v0.4h2.1v2.7c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.5,0c-0.2,0-0.3,0-0.4,0l0,0
            l0.2,0.5c0.4,0,0.6,0,0.8,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.6l0,0V5.2h2.3V4h1V3.6h-1V2.4h-2.3V1.6h3.2V1.2h-3.2
            c-0.1-0.2-0.2-0.5-0.4-0.8l0,0l-0.4,0.2c0.1,0.2,0.2,0.4,0.3,0.6l0,0h-3.2v3.4c0,1.6-0.3,2.8-0.8,3.7l0,0L10.2,8.6z M13.8,3.6
            H11V1.6h2.8v0.8h-2.1v0.5h2.1V3.6z M16.1,3.6h-1.8V2.9h1.8V3.6z M16.1,4.7h-1.8V4h1.8V4.7z M12.8,6.6l0.3-0.4
            c-0.4-0.3-0.9-0.6-1.4-0.8l0,0l-0.3,0.4C11.9,6,12.3,6.2,12.8,6.6L12.8,6.6z M15.2,6.6c0.6-0.2,1.2-0.5,1.7-0.8l0,0l-0.3-0.4
            c-0.5,0.3-1.1,0.5-1.7,0.7l0,0L15.2,6.6z M16.9,8.4L17.2,8c-0.6-0.5-1.3-0.9-2-1.2l0,0L15,7.2C15.7,7.6,16.3,8,16.9,8.4
            L16.9,8.4z M11.3,8.4c0.6-0.3,1.3-0.7,2-1.1l0,0L13,6.8c-0.6,0.4-1.3,0.8-1.9,1.1l0,0L11.3,8.4z"/>
        </g>
        <g>
          <path id="path-3_1_" d="M2,8.5V2l0.2-0.6c0.1-0.4,0.2-0.8,0.3-1l0,0L2.1,0.3C1.7,1.8,1.1,3,0.5,4.1l0,0l0.3,0.4
            c0.3-0.5,0.5-1,0.7-1.4l0,0v5.4H2z M6.5,7.6v-1h2V6.2h-2V5.4h1.8V4.9H6.5V4.2h1.4V2.9h0.5V2.4H7.9V1.2H6.5V0.4H6v0.8H4.9v0.5H6
            v0.8H4.4v0.4H6v0.8H5v0.4H6v0.7H4.6v0.4H6v0.8H4.4v0.5H6v1H6.5z M2.6,8.6c0.4-0.4,0.7-0.8,1-1.3C3.9,7.7,4.4,8,5.1,8.2
            c0.6,0.2,1.7,0.2,3.3,0.2l0,0L8.5,8c-1.6,0-2.7,0-3.3-0.2c-0.6-0.1-1-0.4-1.4-0.9C4.1,6,4.3,4.9,4.3,3.8l0,0H3.2
            c0.3-0.5,0.6-1.2,1-2.1l0,0V1.2H2.7v0.4h1C3.4,2.5,3,3.2,2.7,3.8l0,0v0.4h1.2C3.8,5.1,3.7,5.8,3.5,6.4C3.3,6,3.1,5.5,2.9,5l0,0
            L2.5,5.2C2.7,5.8,3,6.4,3.3,6.9C3,7.3,2.7,7.8,2.3,8.2l0,0L2.6,8.6z M7.4,2.4H6.5V1.6h0.9V2.4z M7.4,3.7H6.5V2.9h0.9V3.7z
            M10.2,8.6C10.7,7.6,11,6.1,11,4l0,0h2.8v0.7h-2.1v0.4h2.1v2.7c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.5,0c-0.2,0-0.3,0-0.4,0l0,0
            l0.2,0.5c0.4,0,0.6,0,0.8,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.6l0,0V5.2h2.3V4h1V3.6h-1V2.4h-2.3V1.6h3.2V1.2h-3.2
            c-0.1-0.2-0.2-0.5-0.4-0.8l0,0l-0.4,0.2c0.1,0.2,0.2,0.4,0.3,0.6l0,0h-3.2v3.4c0,1.6-0.3,2.8-0.8,3.7l0,0L10.2,8.6z M13.8,3.6
            H11V1.6h2.8v0.8h-2.1v0.5h2.1V3.6z M16.1,3.6h-1.8V2.9h1.8V3.6z M16.1,4.7h-1.8V4h1.8V4.7z M12.8,6.6l0.3-0.4
            c-0.4-0.3-0.9-0.6-1.4-0.8l0,0l-0.3,0.4C11.9,6,12.3,6.2,12.8,6.6L12.8,6.6z M15.2,6.6c0.6-0.2,1.2-0.5,1.7-0.8l0,0l-0.3-0.4
            c-0.5,0.3-1.1,0.5-1.7,0.7l0,0L15.2,6.6z M16.9,8.4L17.2,8c-0.6-0.5-1.3-0.9-2-1.2l0,0L15,7.2C15.7,7.6,16.3,8,16.9,8.4
            L16.9,8.4z M11.3,8.4c0.6-0.3,1.3-0.7,2-1.1l0,0L13,6.8c-0.6,0.4-1.3,0.8-1.9,1.1l0,0L11.3,8.4z"/>
        </g>
      </g>
    </g>
    <path id="交叉_1-2" fill="#E8F5EB" d="M232.9,69.9c31.7,18.7,51.4,52.5,51.9,89.3l-44.1,0.1c-1.4-20.8-12.4-39.7-29.8-51.2
      L232.9,69.9z"/>
    <g id="组_767" transform="translate(240.501548, 104.987915)">
      <g id="组_766">
        <path id="路径_339" d="M5,9.3c-0.1,0-0.2,0.1-0.3,0.1C4.6,9.5,4.6,9.6,4.6,9.8V10c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V9.8
          C5.5,9.5,5.3,9.3,5,9.3z"/>
        <g id="组_765">
          <path id="路径_338" d="M9.9,12.5c-0.2-0.1-0.5,0-0.6,0.3c-0.3,0.7-0.9,1.1-1.6,1.1c-0.7,0-1.3-0.4-1.6-1.1
            c-0.1-0.2-0.4-0.4-0.6-0.3c-0.2,0.1-0.4,0.4-0.3,0.6c0.4,1,1.4,1.7,2.4,1.7s2-0.7,2.4-1.7C10.2,12.9,10.1,12.6,9.9,12.5
            L9.9,12.5z"/>
          <path id="路径_340" d="M10.3,9.3c-0.3,0-0.5,0.2-0.5,0.5V10c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V9.8
            C10.8,9.5,10.6,9.3,10.3,9.3z"/>
          <path id="路径_341" d="M6.1,18.2c0.5,0.2,1,0.2,1.5,0.2c0.5,0,1-0.1,1.5-0.2c0.2-0.1,0.7-0.3,0.9-0.4c0.6-0.3,1.1-0.6,1.5-1.1
            c1-0.9,1.6-2.2,1.7-3.5c0.9,0,1.7-0.8,1.7-1.7v-0.6c0-0.4-0.1-0.8-0.4-1.1l1.1-4.3c0.1-0.3-0.1-0.5-0.3-0.6
            c-0.2-0.1-0.5,0.1-0.6,0.3l-1,4c-0.1,0-0.3-0.1-0.4-0.1V7.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
            C10.2,7.9,8,7.9,6.3,7C5.6,6.7,5,6.1,4.6,5.4C4.5,5.3,4.2,5.2,4,5.2C3.8,5.3,3.7,5.5,3.7,5.7c0,1.2-0.2,1.4-1.5,1.5
            C2,7.2,1.8,7.4,1.8,7.7v1.5c-0.2,0-0.3,0-0.5,0.1C1.2,8.7,1.1,8.2,1,7.6c0-1,0-2,0-3c0,0,0,0,0,0c0-0.8,0.6-1.4,1.4-1.4H3
            c0.1,0,0.2,0,0.3-0.1L5,1.4C5.4,1,5.9,0.9,6.3,1l5.8,1.4c1.2,0.3,2.5,0,3.5-0.8c0,0,0,0,0.1,0c0,0,0,0,0,0.1l-0.4,1.4
            c-0.1,0.3,0.1,0.5,0.3,0.6c0.2,0.1,0.5-0.1,0.6-0.3l0.4-1.4c0.1-0.4-0.1-0.9-0.4-1.1c-0.3-0.2-0.8-0.2-1.1,0.1
            c-0.8,0.6-1.7,0.8-2.7,0.6L6.5,0.1c-0.8-0.2-1.6,0-2.2,0.6L2.9,2.2H2.3c-1.3,0-2.3,1-2.3,2.3C0,5.6,0,6.7,0,7.7
            c0.1,0.7,0.3,1.4,0.5,2.1c-0.2,0.3-0.4,0.7-0.4,1.1v0.6c0,1,0.8,1.7,1.7,1.7c0.2,2,1.5,3.8,3.3,4.6C5.4,18,5.8,18.2,6.1,18.2z
            M1.1,10.9c0-0.4,0.3-0.8,0.8-0.8h0.4c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3V8.1c1-0.1,1.5-0.5,1.7-1.3
            c0.4,0.4,0.9,0.7,1.4,1C8,9,10.5,8.7,12.4,8.2v1.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.4c0.4,0,0.8,0.4,0.8,0.8v0.6
            c0,0.4-0.4,0.8-0.8,0.8h-0.4c-0.2,0-0.4,0.2-0.5,0.5c-0.1,1.3-0.6,2.5-1.5,3.3c-0.4,0.4-1,0.8-1.5,1l0,0c-1.2,0.5-2.5,0.5-3.6,0
            l0,0c-1.7-0.7-2.9-2.4-3-4.3c0-0.3-0.2-0.5-0.5-0.5H1.9c-0.4,0-0.8-0.4-0.8-0.8L1.1,10.9z"/>
        </g>
      </g>
      <g id="夫" transform="translate(3.918141, 21.110135)">
        <g>
          <path id="path-4" d="M0.9,7.8c0.9-0.4,1.6-1,2.2-1.6s0.9-1.3,1-2c0.4,1.6,1.5,2.8,3.4,3.6l0,0l0.3-0.4C6.1,6.6,5,5.5,4.6,4.1
            l0,0h3.1V3.6H4.2c0.1-0.6,0.1-1.1,0.1-1.6l0,0h3.1V1.6h-3c0-0.5,0-1,0-1.4l0,0H3.9c0,0.7,0,1.1,0,1.4l0,0h-3v0.4h3
            c0,0.6-0.1,1.1-0.1,1.6l0,0H0.7v0.5h3C3.6,4.8,3.2,5.4,2.7,6S1.4,7.1,0.6,7.4l0,0L0.9,7.8z"/>
        </g>
        <g>
          <path id="path-4_1_" d="M0.9,7.8c0.9-0.4,1.6-1,2.2-1.6s0.9-1.3,1-2c0.4,1.6,1.5,2.8,3.4,3.6l0,0l0.3-0.4C6.1,6.6,5,5.5,4.6,4.1
            l0,0h3.1V3.6H4.2c0.1-0.6,0.1-1.1,0.1-1.6l0,0h3.1V1.6h-3c0-0.5,0-1,0-1.4l0,0H3.9c0,0.7,0,1.1,0,1.4l0,0h-3v0.4h3
            c0,0.6-0.1,1.1-0.1,1.6l0,0H0.7v0.5h3C3.6,4.8,3.2,5.4,2.7,6S1.4,7.1,0.6,7.4l0,0L0.9,7.8z"/>
        </g>
      </g>
    </g>
    <path id="交叉_1-3" fill="#E8F5EB" d="M285.1,161.9c-0.3,36.8-19.8,70.8-51.4,89.6l-22.2-38.1c17.3-11.6,28.1-30.6,29.4-51.3
      L285.1,161.9z"/>
    <g id="组_770" transform="translate(243.482972, 184.492559)">
      <g id="妻" transform="translate(3.273825, 21.425207)">
        <g>
          <path id="path-7" d="M4.8,4.9V4.6h3V3.5h1V3.1h-1V2h-3V1.4h3.8V1H4.8V0.4H4.3V1H0.9v0.4h3.4V2H1.5v0.4h2.8v0.6H0.6v0.4h3.7v0.7
            H1.5v0.4h2.8v0.3H4.8z M7.3,3.1H4.8V2.4h2.5V3.1z M7.3,4.2H4.8V3.5h2.5V4.2z M8.3,8.5l0.3-0.4c-0.8-0.2-1.8-0.5-3-0.9
            C5.8,7,6.2,6.5,6.6,5.9l0,0h2.1V5.5H6.9c0,0,0.1-0.1,0.1-0.3l0,0L6.6,5.1L6.4,5.5H4c0.1-0.2,0.3-0.3,0.3-0.4l0,0L3.9,4.9
            C3.7,5.1,3.6,5.3,3.4,5.5l0,0H0.8v0.4h2.3l-0.9,1C2.8,7,3.5,7.2,4.3,7.4C3.2,7.8,2,8,0.8,8l0,0L1,8.5c1.6-0.1,3-0.4,3.9-0.9
            C6.2,7.8,7.4,8.2,8.3,8.5L8.3,8.5z M4.9,7.1C4.6,7,4,6.9,3.1,6.7c0.1-0.1,0.3-0.4,0.6-0.7l0,0h2.4C5.8,6.4,5.4,6.8,4.9,7.1z"/>
        </g>
        <g>
          <path id="path-7_1_" d="M4.8,4.9V4.6h3V3.5h1V3.1h-1V2h-3V1.4h3.8V1H4.8V0.4H4.3V1H0.9v0.4h3.4V2H1.5v0.4h2.8v0.6H0.6v0.4h3.7
            v0.7H1.5v0.4h2.8v0.3H4.8z M7.3,3.1H4.8V2.4h2.5V3.1z M7.3,4.2H4.8V3.5h2.5V4.2z M8.3,8.5l0.3-0.4c-0.8-0.2-1.8-0.5-3-0.9
            C5.8,7,6.2,6.5,6.6,5.9l0,0h2.1V5.5H6.9c0,0,0.1-0.1,0.1-0.3l0,0L6.6,5.1L6.4,5.5H4c0.1-0.2,0.3-0.3,0.3-0.4l0,0L3.9,4.9
            C3.7,5.1,3.6,5.3,3.4,5.5l0,0H0.8v0.4h2.3l-0.9,1C2.8,7,3.5,7.2,4.3,7.4C3.2,7.8,2,8,0.8,8l0,0L1,8.5c1.6-0.1,3-0.4,3.9-0.9
            C6.2,7.8,7.4,8.2,8.3,8.5L8.3,8.5z M4.9,7.1C4.6,7,4,6.9,3.1,6.7c0.1-0.1,0.3-0.4,0.6-0.7l0,0h2.4C5.8,6.4,5.4,6.8,4.9,7.1z"/>
        </g>
      </g>
      <g id="组_769">
        <path id="路径_334" d="M10.8,13.3c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.2,0.1-0.3,0.3c-0.3,0.7-1,1.1-1.7,1.1S7,14.2,6.7,13.5
          c-0.1-0.2-0.4-0.4-0.7-0.3c-0.3,0.1-0.4,0.4-0.3,0.6c0.4,1.1,1.5,1.8,2.7,1.8s2.2-0.7,2.7-1.8C11.2,13.7,11.1,13.4,10.8,13.3
          L10.8,13.3z"/>
        <path id="路径_335" d="M5.5,9.9c-0.1,0-0.3,0.1-0.4,0.1C5.1,10.1,5,10.2,5,10.4v0.3c0,0.3,0.2,0.5,0.5,0.5
          c0.3,0,0.5-0.2,0.5-0.5v-0.3C6,10.2,6,10.1,5.9,10C5.8,9.9,5.7,9.9,5.5,9.9z"/>
        <path id="路径_336" d="M11.3,9.9c-0.3,0-0.5,0.2-0.5,0.5v0.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-0.3
          c0-0.1-0.1-0.3-0.1-0.4C11.6,9.9,11.5,9.9,11.3,9.9z"/>
        <path id="路径_337" d="M10.5,19.1h3.4c1.5,0,2.7-1.2,2.7-2.7V9c0-0.3-0.2-0.5-0.5-0.5S15.6,8.7,15.6,9v1.1
          c-0.3-0.2-0.6-0.3-1-0.3V7.4c0-1.1-0.4-2.2-1-3.2C13.5,4.1,13.4,4,13.3,4c-0.1,0-0.3,0-0.4,0.1C10,6.4,6.2,7.4,2.6,6.7
          c-0.1,0-0.3,0-0.4,0.1C2.1,6.9,2,7,2,7.1c0,0.1,0,0.2,0,0.3v2.4c-0.3,0-0.7,0.1-1,0.3V6.3C1,3.4,3.5,1,6.5,1h3.4
          c0.6,0,1.2,0.4,1.5,0.9l0.2,0.5c0.1,0.2,0.3,0.3,0.5,0.3h1c1.4,0,2.6,1.1,2.6,2.6v1.4c0,0.3,0.2,0.5,0.5,0.5
          c0.3,0,0.5-0.2,0.5-0.5V5.3c0-2-1.6-3.5-3.6-3.5h-0.7l-0.1-0.2C11.8,0.6,10.9,0,9.8,0H6.5C2.9,0,0,2.8,0,6.3v10.1
          c0,1.5,1.2,2.7,2.7,2.7h3.5l0.4,0.1c1.1,0.3,2.3,0.3,3.3,0 M15.6,16.5c0,0.9-0.8,1.7-1.7,1.7h-1.7c0.2-0.1,0.3-0.2,0.4-0.4
          c1-1,1.7-2.3,1.9-3.7c0.4,0,0.7-0.1,1-0.3V16.5z M2,10.8h0.5c0.3,0,0.5-0.2,0.5-0.5V7.8c3.6,0.5,7.2-0.4,10.1-2.5
          c0.4,0.7,0.6,1.4,0.5,2.1v2.9c0,0.3,0.2,0.5,0.5,0.5h0.5c0.5,0,0.8,0.4,0.9,0.8v0.6c0,0.5-0.4,0.8-0.9,0.8h-0.5
          c-0.3,0-0.5,0.2-0.5,0.5c-0.1,1.3-0.7,2.6-1.6,3.5c-0.5,0.4-1,0.8-1.7,1.1l0,0c-1.3,0.5-2.7,0.5-3.9,0c0,0,0,0,0,0
          c-1.9-0.8-3.2-2.5-3.3-4.5c0-0.3-0.2-0.5-0.5-0.5H2c-0.5,0-0.8-0.4-0.9-0.8v-0.6l0,0c0-0.2,0.1-0.4,0.2-0.6S1.8,10.8,2,10.8
          L2,10.8z M1,13.8c0.3,0.2,0.7,0.3,1,0.3c0.2,1.6,1.1,3.1,2.3,4.1H2.7c-0.9,0-1.7-0.7-1.7-1.7V13.8z"/>
      </g>
    </g>
    <path id="交叉_1-4" fill="#E8F5EB" d="M231.8,252.6c-32,18.2-71.1,18.3-103.2,0.3l21.9-38.3c18.7,9.2,40.5,9.1,59.1-0.2L231.8,252.6
      z"/>
    <g id="组_772" transform="translate(172.922601, 228.220113)">
      <path id="路径_327" d="M9.7,12.2H8.6c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1.9c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
        H9.6V9.2c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v0.7H8.6c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.6,1.7,1.6h1.1
        c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H7.7c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h0.9v0.7c0,0.3,0.2,0.5,0.5,0.5
        c0.3,0,0.5-0.2,0.5-0.5v-0.7h0.1c0.9,0,1.6-0.7,1.6-1.6C11.3,12.9,10.6,12.2,9.7,12.2z"/>
      <path id="路径_328" d="M15.7,11c-0.5-1.2-1.2-2.3-2-3.3C12.9,6.7,12,5.8,11,5.1l2-3.8c0.1-0.2,0.1-0.4-0.1-0.6
        c-0.4-0.5-1-0.8-1.7-0.8c-0.5,0-1.1,0.2-1.5,0.4c-0.3,0.2-0.6,0.3-1,0.3c-0.1,0-0.2,0-0.2,0C7.8,0.3,7,0.1,6.2,0.1
        c-0.9,0-1.8,0.4-2.4,1.1C3.7,1.4,3.7,1.6,3.8,1.8l2.2,3.3C5,5.9,4.1,6.7,3.4,7.6c-0.8,1-1.5,2.1-2,3.3c-0.6,1.4-0.9,3-0.9,4.5
        c0,2,1.6,3.7,3.7,3.7H13c2,0,3.7-1.7,3.7-3.7C16.6,13.9,16.3,12.4,15.7,11L15.7,11z M4.8,1.6C5.2,1.3,5.7,1,6.2,1
        c0.7,0.1,1.4,0.2,2,0.5c0.2,0.1,0.4,0.1,0.5,0.1c0.5,0,0.9-0.2,1.3-0.4c0.4-0.2,0.8-0.3,1.2-0.3C11.5,1,11.8,1,12,1.2l-1.9,3.6
        H6.8L4.8,1.6z M13,18.2H4c-1.5,0-2.7-1.3-2.7-2.8c0-2.6,0.9-5,2.7-7.2c0.8-1,1.7-1.8,2.7-2.5h3.5c1,0.7,1.9,1.6,2.7,2.5
        c1.8,2.2,2.7,4.7,2.7,7.2c0,0.7-0.3,1.4-0.8,1.9C14.4,17.9,13.7,18.2,13,18.2L13,18.2z"/>
      <g id="財" transform="translate(3.649263, 22.426024)">
        <g>
          <path id="path-9" d="M6.1,8.5c0.7,0,1.2,0,1.3,0c0.2,0,0.3-0.1,0.4-0.2S7.9,8,7.9,7.8l0,0V2.6h0.9V2.1H7.9V0.4H7.5v1.7H4.6v0.5
            h2.7C6.7,4.2,5.7,5.4,4.4,6.4l0,0l0.4,0.4c1.1-0.8,1.9-2,2.7-3.3l0,0v4.3c0,0.1,0,0.1-0.1,0.2C7.3,8,7.1,8,6.7,8
            C6.5,8,6.2,8,5.9,8l0,0L6.1,8.5z M1,8.5c0.5-0.6,1-1.2,1.4-2l0,0L2.2,6.3h2V0.8H1.1v5.5H2C1.6,7.1,1.2,7.7,0.7,8.2l0,0L1,8.5z
            M3.7,2.5H1.6V1.3h2.1V2.5z M3.7,4.2H1.6V2.9h2.1V4.2z M3.7,5.9H1.6V4.6h2.1V5.9z M4.1,8.4l0.4-0.3C4.2,7.6,3.9,7.1,3.5,6.6l0,0
            L3.1,6.8C3.5,7.4,3.9,8,4.1,8.4L4.1,8.4z"/>
        </g>
        <g>
          <path id="path-9_1_" d="M6.1,8.5c0.7,0,1.2,0,1.3,0c0.2,0,0.3-0.1,0.4-0.2S7.9,8,7.9,7.8l0,0V2.6h0.9V2.1H7.9V0.4H7.5v1.7H4.6
            v0.5h2.7C6.7,4.2,5.7,5.4,4.4,6.4l0,0l0.4,0.4c1.1-0.8,1.9-2,2.7-3.3l0,0v4.3c0,0.1,0,0.1-0.1,0.2C7.3,8,7.1,8,6.7,8
            C6.5,8,6.2,8,5.9,8l0,0L6.1,8.5z M1,8.5c0.5-0.6,1-1.2,1.4-2l0,0L2.2,6.3h2V0.8H1.1v5.5H2C1.6,7.1,1.2,7.7,0.7,8.2l0,0L1,8.5z
            M3.7,2.5H1.6V1.3h2.1V2.5z M3.7,4.2H1.6V2.9h2.1V4.2z M3.7,5.9H1.6V4.6h2.1V5.9z M4.1,8.4l0.4-0.3C4.2,7.6,3.9,7.1,3.5,6.6l0,0
            L3.1,6.8C3.5,7.4,3.9,8,4.1,8.4L4.1,8.4z"/>
        </g>
      </g>
    </g>
    <path id="交叉_1-5" fill="#E8F5EB" d="M125.8,251.8c-31.7-18.7-51.4-52.6-51.9-89.4l44.1-0.1c1.4,20.8,12.4,39.7,29.8,51.2
      L125.8,251.8z"/>
    <g id="组_774" transform="translate(97.425027, 187.473983)">
      <path id="路径_326" d="M17.6,5.2c0-1.2-1-2.3-2.2-2.3h-3.3V1.7c0-0.9-0.7-1.7-1.6-1.7H7.1C6.2,0,5.5,0.8,5.5,1.7v1.2H2.2
        C1,2.9,0,4,0,5.2v1.6C0,7.4,0.2,8,0.6,8.5v6.1c0,1.2,0.9,2.2,2.1,2.2h12.3c1.2,0,2.1-1,2.1-2.2V8.5c0.4-0.5,0.5-1.1,0.6-1.7
        L17.6,5.2L17.6,5.2z M6.6,1.7L6.6,1.7c0-0.3,0.3-0.6,0.6-0.6h3.3c0.3,0,0.6,0.3,0.6,0.6v1.2H6.6V1.7z M16.6,6.8
        c0,0.9-0.8,1.9-2.3,2.6V9.3c0-0.3-0.2-0.5-0.5-0.5h-2.2C11.3,8.7,11,9,11,9.3V11c0,0.6,0.3,1.2,0.8,1.5c0.5,0.3,1.1,0.3,1.7,0
        s0.8-0.9,0.8-1.5v-0.5c0.6-0.3,1.2-0.6,1.7-1v5c0,0.6-0.5,1.1-1.1,1.1H2.7c-0.6,0-1.1-0.5-1.1-1.1v-5c0.5,0.4,1.1,0.7,1.7,1V11
        c0,0.9,0.6,1.6,1.4,1.7c0.8,0.1,1.6-0.5,1.8-1.3c0.8,0.1,1.5,0.2,2.3,0.2c0.2,0,0.4,0,0.6,0c0.3,0,0.5-0.3,0.5-0.6
        c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0-0.5,0c-0.8,0-1.5-0.1-2.2-0.2v-1C6.6,9,6.3,8.7,6,8.7H3.8C3.5,8.7,3.3,9,3.3,9.3v0.1
        C1.9,8.7,1,7.8,1,6.8V5.2C1,4.6,1.5,4,2.2,4h13.2c0.6,0,1.1,0.5,1.1,1.2L16.6,6.8z M13.2,9.8V11c0,0.2-0.1,0.4-0.3,0.5
        c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.3-0.3-0.5V9.8H13.2z M4.3,11V9.8h1.2V11c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.6,0
        C4.4,11.5,4.3,11.2,4.3,11L4.3,11z"/>
      <g id="官" transform="translate(5.155492, 18.448432)">
        <g>
          <path id="path-12" d="M7.6,3.5v-2H4.2C4,1.1,3.9,0.7,3.7,0.4l0,0L3.2,0.5c0.2,0.3,0.3,0.6,0.4,0.9l0,0H0v2h0.5V1.9h6.6v1.5H7.6z
            M1.5,8.9V8.5H7V6.1H1.5v-1h5.1V2.9H1v6H1.5z M6.1,4.6H1.5V3.4h4.6V4.6z M6.5,8H1.5V6.6h5.1V8z"/>
        </g>
        <g>
          <path id="path-12_1_" d="M7.6,3.5v-2H4.2C4,1.1,3.9,0.7,3.7,0.4l0,0L3.2,0.5c0.2,0.3,0.3,0.6,0.4,0.9l0,0H0v2h0.5V1.9h6.6v1.5
            H7.6z M1.5,8.9V8.5H7V6.1H1.5v-1h5.1V2.9H1v6H1.5z M6.1,4.6H1.5V3.4h4.6V4.6z M6.5,8H1.5V6.6h5.1V8z"/>
        </g>
      </g>
    </g>
    <path id="交叉_1-6" fill="#E8F5EB" d="M73.9,159.8C74.2,123,93.7,89,125.3,70.2l22.2,38.1c-17.3,11.6-28.1,30.6-29.4,51.4
      L73.9,159.8z"/>
    <g id="组_776" transform="translate(97.425027, 103.000299)">
      <path id="Combined-Shape_1_" d="M12.9,0.8c1.4-0.3,2.8-0.4,4.2-0.1c0.2,0,0.4,0.3,0.4,0.5l0,0l0,1.5l1.2,0c0.2,0,0.4,0.2,0.5,0.4
        l0,0.1v12.3c0,0.3-0.3,0.6-0.6,0.5c-1.5-0.4-3-0.6-4.5-0.6c-1.3,0-2.6,0.4-3.8,1.1c-0.1,0.2-0.3,0.2-0.5,0.2
        c-0.2,0-0.4-0.1-0.5-0.2l0.2,0.1c-1.2-0.8-2.6-1.2-4-1.2c-1.5,0-3,0.2-4.5,0.6c-0.3,0.1-0.6-0.1-0.6-0.4l0-0.1V3.2
        c0-0.2,0.2-0.4,0.4-0.5l0.1,0l1.2,0l0-1.5c0-0.2,0.1-0.4,0.3-0.5l0.1,0c1.4-0.3,2.8-0.2,4.2,0.1c1.2,0.4,2.2,1,3.1,1.9
        C10.7,1.8,11.7,1.2,12.9,0.8z M17.5,12.6c0,0.3-0.3,0.5-0.6,0.5c0,0,0,0-0.1,0l0,0l-0.2,0l-0.2,0c-0.8-0.1-1.6-0.1-2.3,0
        c-0.4,0.1-0.8,0.1-1.1,0.3c-1.1,0.5-2.1,1.1-2.9,1.9l0.2-0.2v0.3l0.3-0.1c1-0.4,2-0.7,3.1-0.7l0.3,0c1.2,0,2.5,0.1,3.7,0.4
        l0.4,0.1V3.7l-0.7,0L17.5,12.6z M2.2,3.7l-0.7,0v11.2l0.4-0.1c1-0.2,2.1-0.3,3.2-0.4l0.5,0c1.3,0,2.6,0.3,3.7,0.9l0-0.3
        c-0.8-0.7-1.7-1.3-2.7-1.7c-0.3-0.1-0.7-0.2-1.1-0.3C4.8,13,4,13,3.2,13.1l0,0l-0.2,0l-0.2,0c-0.3,0.1-0.6-0.2-0.6-0.5l0,0
        L2.2,3.7z M6.5,1.7c-1-0.2-2-0.3-3-0.2l0,0l-0.3,0v10.5l0.3,0C4.1,12,4.7,12,5.3,12l0,0l0.4,0c0.5,0.1,0.9,0.2,1.3,0.3
        c0.8,0.3,1.5,0.8,2.2,1.3l0,0l0.1,0.1l0-10.2C8.7,2.9,7.9,2.3,7.1,1.9L6.8,1.8L6.5,1.7z M16.2,1.5c-1-0.1-2,0-3,0.2
        c-1.2,0.4-2.2,1.1-3,2.1l0.2-0.2v10.2l0.1-0.1c0.5-0.3,0.9-0.6,1.5-0.9l0.4-0.2l0.4-0.2c0.4-0.2,0.8-0.3,1.3-0.3
        c0.8-0.1,1.7-0.1,2.5,0l0,0l0,0V1.6L16.2,1.5z"/>
      <g id="讀書" transform="translate(0.000000, 19.327850)">
        <g>
          <path id="path-15" d="M3.3,1.9V1.5h-1C2.2,1.1,2.1,0.7,1.9,0.4l0,0L1.4,0.5c0.2,0.3,0.3,0.6,0.4,0.9l0,0H0.6v0.5H3.3z M8.5,2.2
            V1.8H6.3V1.3h2.4V0.9H6.3V0.4H5.8v0.5H3.6v0.4h2.2v0.5H3.9v0.4H8.5z M8.4,4.1V2.7H3.7v1.3H8.4z M3.2,3.2V2.8H0.8v0.4H3.2z
            M7.9,3.7H7V3.1h1V3.7z M6.5,3.7h-1V3.1h1V3.7z M5.1,3.7h-1V3.1h1V3.7z M3.2,4.6V4.1H0.8v0.5H3.2z M3.6,8.6
            C4.4,8.4,5,8.1,5.5,7.7l0,0L5.2,7.4h2.9V4.5H4v2.9h1.2C4.7,7.7,4.1,8,3.4,8.2l0,0L3.6,8.6z M7.7,5.3H4.4V4.9h3.3V5.3z M1.4,8.6
            V7.9h1.4v0.5h0.5V5.5H0.9v3.1H1.4z M7.7,6.2H4.4V5.7h3.3V6.2z M2.7,7.4H1.4V6h1.4V7.4z M7.7,7H4.4V6.6h3.3V7z M8.4,8.6l0.2-0.4
            C8,8,7.3,7.8,6.7,7.7l0,0L6.5,8C7.2,8.2,7.8,8.4,8.4,8.6L8.4,8.6z M18,5.3V4.9h-3.8V4.3h3.1V3.9h-3.1V3.3h3v-1H18V1.9h-0.8v-1
            h-3V0.4h-0.5v0.5h-2.9v0.4h2.9v0.6H9.9v0.4h3.8v0.6h-2.9v0.4h2.9v0.6h-3.1v0.4h3.1v0.6H9.9v0.4H18z M16.6,1.9h-2.5V1.3h2.5V1.9z
            M16.6,2.9h-2.5V2.3h2.5V2.9z M11.3,8.6V8.3h5.3v0.3h0.5V5.9h-6.2v2.7H11.3z M16.6,6.9h-5.3V6.3h5.3V6.9z M16.6,7.9h-5.3V7.3
            h5.3V7.9z"/>
        </g>
        <g>
          <path id="path-15_1_" d="M3.3,1.9V1.5h-1C2.2,1.1,2.1,0.7,1.9,0.4l0,0L1.4,0.5c0.2,0.3,0.3,0.6,0.4,0.9l0,0H0.6v0.5H3.3z
            M8.5,2.2V1.8H6.3V1.3h2.4V0.9H6.3V0.4H5.8v0.5H3.6v0.4h2.2v0.5H3.9v0.4H8.5z M8.4,4.1V2.7H3.7v1.3H8.4z M3.2,3.2V2.8H0.8v0.4
            H3.2z M7.9,3.7H7V3.1h1V3.7z M6.5,3.7h-1V3.1h1V3.7z M5.1,3.7h-1V3.1h1V3.7z M3.2,4.6V4.1H0.8v0.5H3.2z M3.6,8.6
            C4.4,8.4,5,8.1,5.5,7.7l0,0L5.2,7.4h2.9V4.5H4v2.9h1.2C4.7,7.7,4.1,8,3.4,8.2l0,0L3.6,8.6z M7.7,5.3H4.4V4.9h3.3V5.3z M1.4,8.6
            V7.9h1.4v0.5h0.5V5.5H0.9v3.1H1.4z M7.7,6.2H4.4V5.7h3.3V6.2z M2.7,7.4H1.4V6h1.4V7.4z M7.7,7H4.4V6.6h3.3V7z M8.4,8.6l0.2-0.4
            C8,8,7.3,7.8,6.7,7.7l0,0L6.5,8C7.2,8.2,7.8,8.4,8.4,8.6L8.4,8.6z M18,5.3V4.9h-3.8V4.3h3.1V3.9h-3.1V3.3h3v-1H18V1.9h-0.8v-1
            h-3V0.4h-0.5v0.5h-2.9v0.4h2.9v0.6H9.9v0.4h3.8v0.6h-2.9v0.4h2.9v0.6h-3.1v0.4h3.1v0.6H9.9v0.4H18z M16.6,1.9h-2.5V1.3h2.5V1.9z
            M16.6,2.9h-2.5V2.3h2.5V2.9z M11.3,8.6V8.3h5.3v0.3h0.5V5.9h-6.2v2.7H11.3z M16.6,6.9h-5.3V6.3h5.3V6.9z M16.6,7.9h-5.3V7.3
            h5.3V7.9z"/>
        </g>
      </g>
    </g>
  </g>
  `,
  morningPath: [
    {
      title: '健康早',
      attrs: {
        transform: 'matrix(1 0 0 1 132.9129 46.1198)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M126.4,66.6L99.8,20.7c24-13.1,51-20,78.4-20.1h0.2V53h-0.4C159.8,53,142.1,57.7,126.4,66.6 L126.4,66.6z"/>
      <path fill="#fff" d="M152.2,26.2h-6.4v3.4h-2v-3.4h-6.2v-1.8h6.2v-1.8h-4.7v-7.2h11.4v7.2h-4.8v1.8h6.4V26.2z M141.1,18.2h7.5V17 h-7.5V18.2z M141.1,20.9h7.5v-1.2h-7.5V20.9z"/>
      `
    },
    {
      title: '夫早',
      attrs: {
        transform: 'matrix(1 0 0 1 261.2582 79.3389)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M233.9,68.4l26.5-46.3c23.6,14.4,43.1,34.4,56.9,58.3l0.1,0.1l-45.6,26.3l-0.2-0.3 C262.6,90.7,249.6,77.6,233.9,68.4L233.9,68.4z"/>
      <path fill="#fff" d="M280.5,59.4h-6.4v3.4h-2v-3.4h-6.2v-1.8h6.2v-1.8h-4.7v-7.2h11.4v7.2h-4.8v1.8h6.4V59.4z M269.4,51.4h7.5v-1.2 h-7.5V51.4z M269.4,54.1h7.5V53h-7.5V54.1z"/>
      `
    },
    {
      title: '妻早',
      attrs: {
        transform: 'matrix(1 0 0 1 296.8564 210.5914)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M286.9,162l53.4-0.2c-0.7,27.6-8.3,54.6-22,78.5l-0.1,0.1l-45.6-26.3l0.2-0.3 C281.9,198,286.8,180.2,286.9,162L286.9,162z"/>
      <path fill="#fff" d="M316.2,190.7h-6.4v3.4h-2v-3.4h-6.2v-1.8h6.2V187h-4.7v-7.2h11.4v7.2h-4.8v1.8h6.4V190.7z M305,182.7h7.5v-1.2 H305V182.7z M305,185.4h7.5v-1.2H305V185.4z"/>
      `
    },
    {
      title: '財早',
      attrs: {
        transform: 'matrix(1 0 0 1 201.9324 304.9705)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M232.6,254.3l26.8,46.2c-24.2,13.2-51.3,20.2-78.9,20.2h-0.2v-52.7h0.4 C198.9,268,216.8,263.3,232.6,254.3L232.6,254.3z"/>
      <path fill="#fff" d="M221.3,285.1h-6.4v3.4h-2v-3.4h-6.2v-1.8h6.2v-1.8h-4.7v-7.2h11.4v7.2h-4.8v1.8h6.4V285.1z M210.1,277h7.5 v-1.2h-7.5V277z M210.1,279.7h7.5v-1.2h-7.5V279.7z"/>
      `
    },
    {
      title: '官早',
      attrs: {
        transform: 'matrix(1 0 0 1 73.9954 271.7845)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M124.8,253.3l-26.5,46.3c-23.6-14.4-43.1-34.5-56.9-58.4l-0.1-0.1l45.6-26.3l0.2,0.3 C96.1,231,109.1,244.1,124.8,253.3L124.8,253.3z"/>
      <path fill="#fff" d="M93.3,251.9h-6.4v3.4h-2v-3.4h-6.2v-1.8h6.2v-1.8h-4.7V241h11.4v7.2h-4.8v1.8h6.4V251.9z M82.1,243.9h7.5v-1.2 h-7.5V243.9z M82.1,246.6h7.5v-1.2h-7.5V246.6z"/>
      `
    },
    {
      title: '讀書早',
      attrs: {
        transform: 'matrix(1 0 0 1 37.6959 141.6867)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M72.1,159.7l-53.4,0.2c0.7-27.6,8.3-54.6,22.1-78.5l0.1-0.1l45.6,26.3l-0.2,0.3 C77.1,123.7,72.2,141.5,72.1,159.7L72.1,159.7z"/>
      <path fill="#fff" d="M57,121.8h-6.4v3.4h-2v-3.4h-6.2V120h6.2v-1.8H44v-7.2h11.4v7.2h-4.8v1.8H57V121.8z M45.8,113.8h7.5v-1.2h-7.5 V113.8z M45.8,116.5h7.5v-1.2h-7.5V116.5z"/>
      `
    }
  ],
  nightPath: [
    {
      title: '健康晚',
      attrs: {
        transform: 'matrix(1 0 0 1 201.5805 46.76)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M231.3,66.3L231.3,66.3c-7.6-4.3-15.8-7.6-24.2-9.8c-8.7-2.3-17.8-3.5-26.8-3.5h-0.4V0.6 c0.2,0,0.4,0,0.6,0c13.2,0,26.4,1.6,39.2,4.8c6.5,1.6,13,3.7,19.2,6.1c6.4,2.5,12.7,5.4,18.7,8.6L231.3,66.3z"/>
      <path fill="#fff" d="M219,28.5c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7h-1.7c-1.6,0-2-0.5-2-2v-1 c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4H212v-3.4c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2h-1.6v-12 h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9V28 c0,0.5,0.1,0.5,0.5,0.5H219z M208.3,18.2V21h1.4v-2.8H208.3z M209.6,25.6v-2.9h-1.4v2.9H209.6z M215.1,23c0.1-0.7,0.1-1.3,0.2-2   h-1.7v2H215.1z M214.5,18.2c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H214.5z M218.4,20.9h-1.4c0,0.7-0.1,1.4-0.2,2 h1.6V20.9z"/>
      `
    },
    {
      title: '夫晚',
      attrs: {
        transform: 'matrix(1 0 0 1 296.5919 142.3269)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M287,159.8L287,159.8c-0.1-8.8-1.3-17.6-3.6-26.1c-2.4-8.8-5.9-17.2-10.5-25.1l-0.2-0.3 l45.6-26.3c0.1,0.2,0.2,0.4,0.3,0.6c6.7,11.5,11.9,23.8,15.6,36.6c1.9,6.5,3.3,13.2,4.4,19.9c1.1,6.8,1.7,13.8,1.9,20.7L287,159.8z"/>
      <path fill="#fff" d="M314,124.1c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7h-1.7c-1.6,0-2-0.5-2-2v-1 c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4H307v-3.4c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2h-1.6v-12 h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9v3.5 c0,0.5,0.1,0.5,0.5,0.5H314z M303.3,113.8v2.8h1.4v-2.8H303.3z M304.6,121.1v-2.9h-1.4v2.9H304.6z M310.1,118.5 c0.1-0.7,0.1-1.3,0.2-2h-1.7v2H310.1z M309.6,113.8c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H309.6z M313.4,116.5 h-1.4c0,0.7-0.1,1.4-0.2,2h1.6V116.5z"/>
      `
    },
    {
      title: '妻晚',
      attrs: {
        transform: 'matrix(1 0 0 1 261.2584 271.86)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M234.3,253.7L234.3,253.7c7.6-4.5,14.6-9.9,20.8-16.2c6.4-6.5,12-13.7,16.5-21.6l0.2-0.3 l45.6,26.3c-0.1,0.2-0.2,0.4-0.3,0.6c-6.7,11.5-14.7,22.2-23.9,31.8c-4.7,4.9-9.7,9.5-15,13.7c-5.4,4.3-11.1,8.4-17,12L234.3,253.7 z"/>
      <path fill="#fff" d="M278.4,253.6c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7h-1.7c-1.6,0-2-0.5-2-2 v-1c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4h-2.8v-3.4c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2H266   v-12h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9v3.5   c0,0.5,0.1,0.5,0.5,0.5H278.4z M267.7,243.3v2.8h1.4v-2.8H267.7z M269,250.7v-2.9h-1.4v2.9H269z M274.5,248.1   c0.1-0.7,0.1-1.3,0.2-2H273v2H274.5z M273.9,243.3c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H273.9z M277.8,246h-1.4 c0,0.7-0.1,1.4-0.2,2h1.6V246z"/>
      `
    },
    {
      title: '財晚',
      attrs: {
        transform: 'matrix(1 0 0 1 132.6484 306.0654)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M127,254.5L127,254.5c7.7,4.3,15.9,7.6,24.4,9.9c8.8,2.3,17.9,3.5,27,3.5h0.4v52.7 c-0.2,0-0.4,0-0.6,0c-13.3,0-26.5-1.6-39.4-4.8c-6.6-1.6-13-3.7-19.4-6.1c-6.5-2.5-12.7-5.4-18.8-8.7L127,254.5z"/>
      <path fill="#fff" d="M150.1,287.8c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7h-1.7c-1.6,0-2-0.5-2-2   v-1c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4H143v-3.4c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2h-1.6   v-12h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9v3.5   c0,0.5,0.1,0.5,0.5,0.5H150.1z M139.3,277.5v2.8h1.4v-2.8H139.3z M140.7,284.9v-2.9h-1.4v2.9H140.7z M146.2,282.3   c0.1-0.7,0.1-1.3,0.2-2h-1.7v2H146.2z M145.6,277.5c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H145.6z M149.5,280.2 h-1.4c0,0.7-0.1,1.4-0.2,2h1.6V280.2z"/>
      `
    },
    {
      title: '官晚',
      attrs: {
        transform: 'matrix(1 0 0 1 37.4314 210.6865)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M71.7,161.9L71.7,161.9c0.1,8.8,1.3,17.6,3.6,26.1c2.4,8.8,5.9,17.3,10.5,25.1l0.2,0.3 l-45.6,26.3c-0.1-0.2-0.2-0.4-0.3-0.6c-6.7-11.5-11.9-23.8-15.6-36.6c-1.9-6.5-3.3-13.2-4.4-19.9c-1.1-6.8-1.7-13.8-1.9-20.7 L71.7,161.9z"/>
      <path fill="#fff" d="M54.9,192.4c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7h-1.7c-1.6,0-2-0.5-2-2 v-1c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4h-2.8V185c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2h-1.6 v-12h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9v3.5 c0,0.5,0.1,0.5,0.5,0.5H54.9z M44.1,182.1v2.8h1.4v-2.8H44.1z M45.5,189.5v-2.9h-1.4v2.9H45.5z M51,186.9c0.1-0.7,0.1-1.3,0.2-2 h-1.7v2H51z M50.4,182.1c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H50.4z M54.3,184.9h-1.4c0,0.7-0.1,1.4-0.2,2h1.6 V184.9z"/>
      `
    },
    {
      title: '讀書晚',
      attrs: {
        transform: 'matrix(1 0 0 1 74.0302 79.8381)',
        fill: 'currentColor'
      },
      path: `
      <path fill="currentColor" d="M124.7,68L124.7,68c-7.6,4.5-14.6,9.9-20.8,16.2c-6.4,6.5-12,13.7-16.5,21.6l-0.2,0.3 L41.7,79.9c0.1-0.2,0.2-0.4,0.3-0.6c6.7-11.5,14.7-22.2,23.9-31.8c4.7-4.9,9.7-9.5,15-13.7c5.4-4.3,11.1-8.4,17-12L124.7,68z"/>
      <path fill="#fff" d="M91.5,61.6c0.4,0,0.5-0.3,0.5-1.9c0.4,0.3,1.1,0.6,1.5,0.7c-0.2,2.1-0.6,2.7-1.9,2.7H90c-1.6,0-2-0.5-2-2v-1   c-0.8,1.3-2.1,2.5-4.2,3.3c-0.2-0.4-0.7-1.1-1.1-1.4c2.7-1,3.9-2.5,4.5-4.4h-2.8v-3.4c-0.2-0.3-0.4-0.6-0.7-0.8v7h-3v1.2h-1.6v-12   h4.7v3c1.3-1.2,2.1-2.8,2.6-4.1l1.9,0.3c-0.1,0.3-0.3,0.7-0.4,1h2.6l0.3-0.1l1.4,0.4c-0.4,0.8-1,1.7-1.5,2.4h2.2v5h-2.9V61   c0,0.5,0.1,0.5,0.5,0.5H91.5z M80.7,51.3v2.8h1.4v-2.8H80.7z M82.1,58.6v-2.9h-1.4v2.9H82.1z M87.6,56.1c0.1-0.7,0.1-1.3,0.2-2   h-1.7v2H87.6z M87,51.3c-0.2,0.4-0.5,0.8-0.8,1.2h2.3c0.3-0.4,0.6-0.8,0.9-1.2H87z M90.9,54h-1.4c0,0.7-0.1,1.4-0.2,2h1.6V54z"/>
      `
    }
  ]
}
