import { request } from './request.js'

export const getShopcart = () => request.get('user/shopping-carts')
export const createShopcart = data => request.post('user/shopping-carts', data)
export const deleteShopcartAll = () => request.delete('user/shopping-carts')
export const deleteShopcartById = id => request.delete(`user/shopping-carts/${id}`)

export const getPaymentMethods = () => request.get('payment-methods')
export const getPaymentMethodsById = id => request.get(`payment-methods/${id}`)
export const getRemittanceDays = () => request.get('remittance-days')
export const getShopSystems = () => request.get('shop-systems')
export const getSettlementDays = () => request.get('settlement-days')
export const getLoveCodes = () => request.get('love-codes')
export const getShippingRules = () => request.get('shipping-rules')
export const getShoppingResultViews = () => request.get('shopping-result-views')
export const getShoppingResultViewsById = id => request.get(`shopping-result-views/${id}`)
export const getDeliveryMethods = () => request.get('delivery-methods')
