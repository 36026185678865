import DataModel from '../../library/proto/data-model'
import NatalchartDataModel from './natalchart-data'
import dayjs from 'dayjs'
import solarLunar from 'solarlunar'

const convYear = (value) => {
  if (Number(value) < 10) {
    return '000' + value
  } else if (Number(value) < 100) {
    return '00' + value
  } else if (Number(value) < 1000) {
    return '0' + value
  } else {
    return '' + value
  }
}
/**
 * @param {Number} id
 * @param {Number} sort 排序
 * @param {String} fortune_name 名字
 * @param {String} fortune_gender 性別 M & F
 * @param {String} fortune_calendar 使用曆別
 * @param {String} fortune_birth_at 出生日其
 * @param {String} fortune_year 年 (非資料庫欄位)
 * @param {String} fortune_month 月 (非資料庫欄位)
 * @param {String} fortune_day 日 (非資料庫欄位)
 * @param {String} fortune_hour 時
 * @param {String} fortune_minute 分
 * @param {Number} fortune_is_leap 是否為農曆
 * @param {String} fortune_china_hour 出生時辰
 * @param {String} fortune_word 字型，通常由此項在完成訂單處判斷是否為開運商品
*/
export default class NatalchartModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.sort = entity.sort || 0
    this.fortune_name = entity.fortune_name || ''
    this.fortune_gender = entity.fortune_gender || ''
    this.fortune_birth_at = entity.fortune_birth_at || ''
    this.fortune_calendar = entity.fortune_calendar || '西元'
    this.fortune_china_hour = entity.fortune_china_hour || ''
    this.fortune_hour = entity.fortune_hour || '00'
    this.fortune_minute = entity.fortune_minute || '00'
    this.fortune_is_leap = entity.fortune_is_leap || 0
    this.result = new NatalchartDataModel(entity.result)

    if (this.fortune_china_hour === '吉時') {
      this.fortune_time = '--:--'
    } else {
      this.fortune_time = (() => {
        if (this.fortune_time && dayjs(`0000/00/00 ${this.fortune_time}`).isValid()) {
          // console.log('a', dayjs(`0000/00/00 ${this.fortune_time}`).isValid())
          return dayjs(`0000/00/00 ${this.fortune_time}`).format('HH:mm:ss')
        }
        if (this.fortune_hour && this.fortune_minute && dayjs(`0000/00/00 ${this.fortune_hour}`).isValid() && dayjs(`0000/00/00 ${this.fortune_minute}`).isValid()) {
          // console.log('b', dayjs(`0000/00/00 ${this.fortune_time}`).isValid())
          return dayjs(`0000/00/00 ${this.fortune_hour}:${this.fortune_minute}:00`).format('HH:mm:ss')
        }
        // console.log('c', this.fortune_hour, this.fortune_minute)
        return ''
      })()
    }
    if (this.fortune_birth_at && dayjs(this.fortune_birth_at).isValid()) {
      if (this.fortune_calendar === '國曆') {
        this.fortune_year = convYear(dayjs(this.fortune_birth_at).format('YYYY') - 1911)
        this.fortune_month = dayjs(this.fortune_birth_at).format('MM')
        this.fortune_day = dayjs(this.fortune_birth_at).format('DD')
      } else if (this.fortune_calendar === '農曆') {
        const lcArr = this.result.LC.split('/')
        this.fortune_year = convYear(lcArr[0])
        // console.log('農2曆', this.fortune_year)
        this.fortune_month = lcArr[1]
        this.fortune_day = lcArr[2]
      } else {
        this.fortune_year = dayjs(this.fortune_birth_at).format('YYYY')
        this.fortune_month = dayjs(this.fortune_birth_at).format('MM')
        this.fortune_day = dayjs(this.fortune_birth_at).format('DD')
      }
    } else {
      this.fortune_year = entity.fortune_year || ''
      this.fortune_month = entity.fortune_month || ''
      this.fortune_day = entity.fortune_day || ''
    }
    this.lucky = this.fortune_china_hour === '吉時'
    this.error_message = ''
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'result') {
        this[key] = new NatalchartDataModel(entity[key])
      } else {
        this[key] = entity[key]
      }
    })
  }

  toDatabase (addObject = {}) {
    // 要轉成西元格式儲存
    if (!addObject.fortune_birth_at) {
      const year = this.fortune_year
      const month = this.fortune_month
      const day = this.fortune_day
      if (this.fortune_calendar === '國曆') {
        addObject.fortune_birth_at = dayjs(`${Number(year) + 1911}/${month}/${day}`).format('YYYY-MM-DD HH:mm:ss')
        addObject.fortune_is_leap = this.fortune_is_leap
      }
      if (this.fortune_calendar === '農曆') {
        const solarData = solarLunar.lunar2solar(Number(year) + 1911, Number(month), Number(day))
        addObject.fortune_birth_at = dayjs(`${solarData.cYear}/${solarData.cMonth}/${solarData.cDay}`).format('YYYY-MM-DD HH:mm:ss')
        addObject.fortune_is_leap = this.fortune_is_leap
      }
      if (this.fortune_calendar === '西元') {
        addObject.fortune_birth_at = dayjs(`${year}/${month}/${day}`).format('YYYY-MM-DD HH:mm:ss')
      }
    }
    return {
      fortune_name: this.fortune_name,
      fortune_gender: this.fortune_gender,
      fortune_calendar: this.fortune_calendar,
      fortune_china_hour: this.fortune_china_hour,
      fortune_hour: this.fortune_hour,
      fortune_minute: this.fortune_minute,
      fortune_is_leap: this.fortune_is_leap,
      result: JSON.stringify(this.result),
      ...addObject
    }
  }

  rules () {
    return {
      fortune_name: {
        presence: {
          allowEmpty: false,
          message: '^請填寫姓名'
        },
        words: {
          test: /[\u4e00-\u9fa5]|\s|[a-z]|[A-Z]/,
          message: '^僅能輸入中文和英文'
        }
      },
      fortune_gender: {
        presence: {
          allowEmpty: false,
          message: '^請選擇性別'
        }
      },
      fortune_birth_at: () => {
        const allowEmpty = {
          presence: {
            allowEmpty: false,
            message: '^請填寫生日期'
          }
        }
        const inclusion = {
          inclusion: {
            message: '^'
          }
        }
        if (this.fortune_year === '' && this.fortune_month === '' && this.fortune_day === '') {
          return allowEmpty
        }
        if (this.fortune_year === '' && this.fortune_month === '') {
          inclusion.inclusion.message = '^請填寫出生年和月'
          return inclusion
        }
        if (this.fortune_year === '' && this.fortune_day === '') {
          inclusion.inclusion.message = '^請填寫出生年和日'
          return inclusion
        }
        if (this.fortune_month === '' && this.fortune_day === '') {
          inclusion.inclusion.message = '^請填寫出生月和日'
          return inclusion
        }
        if (this.fortune_year === '') {
          inclusion.inclusion.message = '^請填寫出生年'
          return inclusion
        }
        if (this.fortune_month === '') {
          inclusion.inclusion.message = '^請填寫出生月'
          return inclusion
        }
        if (this.fortune_day === '') {
          inclusion.inclusion.message = '^請填寫出生日'
          return inclusion
        }
        if (this.fortune_calendar === '西元') {
          if (Number(this.fortune_year) < 1913 || Number(this.fortune_year) > 2040) {
            return { inclusion: { message: '^您請填寫的年分範圍不正確' } }
          }
        }
        if (this.fortune_calendar === '國曆' || this.fortune_calendar === '農曆') {
          if (Number(this.fortune_year) < 2 || Number(this.fortune_year) > 129) {
            return { inclusion: { message: '^您請填寫的年分範圍不正確' } }
          }
        }
        if (this.fortune_birth_at) {
          return {
            datetime: {
              message: '^出生日期格式不正確'
            }
          }
        }
      },
      fortune_calendar: {
        presence: {
          allowEmpty: false,
          message: '^請選擇曆別'
        }
      },
      fortune_year: {
        presence: {
          allowEmpty: false,
          message: '^請填寫出生年'
        }
      },
      fortune_month: {
        presence: {
          allowEmpty: false,
          message: '^請填寫出生月'
        }
      },
      fortune_day: {
        presence: {
          allowEmpty: false,
          message: '^請填寫出生日'
        }
      },
      fortune_is_leap: () => {
        if (this.fortune_calendar === '農曆') {
          return {
            presence: {
              allowEmpty: false,
              message: '^請選擇是否閏月'
            }
          }
        } else {
          return {}
        }
      },
      fortune_time: () => {
        if (this.fortune_china_hour === '吉時') {
          return {}
        } else {
          return {
            presence: {
              allowEmpty: false,
              message: '^請選擇出生時辰'
            }
          }
        }
      }
    }
  }
}
