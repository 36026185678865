export default {
  mount: '.icon-cart',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'white'
  },
  path: `
    <path d="M6.94995 20.95C7.50224 20.95 7.94995 20.5022 7.94995 19.95C7.94995 19.3977 7.50224 18.95 6.94995 18.95C6.39767 18.95 5.94995 19.3977 5.94995 19.95C5.94995 20.5022 6.39767 20.95 6.94995 20.95Z" stroke="#009040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.95 20.95C18.5022 20.95 18.95 20.5022 18.95 19.95C18.95 19.3977 18.5022 18.95 17.95 18.95C17.3977 18.95 16.95 19.3977 16.95 19.95C16.95 20.5022 17.3977 20.95 17.95 20.95Z" stroke="#009040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 1H3L5.66 13.42C5.75758 13.8749 6.01067 14.2815 6.37571 14.5699C6.74075 14.8582 7.19491 15.0103 7.66 15H17.44C17.8952 14.9993 18.3365 14.8433 18.691 14.5578C19.0456 14.2724 19.2921 13.8745 19.39 13.43L21.04 6H4.07" stroke="#009040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  `
}
