import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} title 標題
 * @param {String} keyword 關鍵字
 * @param {String} description 說明文字
 * @param {String} author 作者
 * @param {String} updated_user 更新使用者
*/
export default class SeometasModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.title = entity.title || ''
    this.keyword = entity.keyword || ''
    this.description = entity.description || ''
    this.author = entity.author || ''
    this.updated_user = entity.updated_user || ''
  }

  toDatabase (addObject) {
    return {
      ...addObject
    }
  }
}
