<template lang="pug">
  div
    div(class="d-flex py-3 px-sm-4")
      h4(class="py-4 mb-0 font-weight-bold px-2") 會員同意條款
    div(class="px-sm-4")
      div(class="text-dark px-2" v-html="content")
</template>

<script>
import { ContactModel } from '../../../models'

export default {
  data () {
    return {
      model: new ContactModel({
        loaded: true
      }),
      content: '<div class="policy_box" id="popup_policy">\n' +
        '                                                    <p>東震股份有限公司(東震)係依據東震網站電子服務條款提供東震網站(https://www.tocin.com.tw)服務。當您使用東震網站電子服務時，表示您已閱讀、瞭解並同意接受相關規定。東震公司有權於任何時間修改或變更本約定書之內容。若任何條文修改或變更之後，您仍繼續使用東震網站服務，視為您已閱讀、瞭解並同意接受該項修改或變更。如果您不同意本約定書的內容，或者您所屬的國家或地區排除本約定書內容之任一部分時，您應停止使用東震網站服務。&nbsp;\n' +
        '                                                        <br>  <br> 若您未滿十八歲，除應遵守上述規定之外，並應確認您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容，方能使用或繼續使用東震網站服務。當您使用或繼續使用東震時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更事項。\n' +
        '                                                    </p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>關於您的隱私，依東震網站之「隱私權政策」受到保護與規範。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>傳銷商加入東震之後，即有一預設之傳銷商密碼。維持密碼及傳銷商編號的機密安全，是您的責任。利用該密碼及傳銷商編號所進行的一切行動，您將負完全的責任。您並同意以下事項：&nbsp;\n' +
        '                                                        <br> 1.您不任意將您的傳銷商編號與密碼提供他人使用，包含訂貨或查閱積分…等。\n' +
        '                                                        <br> 2.您的傳銷商編號與密碼遭到盜用或有其他任何安全問題發生時，您將立即通知東震\n' +
        '                                                        <br> 3.使用傳銷商專區功能結束之後，均登出結束帳號使用。\n' +
        '                                                    </p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>您承諾絕不為任何非法目的或以任何非法方式使用東震網站之任何電子服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或區域之法規。若您誤觸相關法規，您同意自行負擔相關法律責任。此外，您同意並保證不得利用東震網站電子服務從事侵害他人權益或違法之行為，包括但不限於：&nbsp;\n' +
        '                                                        <br> 1.公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於東震上\n' +
        '                                                        <br> 2.侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利\n' +
        '                                                        <br> 3.違反依法律或契約所應負之保密義務\n' +
        '                                                        <br> 4.違反東震營業守則\n' +
        '                                                        <br> 5.冒用他人名義使用東震網站電子服務\n' +
        '                                                        <br> 6.傳輸或散佈電腦病毒\n' +
        '                                                        <br> 7.從事不法交易行為或張貼虛假不實、引人犯罪之訊息\n' +
        '                                                        <br> 8.販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物\n' +
        '                                                        <br> 9.提供賭博資訊或以任何方式引誘他人參與賭博\n' +
        '                                                        <br> 10.濫發廣告郵件\n' +
        '                                                        <br> 11.其他東震有正當理由認為不適當之行為\n' +
        '                                                    </p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>東震網站電子服務有時可能會出現中斷或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。您於使用東震網站電子服務時宜自行採取防護措施。東震對於您因使用（或無法使用）東震網站電子服務而造成的損害，不負任何賠償責任。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>您於東震網站下載資料之後，需依照著作權法相關規定使用。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>您同意東震得就東震網站電子服務訂定一般措施及限制，包含但不限於電子郵件服務將保留電子郵件訊息、討論區內容管理或其他上載內容之最長期間、東震eMail服務一個帳號當中可收發電子郵件訊息的數量限制、一個帳號可收發電子郵件訊息的最大檔案、東震伺服器分配的最大磁碟空間，以及一般特定期間內您使用東震網站電子服務之次數上限（及每次使用時間之上限）。若東震將東震網站電子服務維持或傳送之任何訊息、通訊和內容刪除或未予儲存，您同意東震毋須承擔任何責任。您亦同意，長時間未使用的帳號，東震有權關閉。您也同意，東震有權依其自行之考量，不論通知與否，隨時變更這些一般措施及限制。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>東震對於您使用東震網站電子服務如eMail 、ADSL或撥接方式自行取得之非東震提供之資訊或建議，不擔保其為完全正確無誤。此外，東震將依營運需求隨時會與其他公司、廠商等第三人（「內容提供者」）合作或取得其同意，由其提供包括新聞或資訊等不同內容連結。基於尊重智慧財產權，東震對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>由傳銷商公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料，均由訊息提供之傳銷商本人提供者自負責任。此外，東震依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將此傳銷商網站行為加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：</p>\n' +
        '                                                    <p>(a)遵守法令或政府機關之要求律程序，</p>\n' +
        '                                                    <p>(b)執行東震網站電子服務條款，</p>\n' +
        '                                                    <p>(c)回應任何侵害第三人權利之主張，或</p>\n' +
        '                                                    <p>(d)保護東震及其使用者及公眾之權利、財產或個人安全。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>東震網站資訊、程式與服務，受智慧財產權保護。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得東震或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對東震負損害賠償責任（包括但不限於訴訟費用及律師費用等）。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>您同意東震得基於其自行之考量，因任何理由，包含但不限於缺乏使用，或東震認為您已經違反東震網站電子服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或東震網站電子服務之使用。東震亦得依其自行之考量，於通知或未通知之情形下，隨時終止東震網站電子服務或其任何部分。您同意依東震網站電子服務條款任何規定提供之東震網站電子服務，無需進行事先通知即得終止，您承認並同意，東震得立即關閉或刪除您的帳號及您帳號中所有相關資料及檔案，及停止東震網站電子服務之使用。此外，您同意若東震網站電子服務之使用被終止，東震對您或任何第三人均不承擔責任。</p>\n' +
        '                                                    <p>&nbsp;</p>\n' +
        '                                                    <p>本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照中華民國法律予以處理，並以台灣台中地方法院為第一審管轄法院。</p>\n' +
        '                                                </div>'
    }
  },
  created () {
    if (this.$route.meta && this.$route.meta.content) {
      this.content = this.$route.meta.content
    } else {
      this.$route.meta.content = this.content
    }
  },
  methods: {
  }
}
</script>
<style scoped>
  textarea::placeholder {
    color: #D8D8D8;
  }
</style>
