import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} title 標題文字
 * @param {Array<String>} dumpling_day 納財日
 * @param {String} information 資訊
 * @param {Number} status 狀態碼
 * @param {String} is_notifiable 是否訊息通知
 * @param {String} url 連結網址
 * @param {String} published_at 公開時間
 * @param {String} finished_at 到期時間
 * @param {String} updated_user 更新使用者
*/
export default class CalendarModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.title = entity.title || ''
    this.dumpling_day = (() => {
      if (Array.isArray(entity.dumpling_day)) {
        return entity.dumpling_day.map(p => String(p))
      } else if (entity.dumpling_day instanceof String) {
        return entity.dumpling_day.split(',')
      }
      return []
    })()
    this.information = entity.information || ''
    this.status = entity.status || 0
    this.is_notifiable = entity.is_notifiable || '0'
    this.url = entity.url || ''
    this.published_at = entity.published_at || ''
    this.finished_at = entity.finished_at || ''
    this.updated_user = entity.updated_user || ''
  }
}
