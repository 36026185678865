import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
*/

export default class EbookImagesModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.ebook_class_id = entity.ebook_class_id || 0
    this.image = entity.image || ''
    this.page = entity.page || 0
    this.tag_use = entity.tag_use || 0
    this.tag_name = entity.tag_name || ''
    this.updated_user = entity.updated_user || ''
  }
}
