import { request } from './request.js'

export const getGeneralAddress = () => request.get('user/general-addresses')
export const getGeneralAddressById = id => request.get(`user/general-addresses/${id}`)
export const createGeneralAddress = data => request.post('user/general-addresses', data)
export const updateGeneralAddress = (id, data) => request.put(`user/general-addresses/${id}`, data)
/**
 * @param {String} data._method 固定帶 "delete"
 * @param {Array<Number>} data.id 要刪除的群組
 */
export const deleteGeneralAddressGroup = data => request.post('user/general-addresses/batch-delete', data)
export const getInvoiceAddress = () => request.get('user/invoice-addresses')
export const getInvoiceAddressById = id => request.get(`user/invoice-addresses/${id}`)
export const updateInvoiceAddress = data => request.post('user/invoice-addresses', data)
