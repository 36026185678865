<template lang="pug">
  div(:style="{backgroundImage: `url(${urlBodyBg})`}")
    div(class="container product bg-white" style="min-height: calc(100vh - 190px);")
      router-view
</template>

<script>
import urlBodyBg from '@/assets/bg.png'

export default {
  name: 'Product',
  data () {
    return {
      urlBodyBg
    }
  },
  created () {
    if (this.$route.path === '/product') {
      this.$router.replace('/product/view')
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/product') {
        this.$router.replace('/product/view')
      }
    }
  }
}
</script>
