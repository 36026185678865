import { request } from './request.js'

export const getNatalcharts = () => request.get('user/characters')
export const getNatalchartsById = id => request.get(`user/characters/${id}`)
export const createNatalcharts = data => request.post('user/characters', data)
/**
 * @param {String} data._method 固定帶 "delete"
 * @param {Array<Number>} data.id 要刪除的群組
 */
export const deleteNatalchartsGroup = data => request.post('user/characters/batch-delete', data)
export const updatetNatalcharts = (id, data) => request.put(`user/characters/${id}`, data)
