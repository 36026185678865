import DataModel from '../../library/proto/data-model'

/**
 * @param {String}
 * @param {String}
*/

export default class ContactModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.sub = entity.sub || ''
    this.name = entity.name || ''
    this.phone = entity.phone || ''
    this.email = entity.email || ''
    this.content = entity.content || ''
    this.error_message = ''
  }

  toBackend (addObject) {
    return {
      sub: this.sub,
      name: this.name,
      phone: this.phone,
      email: this.email,
      content: this.content,
      ...addObject
    }
  }

  rules () {
    return {
      sub: {
        presence: {
          allowEmpty: false,
          message: '^請填寫詢問主旨'
        }
      },
      name: {
        presence: {
          allowEmpty: false,
          message: '^請填寫姓名'
        },
        words: {
          test: /[\u4e00-\u9fa5]|\s|[a-z]|[A-Z]/,
          message: '^僅能輸入中文和英文'
        }
      },
      phone: {
        presence: {
          allowEmpty: false,
          message: '^請填寫電話號碼'
        },
        phone: {
          message: '^填寫的電話號碼格式不正確'
        }
      },
      email: {
        presence: {
          allowEmpty: false,
          message: '^請填寫電子信箱'
        },
        email: {
          message: '^填寫的電子信箱格式不正確'
        }
      }
      // content: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^請填寫詢問內容'
      //   }
      // }
    }
  }
}
