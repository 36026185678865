import './assets/htaccess/dist.htaccess'
import './library/init'
import './plugins/init'
import './components/init'
import Vue from 'vue'
import mixin from './library/mixin'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import { initProto } from './library'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.mixin(mixin)
axios.$router = router
new Vue({
  router,
  store,
  render: h => {
    initProto(h)
    return h(App)
  }
}).$mount('#app')
