import Home from '../views/pages/home/Index.vue'
// 東震之聲 最新消息
import Sound from '../views/pages/sound/Index.vue'
import News from '../views/pages/news/Index.vue'
import Monthly from '../views/pages/sound/monthly/Index.vue'
import MonthlyDetail from '../views/pages/sound/monthly/Detail.vue'
import Calendar from '../views/pages/news/calendar/Index.vue'
import CalendarDetail from '../views/pages/news/calendar/Detail.vue'
// 會員專區 購物車
import Member from '../views/pages/member/Index.vue'
import Login from '../views/pages/member/Login.vue'
import Forgot from '../views/pages/member/Forgot.vue'
import Change from '../views/pages/member/Change.vue'
import Fegister from '../views/pages/member/Register.vue'
import Account from '../views/pages/member/Account.vue'
import Address from '../views/pages/member/Address.vue'
import Natalchart from '../views/pages/member/Natalchart.vue'
import Activity from '../views/pages/member/Activity.vue'
import Points from '../views/pages/member/Points.vue'
import Favorites from '../views/pages/member/Favorites.vue'
import PerformanceSearch from '../views/pages/member/PerformanceSearch.vue'
import Order from '../views/pages/member/order/Index.vue'
import OrderList from '../views/pages/member/order/List.vue'
import OrderDetail from '../views/pages/member/order/Detail.vue'
import Shopcart from '../views/pages/shopcart/Index.vue'
import ShopClose from '../views/pages/shopcart/ShopClose.vue'
import ShopcartStep1 from '../views/pages/shopcart/Step1.vue'
import ShopcartStep2 from '../views/pages/shopcart/Step2.vue'
import ShopcartStep3 from '../views/pages/shopcart/Step3.vue'
import ShopcartStep4 from '../views/pages/shopcart/Step4.vue'
import ShopcartStep5 from '../views/pages/shopcart/Step5.vue'
// 商品專區
import Product from '../views/pages/product/Index.vue'
import ProductList from '../views/pages/product/List.vue'
import ProductDetail from '../views/pages/product/Detail.vue'
import ProductView from '../views/pages/product/View.vue'

// 關於東震 加入東震 客服中心
import About from '../views/pages/about/Index.vue'
import Join from '../views/pages/join/Index.vue'
import Service from '../views/pages/service/Index.vue'
import ArticleListPage from '../container/article-page/List.vue'
import ArticleDetailPage from '../container/article-page/Detail.vue'
import Contact from '../views/pages/service/Contact.vue'
import Provision from '../views/pages/service/Provision.vue'
import ContactResponse from '../views/pages/service/ContactResponse.vue'
import Stronghold from '../views/pages/service/Stronghold.vue'
// 其他
import Scam from '../views/pages/scam/Index.vue'

console.log('process.env.NODE_ENV = ' + process.env.NODE_ENV)
const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '首頁'
    },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '關於東震'
    },
    component: About,
    children: []
  },
  {
    path: '/join',
    name: 'Join',
    meta: {
      title: '加入東震'
    },
    component: Join,
    children: []
  },
  {
    path: '/sound',
    name: 'Sound',
    meta: {
      title: '東震之聲'
    },
    component: Sound,
    children: [
      {
        path: '/sound/monthly',
        name: 'Monthly',
        meta: {
          title: '東震月刊'
        },
        component: Monthly,
        children: [{
          path: ':id',
          name: 'MonthlyDetail',
          meta: {
            title: '東震月刊',
            hidden: true
          },
          component: MonthlyDetail
        }]
      },
      {
        path: '/sound/boss',
        name: 'Boss',
        meta: {
          title: '總經理專欄',
          modelName: 'BossArticles',
          rootPath: '/sound/boss'
        },
        component: ArticleListPage,
        children: [{
          path: ':id',
          name: 'BossDetail',
          meta: {
            title: '總經理專欄',
            hidden: true,
            paginationBar: true
          },
          component: ArticleDetailPage
        }]
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: '最新消息'
    },
    component: News,
    children: [
      {
        path: '/news/activity',
        name: 'NewsArticles',
        meta: {
          title: '特惠活動',
          modelName: 'NewsArticles',
          rootPath: '/news/activity'
        },
        component: ArticleListPage,
        children: [
          {
            path: ':id',
            name: 'ActivityDetail',
            meta: {
              title: '特惠活動',
              hidden: true,
              paginationBar: true
            },
            component: ArticleDetailPage
          }
        ]
      },
      {
        path: '/news/matter',
        name: 'Matter',
        meta: {
          title: '公司公告',
          modelName: 'OfficialBulletins',
          rootPath: '/news/matter'
        },
        component: ArticleListPage,
        children: [
          {
            path: ':id',
            name: 'MatterDetail',
            meta: {
              title: '公司公告',
              hidden: true,
              paginationBar: true
            },
            component: ArticleDetailPage
          }
        ]
      },
      {
        path: '/news/travel',
        name: 'Travel',
        meta: {
          title: '旅遊資訊',
          modelName: 'TravelArticles',
          rootPath: '/news/travel'
        },
        component: ArticleListPage,
        children: [
          {
            path: ':id',
            name: 'TravelDetail',
            meta: {
              title: '旅遊資訊',
              hidden: true,
              paginationBar: true
            },
            component: ArticleDetailPage
          }
        ]
      },
      {
        path: '/news/calendar',
        name: 'Calendar',
        meta: {
          title: '行事曆',
          modelName: 'Calendars',
          rootPath: '/news/calendar'
        },
        component: Calendar,
        children: [
          {
            path: ':id',
            name: 'CalendarDetail',
            meta: {
              title: '行事曆',
              hidden: true,
              paginationBar: true
            },
            component: CalendarDetail
          }
        ]
      }
    ]
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      title: '商品專區'
    },
    component: Product,
    children: [
      {
        path: 'list',
        name: 'ProductList',
        component: ProductList,
        meta: {
          hidden: true,
          title: '商品列表',
          togglePanelBar: true
        }
      },
      {
        path: 'view',
        name: 'ProductView',
        component: ProductView,
        meta: {
          hidden: true,
          title: '商品列表',
          togglePanelBar: true
        }
      },
      {
        path: ':id',
        name: 'ProductDetail',
        component: ProductDetail,
        meta: {
          hidden: true,
          title: '商品專區'
        }
      }
    ]
  },
  {
    path: '/member',
    name: 'Member',
    meta: {
      title: '會員專區'
    },
    component: Member,
    children: [
      {
        path: '/member/login',
        name: 'Login',
        meta: {
          title: '會員登入',
          hidden: true
        },
        component: Login
      },
      {
        path: '/member/forgot',
        name: 'Forgot',
        meta: {
          title: '忘記密碼',
          hidden: true
        },
        component: Forgot
      },
      {
        path: '/member/change',
        name: 'Change',
        meta: {
          title: '修改密碼',
          hidden: true
        },
        component: Change
      },
      {
        path: '/member/register',
        name: 'Fegister',
        meta: {
          title: '第一次登入',
          hidden: true
        },
        component: Fegister
      },
      {
        path: '/member/account',
        name: 'Account',
        meta: {
          title: '我的帳戶'
        },
        component: Account
      },
      {
        path: '/member/address',
        name: 'Address',
        meta: {
          title: '訂購通訊錄'
        },
        component: Address
      },
      {
        path: '/member/natalchart',
        name: 'Natalchart',
        meta: {
          title: '命盤管理',
          togglePanelBar: true
        },
        component: Natalchart
      },
      {
        path: '/member/shopcart',
        name: 'Shopcart',
        meta: {
          title: '購物車'
        },
        component: Shopcart,
        children: [
          {
            path: 'shop-close',
            name: 'ShopClose',
            component: ShopClose,
            meta: {
              title: '購物車-網站維護中',
              hidden: true
            }
          },
          {
            path: 'step/1',
            name: 'Step1',
            component: ShopcartStep1,
            meta: {
              title: '購物車-購物清單',
              hidden: true
            }
          },
          {
            path: 'step/2',
            name: 'Step2',
            component: ShopcartStep2,
            meta: {
              title: '購物車-填寫資料',
              hidden: true
            }
          },
          {
            path: 'step/3',
            name: 'Step3',
            component: ShopcartStep3,
            meta: {
              title: '購物車-付款資訊',
              hidden: true
            }
          },
          {
            path: 'step/4',
            name: 'Step4',
            component: ShopcartStep4,
            meta: {
              title: '購物車-訂單預覽',
              hidden: true
            }
          },
          {
            path: 'step/5',
            name: 'Step5',
            component: ShopcartStep5,
            meta: {
              title: '購物車-訂單送出',
              hidden: true
            }
          }
        ]
      },
      {
        path: '/member/favorite',
        name: 'Favorites',
        meta: {
          title: '我的最愛'
        },
        component: Favorites
      },
      {
        path: '/member/performance-search',
        name: 'PerformanceSearch',
        meta: {
          hidden: true,
          title: '業績查詢'
        },
        component: PerformanceSearch
      },
      {
        path: '/member/order',
        name: 'Order',
        meta: {
          title: '購物紀錄'
        },
        component: Order,
        children: [
          {
            path: 'list',
            name: 'OrderList',
            component: OrderList,
            meta: {
              title: '購物紀錄-列表',
              togglePanelBar: true
            }
          },
          {
            path: ':id',
            name: 'OrderDetail',
            component: OrderDetail,
            meta: {
              hidden: true,
              title: '購物紀錄-詳細'
            }
          }
        ]
      },
      {
        path: '/member/activity',
        name: 'MemberActivity',
        meta: {
          title: '活動兌換',
          hidden: true
        },
        component: Activity
      },
      {
        path: '/member/points',
        name: 'Points',
        meta: {
          title: '點數查詢'
        },
        component: Points
      }
    ]
  },
  {
    path: '/service',
    name: 'Service',
    meta: {
      title: '客服中心'
    },
    component: Service,
    children: [
      {
        path: '/service/contact',
        name: 'Contact',
        meta: {
          title: '聯絡我們'
        },
        component: Contact
      },
      {
        path: '/service/member-provision',
        name: 'Provision',
        meta: {
          title: '會員同意條款'
        },
        component: Provision
      },
      {
        path: '/service/contact/response',
        name: 'ContactResponse',
        meta: {
          hidden: true,
          title: '聯絡我們'
        },
        component: ContactResponse
      },
      {
        path: '/service/stronghold',
        name: 'stronghold',
        meta: {
          title: '全國通訊錄'
        },
        component: Stronghold
      }
    ]
  },
  {
    path: '/scam',
    name: 'Scam',
    meta: {
      title: '反詐騙專區'
    },
    component: Scam
  },
  {
    path: '/error',
    name: 'Error'
  }
]

// 正式版月刊拿掉
// if (location.host.indexOf('tocin.com.tw') > -1) {
//   routes[4].children.shift()
// }

export default routes
