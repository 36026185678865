import InputBox from './input-box/Index.vue'
import TextareaBox from './textarea-box/Index.vue'
import ErrorMessage from './error-message'
import ButtonBox from './button/Index.vue'

const FromInstall = {
  install (Vue) {
    // Vue.component('photo-frame', PhotoFrame)
    Vue.component('InputBox', InputBox)
    Vue.component('TextareaBox', TextareaBox)
    Vue.component('ErrorMessage', ErrorMessage)
    Vue.component('ButtonBox', ButtonBox)
  }
}

export default FromInstall
