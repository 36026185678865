export default {
  state: {
    fontSize: 16,
    mobileMenuActive: false,
    scrollTopMenuActive: false,
    searchBarActive: false,
    animationGroup: [],
    togglePanelBar: false,
    paginationBar: false,
    router: [],
    loginPage: '',
    search: {
      page: 1,
      series_id: null,
      category_id: null,
      order_by: null,
      sort: null,
      keyword: null
    }
  },
  mutations: {
    setRouter (state, router) {
      state.router = router
    },
    setSearch (state, param) {
      state.search = param
    },
    setLoginPage (state, router) {
      state.loginPage = router
    },
    /**
     * @param {Boolean|undefined} bool 進行判斷給予開啟或關閉，不給值則 toggle
     */
    toggleSearchBar (state, bool) {
      if (bool) {
        state.searchBarActive = bool
      } else {
        state.searchBarActive = !state.searchBarActive
      }
    },
    /**
     * @param {Boolean|undefined} bool 進行判斷給予開啟或關閉，不給值則 toggle
     */
    toggleMenuBar (state, bool) {
      if (bool === false || bool === true) {
        state.mobileMenuActive = bool
      } else {
        state.mobileMenuActive = !state.mobileMenuActive
      }
      if (state.mobileMenuActive) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    /**
     * 使用 addEventListener('scroll', mutations.scrollTop) 綁定
     */
    scrollTop (state) {
      const scrollTopCheck = 136
      if (state.scrollTopMenuActive) {
        if (window.scrollY <= scrollTopCheck) state.scrollTopMenuActive = false
      } else {
        if (window.scrollY >= scrollTopCheck) state.scrollTopMenuActive = true
      }
    },
    /**
     * @param {String|Number} data 要設定的文字大小，可以用"+"、"-"符號表示增加一個級距(+-2)，或使用數值。
     */
    setFontSize (state, data) {
      if (typeof data === 'string' && /\+/.test(data)) {
        if (state.fontSize >= 24) return 24
        state.fontSize += 2
      } else if (typeof data === 'string' && /-/.test(data)) {
        if (state.fontSize <= 14) return 14
        state.fontSize -= 2
      } else if (!isNaN(Number(data))) {
        if (state.fontSize <= 14 || state.fontSize >= 24) return
        state.fontSize = Number(data)
      }
    },
    /**
     * @param {Array<Object>} animationGroup 設定 state.animationGroup，供 toggleAnimationTarget 使用
     * @param {HTMLElement} animationGroup.element 對應綁定的 Dom 實體對象，必填
     * @param {Boolean} animationGroup.active 用來綁定的開合參數，切勿使用該 key
     */
    setAnimationGroup (state, animationGroup) {
      animationGroup.forEach(item => {
        item.active = true
      })
      state.animationGroup = animationGroup
    },
    /**
     * @param {*} route 傳入 route 判斷 meta 內是否有對應需要的參數判斷
     * 目前使用 meta.togglePanelBar、meta.paginationBar
     */
    setFixedBar (state, route) {
      if (route.meta.togglePanelBar) {
        state.togglePanelBar = true
      } else {
        state.togglePanelBar = false
      }
      if (route.meta.paginationBar) {
        state.paginationBar = true
      } else {
        state.paginationBar = false
      }
    }
  },
  actions: {
    /**
     * @param {HTMLElement} element 要執行動畫的元素，如果空值則自動去找 state.animationGroup[*].element
     * @param {Function} callback 完成動畫要執行的 function
     * @param {Object} animation 動畫設定的 config
     * @param {Number} animation.duration 動畫執行的時間 (ms)，預設 400ms
     * @param {String} animation.easing 動畫執行的函數，預設ease
     * @param {Number} animation.delay 執行動畫前的等待時間 (ms)
     * @param {String} animation.type 動畫類型，目前有 none、slide
     * @param {Boolean|undefined} order 進行判斷給予開啟或關閉，不給值則 toggle
     */
    toggleAnimationTarget ({ state }, { index, element, callback, animation, order }) {
      const target = state.animationGroup[index]
      const {
        duration,
        easing,
        delay,
        type
      } = animation || {}
      const animationType = type || 'default'
      const dom = element || target.element
      if (!!order === !!target.active || order === undefined) {
        if (target.active && dom) {
          target.active = false
          if (animationType === 'slide') {
            dom.slideUp(duration, easing, delay, callback)
          } else {
            dom.style.display = 'none'
          }
        } else {
          target.active = true
          if (animationType === 'slide') {
            dom.slideDown(duration, easing, delay, callback)
          } else {
            dom.style.display = ''
          }
        }
      }
    },
    /**
     * @param {Array<HTMLElement>} element 要執行動畫的元素，如果空值則自動去找 state.animationGroup[*].element
     * @param {Function} callback 完成動畫要執行的 function
     * @param {Object} animation 參照 actions:toggleAnimationTarget
     * @param {Boolean|undefined} order 進行判斷給予開啟或關閉，不給值則 toggle
     */
    toggleAnimationAll ({ state, dispatch }, { element, callback, animation, order }) {
      const dom = element || state.animationGroup.map(p => p.element)
      Promise.all(
        state.animationGroup.map((item, index) => {
          return new Promise(resolve => {
            dispatch('toggleAnimationTarget', {
              index,
              animation,
              element: dom[index],
              callback: resolve,
              order
            })
          })
        })
      ).then(() => {
        if (callback) callback()
      })
    }
  }
}
