import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
*/
export default class SeriesModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.category_id = entity.category_id || 0
    this.sort = entity.sort || 0
    this.name = entity.name || ''
    this.products_count = entity.products_count || ''
    this.published_at = entity.published_at || ''
    this.finished_at = entity.finished_at || ''
    this.seo_description = entity.seo_description || ''
    this.seo_name = entity.seo_name || ''
  }
}
