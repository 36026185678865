import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
*/

export default class ShippingRulesModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.shipping_fee = entity.shipping_fee || 0
    this.free_shipping = entity.free_shipping || 0
  }
}
