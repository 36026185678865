<template lang="pug">
  div(class="container")
    div(class="bg-white px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="d-flex justify-content-between pt-xl-3 pb-5")
        div(class="fs-24 font-weight-bold text-left pr-2") 東震月刊
        div(class='d-none d-md-flex align-items-center')
            Icon(class="mr-1" pattern="FormatSize" size="24")
            button(class="btn-30 font-icon rounded-left border border-light border-right-0 bg-white p-0 focus-none" @click="setFontSize('+')")
              Icon(class="" pattern="Add" size="24")
            button(class="btn-30 font-icon rounded-right border border-light border-right-0 bg-white p-0 focus-none" @click="setFontSize('-')")
              Icon(class="" pattern="Remove" size="24")
      div(class="px-md-5")
        div(class="monthly d-flex flex-wrap")
          div(class="d-flex px-3 flex-column align-items-center col-12 col-sm-6 col-md-4 col-xl-3" :style="{fontSize: global.fontSize + 'px'}" style="margin-bottom:80px" v-for="item in monthly")
            a(:href="'/sound/monthly/'+item.id" target="_blank")
              img(class="bg-dark mb-4 img-fluid" :src="item.image")
              div(class="text-center text-dark") {{ item.title }}
              div(class="text-center text-secondary") {{ dayFormat(item.published_at,"YYYY-MM") }}
    router-view
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapMutations } from 'vuex'

const ModelName = 'Ebooks'

export default {
  name: 'Monthly',
  data () {
    return {
      monthly: []
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      listModel: state => state.model[ModelName]
    })
  },
  methods: {
    ...mapMutations(['setFontSize']),
    dayFormat (time, format) {
      if (time) {
        return dayjs(time).format(format)
      }
      return ''
    }
  },
  created () {
    this.$store.dispatch('getListModel', {
      name: ModelName
    }).then(list => {
      this.monthly = list.data
    })
  }
}
</script>

<style scoped>
@media (max-width:768px){
  .container {
    padding: 0;
    margin: 0;
    max-width: none;
  }
}
</style>
