import DataModel from './data-model'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} title 該筆文章的標題
 * @param {String} title 該筆文章的內容
 * @param {String} status 該筆文章的狀態碼
 * @param {Number} sort 該筆文章的排序
 * @param {String} image 預覽用圖片
 * @param {String} index_title 首頁專用的標題
 * @param {String} is_notifiable 是否訊息通知
 * @param {String} prefix_time 前綴時間標題 YYYY/MM/DD HH:MM:ss
 * @param {String} published_at 該筆文章的公開時間 YYYY/MM/DD HH:MM:ss
 * @param {String} finished_at 該筆文章的下架時間 YYYY/MM/DD HH:MM:ss
 * @param {String} updated_user 該筆文章最後編輯的使用者
 */
export default class Article extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || uuid()
    this.title = entity.title || ''
    this.content = entity.content || ''
    this.status = entity.status || 0
    this.sort = entity.sort || 0
    this.image = entity.image || ''
    this.index_title = entity.index_title || ''
    this.is_notifiable = entity.is_notifiable || '0'
    this.prefix_time = entity.prefix_time || ''
    this.index_title = entity.index_title || ''
    this.published_at = entity.published_at ? dayjs(entity.published_at).format('YYYY/MM/DD HH:MM:ss') : dayjs().format('YYYY/MM/DD HH:MM:ss')
    this.finished_at = entity.finished_at ? dayjs(entity.finished_at).format('YYYY/MM/DD HH:MM:ss') : dayjs().format('YYYY/MM/DD HH:MM:ss')
    this.updated_user = entity.updated_user || ''
  }
}
