import DataModel from '../../library/proto/data-model'
import SeriesModel from './series'

/**
 * @param {Number} id 該Model的辨識索引
*/
export default class CategoriesModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.sort = entity.sort || 0
    this.name = entity.name || ''
    this.image = entity.image || ''
    this.banner_image = entity.banner_image || ''
    this.icon_image = entity.icon_image || ''
    this.icon_image_alt = entity.icon_image_alt || ''
    this.icon_image_title = entity.icon_image_title || ''
    this.products_count = entity.products_count || 0
    this.published_at = entity.published_at || ''
    this.finished_at = entity.finished_at || ''
    this.seo_description = entity.seo_description || ''
    this.seo_name = entity.seo_name || ''
    this.series = entity.series ? entity.series.map(p => new SeriesModel(p)) : []
  }
}
