import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
// import JDatePicker from 'vue-jlunar-datepicker'
import './simple-calendar'
import './slick'
import './paginate'

// Vue.component('DatePicker', JDatePicker)
Vue.use(VueAxios, axios)
