
import { getFavorites, createFavorites, deleteFavorites, createShopcart, deleteShopcartById, getActivitiesFive, updateActivityFive } from '../api'
import { ShopCartModel, NatalchartDataModel } from '../models'
import axios from 'axios'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    /**
     * 新增該 prodoct_id 於我的最愛 List
     * @param {Number} id
     * @returns {Array<Object>} 取得 Response 資料
     */
    async addFavorites ({ rootState }, id) {
      await createFavorites(id)
      const res = await getFavorites()
      rootState.model.Favorites.set({
        data: res.data
      })
      return res.data
    },
    /**
     * 移除該 id 於我的最愛對應的 Model
     * @param {Number} id
     * @returns {ProductsModel} 取得被移除的Model
     */
    async removeFavorites ({ rootState }, id) {
      await deleteFavorites(id)
      const indexOf = rootState.model.Favorites.data.map(p => p.id).indexOf(id)
      if (indexOf >= 0) {
        const cache = rootState.model.Favorites.data.splice(indexOf, 1)
        return cache[0]
      }
    },
    /**
     * 新增該 prodoct_id 於購物車 List
     * 如果購物車內含開運商品，會跳出 confirm 確認是否加入購物車
     * @param {Number} id
     * @returns {Array<Object>} 取得 Response 資料
     */
    async addShopcart ({ rootState, dispatch }, id) {
      if (rootState.model.ShopCarts.data.length && rootState.model.ShopCarts.data.every(p => p.is_lucky) && rootState.model.ShopCarts.data.every(p => p.is_installment === 1)) {
        if (!confirm('購物車內含開運商品，若加入一般商品則無法進行信用卡12期分期付款，是否要將該商品加入購物車?')) {
          return ''
        }
      }
      const prod = await dispatch('getModelById', { name: 'Products', id })
      console.log('praddShopcartod', prod)
      if (prod.quantity <= 0) {
        return { out_of_stock: 1 }
      }
      const ShopCart = new ShopCartModel({ product_id: id, products: prod })
      const res = await createShopcart(ShopCart.toDatabase())
      const cache = rootState.model.ShopCarts.data.find(p => Number(p.product_id) === Number(res.data.id))
      if (cache) {
        cache.set(res.data)
      } else {
        ShopCart.set(res.data)
        rootState.model.ShopCarts.data.push(new ShopCartModel(ShopCart))
      }
      console.log('rootState.model.ShopCarts', rootState.model.ShopCarts)
      return res.data
    },
    /**
     * 移除該 id 於購物車對應的 Model
     * @param {Number} id
     * @returns {ShopcartModel} 取得被移除的Model
     */
    async removeShopcartByProdId ({ rootState }, id) {
      const indexOf = rootState.model.ShopCarts.data.map(p => p.product_id).indexOf(id)
      const cache = rootState.model.ShopCarts.data.splice(indexOf, 1)[0]
      await deleteShopcartById(cache.id)
      return cache
    },
    /**
     * 移除該 shop cart id 於購物車對應的 Model
     * @param {Number} id
     * @returns {ShopcartModel} 取得被移除的Model
     */
    async removeShopcartByShopCartId ({ rootState }, id) {
      const indexOf = rootState.model.ShopCarts.data.map(p => p.id).indexOf(id)
      const cache = rootState.model.ShopCarts.data.splice(indexOf, 1)[0]
      await deleteShopcartById(cache.id)
      return cache
    },

    async getActivityFiveList ({ rootState }, id) {
      return getActivitiesFive(id)
    },

    async updateActivityFive ({ rootState }, modal) {
      console.log('modal', modal)
      return updateActivityFive(modal.id, modal.product_number)
    },

    /**
     * @param {Number} model.fortune_calendar
     * @param {String} model.fortune_year
     * @param {String} model.fortune_month
     * @param {String} model.fortune_day
     * @param {String} model.fortune_gender M | F
     * @param {Number} model.fortune_is_leap 1 | 0
     * @param {String} model.fortune_hour
     * @param {String} model.fortune_minute
     * @returns {Object} 取得 NatalchartDataModel 原型
     */
    async natalchartFormat (context, model) {
      const yearType = (calendar) => {
        switch (String(calendar)) {
          case '國曆': return 1
          case '農曆': return 2
          case '西元': return 3
          default: return 3
        }
      }
      const gender = (sex) => {
        switch (sex) {
          case 'M': return 1
          case 'F': return 2
        }
      }
      const minute = (fortuneMinute) => {
        if (fortuneMinute === null || fortuneMinute === '') {
          return '00'
        } else {
          return fortuneMinute
        }
      }
      const params = {
        Yeartype: yearType(model.fortune_calendar),
        Year: model.fortune_year,
        Month: model.fortune_month,
        Day: model.fortune_day,
        gender: gender(model.fortune_gender),
        leapyear: yearType(model.fortune_calendar) === 2 && (model.fortune_is_leap === 1 || model.fortune_is_leap === '1') ? 1 : 0,
        hour: model.fortune_hour,
        minute: minute(model.fortune_minute)
      }
      console.log('natalchartFormat')
      console.log(params)
      // const res = await axios.get('http://211.75.213.163:8081/Tocin8word/newtocin2018.ashx', { params })
      const res = await axios.get(process.env.VUE_APP_BASE_URL + 'newtocin2018', { params })
      console.log(res)
      if (typeof res.data === 'string') {
        const error = JSON.parse(res.data)
        return new NatalchartDataModel(error)
      }
      return new NatalchartDataModel(res.data)
    }
  }
}
