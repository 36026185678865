<template lang="pug">
  div#product-detail
    template(v-if="init")
      div(class="w-100")
        h1(class="text-center" style="padding:200px 0px") Loading...
    template(v-else-if="model.products != null && model.products.status !== 0")
      div(class="py-2")
        ul(class="breadcrumb mb-3")
          li(class="breadcrumb-item")
            router-link(class="" to="/product/list") 全部商品
            Icon(class="mx-2" pattern="ArrowRight" size="24")
          li(class="breadcrumb-item" v-if="category")
            router-link(class="" :to="{ path: '/product/list', query: { category_id: category.id }}") {{ category.name }}
            Icon(class="mx-2" pattern="ArrowRight" size="24")
          li(class="breadcrumb-item" v-if="serie")
            router-link(class="" :to="{ path: '/product/list', query: { series_id: serie.id, category_id: serie.category_id }}") {{ serie.name }}
            Icon(class="mx-2" pattern="ArrowRight" size="24")
          li(class="breadcrumb-item")
            span {{ model.products.name }}
      div(class="row")
        div(class="col-12 d-md-none")
          h2(class="h5 font-weight-bold") {{ model.products.name }}
          div(class="mb-2") 品號：{{ model.products.product_number }}
        div(class="col-12 col-md-5 pb-3" v-if="model.products.images && model.products.images.length")
          div(class="w-100")
            div(class="p-100 position-relative")
              div(class="position-fill d-flex flex-center")
                img(class="img-fluid" :src="model.products.images[preview].image")
          div(class="d-flex flex-wrap")
            div(class="col-3 py-2" v-for="(item, index) in model.products.images" :key="item.id")
              button(class="btn btn-icon mx-1" @click="changePreview(index)")
                img(class="w-100" :src="item.image")
        div(class="col-12 col-md-5 pb-3" v-else)
          div
            img(class="w-100" :src="defaultImage")
        div(class="col col-md-7")
          div(class="col-12 d-none d-md-block pb-3")
            h2(class="h5 font-weight-bold") {{ model.products.name }}
            div(class="") 品號：{{ model.products.product_number }}
          div(class="border-top py-2")
            div(class="d-flex py-1 font-weight-bold")
              div(class="col-auto" style="width: 100px;") 建議售價
              div(class="col") NT$ {{ commaFormat(model.products.price) }}
            div(v-if="UserData.loginStatus" class="d-flex py-1" style="color:#3961CA;")
              div(class="col-auto" style="width: 100px;") 傳銷商價
              div(class="col") NT$ {{ commaFormat(model.products.member_price) }}
            div(v-if="UserData.loginStatus" class="d-flex py-1" style="color:#AE0000;")
              div(class="col-auto" style="width: 100px;") 積分
              div(class="col") {{ commaFormat(model.products.pv) }}
          div(class="border-top py-2" v-if="UserData.loginStatus && inActivitiesByProduct.length")
            div(class="col text-primary py-2 font-weight-bold") 特惠活動
            div(class="col")
              div(class="py-2" v-for="activity in inActivitiesByProduct" :key="activity.id" v-html="activity.description")
          div(class="border-top py-2" v-if="model.products.brief")
            div(class="col")
              div(class="py-2 font-weight-bold" v-html="model.products.brief")
          div(class="col" v-if="luckyAccessories")
            div(class="py-2 font-weight-bold") 網購飾品不接收急單處理，若有急單需求請至營業所訂購飾品
          //div(class="border-top py-2" v-if="isLuckyProduct(model.products.product_number)")
          //  div(class="col")
          //    div(class="py-2 font-weight-bold")
          //      span(class="" style="color: #EF716D;") 訂購開運飾品，刷台新信用卡可分3、6、9、12期，但選擇分12期，需單獨成立訂單，不得再選購其他商品。
          div(class="border-top py-2" v-if="isLuckyProduct(model.products.product_number) && UserData.loginStatus")
            div(class="col mb-3")
              div(class="row my-1")
                div(class="col-auto form-title") 選擇命盤
                div(class="col")
                  select(class="w-100 custom-select mt-2" style="text-align-last: left;" v-model="selectedNatalchart" @change="changeNatalchart")
                    option(value="" selected disabled hidden) 請選擇
                    option(:value="0" :key="0") 請選擇
                    option(v-for="item in Natalcharts.data" :value="item.id" :key="item.id") {{ item.fortune_name }} 命盤
            NatalchartEditor(:model="natalchar" @submit="addNatalchart" @change="editNatalchart")
            div(class="col")
              div(class="row my-1")
                div(class="col-auto form-title") 訂購字型
                div(class="col")
                  select(class="w-100 custom-select mt-2" :class="{'is-invalid': hasError(model, 'fortune_word')}" style="text-align-last: left;" v-model="model.fortune_word")
                    option(value="" disabled) 請選擇字型
                    option(v-for="word in wordData" :value="word") {{ word }}
                  ErrorMessage(class="mt-0" :model="model" handle="fortune_word")
              div(class="row" v-if="model.products.product_number === '01209' || model.products.product_number === '01005'")
                div(class="col-auto form-title") 皮帶長度
                div(class="col")
                  div(class="d-flex mx-n1 radio-group")
                    div(class="col custom-control custom-radio my-2")
                      input(type="radio" class="custom-control-input" value="M-21cm" :class="{'is-invalid': hasError(model, 'fortune_wrist_size')}" :id="uuid.sizeM" v-model="model.fortune_wrist_size")
                      label(class="custom-control-label" :for="uuid.sizeM") M-21cm
                    div(class="col custom-control custom-radio my-2")
                      input(type="radio" class="custom-control-input" value="L-24cm" :class="{'is-invalid': hasError(model, 'fortune_wrist_size')}" :id="uuid.sizeL" v-model="model.fortune_wrist_size")
                      label(class="custom-control-label" :for="uuid.sizeL") L-24cm
                  ErrorMessage(class="mt-0" :model="model" handle="fortune_wrist_size")
              div(class="row my-1" v-if="model.products.product_number === '01205' || model.products.product_number === '01001'")
                div(class="col-auto form-title") 戒圍
                div(class="col")
                  select(class="w-100 custom-select mt-2" :class="{'is-invalid': hasError(model, 'fortune_ring_size')}" style="text-align-last: left;" v-model="model.fortune_ring_size")
                    option(value="" disabled) 請選擇戒圍
                    option(v-for="ring in ringData" :value="ring") 國際戒圍{{ ring }}號
                  ErrorMessage(class="mt-0" :model="model" handle="fortune_ring_size")
              div(class="row my-1" v-if="model.products.product_number === '01205' || model.products.product_number === '01001'")
                div(class="col-auto form-title") 位置
                div(class="col")
                  div(class="pt-3")
                    span(v-if="fingerSelected") {{ fingerSelected.title }}
                    span(v-else :class="{'text-danger': hasError(model, 'fortune_finger')}") 請選擇配戴位置
                div(class="w-100 py-3")
                  div(class="mx-auto" style="max-width: 440px;")
                    svg(xmlns="http://www.w3.org/2000/svg" :viewBox="prodHand.attrs.viewBox")
                      g(fill="#D8D8D8" v-html="prodHand.path")
                      g(v-for="item in fingerData" :key="item.id" :title="item.title")
                        g(v-html="item.hoverPath" class="cursor-pointer" style="fill: transparent;" @mouseover="mouseoverFinger(item)" @mouseout="mouseoutFinger(item)" @click="clickFinger(item)")
                        g(v-html="item.path" :style="{fill: item.hoverAction || model.fortune_finger === item.title ? '#56870F' : 'transparent'}")
          div(class="border-top py-2" v-if="UserData.loginStatus")
            div(class="col")
              div(class="d-flex my-2")
                div(class="col-12 col-md-6 px-0")
                  div(class="row font-weight-bold align-items-center")
                    div(class="col-auto" style="width: 100px;") 數量
                    div(class="col")
                      div(v-if="isLuckyProduct(model.products.product_number)") 1
                      div(class="text-danger" v-else-if="maxLimitedBuy(model.products) === 0") 已售完
                      select(v-else class="w-100 custom-select py-2 rounded" style="text-align-last: left;" v-model="product_quantity")
                        option(v-for="i in maxLimitedBuy(model.products)" :value="i" :selected="i === model.product_quantity") {{ i }}
              ErrorMessage(class="mt-0" :model="model" handle="error_message")
              div(class="row flex-column flex-md-row mx-n1 my-2")
                div(class="col-12 col-md-6 p-1")
                  template(v-if="maxLimitedBuy(model.products)")
                    ButtonBox(:model="model" @click="addShopChart" class="w-100")
                      template(v-slot:icon)
                        Icon(pattern="ShoppingCart" size="16")
                      template(v-slot:default)
                        span(class="ml-2") 加入購物車
                  template(v-else)
                    button(class="btn btn-success w-100 d-flex flex-center" type="button" :disabled="true")
                      Icon(pattern="ShoppingCart" size="16")
                      span(class="ml-2") 暫無庫存
                div(class="col-12 col-md-6 p-1" v-if="isLuckyProduct(model.products.product_number)")
                  button(class="btn btn-outline-success w-100 d-flex flex-center" type="button" @click="addNatalchart" :disabled="!(!submitDisabled && !selectedNatalchart)")
                    Icon(pattern="Add" size="16")
                    span(class="ml-2") 儲存命盤
                div(class="col-12 col-md-6 p-1" v-else)
                  button(class="btn btn-outline-success w-100 d-flex flex-center" :class="{'active': inFavorites(model.product_id)}" type="button" @click="clickFavorites(model.product_id)" :disabled="inFavorites(model.product_id)")
                    Icon(pattern="Favorite" size="16")
                    span(class="ml-2" v-if="inFavorites(model.product_id)") 已加入我的最愛
                    span(class="ml-2" v-else) 加入我的最愛
                div(class="col-12 p-1" v-if="isLuckyProduct(model.products.product_number)")
                  router-link(class="btn btn-outline-success w-100 d-flex flex-center" to="/member/natalchart")
                    Icon(pattern="ArrowRight" size="16" style="transform: rotate(180deg);")
                    span(class="ml-2") 前往命盤管理
      div(class="border-top py-2")
        div(class="d-flex")
          div(class="col font-weight-bold py-2") 相關商品推薦
        div(class="w-100")
            Slick(v-bind="prodSlickSettings" v-if="recommended.data && recommended.data.length")
              div(class="d-flex" v-for="item in recommended.data")
                div(class="col")
                  div(class="")
                    div(class="p-100 position-relative")
                      router-link(class="position-fill bg-contain" :title="item.title" :to="'/product/' + item.id" v-if="item.images && item.images.length && item.images[0]" :style="{backgroundImage: `url(${item.images[0].image})`}")
                      router-link(class="position-fill bg-contain" :title="item.title" :to="'/product/' + item.id" v-else :style="{backgroundImage: `url(${defaultImage})`}")
                  div(class="d-flex flex-column flex-center")
                      div(class="d-flex align-items-center")
                      span(class="text-truncate w-100 text-center text-dark d-block") {{ item.name }}
                      span(class="text-success") NT$ {{ commaFormat(item.price) }}
      div(class="border-top py-2" v-if="UserData.loginStatus && (model.products.description || model.products.info || model.products.feature)")
        div(class="py-2" v-if="model.products.description")
          h3(class="bg-primary-o-1 h6 p-2 font-weight-bold") 說明
          div(class="col" v-html="model.products.description")
        div(class="py-2" v-if="model.products.info")
          h3(class="bg-primary-o-1 h6 p-2 font-weight-bold") 商品資訊
          div(class="col" v-html="model.products.info")
        div(class="py-2" v-if="model.products.feature")
          h3(class="bg-primary-o-1 h6 p-2 font-weight-bold") 商品特色
          div(class="col" v-html="model.products.feature")
    template(v-else)
      div(class="w-100")
        h1(class="text-center" style="padding:200px 0px") 暫無此商品
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import solarLunar from 'solarlunar'
import { ProductsModel, ShopCartModel, NatalchartModel } from '../../../models'
import { ListModel } from '../../../library/proto'
import {
  getRecommendedProducts,
  createNatalcharts,
  createShopcart,
  deleteShopcartById
} from '../../../api'
import prodHand from '../../../components/icon/pattern/prod-hand'
import NatalchartEditor from '../../../container/natalchart/Index.vue'
import defaultImage from '@/assets/images/default.png'
import LuckyProductCheck from '../../dialog/LuckyProductCheck'
import { Toast } from '@/utils/helper'

export default {
  name: 'ProductDetail',
  data () {
    return {
      installment: 0,
      openDialogChange: 0,
      luckyAccessories: false,
      init: true,
      model: new ShopCartModel(),
      static_model: new ShopCartModel(),
      preview: 0,
      product_quantity: 1,
      currentQuantity: 0,
      recommended: new ListModel({
        model: ProductsModel
      }),
      natalchar: new NatalchartModel(),
      prodHand,
      fingerData: prodHand.pathData.map(item => {
        item.id = item.id + '-' + uuidv4().substring(0, 8)
        item.hoverAction = false
        item.active = false
        return item
      }),
      // ["甲","乙","丙","丁","戊","己","庚","辛","壬","癸","子","丑","寅","卯","辰","巳","午","未","申","酉","戌","亥"]
      wordData: [].concat(solarLunar.gan, solarLunar.zhi),
      // 3 ~ 25
      ringData: new Array(30).fill('').map((item, index) => {
        return 3 + index * 0.5
      }),
      uuid: {
        sizeM: uuidv4(),
        sizeL: uuidv4()
      },
      selectedNatalchart: 0,
      submitDisabled: true,
      prodSlickSettings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }]
      },
      defaultImage
    }
  },
  beforeCreate () {
  },
  updated () {
    // console.log('fortune_word updated', this.model)
  },
  created () {
    // console.log('fortune_word created', this.model.fortune_word)
    this.model.fortune_word = ''
    this.model.set({
      fortune_word: ''
    })
    getRecommendedProducts().then(res => {
      this.recommended.set({
        data: res.data
      })
      this.$route.meta.recommended = this.recommended
    })

    this.$store.dispatch('getListModel', {
      name: 'Products'
    })
    this.getModelById()
    // console.log('model', this.model)
  },
  watch: {
    $route () {
      this.getModelById()
      this.preview = 0
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Products: state => state.model.model.products,
      Natalcharts: state => state.model.Natalcharts,
      ShopCarts: state => state.model.ShopCarts,
      Favorites: state => state.model.Favorites,
      Categories: state => state.model.Categories,
      Activities: state => state.model.Activities,
      UserData: state => state.model.UserData,
      StoreProducts: state => state.model.Products
    }),
    fingerSelected () {
      return this.fingerData.find(p => p.title === this.model.fortune_finger)
    },
    category () { // 該產品系列
      const category = this.Categories.data.find(p => Number(p.id) === Number(this.model.products.category_id))
      if (category) {
        return category
      }
      return false
    },
    serie () { // 該產品分類
      if (!this.category) {
        return false
      }
      const serie = this.category.series.find(p => Number(p.id) === Number(this.model.products.series_id))
      if (serie) {
        return serie
      }
      return false
    },
    inActivitiesByProduct () {
      return this.Activities.data.filter(item => {
        return (
          item.ruleable.activity_limit_products.map(p => Number(p.id)).includes(Number(this.model.product_id)) ||
          item.ruleable.activity_products.map(p => Number(p.id)).includes(Number(this.model.product_id))
        )
      })
    }
  },
  methods: {
    ...mapActions(['getListModel', 'natalchartFormat']),
    getModelById () {
      window.scrollTo({ top: 0 })
      const id = Number(this.$route.params.id)

      return new Promise(resolve => {
        this.$store.dispatch('getModelById', { name: 'Products', id }).then(data => {
          this.getListModel({ name: 'Activities' })
          // console.log('data', data.product_number)
          // console.log('fortune_word', this.model.fortune_word)
          // if (parseInt(data.product_number) >= 1205 && parseInt(data.product_number) <= 1209) {
          //   this.luckyAccessories = true
          // }

          this.product_quantity = 1
          this.init = false
          // console.log('data', data)
          this.submitDisabled = false
          const cacheShopcart = this.ShopCarts.data.find(p => Number(p.product_id) === Number(data.id))
          // console.log('cacheShopcart', cacheShopcart)
          if (cacheShopcart) {
            // console.log('cacheShopcart', cacheShopcart)
            this.model = cacheShopcart
            this.model.products = new ProductsModel(data)
            this.model.fortune_word = ''
            this.model.fortune_finger = ''
            this.model.fortune_wrist_size = ''
            this.static_model = Object.assign({}, this.model)
            this.currentQuantity = this.static_model.product_quantity // 紀錄購物車基礎的商品數，以方便往上加
            // this.model.product_quantity = cacheShopcart.product_quantity
            // cacheShopcart.product_quantity = 1 // 預設改為 1 個
          } else {
            this.model = new ShopCartModel({
              product_id: data.id,
              is_lucky: this.isLuckyProduct(data.product_number),
              products: new ProductsModel(data),
              fortune_word: ''
            })
            this.currentQuantity = 0
            // console.log('not cache cacheShopcart', this.model)
          }
          // console.log('this.model', data.status)
          // console.log('this.$route', this.$route.query.action)

          if (data.status === 0) {
            this.$store.dispatch('removeShopcartByProdId', data.id)
            this.$store.dispatch('getListModel', {
              name: 'ShopCarts'
            })
          }

          if (this.$route.query && this.$route.query.action === 'buy') {
            this.$store.dispatch('addShopcart', data.id)
          }
          resolve(this.model)
        }).catch(() => {
          // this.Products.push({ status: 1 })
          // console.log('Do that')
        })
      })
    },
    changePreview (index) { // 預覽圖
      this.preview = index
    },
    mouseoverFinger (item) {
      item.hoverAction = true
    },
    mouseoutFinger (item) {
      item.hoverAction = false
    },
    clickFinger (item) {
      this.model.fortune_finger = item.title
    },
    changeNatalchart (id) { // 切換命盤
      console.log('切換命盤')
      if (typeof id === 'number' && !isNaN(id)) {
        this.selectedNatalchart = id
      }
      this.natalchar = new NatalchartModel(
        this.Natalcharts.data.find(p => p.id === this.selectedNatalchart)
      )
      console.log('changeNatalchart', this.natalchar)
    },
    editNatalchart () { // 當修改命盤恢復為自訂義選項
      this.selectedNatalchart = 0
    },
    async addNatalchart () {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.natalchar.validate()
      if (errorMessage.length) {
        return
      }
      this.submitDisabled = true
      this.natalchartFormat(this.natalchar).then(result => {
        if (result.errorstatus) {
          this.model.validate({
            error_message: { inclusion: { message: `^${result.error}` } }
          })
          return
        }
        // console.log('addNatalchart')
        this.natalchar.result = result
        // console.log(this.natalchar.result)
        createNatalcharts(
          this.natalchar.toDatabase()
        ).then(async res => {
          this.natalchar.id = res.data.id
          // console.log(this.natalchar.id)
          // console.log(this.natalchar)
          this.Natalcharts.data.push(new NatalchartModel(this.natalchar))
          this.changeNatalchart(this.natalchar.id)
          this.submitDisabled = false
        }).catch(() => {
          this.submitDisabled = false
        })
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    convNatalcharToShopcart (model) { // 將 NatalcharModel 轉 ShopcartModel
      console.log('convNatalcharToShopcart')
      console.log(JSON.stringify(model))
      const year = model.fortune_year
      const month = model.fortune_month
      const day = model.fortune_day
      const data = {}
      if (model.fortune_calendar === '國曆') {
        console.log('國曆')
        data.fortune_calendar = model.fortune_calendar
        data.fortune_year = Number(year) + 1911
        data.fortune_month = month
        data.fortune_day = day
        data.fortune_is_leap = 0
      }
      if (model.fortune_calendar === '農曆') {
        console.log('農曆')
        console.log('solarLunar.lunar2solar')
        const solarData = solarLunar.lunar2solar(Number(year) + 1911, Number(month), Number(day), model.fortune_is_leap)
        console.log(solarData)
        console.log(JSON.stringify(solarData))
        data.fortune_calendar = model.fortune_calendar
        data.fortune_year = solarData.cYear
        data.fortune_month = solarData.cMonth
        data.fortune_day = solarData.cDay
        data.fortune_is_leap = model.fortune_is_leap
      }
      if (model.fortune_calendar === '西元') {
        console.log('西元')
        data.fortune_calendar = model.fortune_calendar
        data.fortune_year = year
        data.fortune_month = month
        data.fortune_day = day
        data.fortune_is_leap = 0
      }
      console.log('convNatalcharToShopcart')
      console.log(JSON.stringify(data))
      return {
        ...data,
        fortune_name: model.fortune_name,
        fortune_gender: model.fortune_gender,
        fortune_china_hour: model.fortune_china_hour,
        fortune_hour: model.fortune_hour,
        fortune_minute: model.fortune_minute,
        result: model.result
      }
    },
    async addShopChart () {
      // 驗證文字
      const errorMessage = this.model.is_lucky ? [].concat(this.model.validate(), this.natalchar.validate()) : [].concat(this.model.validate())
      // console.log('addShopChart')
      if (errorMessage.some(p => p)) {
        window.scrollTo({ top: 460 })
        return
      }
      this.currentQuantity = 0
      // 判斷是否為開運商品
      if (this.model.is_lucky) {
        const result = await this.natalchartFormat(this.natalchar)
        if (result.errorstatus) {
          this.model.validate({
            error_message: { inclusion: { message: `^${result.error}` } }
          })
          return
        }
        console.log('this.natalchar', JSON.stringify(this.natalchar))

        result.fortune_is_leap = 0
        if (Object.hasOwn(result, 'leap') && result.leap === '1' && result.fortune_calendar === '農曆') {
          this.natalchar.fortune_is_leap = 1
          result.fortune_is_leap = 1
          // console.log('change natalchartFormat', result.leap)
        }
        this.model.set(this.convNatalcharToShopcart(this.natalchar))
        this.model.set({ result })

        console.log('result', result)

        // 進行分期確認
        await this.$dialog.open(LuckyProductCheck, {
          onBackgroundClick: false,
          attrs: {
            model: this.model,
            result: result,
            close: 0,
            installment: this.installment
          },
          width: '1080px'
        }).then(attrs => {
          console.log('attrs.close', attrs.close)
          this.openDialogChange = attrs.close
        })
      } else {
        if (this.ShopCarts.data.length && this.ShopCarts.data.every(p => p.is_lucky) && this.ShopCarts.data.every(p => p.is_installment === 1)) {
          if (!confirm('購物車內含開運商品，若加入一般商品則無法進行信用卡12期分期付款，是否要將該商品加入購物車?')) {
            return
          }
        }

        const products = this.model.products
        this.model.loaded = false

        const id = Number(this.$route.params.id)
        const cacheShopcart = this.ShopCarts.data.find(p => Number(p.product_id) === Number(id))
        if (cacheShopcart) {
          this.static_model = Object.assign({}, cacheShopcart)
          this.currentQuantity = this.static_model.product_quantity // 紀錄購物車基礎的商品數，以方便往上加
        }
        console.log('this.ShopCarts.data', cacheShopcart)
        console.log('this.product_quantity', this.product_quantity)
        console.log('this.currentQuantity', this.currentQuantity)
        console.log('to', this.product_quantity + (this.isLuckyProduct(products.product_number) ? 0 : this.currentQuantity))
        const res = await createShopcart(
          this.model.toDatabase({
            product_quantity: this.product_quantity + (this.isLuckyProduct(products.product_number) ? 0 : this.currentQuantity)
          })
        ).catch(() => {
          this.model.loaded = true
        })

        // console.log('.products', this.model.products)
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('out_of_stock')) {
          if (res.data.out_of_stock === 1) {
            await deleteShopcartById(res.data.id)

            this.model.toDatabase({
              product_quantity: 0
            })
            Toast.fire({
              icon: 'error',
              title: '商品庫存不足'
            })
            console.log(' inin .products', this.model.products)

            return
          }
        }
        res.data.products = products
        this.model.set(
          new ShopCartModel({
            ...res.data,
            products
          })
        )
        this.currentQuantity = this.product_quantity + (this.isLuckyProduct(products.product_number) ? 0 : this.currentQuantity)
        /* eslint-disable */
        // 判斷是要更新 store 中的 model 還是增加新的 model
        const cache = this.ShopCarts.data.find(p => Number(p.product_id) === Number(this.model.product_id))
        if (cache && !this.isLuckyProduct(products.product_number)) {
          cache.set(res.data)
        } else {
          this.model.set(res.data)
          this.ShopCarts.data.push(new ShopCartModel(this.model))
        }
        this.selectedNatalchart = 0

      }
      await this.$nextTick()

      // 等待畫面更新後送出 API

      // console.log('this.ShopCarts', this.ShopCarts)
      this.model.loaded = true
      // console.log('this.ShopCarts', this.ShopCarts.data)
      // console.log('this.ShopCarts', this.ShopCarts.data.length)
      let text = ''


      let is_installment = 1
      let is_lucky = false
      if(this.ShopCarts.data !== null && this.ShopCarts.data.length > 0){
        is_installment = this.ShopCarts.data[this.ShopCarts.data.length - 1].is_installment
        is_lucky = this.ShopCarts.data[this.ShopCarts.data.length - 1].is_lucky
      }

      if(is_lucky) {
        // console.log('this.is_installment', is_installment)
        if (is_installment === 0) {
          text = '保留其他商品(不分十二期)! 飾品需2~3週製作期，如訂單中有其他商品則會先行寄出。'
        } else {
          text = '其餘商品將被刪除! 飾品需2~3週製作期，如訂單中有其他商品則會先行寄出。'
        }
      }
      // 其餘商品將被刪除!
      // 保留其他商品(不分十二期)!

      Toast.fire({
        icon: 'success',
        title: '成功加入購物車',
        text: text,
      })

      if(is_lucky) {
        this.$router.replace('/product/list')
      }


    },
    inFavorites (id) {
      return this.Favorites.data.map(p => p.id).includes(id)
    },
    inShopCart (id) {
      return this.ShopCarts.data.map(p => p.product_id).includes(id)
    },
    clickFavorites (id) {
      if (!this.UserData.loginStatus) {
        this.$store.commit('setLoginPage', this.$route.path)
        this.$router.replace('/member/login')
        return
      }
      if (this.Favorites.data.map(p => Number(p.id)).includes(Number(id))) {
        this.$store.dispatch('removeFavorites', id)
      } else {
        this.$store.dispatch('addFavorites', id)
      }
    },
    clickShopcart (id) {
      if (!this.UserData.loginStatus) {
        this.$store.commit('setLoginPage', this.$route.path)
        this.$router.replace('/member/login')
        return
      }
      if (this.ShopCarts.data.map(p => Number(p.product_id)).includes(Number(id))) {
        this.$store.dispatch('removeShopcartByProdId', id)
      } else {
        this.$store.dispatch('addShopcart', id)
      }
    }
  },
  components: {
    NatalchartEditor
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-items: center;
    margin: 0;
    .breadcrumb-item{
      display: flex;
      align-items: center;
    }
  }
  .bg-primary-o-1 {
    background-color: rgba(86, 135, 15, 0.1);
  }
  .radio-group{
    .custom-radio {
      max-width: 120px;
    }
  }
  #product-detail {
    font-size: 14px;
    .form-title{
      margin-top: 1rem;
      width: 86px;
    }
    @media (min-width: 768px) {
      font-size: 16px;
      .form-title{
        width: 94px;
      }
    }
  }
  .img-fluid{
    max-height: 100%;
  }
</style>
