<template lang="pug">
  div(class="error container-fluid d-flex justify-content-center align-items-center")
    div(class="error__container")
      div(class="text-center pb-5 mb-5 error__container__logo")
        img(:src="logoImg" class="img-fluid logo")
      div(class="text-center problem mb-5 ")
        img(:src="problemImg" class="img-fluid")
        p(class="problem__try text-dark mb-0") 請稍後再試
        p(class="problem__try text-dark mb-0") 網頁關閉期間，請至各區營業所出貨，或傳真至各營業所進行傳刷作業
      div(class="my-2")
        button(class="btn btn-success w-100" type="button"  onclick="javascript:window.open('http://tocinmail.com.tw', '_blank')") 業績信箱
      div(class="my-2")
        button(class="btn w-100 btn-search text-success" type="button" onclick="javascript:window.open('http://211.75.213.163/(S(abh0ek550evy4l55j223ve45))/pv.aspx', '_blank')") 業績查詢
</template>

<script>
import logoImg from '@/assets/svg/error-logo.svg'
import problemImg from '@/assets/svg/error-problem.svg'

export default {
  data () {
    return {
      logoImg,
      problemImg
    }
  }
}
</script>

<style lang="scss" scoped>
  .error{
    height: 100vh;
    .error__container{
      width: 100%;
      max-width: 301px;
    }
    .btn-search{
      border: 1px solid #56870F;
    }
    .error__container__logo{
      border-bottom: 1px solid #D8D8D8;
    }
  }
  @media screen and(max-width:768px) {
    .logo{
      width: 102px;
    }
  }
</style>
