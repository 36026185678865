import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} address 地址
 * @param {String} telphone 電話號碼
 * @param {String} fax 傳真號碼
 * @param {String} email 電子郵件
 * @param {String} declare copyright訊息
 * @param {String} link_fb FB-icon連結
 * @param {String} link_line Line-icon連結
 * @param {String} link_youtube YouTube-icon連結
 * @param {String} link_ig instagram-icon連結
 * @param {String} link_wechat 微信-icon連結
 * @param {String} link_twitter Twitter-icon連結
*/
export default class SystemRightsModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.address = entity.address || ''
    this.telphone = entity.telphone || ''
    this.fax = entity.fax || ''
    this.email = entity.email || ''
    this.declare = entity.declare || ''
    this.link_fb = entity.link_fb || ''
    this.link_line = entity.link_line || ''
    this.link_youtube = entity.link_youtube || ''
    this.link_ig = entity.link_ig || ''
    this.link_wechat = entity.link_wechat || ''
    this.link_twitter = entity.link_twitter || ''
  }
}
