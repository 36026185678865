export default {
  mount: '.icon-zoom-out',
  attrs: {
    viewBox: '0 0 40 40',
    fill: 'currentColor'
  },
  path: `
    <path d="M25.8333333,23.3333333 L24.5166667,23.3333333 L24.05,22.8833333 C25.6833333,20.9833333 26.6666667,18.5166667 26.6666667,15.8333333 C26.6666667,9.85 21.8166667,5 15.8333333,5 C9.85,5 5,9.85 5,15.8333333 C5,21.8166667 9.85,26.6666667 15.8333333,26.6666667 C18.5166667,26.6666667 20.9833333,25.6833333 22.8833333,24.05 L23.3333333,24.5166667 L23.3333333,25.8333333 L31.6666667,34.15 L34.15,31.6666667 L25.8333333,23.3333333 Z M15.8333333,23.3333333 C11.6833333,23.3333333 8.33333333,19.9833333 8.33333333,15.8333333 C8.33333333,11.6833333 11.6833333,8.33333333 15.8333333,8.33333333 C19.9833333,8.33333333 23.3333333,11.6833333 23.3333333,15.8333333 C23.3333333,19.9833333 19.9833333,23.3333333 15.8333333,23.3333333 Z M11.6666667,15 L20,15 L20,16.6666667 L11.6666667,16.6666667 L11.6666667,15 Z" fill="#424242"/>
  `
}
