import DataModel from '../../library/proto/data-model'
import ProductsModel from './products'

class FreebiesModel extends ProductsModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    // console.log('FreebiesModel', entity)
    this.gift_quota = entity.gift_quota || 0
    this.gift_quantity = entity.gift_quantity || 0
    this.pv_start = entity.pv_start || 0
    this.pv_end = entity.pv_end || 0
    this.remaining_gift_quota = entity.remaining_gift_quota || 0
    this.probability = entity.probability || '00.00'
  }
}

/**
 * @param {Number} id
 * @param {Number} per_buy_pv 購買點數
 * @param {Number} per_buy_quantity 購買數量
 * @param {Number} per_freebie_quantity 贈送數量
 * @param {Number} total_quota 總配額
 * @param {Number} max_iterative 最大次數
 * @param {Number} is_accumulated 是否可換積累
 * @param {Number} remaining_quota 活總配額
 * @param {Array<ProductsModel>} activity_products 活動商品
 * @param {Array<ProductsModel>} activity_limit_products 參與活動的條件商品
 * @param {Array<FreebiesModel>} freebies 贈送商品
 * @param {Array<FreebiesModel>} freebies_range 每個範圍的商品
 * @param {Array<FreebiesModel>} raffle_list 抽獎商品
 * @param {Number} selected_gift 可選擇的贈品數
*/

export default class ActivitieRuleModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.per_buy_pv = entity.per_buy_pv || 0
    this.per_buy_quantity = entity.per_buy_quantity || 0
    this.per_freebie_quantity = entity.per_freebie_quantity || 1
    this.total_quota = entity.total_quota || 0
    this.max_iterative = entity.max_iterative || 0
    this.is_accumulated = entity.is_accumulated || 0
    this.remaining_quota = entity.remaining_quota || 0
    this.activity_products = entity.activity_products ? entity.activity_products.map(p => new ProductsModel(p)) : []
    this.activity_limit_products = entity.activity_limit_products ? entity.activity_limit_products.map(p => new ProductsModel(p)) : []
    this.freebie = entity.freebie ? new FreebiesModel(entity.freebie) : null
    this.freebies = entity.freebies ? entity.freebies.map(p => new FreebiesModel(p)) : []
    this.freebies_range = entity.freebies_range ? entity.freebies_range.map(p => new FreebiesModel(p)) : []
    this.raffle_list = entity.raffle_list ? entity.raffle_list.map(p => new FreebiesModel(p)) : []
    this.selected_gift = entity.selected_gift || 0
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'activity_products' || key === 'activity_limit_products') {
        this[key] = entity[key] ? entity[key].map(p => new ProductsModel(p)) : []
      } else if (key === 'freebies_range' || key === 'raffle_list' || key === 'freebies') {
        this[key] = entity[key] ? entity[key].map(p => new FreebiesModel(p)) : []
      } else if (key === 'freebie') {
        this[key] = new FreebiesModel(entity.freebies)
      } else {
        this[key] = entity[key]
      }
    })
  }
}
