import { request } from './request.js'

export const getOfficialBulletins = () => request.get('official-bulletins')
export const getOfficialBulletinsById = id => request.get('official-bulletins/' + id)
export const getNews = () => request.get('news')
export const getNewsById = id => request.get('news/' + id)
export const getTravels = () => request.get('travels')
export const getTravelsById = id => request.get('travels/' + id)
export const getCalendars = () => request.get('calendars')
export const getCalendarsNow = () => request.get('calendar/now')
export const getCalendarsById = id => request.get('calendars/' + id)
