import { request } from './request.js'

// 登入
export const getUserLogin = data => request.post('./login', data)
// 第一次登入
export const getUserLoginFirst = data => request.post('./login?_first', data)
// 更新 Token
export const getToken = () => request.get('./refresh-token')
// 忘記密碼
export const getUserForgotPassword = data => request.post('./forgot-password', data)
// export const getUserForgotPassword = data => request.post('../forgot_password', data)
// 重設密碼
export const getUserResetPassword = data => request.put('./reset-password', data)
// 修改密碼
export const updateUserChangePassword = data => request.put('./user/change-password', data)
// export const getUserResetPassword = data => request.put('../reset_password', data)
// OTP驗證
export const getUserOTP = data => request.post('./otp/check', data)
export const getUserResendOTP = data => request.post('./otp/resend', data)
// 取使用者資料
export const getUser = () => request.get('./users')
// 修改使用者資料
export const updateUser = data => request.put('./user', data)
// renewal
export const getRenewalSettings = () => request.get('./renewal_settings')
// terms
export const getTerms = () => request.get('./terms')
