<template lang="pug">
  div
    div(class="row py-3")
      div(class="col-lg-6")
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 業
            span 績
            span 人
          div(class="col py-2 font-weight-bold") {{ UserData.name || UserData.name }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 商
            span 品
            span 名
            span 稱
          div(class="col py-2 font-weight-bold") {{ model.products.name }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 訂
            span 購
            span 字
            span 型
          div(class="col py-2 font-weight-bold") {{ model.fortune_word }}
        div(class="d-flex" v-if="model.fortune_wrist_size")
          div(class="col-auto information-title py-2")
            span 皮
            span 帶
            span 長
            span 度
          div(class="col py-2 font-weight-bold") {{ model.fortune_wrist_size }}
        div(class="d-flex" v-if="model.fortune_ring_size")
          div(class="col-auto information-title py-2")
            span 戒
            span 圍
          div(class="col py-2 font-weight-bold") {{ model.fortune_ring_size }}
        div(class="d-flex" v-if="model.fortune_finger")
          div(class="col-auto information-title py-2")
            span 位
            span 置
          div(class="col py-2 font-weight-bold") {{ model.fortune_finger }}
      div(class="col-lg-6")
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 使
            span 用
            span 者
          div(class="col py-2 font-weight-bold") {{ model.fortune_name }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 性
            span 別
          div(class="col py-2 font-weight-bold") {{ convGenderByCode(model.fortune_gender) }}
        div(class="d-flex")
          div(class="col-auto  py-2")
            span 出
            span 生
            span 日
            span 期
            span (國曆)
          div(class="col py-2 font-weight-bold") {{ dayFormatLuna(model.result.CC, 'CC',model) }}
        div(class="d-flex")
          div(class="col-auto  py-2")
            span 出
            span 生
            span 日
            span 期
            span (農曆)
          div(class="col py-2 font-weight-bold") {{ dayFormatLuna(model.result.LC, 'LC', model) }}
        div(class="d-flex")
          div(class="col-auto py-2")
            span 出
            span 生
            span 日
            span 期
            span (西元)
          div(class="col py-2 font-weight-bold") {{ dayFormatLuna(model.result.AD, 'AD',model) }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 閏月
          div(class="col py-2 font-weight-bold") {{ model.result.leap === '1' ? '是':'否' }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 出
            span 生
            span 時
            span 辰
          div(class="col py-2 font-weight-bold") {{ covChinaTime(model.fortune_hour) }}
    div(class="d-flex flex-wrap")
      div(class="col-12 col-lg-auto information-title py-2")
        div(style="margin-top: 10px;") 八字命盤
      div(class="col-12 col-lg py-2")
        table(class="table text-center")
          thead
            tr
              th(v-for="data in model.result.name") {{ data }}
              th() 十神
          tbody
            tr
              td(v-for="data in model.result.gz") {{ data[0] }}
              th 天干
            tr
              td(v-for="data in model.result.gz") {{ data[1] }}
              th 地支
</template>

<script>
import { mapState } from 'vuex'
import { DataModel } from '../../library/proto'

export default {
  name: 'OrderLuckyView',
  props: {
    model: {
      type: DataModel,
      required: true
    }
  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    })
  },
  methods: {
    convGenderByCode (code) {
      switch (String(code)) {
        case 'M': return '男'
        case 'F': return '女'
        default: return ''
      }
    },
    closeModal () {
      // this.$dialog.show = false
      // this.$dialog.view = 'null'
      // this.$dialog.close()
      this.$dialog.closeDialog()
    }
  }
}
</script>
<style lang="css">
  .close_button:focus {
    outline: none;
  }
</style>
