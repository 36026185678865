import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {Number} is_fortune_use 是否為取貨用
 * @param {Number} is_sales_territory_use 是否為業績地區用
*/
export default class OfficesModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.name = entity.name || ''
    this.sort = entity.sort || 0
    this.code = entity.code || ''
    this.postal_code = entity.postal_code || ''
    this.city = entity.city || ''
    this.district = entity.district || ''
    this.address = entity.address || ''
    this.telephone = entity.telephone || ''
    this.is_sales_territory_use = entity.is_sales_territory_use || ''
    this.is_fortune_use = entity.is_fortune_use || ''
    this.updated_user = entity.updated_user || ''
  }

  toDatabase (addObject) {
    return {
      ...addObject
    }
  }
}
