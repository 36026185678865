import DataModel from '../../library/proto/data-model'

export default class LoveCodes extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.sort = entity.sort || 0
    this.name = entity.name || ''
    this.city = entity.city || ''
    this.love_code = entity.love_code || ''
    this.nickname = entity.nickname || ''
    this.vat_number = entity.vat_number || 0
    this.status = entity.status || 0
    this.updated_user = entity.updated_user || ''
  }

  toDatabase (addObject) {
    return {
      ...addObject
    }
  }
}
