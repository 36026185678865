export default {
  mount: '.icon-qimen-baguazhen',
  attrs: {
    viewBox: '0 0 321 320',
    fill: 'currentColor'
  },
  path: `
  <g title="八角形" fill="#F4F4F4">
    <polygon points="226.5,0 94.5,0 1.1,93.7 1.1,226.3 94.5,320 226.5,320 319.9,226.3 319.9,93.7 "/>
  </g>
  <g title="八卦" transform="translate(6.743295, 4.923077)">
    <g title="坎" fill="currentColor">  
      <g transform="translate(113.102550, 255.108658)">
        <rect x="0.6" y="0" width="38.7" height="8.3"/>
        <rect x="43.7" y="0" width="38.7" height="8.3"/>
        <rect x="0.6" y="22.8" width="38.7" height="8.3"/>
        <rect x="43.7" y="22.8" width="38.7" height="8.3"/>
        <rect x="0.5" y="11.5" width="81.8" height="8.3"/>
      </g>
      <path transform="translate(136.025171, 293.393584)" d="M6.1,6.4c0.5-0.7,1-1.6,1.3-2.6h3.5c-0.2,0.8-0.4,1.5-0.6,2l1.3,0.4c0.4-0.9,0.8-2.4,1.1-3.7l-1.1-0.3l-0.2,0.1H7.9c0.2-0.6,0.3-1.2,0.4-1.8L6.8,0.3c-0.4,2-1,4-2.1,5.2C5.1,5.8,5.8,6.2,6.1,6.4z M1.4,10.3c1.2-0.5,2.7-1.2,4-1.9L5.1,7.1C4.7,7.3,4.2,7.5,3.8,7.6V4.7h1.3V3.3H3.8V0.5H2.4v2.8H1v1.4h1.4v3.5C1.8,8.4,1.3,8.7,0.8,8.8L1.4,10.3z M5.7,12.3c1.8-0.9,2.8-2,3.2-3.2c0.6,1.4,1.6,2.5,3,3.1c0.2-0.4,0.6-1,1-1.3c-2-0.7-3-2.3-3.4-4.5V4.8H8v1.3c0,1.5-0.3,3.6-3.3,5C5,11.4,5.5,11.9,5.7,12.3z M16.2,13.7l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1l-1.2-0.5c-1.2,2-1.9,4.1-1.9,6.6C14.3,9.7,15,11.7,16.2,13.7z M19,12.1c1.5-0.6,3.5-1.4,5.2-2.2l-0.3-1.4l-0.7,0.3V0.5h-1.6v2.8h-2.9v1.5h2.9v4.5c-1.3,0.4-2.5,0.9-3.4,1.1h0L19,12.1z M28.2,11.9c1.4,0,1.8-1,1.9-3.7c-0.4-0.1-1.1-0.4-1.4-0.7c-0.1,2.3-0.2,2.9-0.6,2.9H27c-0.4,0-0.5-0.1-0.5-0.7l0-4.8h3.3V3.3h-3.3V0.5h-1.6v9c0,1.8,0.4,2.3,1.8,2.3H28.2z M32.5,13.7c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6L31.3,1c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L32.5,13.7z"/>
    </g>
    <g title="巽" fill="currentColor" transform="translate(65.143313, 66.233486) rotate(135.000000) translate(-65.143313, -66.233486) translate(24.070516, 39.156563)">
      <rect x="-0.4" y="22.3" width="38.7" height="8.3"/>
      <rect x="42.8" y="22.3" width="38.7" height="8.3"/>
      <rect x="-0.4" y="-0.4" width="81.8" height="8.3"/>
      <rect x="-0.4" y="11" width="81.8" height="8.3"/>
      <path transform="translate(16.454645, 38.208348)" d="M0.9,5.5c0.4-0.2,0.9-0.2,4.5-0.6c0-0.3,0-0.8,0-1.2L2.3,4V3.2h3.2V0.4H0.9v3.1c0,0.5-0.3,0.7-0.5,0.8C0.6,4.6,0.8,5.1,0.9,5.5z M11.5,5c-0.1-0.3-0.1-0.9-0.1-1.2c-0.3,0.1-0.9,0.1-1.2,0.1h-2c-0.4,0-0.5-0.2-0.5-0.6V3.2H11V0.4H6.3v2.8c0,1.3,0.4,1.8,1.9,1.8h2.1C10.7,5.1,11.3,5.1,11.5,5z M4,2.1H2.3V1.5H4V2.1z M9.6,2.1H7.7V1.5h1.9V2.1z M1.3,11.9c1.3-0.3,3.1-0.9,4.2-1.4L4.3,9.5h3.2l-0.8,0.9c1.4,0.4,2.9,1,3.8,1.4l1.2-1.1c-0.9-0.4-2.2-0.8-3.6-1.2h3.6V8.2H8.8V7.4h2.1V6H8.8V5.4H7.3V6H4.7V5.4H3.2V6H1.1v1.3h2.1v0.9H0.4v1.3h3.6c-1,0.4-2.5,0.9-3.7,1.2C0.5,11.1,1,11.6,1.3,11.9z M7.3,8.2H4.7V7.4h2.6V8.2z M15.3,13.4l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1l-1.2-0.5c-1.2,2-1.9,4.1-1.9,6.6C13.4,9.3,14.1,11.4,15.3,13.4z M24.2,11.9V8.8c1.2,1.3,2.7,2.3,4.2,3c0.2-0.4,0.7-1,1.1-1.3c-1.5-0.5-3-1.4-4.1-2.4h2.7V3.2h-3.9V2.5h4.9V1h-4.9V0h-1.6v1h-4.8v1.4h4.8v0.7H19v4.9h2.6c-1.1,1-2.6,1.9-4,2.4c0.3,0.3,0.8,0.9,1,1.3c1.5-0.6,3-1.7,4.1-2.9v3H24.2z M22.7,5.1h-2.2V4.3h2.2V5.1z M26.6,5.1h-2.3V4.3h2.3V5.1z M22.7,6.9h-2.2V6.1h2.2V6.9z M26.6,6.9h-2.3V6.1h2.3V6.9z M32.6,11.9V4.8h4.7c-0.1,0.5-0.3,1.1-0.5,1.6h-1.9l0.7-0.2c-0.1-0.4-0.3-1-0.6-1.4l-1.2,0.3C34,5.5,34.2,6,34.3,6.4h-1v1.2h2.1v0.8h-2.3v1.2h2.3v1.9h1.4V9.6h2.4V8.4h-2.4V7.6H39V6.4h-0.9c0.2-0.4,0.4-0.9,0.5-1.3l-1.3-0.3h2.4v5.5c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-1,0-1.6,0c0.2,0.4,0.4,0.9,0.5,1.3c1,0,1.8,0,2.3-0.2c0.5-0.2,0.7-0.6,0.7-1.3V3.4h-4.7c0.1-0.3,0.1-0.6,0.2-1h4.9V1h-4.7c0-0.3,0.1-0.6,0.1-0.9L35.5,0c0,0.3,0,0.7-0.1,1.1h-4.9v1.4h4.7c0,0.3-0.1,0.7-0.1,1h-4.1v8.4H32.6z M44.3,13.4c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L44.3,13.4z"/>
    </g>
    <g title="艮" fill="currentColor" transform="translate(65.359228, 245.097959) rotate(45.000000) translate(-65.359228, -245.097959) translate(24.286431, 218.021036)">
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 46.2144 6.791)" x="15.5" y="7.1" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535181e-06 1 -1 2.535181e-06 89.3383 -36.333)" x="58.7" y="7.1" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 45.0872 -37.4601)" x="37.1" y="-37.1" width="8.3" height="81.8"/>
      <rect transform="matrix(2.535181e-06 1 -1 2.535181e-06 34.8315 -4.5918)" x="15.5" y="-4.2" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535181e-06 1 -1 2.535181e-06 77.9555 -47.7159)" x="58.7" y="-4.2" width="8.3" height="38.7"/>
      <path transform="translate(17.680699, 38.208348)" d="M1.2,12C1.2,12,1.2,12,1.2,12L1.2,12c0.5-0.3,1.2-0.5,4.4-1.3c-0.1-0.3-0.2-0.9-0.2-1.4L2.7,9.9V6.6H4c1.4,2.6,3.5,4.4,6.6,5.2c0.2-0.4,0.7-1.1,1-1.4C10.3,10.1,9.2,9.7,8.2,9c0.7-0.4,1.5-0.9,2.2-1.4l-0.9-1h0.3v-6H1.1v9.2c0,0.6-0.3,1-0.6,1.1C0.8,11.1,1.1,11.7,1.2,12z M8.2,2.9H2.7V2h5.5V2.9z M8.2,5.1H2.7v-1h5.5V5.1z M7.1,8.2c-0.6-0.5-1-1-1.4-1.7h3.7C8.8,7.1,7.9,7.8,7.1,8.2z M14.8,13.4l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1l-1.2-0.5c-1.2,2-1.9,4.1-1.9,6.6C12.9,9.3,13.6,11.4,14.8,13.4z M23.7,11.9V8.8c1.2,1.3,2.7,2.3,4.2,3c0.2-0.4,0.7-1,1.1-1.3c-1.5-0.5-3-1.4-4.1-2.4h2.7V3.2h-3.9V2.5h4.9V1h-4.9V0h-1.6v1h-4.8v1.4h4.8v0.7h-3.7v4.9H21c-1.1,1-2.6,1.9-4,2.4c0.3,0.3,0.8,0.9,1,1.3c1.5-0.6,3-1.7,4.1-2.9v3H23.7z M22.2,5.1h-2.2V4.3h2.2V5.1z M26.1,5.1h-2.3V4.3h2.3V5.1z M22.2,6.9h-2.2V6.1h2.2V6.9z M26.1,6.9h-2.3V6.1h2.3V6.9z M30.3,11.7c1.5-0.6,3.5-1.4,5.2-2.2l-0.3-1.4l-0.7,0.3V0.2H33V3H30v1.5H33V9c-1.3,0.4-2.5,0.9-3.4,1.1h0L30.3,11.7z M39.5,11.5c1.4,0,1.8-1,1.9-3.7c-0.4-0.1-1.1-0.4-1.4-0.7c-0.1,2.3-0.2,2.9-0.6,2.9h-1.1c-0.4,0-0.5-0.1-0.5-0.7l0-4.8h3.3V3h-3.3V0.2h-1.6v9c0,1.8,0.4,2.3,1.8,2.3H39.5z M43.8,13.4c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L43.8,13.4z"/>
    </g>
    <g title="坤" fill="currentColor" transform="translate(242.982235, 66.070375) rotate(-135.000000) translate(-242.982235, -66.070375) translate(201.909438, 38.993452)">
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 3.1011 34.9082)" x="14.8" y="-3.5" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535181e-06 -1 1 -2.535181e-06 46.2252 78.0322)" x="58" y="-3.5" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 14.4839 23.5253)" x="14.8" y="-14.8" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 57.6081 66.6493)" x="58" y="-14.8" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 -8.2818 46.2911)" x="14.8" y="7.9" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535181e-06 -1 1 -2.535181e-06 34.8423 89.4152)" x="58" y="7.9" width="8.3" height="38.7"/>
      <path transform="translate(16.454645, 38.284926)" d="M8.6,12.6V9.1h1.3v0.5h1.4V2.4H8.6V0.7H7.2v1.7H4.6v7.4h1.4V9.1h1.3v3.5H8.6z M0.6,10.6c1.1-0.5,2.5-1.2,3.8-1.8L4,7.4L2.9,7.9V5.1h1.3V3.6H2.9V0.8H1.5v2.8H0.1v1.4h1.3v3.4C0.9,8.7,0.4,8.9,0,9.1L0.6,10.6z M7.2,5H5.9V3.8h1.3V5z M9.9,5H8.6V3.8h1.3V5z M9.9,7.7H8.6V6.4h1.3V7.7z M7.2,7.7H5.9V6.4h1.3V7.7z M15.3,14.1l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1l-1.2-0.5c-1.2,2-1.9,4.1-1.9,6.6C13.4,10,14.1,12.1,15.3,14.1z M19.9,12.6v-0.7h7.3v0.7h1.5V4.2h-3.2V2.8h3.6V1.4H17.8v1.5h3.6v1.4h-3v8.3H19.9z M24.1,4.2h-1.3V2.8h1.3V4.2z M26.9,7.4h-1c-0.3,0-0.4,0-0.4-0.4l0-1.4h1.6v1.8C27.1,7.4,27.1,7.4,26.9,7.4z M27.2,10.4h-7.3V8.5c0.2,0.2,0.4,0.5,0.5,0.7c1.8-0.8,2.3-2.2,2.3-3.6h1.3V7c0,1.4,0.3,1.9,1.6,1.9h1.5V10.4z M19.9,7.9V5.6h1.5C21.4,6.5,21.1,7.3,19.9,7.9z M32.6,12.6V5.5h4.7C37.2,6,37,6.6,36.8,7.1h-1.9l0.7-0.2c-0.1-0.4-0.3-1-0.6-1.4l-1.2,0.3c0.2,0.4,0.4,0.9,0.5,1.3h-1v1.2h2.1v0.8h-2.3v1.2h2.3v1.9h1.4v-1.9h2.4V9.1h-2.4V8.3H39V7.1h-0.9c0.2-0.4,0.4-0.9,0.5-1.3l-1.3-0.3h2.4V11c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-1,0-1.6,0c0.2,0.4,0.4,0.9,0.5,1.3c1,0,1.8,0,2.3-0.2c0.5-0.2,0.7-0.6,0.7-1.3V4.1h-4.7c0.1-0.3,0.1-0.6,0.2-1h4.9V1.7h-4.7c0-0.3,0.1-0.6,0.1-0.9l-1.7-0.2c0,0.3,0,0.7-0.1,1.1h-4.9v1.4h4.7c0,0.3-0.1,0.7-0.1,1h-4.1v8.4H32.6z M44.3,14.1c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L44.3,14.1z"/>
    </g>
    <g title="兌" fill="currentColor" transform="translate(279.823363, 155.460319) rotate(-90.000000) translate(-279.823363, -155.460319) translate(238.750566, 128.383396)">
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 13.4108 68.4297)" x="36.8" y="-13.4" width="8.3" height="81.8"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 14.6911 24.0253)" x="15.2" y="-14.7" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 57.8152 67.1493)" x="58.3" y="-14.7" width="8.3" height="38.7"/>
      <rect transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 24.7937 57.0468)" x="36.8" y="-24.8" width="8.3" height="81.8"/>
      <path transform="translate(22.922964, 38.284926)" d="M1.5,12.8c3.2-0.9,3.9-2.5,4.2-4.7H7v2.6c0,1.4,0.4,1.8,1.9,1.8h1.6c1.2,0,1.6-0.5,1.8-2.3c-0.4-0.1-1.1-0.3-1.4-0.6c-0.1,1.3-0.1,1.5-0.5,1.5H9.1c-0.5,0-0.5-0.1-0.5-0.5V8.1h2.2V3.3H8.9c0.4-0.6,0.9-1.3,1.3-2L8.6,0.8C8.3,1.6,7.7,2.6,7.2,3.3H4.7L5.3,3C5.1,2.4,4.6,1.5,4.1,0.8L2.7,1.4c0.4,0.6,0.8,1.3,1,1.9H1.9v4.8h2.2c-0.2,1.6-0.7,2.7-3.5,3.4C1,11.8,1.3,12.4,1.5,12.8z M9.2,6.8H3.5V4.6h5.7V6.8z M15.7,14.2l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1L15.7,1c-1.2,2-1.9,4.1-1.9,6.6C13.8,10.2,14.5,12.2,15.7,14.2z M20.3,12.7V12h7.3v0.7h1.5V4.4h-3.2V3h3.6V1.5H18.2V3h3.6v1.4h-3v8.3H20.3z M24.4,4.4h-1.3V3h1.3V4.4z M27.3,7.6h-1c-0.3,0-0.4,0-0.4-0.4l0-1.4h1.6v1.8C27.5,7.6,27.4,7.6,27.3,7.6z M27.5,10.6h-7.3V8.7c0.2,0.2,0.4,0.5,0.5,0.7c1.8-0.8,2.3-2.2,2.3-3.6h1.3v1.4c0,1.4,0.3,1.9,1.6,1.9h1.5V10.6z M20.3,8.1V5.8h1.5C21.7,6.6,21.4,7.4,20.3,8.1z M32,14.2c1.2-2,1.9-4,1.9-6.6C33.9,5.1,33.2,3,32,1l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L32,14.2z"/>
    </g>
    <g title="震" fill="currentColor" transform="translate(27.898264, 155.767001) rotate(90.000000) translate(-27.898264, -155.767001) translate(-13.174533, 128.690078)">
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 34.4081 -4.3082)" x="15.2" y="-4.3" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 67.4296 -14.4108)" x="36.8" y="-14.4" width="8.3" height="81.8"/>
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 77.5321 -47.4322)" x="58.3" y="-4.3" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 23.0252 -15.691)" x="15.2" y="-15.7" width="8.3" height="38.7"/>
      <rect transform="matrix(2.535182e-06 1 -1 2.535182e-06 66.1492 -58.8151)" x="58.3" y="-15.7" width="8.3" height="38.7"/>
      <path transform="translate(22.922964, 38.284926)" d="M2.5,5.3c0.8-0.2,1.7-0.4,2.7-0.6L5.1,3.8L4.8,3.9l0.4-0.8C4.7,2.9,3.8,2.7,3.1,2.6h2.5v2.5h1.5V2.6h2.1C8.6,2.7,7.9,2.9,7.5,3l0.5,0.7c0.7-0.1,1.6-0.2,2.3-0.5L9.7,2.6h0.7v1.4h1.5V1.7H7.1V1.3h4V0.2H1.6v1.1h3.9v0.4H0.8v2.2h1.4V2.6h0.7L2.5,3.3c0.7,0.1,1.6,0.4,2.2,0.5C3.7,4,2.8,4.2,2.1,4.2L2.5,5.3z M10.2,5.3l0.3-0.9c-0.7-0.2-2-0.5-2.9-0.6L7.3,4.6C8.2,4.7,9.5,5,10.2,5.3z M1.4,11.8C2.2,11,2.6,10,2.8,8.9h0.8v0.7c0,0.5-0.3,0.8-0.6,0.9c0.2,0.3,0.5,0.9,0.5,1.2l0,0l0,0l0,0c0.3-0.1,0.9-0.2,3.7-0.7c0-0.2,0.1-0.7,0.1-1c1,0.9,2.3,1.3,4.2,1.5c0.2-0.4,0.5-0.9,0.7-1.1c-0.8,0-1.6-0.1-2.2-0.3c0.4-0.2,0.9-0.4,1.3-0.7l-0.8-0.4h1.5v-1H2.9c0-0.1,0-0.3,0-0.4h8V6.7H3V6.3h8.7v-1H1.6V7c0,1.2-0.1,2.8-1.3,3.9C0.6,11,1.2,11.6,1.4,11.8z M9,9.7C8.6,9.5,8.2,9.2,7.9,8.9h2.2C9.8,9.2,9.4,9.5,9,9.7z M5,10.2V8.9h1.5c0.2,0.4,0.5,0.7,0.8,1L5,10.2z M15.7,13.2l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1L15.7,0c-1.2,2-1.9,4.1-1.9,6.6C13.8,9.2,14.5,11.2,15.7,13.2z M24.6,11.8V8.6c1.2,1.3,2.7,2.3,4.2,3c0.2-0.4,0.7-1,1.1-1.3c-1.5-0.5-3-1.4-4.1-2.4h2.7V3h-3.9V2.3h4.9V0.9h-4.9v-1H23v1h-4.8v1.4H23V3h-3.7v4.9h2.6c-1.1,1-2.6,1.9-4,2.4c0.3,0.3,0.8,0.9,1,1.3c1.5-0.6,3-1.7,4.1-2.9v3H24.6z M23,4.9h-2.2V4.2H23V4.9z M26.9,4.9h-2.3V4.2h2.3V4.9z M23,6.8h-2.2V6H23V6.8z M26.9,6.8h-2.3V6h2.3V6.8z M32,13.2c1.2-2,1.9-4,1.9-6.6C33.9,4.1,33.2,2,32,0l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L32,13.2z"/>
    </g>
    <g title="離" fill="currentColor" transform="translate(153.869747, 28.556054) rotate(180.000000) translate(-153.869747, -28.556054) translate(112.796950, 0.863746)">
      <rect x="-0.5" y="12.5" width="38.7" height="8.3"/>
      <rect x="-0.5" y="0" width="81.8" height="9.4"/>
      <rect x="-0.5" y="24" width="81.8" height="8.3"/>
      <rect x="42.6" y="12.5" width="38.7" height="8.3"/>
      <path transform="translate(22.922964, 39.403242)" d="M6.2,2.5V1.3H3.9C3.8,1,3.6,0.6,3.5,0.3L2.2,0.6c0.1,0.2,0.2,0.5,0.3,0.7H0v1.2H6.2z M3.7,2.8C3.5,3,3.3,3.2,3.1,3.4C2.9,3.2,2.6,3,2.4,2.8L1.8,3.3C2,3.5,2.3,3.7,2.6,3.9C2.3,4.2,1.9,4.5,1.6,4.7c0.2,0.1,0.6,0.4,0.7,0.6c0.3-0.2,0.6-0.5,0.9-0.7C3.5,4.8,3.8,5.1,4,5.3l0.5-0.5v0.6H1.6V2.9H0.5v3.5h2c0,0.2-0.1,0.4-0.1,0.7h-1V6.7H0.3V7H0v1.1h0.3v4h1.2v-4h0.7C2.1,8.8,2,9.3,1.9,9.7L1.5,9.8l0.3,1c0.7-0.2,1.4-0.4,2.2-0.5c0.1,0.2,0.1,0.4,0.2,0.5l0.7-0.3V11c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.6,0-1,0c0.1,0.3,0.3,0.8,0.3,1.1l0.3,0c0.6,0,1.1,0,1.4-0.2c0.4-0.2,0.5-0.5,0.5-1V7H3.6l0.1-0.7h2V5.1c0.3,0.2,0.5,0.5,0.7,0.6l0.1-0.2v6.7h1.3v-0.6h4l0-1.4h-1.5V9h1.3V7.7h-1.3V6.5h1.3V5.2h-1.3V4.1h1.4V2.7H8c0.3-0.7,0.5-1.4,0.7-2L7.3,0.3C6.9,1.7,6.4,3.2,5.7,4.2V2.9H4.5v0.3L3.7,2.8z M9.3,2.7l1.4-0.5C10.5,1.7,10.2,1,9.9,0.5L8.6,0.9C8.9,1.4,9.2,2.2,9.3,2.7z M8.9,5.2H7.8V4.1h1.2V5.2z M8.9,7.7H7.8V6.5h1.2V7.7z M4.9,10C4.7,9.5,4.5,8.9,4.2,8.4L3.4,8.7c0.1,0.2,0.2,0.5,0.3,0.7L3,9.5c0.1-0.4,0.2-0.8,0.3-1.3h1.6V10z M8.9,10.3H7.8V9h1.2V10.3z M4.5,3.1v1.4C4.3,4.4,4.1,4.1,3.8,3.9C4,3.7,4.2,3.5,4.4,3.3L4.5,3.1z M15.2,13.7l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1l-1.2-0.5c-1.2,2-1.9,4.1-1.9,6.6C13.3,9.7,14,11.7,15.2,13.7z M19.7,12.2V5.2h4.7c-0.1,0.5-0.3,1.1-0.5,1.6h-1.9l0.7-0.2c-0.1-0.4-0.3-1-0.6-1.4L21,5.5c0.2,0.4,0.4,0.9,0.5,1.3h-1v1.2h2.1v0.8h-2.3V10h2.3v1.9H24V10h2.4V8.8H24V7.9h2.1V6.7h-0.9c0.2-0.4,0.4-0.9,0.5-1.3l-1.3-0.3h2.4v5.5c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-1,0-1.6,0c0.2,0.4,0.4,0.9,0.5,1.3c1,0,1.8,0,2.3-0.2c0.5-0.2,0.7-0.6,0.7-1.3V3.8h-4.7c0.1-0.3,0.1-0.6,0.2-1h4.9V1.4h-4.7c0-0.3,0.1-0.6,0.1-0.9l-1.7-0.2c0,0.3,0,0.7-0.1,1.1h-4.9v1.4h4.7c0,0.3-0.1,0.7-0.1,1h-4.1v8.4H19.7z M31.5,13.7c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6L30.3,1c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L31.5,13.7z"/>
    </g>
    <g title="乾" fill="currentColor" transform="translate(243.884475, 245.316524) rotate(-45.000000) translate(-243.884475, -245.316524) translate(202.811678, 217.624216)">
      <rect x="0.4" y="0.4" width="81.8" height="9.4"/>
      <rect x="0.4" y="24.3" width="81.8" height="8.3"/>
      <rect x="0.4" y="12.9" width="81.8" height="8.3"/>
      <path transform="translate(16.131178, 39.403242)" d="M4.5,12.4v-1.7h2V9.5h-2V8.6h1.8V4.5C6.7,4.7,7.1,5,7.4,5.3C7.7,4.9,8,4.4,8.3,3.9h4.2V2.4H9C9.2,2,9.4,1.6,9.5,1.1L8.1,0.7C7.7,2,7.1,3.3,6.3,4.2V3.8H4.5v-1h2.1V1.7H4.5v-1H3v1H1v1.2H3v1H1.2v4.8h1.8v0.9H0.9v1.2h2.2v1.7H4.5z M4.9,5.8H2.5V4.9h2.4V5.8z M10.9,12.4c1.2,0,1.7-0.4,1.8-2.6c-0.4-0.1-0.9-0.3-1.3-0.5c0,1.4-0.2,1.6-0.5,1.6L9,11c-0.4,0-0.6-0.1-0.6-0.4l0,0l0,0l0,0c0,0,0,0,0,0l0,0C8.5,10,8.9,9.3,11.8,6c0.1-0.1,0.1-0.2,0.2-0.3l-1-0.4l-0.3,0H7.2v1.4h2.1c-2.2,2.7-2.4,3.4-2.4,4l0,0.1c0,0,0,0,0,0c0,0.9,0.7,1.6,2.1,1.6H10.9z M4.9,7.5H2.5V6.6h2.4V7.5z M16,14.1l1.2-0.5c-1.1-1.9-1.5-4-1.5-6.1c0-2.1,0.5-4.2,1.5-6.1L16,0.9c-1.2,2-1.9,4.1-1.9,6.6C14.1,10,14.8,12.1,16,14.1z M20.6,12.6v-0.7h7.3v0.7h1.5V4.2h-3.2V2.8h3.6V1.4H18.5v1.5h3.6v1.4h-3v8.3H20.6z M24.8,4.2h-1.3V2.8h1.3V4.2z M27.6,7.4h-1c-0.3,0-0.4,0-0.4-0.4l0-1.4h1.6v1.8C27.8,7.4,27.8,7.4,27.6,7.4z M27.9,10.4h-7.3V8.5C20.8,8.8,21,9,21.1,9.2c1.8-0.8,2.3-2.2,2.3-3.6h1.3V7c0,1.4,0.3,1.9,1.6,1.9h1.5V10.4z M20.6,7.9V5.6h1.5C22.1,6.5,21.8,7.3,20.6,7.9z M31.5,12.4c1.5-0.6,3.5-1.4,5.2-2.2l-0.3-1.4l-0.7,0.3V0.9h-1.6v2.8h-2.9v1.5h2.9v4.5c-1.3,0.4-2.5,0.9-3.4,1.1h0L31.5,12.4z M40.8,12.2c1.4,0,1.8-1,1.9-3.7c-0.4-0.1-1.1-0.4-1.4-0.7c-0.1,2.3-0.2,2.9-0.6,2.9h-1.1c-0.4,0-0.5-0.1-0.5-0.7l0-4.8h3.3V3.7H39V0.9h-1.6v9c0,1.8,0.4,2.3,1.8,2.3H40.8z M45,14.1c1.2-2,1.9-4,1.9-6.6c0-2.5-0.7-4.6-1.9-6.6l-1.2,0.5c1.1,1.9,1.5,4,1.5,6.1c0,2.1-0.5,4.3-1.5,6.1L45,14.1z"/>
    </g>
  </g>
  <g title="太極">
    <g title="太極陰" transform="translate(110.957854, 109.538462)">
      <path fill="#fff" d="M47.7,0c6.4,0,12.5,2.5,17,7.1s7.1,10.7,7.1,17.1c0,6.4-2.5,12.6-7.1,17.1c-4.5,4.5-10.6,7.1-17,7.1c-6.4,0-12.5,2.5-17,7.1s-7.1,10.7-7.1,17.1c0,13.4,10.8,24.2,24.1,24.2C21.1,96.6-0.5,75-0.5,48.3S21.1,0,47.7,0z"/>
    </g>
    <g title="太極陽" transform="translate(110.957854, 109.538462)">
      <path fill="currentColor" transform="translate(23.952939, 0.000000)" d="M23.6,96.6c-6.4,0-12.5-2.5-17-7.1C2,85-0.5,78.9-0.5,72.5c0-6.4,2.5-12.6,7.1-17.1s10.6-7.1,17-7.1c13.3,0,24.1-10.8,24.1-24.2C47.7,10.8,36.9,0,23.6,0c26.6,0,48.2,21.6,48.2,48.3S50.2,96.6,23.6,96.6z"/>
    </g>
    <g title="太極陰點">
      <ellipse transform="translate(110.957854, 109.538462)" fill="currentColor" cx="50.2" cy="25.3" rx="5.7" ry="5.7"/>
    </g>
    <g title="太極陽點">
      <ellipse transform="translate(110.957854, 104.538462)" fill="#fff" cx="50.2" cy="78.2" rx="5.7" ry="5.7"/>
    </g>
  </g>
  `,
  textPath: [
    {
      attrs: {
        transform: 'translate(196, 113)'
      },
      text: '坤卦'
    },
    {
      attrs: {
        transform: 'translate(218, 167)'
      },
      text: '兌卦'
    },
    {
      attrs: {
        transform: 'translate(196, 221)'
      },
      text: '乾卦'
    },
    {
      attrs: {
        transform: 'translate(145, 241)'
      },
      text: '坎卦'
    },
    {
      attrs: {
        transform: 'translate(94, 221)'
      },
      text: '艮卦'
    },
    {
      attrs: {
        transform: 'translate(72, 167)'
      },
      text: '震卦'
    },
    {
      attrs: {
        transform: 'translate(94, 113)'
      },
      text: '巽卦'
    },
    {
      attrs: {
        transform: 'translate(145, 94)'
      },
      text: '離卦'
    }
  ],
  taichiPath: [
    {
      attrs: {
        transform: 'translate(188, 164)',
        'text-anchor': 'middle',
        fill: '#fff',
        style: 'font-size:12px;'
      },
      text: '太陰'
    },
    {
      attrs: {
        transform: 'translate(127, 164)',
        'text-anchor': 'middle',
        fill: 'currentColor',
        style: 'font-size:12px;'
      },
      text: '太陽'
    }
  ]
}
