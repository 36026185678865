<template lang="pug">
  div(class="lighbox position-relative p-4")
    div(class="rounded-lg" style="background: #ffffff;")
      div(class="px-3 py-4")
        <button class="close_button close" type="button" @click="cancel">
          <span>&times;</span>
        </button>
        OrderLuckyView(:model="attrs.model")
        div(class="p-3 py-3")
          div(class="border border-danger text-danger font-weight-bold text-center py-3 px-4") 單獨飾品訂單 (品號01001-01005) 才可刷卡分12期，網購僅受理「台新信用卡」分期，其它銀行卡皆不受理。 <br />飾品需2~3週製作期，如訂單中有其他商品則會先行寄出。
        div(class="d-flex justify-content-center")
          div(class="text-center")
            template(v-if="unLucky")
              button(class="btn btn-outline-success m-1" type="button" @click="clear") 我要分12期 <br> （購物車的一般商品將會被清除）
              button(class="btn btn-outline-success m-1" type="button" @click="close") 不要分12期 <br> （可繼續選購其他商品合併結帳）
            button(v-else class="btn btn-success m-1" type="button" @click="close") 確定
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OrderLuckyView from '../../container/order/OrderLuckyView.vue'
import { ShopCartModel } from '@/models'
import { createShopcart } from '@/api'

export default {
  name: 'LuckyProductCheck',
  data () {
    return {
      model: {},
      installment: 0
    }
  },
  props: {
    attrs: Object
  },
  created () {
    const model = this.attrs.model
    console.log('lucky model', model)
    this.model = new ShopCartModel({
      ...model
    })

    this.installment = this.attrs.installment
  },
  computed: {
    ...mapState({
      ShopCarts: state => state.model.ShopCarts
    }),
    unLucky () {
      return this.ShopCarts.data.every(p => !p.products.is_lucky)
    }
  },
  methods: {
    ...mapActions(['removeShopcartByProdId']),
    cancel () {
      this.$dialog.closeDialog()
    },
    async close () {
      console.log('LuckyProductCheck ')
      console.log(JSON.stringify(this.model))
      const res = await createShopcart(
        this.model.toDatabase({
          product_quantity: 1,
          is_installment: 0
        })
      ).catch(() => {
        this.model.loaded = true
      })

      this.installment = 0
      this.model.set(res.data)
      this.ShopCarts.data.push(new ShopCartModel(this.model))
      this.$dialog.close(1)
    },
    async clear () {
      // console.log('clear 0 installment', this.model.installment)
      await Promise.all(
        this.ShopCarts.data.filter(p => !p.is_lucky).map(p => this.removeShopcartByProdId(p.product_id))
      )

      const res = await createShopcart(
        this.model.toDatabase({
          product_quantity: 1,
          is_installment: 1
        })
      ).catch(() => {
        this.model.loaded = true
      })

      this.installment = 1
      this.model.set(res.data)
      this.ShopCarts.data.push(new ShopCartModel(this.model))
      console.log('clear this.model', this.model)
      // console.log('this.installment', this.installment)
      this.$dialog.close(1)
    }
  },
  components: {
    OrderLuckyView
  }
}
</script>
