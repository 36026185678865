export default {
  mount: '.icon-totop',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'currentColor'
  },
  path: `
    <path d="M19,9 L15,9 L15,3 L9,3 L9,9 L5,9 L12,16 L19,9 Z M5,18 L5,20 L19,20 L19,18 L5,18 Z" fill="#424242"/>
  `
}
