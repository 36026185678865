import DataModel from '../../library/proto/data-model'
import ProductsModel from './products'
import NatalchartDataModel from './natalchart-data'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {Number} product_id 產品id
 * @param {Number} product_quantity 購買數量
 * @param {Boolean} is_lucky 使否為開運商品 (非資料庫欄位)
 * @param {String} fortune_name 開運商品填寫的名字
 * @param {String} fortune_gender 開運商品填寫的性別 M & F
 * @param {String} fortune_year 開運商品填寫的年
 * @param {String} fortune_month 開運商品填寫的月
 * @param {String} fortune_day 開運商品填寫的日
 * @param {String} fortune_hour 開運商品填寫的時
 * @param {String} fortune_minute 開運商品填寫的分
 * @param {Number} fortune_is_leap 開運商品填寫的是否為農曆
 * @param {String} fortune_china_hour 開運商品填寫的出生時辰
 * @param {String} fortune_wrist_size 開運腕飾填寫的尺寸
 * @param {String} fortune_ring_size 開運戒指填寫的戒圍
 * @param {String} fortune_finger 開運戒指填寫的哪一支手指
 * @param {String} fortune_word 開運商品填寫的字型，通常由此項在完成訂單處判斷是否為開運商品
 * @param {ProductsModel} products 該Model的辨識索引 (非資料庫欄位)
 * @param {NatalchartDataModel} result 該Model的辨識索引 (非資料庫欄位)
*/

export default class ShopCartModel extends DataModel {
  constructor (args) {
    // console.log('進入ShopCartModel')
    // console.log('args:', args)
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.product_id = entity.product_id || 0
    this.product_quantity = entity.product_quantity || 1
    this.is_lucky = entity.is_lucky || false
    this.fortune_name = entity.fortune_name || ''
    this.fortune_gender = entity.fortune_gender || ''
    this.fortune_year = entity.fortune_year || ''
    this.fortune_month = entity.fortune_month || ''
    this.fortune_day = entity.fortune_day || ''
    this.fortune_hour = entity.fortune_hour || '0'
    this.fortune_minute = entity.fortune_minute || ''
    this.fortune_is_leap = entity.fortune_is_leap || ''
    this.fortune_china_hour = entity.fortune_china_hour || ''
    this.fortune_wrist_size = entity.fortune_wrist_size || ''
    this.fortune_ring_size = entity.fortune_ring_size || ''
    this.fortune_finger = entity.fortune_finger || ''
    this.fortune_word = entity.fortune_word || ''
    this.products = new ProductsModel(entity.products)
    this.result = new NatalchartDataModel(entity.result)
    this.is_installment = entity.is_installment || 0
    // 特定頁面使用
    this.error_message = ''
    // console.log('ShopCartModel entity:', entity)
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'products') {
        this[key] = new ProductsModel(entity.products)
      } else if (key === 'result') {
        this[key] = new NatalchartDataModel(entity.result)
      } else {
        this[key] = entity[key]
      }
    })
  }

  toDatabase (addObject) {
    return {
      product_id: this.product_id,
      product_quantity: this.product_quantity,
      is_lucky: this.is_lucky,
      fortune_name: this.fortune_name,
      fortune_gender: this.fortune_gender,
      fortune_year: this.fortune_year,
      fortune_month: this.fortune_month,
      fortune_day: this.fortune_day,
      fortune_hour: this.fortune_hour,
      fortune_minute: this.fortune_minute,
      fortune_china_hour: this.fortune_china_hour,
      fortune_is_leap: this.fortune_is_leap,
      fortune_ring_size: this.fortune_ring_size,
      fortune_wrist_size: this.fortune_wrist_size,
      fortune_word: this.fortune_word,
      fortune_finger: this.fortune_finger,
      is_installment: this.is_installment,
      ...addObject
    }
  }

  rules () {
    /**
     * case '01205' 開運戒指
     * case '01206' 開運墜子
     * case '01207' 開運領帶夾
     * case '01208' 開運胸針
     * case '01209' 開運皮帶
     * case '01209' 開運腕飾
     */
    if (this.is_lucky) {
      return {
        fortune_ring_size: () => {
          if (this.products.product_number === '01205' || this.products.product_number === '01001') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇戒圍'
              }
            }
          }
        },
        fortune_finger: () => {
          if (this.products.product_number === '01205' || this.products.product_number === '01001') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇配戴位置'
              }
            }
          }
        },
        fortune_wrist_size: () => {
          if (this.products.product_number === '01209' || this.products.product_number === '01005') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇皮帶長度'
              }
            }
          }
        },
        fortune_word: {
          presence: {
            allowEmpty: false,
            message: '^請選擇字形'
          }
        }
      }
    }
    return {}
  }
}
