export default {
  mount: '.icon-menu',
  attrs: {
    viewBox: '0 0 40 40',
    fill: 'currentColor'
  },
  path: `
    <path d="M6.66666667,13.3333333 L13.3333333,13.3333333 L13.3333333,6.66666667 L6.66666667,6.66666667 L6.66666667,13.3333333 Z M16.6666667,33.3333333 L23.3333333,33.3333333 L23.3333333,26.6666667 L16.6666667,26.6666667 L16.6666667,33.3333333 Z M6.66666667,33.3333333 L13.3333333,33.3333333 L13.3333333,26.6666667 L6.66666667,26.6666667 L6.66666667,33.3333333 Z M6.66666667,23.3333333 L13.3333333,23.3333333 L13.3333333,16.6666667 L6.66666667,16.6666667 L6.66666667,23.3333333 Z M16.6666667,23.3333333 L23.3333333,23.3333333 L23.3333333,16.6666667 L16.6666667,16.6666667 L16.6666667,23.3333333 Z M26.6666667,6.66666667 L26.6666667,13.3333333 L33.3333333,13.3333333 L33.3333333,6.66666667 L26.6666667,6.66666667 Z M16.6666667,13.3333333 L23.3333333,13.3333333 L23.3333333,6.66666667 L16.6666667,6.66666667 L16.6666667,13.3333333 Z M26.6666667,23.3333333 L33.3333333,23.3333333 L33.3333333,16.6666667 L26.6666667,16.6666667 L26.6666667,23.3333333 Z M26.6666667,33.3333333 L33.3333333,33.3333333 L33.3333333,26.6666667 L26.6666667,26.6666667 L26.6666667,33.3333333 Z" fill="#424242"/>
  `
}
