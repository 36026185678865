<template lang="pug">
  div(:style="{backgroundImage: `url(${urlBodyBg})`}")
    div(class="container service bg-white" style="min-height: calc(100vh - 190px);")
      router-view
</template>

<script>
import urlBodyBg from '@/assets/bg.png'

export default {
  name: 'Service',
  data () {
    return {
      urlBodyBg
    }
  }
}
</script>
