export default {
  mount: '.icon-taiwan',
  attrs: {
    viewBox: '0 0 850 1200',
    fill: 'currentColor'
  },
  path: `
  <g title="括號">
    <polygon title="金門" fill="#0000006a" points="169.3,389.7 42.3,389.7 42.3,386.7 166.3,386.7 166.3,262.7 169.3,262.7"/>
    <polygon title="馬祖" fill="#0000006a" class="st1" points="270.7,233.2 42.3,233.2 42.3,230.2 267.7,230.2 267.7,37 270.7,37"/>
  </g>
  <g title="馬祖" id="mazu">
    <polygon points="60.3,200.8 64.7,201.2 66.6,198 60.7,198"/>
    <polygon points="75.8,200 71.8,209.6 73.4,209.2 81,204 77,202.4"/>
    <polygon points="70.2,133.7 67,136.4 62.7,137.2 60.3,134 55.9,130.9 55.5,135.2 56.3,140 55.5,142.8 61.9,143.6
      66.6,142.4 73,137.2"/>
    <polygon points="73.8,115.4 73.8,115.4 74.2,124.9 75,120.5 78.2,118.5 82.9,118.1 86.5,115.8 89.3,118.9 90.1,117
      86.5,111"/>
    <polygon points="81.4,98.7 80.2,94.7 77.8,99.5 79.5,100.2"/>
    <polygon points="86.1,105.4 83.3,108.2 87.3,107.4"/>
    <polygon points="258.4,64.4 257,63.9 255.1,62.1 254,61.9 253.3,63.6 252.3,64.8 251.1,64.7 251.1,64.7 251,66.2
      250.2,67.1 249.8,68.3 251,69.1 255.1,67.8 256.5,65.6 257.7,65.9 259,65.5"/>
    <polygon points="249.7,62.2 247.9,60.3 245.5,62.4 245.7,63.1 249.9,63.4"/>
  </g>
  <g title="金門縣" id="kinmen">
    <polygon points="86.5,326.6 85.6,326.1 84.3,325.9 83.4,325.9 82.6,326.5 81.9,327.2 79.4,326.9 78.5,327.3
      78.1,328.3 78.1,331.1 77.6,332 77.5,333.3 76.7,333.9 75.3,333.9 74.4,334.4 74.7,335.8 75.3,336.7 74.8,337.7 74,338.5
      73,338.9 72.6,338.9 73.3,340 75.9,340.2 76.3,341.4 77.6,341.3 79.6,338.6 80.7,338.4 81.9,338.6 82.5,339.5 83.5,339 84,338.1
      83.9,336.8 84.5,335.9 87.2,335.8 88.4,335.4 89,334.7 90.5,330.4 90.3,329.3 87.3,327.4"/>
    <polygon points="58,346.4 57.4,344.3 55.7,344.4 55.7,344.4 56.2,345.5 56.8,346.4 56.5,347.2 57.8,347.3 58.8,347.7
      59.6,347.2 59.4,346.5"/>
    <polygon points="56.7,349 54.3,348.2 53.7,349 56,349.6"/>
    <polygon points="156.4,326.9 155.5,326.5 154.1,326.5 153.8,325.8 153.1,325 152.3,321.6 152.3,319.4 153.8,318.5
      154.2,317.4 153.5,316.7 151.5,317.4 150.6,317.1 150.1,316.2 149.6,313.9 149.8,312.7 149.1,312.2 148.2,311.7 148,308.3
      142.4,308 142.4,308 142,306.7 141.4,305.8 141.4,304.4 140.8,303.6 141.3,303 140.1,303 140.2,302.2 139.3,302.1 138.5,302.3
      138.2,301.7 136.6,303.4 136.2,302.5 137.1,301.9 136.3,301.4 135.3,301.9 134.8,302.8 135.8,303.6 136.5,304.6 136.2,305.7
      135,305.9 133.3,307.1 132.1,307.3 130.9,307.2 130.5,307.5 130.2,308.4 130.6,309.1 130.7,310.4 132,309 133,309.5 134.4,309.5
      134.3,310.2 132.9,310.2 133.9,311 134.3,312 133.5,312.5 133.5,313.9 132.6,313.6 131.4,312 130.6,311.3 130.5,312.2 131,313.2
      131,313.4 130.1,314.4 129.2,315 128.6,315.8 127.7,316.3 126.3,316.3 127.6,317.5 127.2,317.9 127.8,320.3 127.5,321.1
      127,321.1 125.2,322.4 124.7,323.4 116.6,322.3 105.7,314.4 104.5,314.2 104.2,313.9 103.5,314.6 102.2,314.8 100.1,316.9
      98.9,317.1 96.9,320.9 99.1,322.2 100.3,322.4 100.1,321.5 99.4,320.7 100.3,320.2 103.1,320.3 103.5,319.2 104.5,318.7
      105.2,319.5 105.1,321.3 104.4,322 102,322.4 101.5,323.6 101.5,325.5 101.9,325.8 102.6,326.9 103.3,329.3 103.3,332.1
      103.5,332.8 105.1,333 105.7,333.8 105.6,335 104.6,335.4 104,333.7 103.5,334.6 101.3,334.9 101.2,337.2 100.4,337.8 96.4,337.9
      95.8,337.4 95.9,336.1 95.1,337.8 94,337.6 93.7,338.5 94.7,339.3 95.6,339.6 97.3,341.8 98.8,343 99.3,344 102,344.2 103,344.7
      103.5,345.8 104.8,346.3 106.3,346.4 108.3,347.7 108.8,346.9 110.3,346.6 110.7,345.6 111.4,345 112,342.6 115,338.3
      115.8,337.6 116.4,336.7 117.2,336 117.4,335.6 118.6,335.4 119.4,334.9 120.6,334.6 121.4,333.9 122.3,333.5 123.6,333.4
      125.6,332.6 133.5,332 135,332.6 136.6,333.9 137.5,334.4 138.9,334.4 141.2,335.3 143.6,337.7 143.5,338.8 142.9,339.6
      142,340.2 141.5,340.4 142.6,340.8 144,340.8 145,340.5 145.8,339.8 147,339.5 147.7,338.6 149.9,338.1 150.3,337.1 149.9,336.2
      150.1,335.2 151,334.9 152.3,335.1 153,334.4 153.3,333.6 152,331.9 151.8,330.9 153.6,329.8 154.6,329.7 155.2,330.3
      156.2,329.4 155.8,328.6 155.9,327.7"/>
    <polygon points="145.4,303.9 144.7,303.1 144.7,303.1 143.6,303.4 144.1,303.9"/>
    <polygon points="150.6,338.7 151.9,338.6 151.1,338.2"/>
  </g>
  <g title="澎湖縣" id="penghu">
    <path d="M115.9,584.2l0.7-2.5l2-0.7l-0.9,1.4l-0.5,2.3L115.9,584.2
      C115.9,584.2,115.9,584.2,115.9,584.2z"/>
    <path d="M124.7,577.3l2.3-0.4l-0.5,1.8L124.7,577.3C124.7,577.3,124.7,577.3,124.7,577.3z"/>
    <path d="M82.2,624.9l-0.2,2.5l2.1-0.9L82.2,624.9L82.2,624.9z"/>
    <path d="M85.9,631.2l-4.3,2.3l-1.6-0.7l0.4,2.3l5.4-0.4l4.1-2.5l-0.4-1.2l-2.5-0.4L85.9,631.2
      L85.9,631.2z"/>
    <path d="M79,585.8l-0.6,1.2l-0.2,0.8l0.8,0.9l-1.5,1.4l0.4,1.1l1.1,0.5l0.8,0.7l0.4,0.8l-0.9,0.4
      l-0.7,2.1l0.4,1.2l-1.2,0.4h-1.3l-1.1,0.7l0.4,1l1.4,0.3l1.8,2.3v1.3l-0.5,1.1l-1,0.7l-0.7,0.9l-1,0.7l-1,0.3l-1.8-1.6l-1.2,0.4
      l-0.9,0.7l-0.6,1l-1.5,1.3l-0.6,0.9l0.9,0.5h1.3l0.6-0.8h4l0.7-0.6l0.9-0.4l2.6,0.4l1.2,0.5h1.3l1.2-0.5l-0.4-1.2l-0.7-0.9l-0.4-1
      l-0.1-1.2l1.5-1.4l-1.2-2.9l3.7-3.3l-0.4-0.9l-1-0.7l-0.2-1.2l0.6-2.1l-0.5-1.2l-0.6-0.8l-0.3-1.1l0.4-1l1-0.6h2.7l2.3-0.6l1-0.6
      l0.2-1.3l-0.7-0.6l-1.1,1l-1.1,0.3l-1.2-0.6l-2.1-2.4l0.3-1.2l-0.4-1l-1.1,0.1l-0.8,0.7l-2.2,4.1L79,585.8L79,585.8L79,585.8z"/>
    <path d="M93.8,575.4l-1,1l0.1,1.2l3,2.1l0.6,1.1l0.6-0.6l0.9-2.1l0.9-0.6l2.8,0.1l0.5,1.2v1.3l0.7,0.8
      l0.4,1l2.2,2.3l0.4,1.2l0.9,0.2l0.8-1l0.2-1.3l2.2-1.2l1.3-0.2l-0.1-1.2l-1.2-1.2l-0.5-0.8l0.8-1.4l-1.4-0.1l-1.7-3.1l-0.7-0.2
      l-0.8,0.5l-2.1-0.6l-0.3-1.1l-1.3-0.2l-1.2,0.5l-0.5,1.1l-1.3,0.3l-2.3-0.3l-1.1-0.5l-0.3-0.6L93.8,575.4L93.8,575.4L93.8,575.4z"/>
    <path id="path2478" d="M106,586.8l-1.4,1.9l1.8,1.7h1.3l1-0.6l0.1-1.2l1.7-2.1l-0.8-0.5h-1.3l-1.2,0.3L106,586.8
      L106,586.8z"/>
    <path d="M109.3,590.8l-1.2,0.5l-0.4,1l0.4,1.1l1.1,0.5h1.3l1.2-0.3l0.4-1.2l-0.6-0.9l-1.2-0.6
      L109.3,590.8C109.3,590.8,109.3,590.8,109.3,590.8z"/>
    <path id="path2482" d="M102.3,599.6l-2.9,2.5l-1.2,0.4h-1.3l0.6,1.2v1.3l-0.3,1.2l-1.8,1.5l-0.2,1.3l1,0.5l2.4-1.1
      l1-0.7l1.4,0.1l0.9,0.6l0.8,0.9l-2.7,2.9l-1.3,0.2L96,612l1.5,1.7l2.1,0.9l1.6-0.2l1.2-0.7l4,0.1l0.8,1.1l0.4,1.2l-1.3,2.1
      l-0.2,0.7l1.2,0.8l-0.1,1l-1.1,1.1l-1.2-0.1l-0.8-0.7l-1.1-0.5l-1.3,0.2l-1.6,3.4l-1.5,1.6l0.6,1.1l2.6,0.3l1.4-0.2l1.8-0.6
      l1.5-0.1l1.6,1.8l1.2-0.3l0.2-1.2l1.1-1l0.1-1.2l0.4-1.2l-1.1-0.6l-0.2-1l0.9-1l0.6-1.2l4.5-3.5l1.2-0.2l2.2-2.8l0.4-1.1l1-0.8
      l6.2-0.2l1.9-0.9l0.8,0.6l0.3,1.3l0.6,1.2l1.1,0.7l1.2-0.3l1-0.5h1.4l0.5-0.8l-3.1-3.3l-0.8-2.9l0.4-1.1v-1.3l-0.5-1.2l-2.4-2.6
      l-0.6-2.1l-0.7,0.8l-0.4,2.5l-1.2-0.2l-0.6-1.2l-0.8-0.9l0.4-0.8l0.7-0.5l-1.7-1.6l-1.2-0.4l-0.8,0.8l-1.7,3.1l-1-0.2l-0.9,0.3
      l-1.5,1.5l-0.3,1.1l-0.7,0.2l-0.1-4l1-2.1l-1.5,0.2l-0.8,0.5l-5.2,0.9l-0.8,0.6l0.1,1.2l0.7,1v1.3l-0.5,0.9l-1.2,0.4l-0.8,1v2.8
      l-0.7,0.8l-1.3-0.6l-1-0.9l0.2-1.8l-1.4-0.2l-0.2-1.2l0.7-1.2l0.1-0.8L102.3,599.6L102.3,599.6L102.3,599.6z"/>
    <path d="M92,612.4l-0.5,1.3l-1,0.4l-0.4,1.2l0.1,2.6l1.5,0.7l0.4,0.7l0.9,0.8l0.4-0.9l-0.7-0.9l1.2-1.1
      l-0.7-0.8l-0.3-2.9l-0.9-0.7"/>
    <path d="M94.3,620.5v1.4l4.4,0.1l0.5-0.8l-0.7-0.9l-1.1-0.8l-0.5-0.8l-0.3,1L94.3,620.5L94.3,620.5
      L94.3,620.5z"/>
    <path d="M109.4,549l-1.9,3.2l0.4,1.6l1.3,0.5l1.1-1.8l1.3-1l1.6,0.3l1.3-0.6l1.1-1.1l-4.2-2.9l-1,0.1
      L109.4,549L109.4,549L109.4,549z"/>
    <path d="M22.3,658.5l-1.4,3.2l1.4,0.9l1.6-0.4l1.4-0.9V660l-2.3-1.8L22.3,658.5
      C22.3,658.5,22.3,658.5,22.3,658.5z"/>
    <path d="M73.8,663.5l0.9,1.9v8.7l-0.4,1.6l-1.5,0.6l0.9,1.3l5.6,0.1l0.4-0.9l-0.6-1.4l0.6-1.3l1.6-0.5
      l1.3,1.4l1.3,0.9l1.5-0.5l0.9-1h1.9l1.1-0.8l-0.5-1.6l-0.8-1.3l-1.6,0.6l-2,2.3l-4.5-2.7l-0.5-1.4v-3.8l-1,0.3l-1.9-0.1
      L73.8,663.5L73.8,663.5L73.8,663.5z"/>
    <path d="M83.8,703.9l0.3,1.9l1.6,0.1l-1-1.4L83.8,703.9C83.8,703.9,83.8,703.9,83.8,703.9z"/>
    <path d="M87.3,706.7l-0.5,2l-1,1.4l1.6-0.5l1.8-2.3L87.3,706.7L87.3,706.7z"/>
    <path d="M126.9,708.2l-0.9,1.9l0.8,1.1v1.4l1.1,1.1l0.8-0.1v-1.9l1.3-1.4l0.6-1.3l-1-0.9l-1.9-0.1
      L126.9,708.2L126.9,708.2L126.9,708.2z"/>
    <path d="M55.3,721l-3.2,1.1l-0.4,1.5l0.4,1.8l1.1,0.9v1.9l1.8,2.4l0.6,0.3l0.1-1.8l1.5-1l1.5-0.4
      l0.4-3.7l1.3-1.1l-2.5-2L55.3,721L55.3,721L55.3,721z"/>
  </g>
  <g title="臺北市" id="taipei">
    <path d="M671.6,94.5l-1.2,2.7l-1.2,1.2l-0.4,0.7l-1.2,1.4v2.7l-1.8,1.1l-0.4,2.3l0.7,2.3l1.8,1.2
      l5.7,0.7l3.6,3.4l1.4,2.7l0.2,8.2l-2.7,3.7l-1.8,1.6l-0.9,2l0.5,5.2l1.1,2.1l2.1-0.2l2-1.1l1.2-1.8l1.2-0.2l2.9,1.2l1.6,1.2l0.7,2
      v2.7l1.2,2.1l2.3,1.6l2.5,0.2l1.1,4.3l3.8,2.5l10,1.1l2.1-0.9h2.9l0.2-1.8l-4.8-2.3l-0.4-2.5l0.2-2.1l-1.2-4.1v-2.3l1.2-2l1.8-1.1
      l5,0.4l1.6-1.1l2.1-0.5l6.8,0.4l0.5-1.4l-1.6-0.7l-2.3,0.7l-3.9-1.8l-4.8-3.6l-0.9-2l1.2-1.6l-0.4-2l-1.4-1.4l1.1-1.2l2.3-4.8
      l-0.5-4.6l-7.7-6.6l0.5-2.3l1.4-1.6l-0.5-1.8l-2.9-2.5l-1.1-1.6l-0.2-5.2l-2.9-2.9V84l0.7-2v-2.7l-4.6,0.4l-2-3.6l-1.4-1.6
      l-2.5,3.9l-2,0.9l-0.9,2l-3.8,2.1l-0.9,2.3l-1.6,1.1l-2.5,0.2l-2,0.9l-3,3.6l-0.9,2.3L671.6,94.5L671.6,94.5L671.6,94.5z"/>
  </g>
  <g title="基隆市" id="keelung">
    <path d="M768.4,99.7l0.1-1.2l-1.1-1.1l0.5-3.4l-1.8,0.1l-2.4,1.5l-2.2-2l-1.9-0.2l-0.9-0.6v-2.1l-1.6-0.4
      l-0.8,1.4l-1.5,0.4l-0.4-1.5h-1.3l0.5,1.5l1.4,2l-1.8,2.2l-2.8,2l-1.2,0.1l2.3-2.2l-0.8-1.4v-3.2l-1.5-0.1l-1.4-0.8l-1.8-0.2l-2-2
      h-3.9l-1.1-0.8l0.3-1.4l-0.4-0.9l-1.8,1.2l-2,0.7l-1.1,1.6l-2.3,0.5l-1.2,1.6l-4.1,1.8l-2.5,0.2l-2,0.7l-1.8,1.2l-4.8,0.5v2.7
      l1.1,2l2.3,0.7l1.4,1.6l0.2,2.5l3,6.8l2.3,1.6l1.6,2.1l2.3,0.5l1.4-1.2v2l1.6,1.8l7,1.8l5.4,4.6l5.2,0.4l4.6-2.3l2.5-0.2l1.1-1.8
      l0.7-2.1l-4.6-4.5v-4.8l-0.5-2.1l2.3-0.5l2.9-3l2.5,0.5l1.8,1.2l0.9,0.2l1.8-2.9l1.4-1.4L768.4,99.7L768.4,99.7L768.4,99.7z"/>
  </g>
  <g title="新北市" id="new-taipei">
    <path d="M817.3,147.5l-3.7-0.7l-2.5,0.2l-6.2,2.3l-5.4,0.4l-6.8,4.8l-1.4,1.6h-2.9l-2.3-0.9l-5.2-0.4
      l-1.8,1.4l-0.5,2.1l-0.9,1.8l4.1,5.9l-1.4,4.3l-5.9,3.9l-5.4-0.4l-2.3,0.4l-3,2.5l-2.5,3.2l0.5,2.3l-0.2,2.1l-1.4,1.8l-0.2,2.1
      l0.2,1.2l-2.5,0.5l-1.8,0.9l-7.5-1.1l-2.3,0.4l-2,1.1l-2.7,3.2l-4.8,2.6l-0.5,1.7l-1.8,1.6l-1.1,1.8l-2.1,0.7l-0.7,2l-1.6-1.8
      l-3.6,1.8l-10.5,1.2l1.4,1.2l-0.5,2.1l-2.3,1.8l-2.5-0.7l-1.8,1.1l-2.1,0.5l-0.9,2l0.2,2.5l-0.4,2.3l-2,1.6l0.4,2.5l1.4,1.4
      l0.7,2.1v2.7l-1.4,4.3l-2.3,0.4l-1.6,1.2l-1.1,1.6l-2.1,0.7l-2.7,0.2l-5.9,3.2l-0.5,2.5l-5.2,3.4l-4.6,0.7l-2.3,1.4l-4.8-0.3
      l-1.5-0.6l-0.5-5l-2-1.4l-1.4-2l-2.5-0.7l-0.2-1.8l-2.5-1.1l0.5-2l-3.2-3l-2-5v-1.1l-0.9-1.8l-0.4-2.3l1.1-2l3.2-2.7l1.1-1.6
      l1.6-1.1v-2.7l-2.3-0.7l-0.9-2.5l-0.2-2.5l-1.6-1.8l-1.6-0.9l-2.9-2.9l-0.7-2l-1.4-1.4l-0.4-0.9l-1.2-0.7l-5.4,0.4l-2,1.1l-2.7,0.2
      l-2.9-0.7l-1.8-1.1l-1.6-1.6l-1.6,1.2l-2.5-0.4l0.2-2.1l4.6-0.9l1.6-1.2l-2.5-1.6l-0.2-0.2l-0.4-2.1l1.2-1.6l-3.4-5.2l-4.5-2.7
      l-0.9-4.3l0.9-2l1.2-1.4l2.1-1.4l-1.6-1.2l-2.1-0.2l-1.2-2.3l0.9-2l1.4-1.4l0.5-5l-1.1-2l0.5-2.1l1.8-0.9l11.2,0.4l2.9-2l2-3.6
      l2.1-0.7l1.6-1.1l-0.2-2.1l-2.1-1.6l2-0.7l2-1.2l0.9-1.6l-2.3-1.6l-1.2-1.4l-0.4-2.3l0.9-1.8v-0.7l-0.5-2.3l-1.8-2.1l-1.2-0.9h-5.4
      l-5.2-1.2l-2-1.8l0.5-3.6l-9.3-4.5l-5-0.5l-1.2-1.6l-1.2-0.4l-1.8-2.5l1-1.4l2.4-2.3l12-0.1l1.3-0.9l5.2-1.6l4.2-2.8l1.5-0.4
      l2.8-1.7l1.6-0.5l3.7-3.5l0.6-2.4l1.1-1h1.9l5.8,3.8l2.2,2.3l0.7,1.8l0.1,1.8l2,2.7l0.2,1.6l0.8,1.7l0.2,1.6l0.9-0.8l-0.2-7.6
      l-2.2-4.4l-1-1.2l-1.4-1l-1.5-2.6l-3.5-1.7l-1.9-0.1l-3.4-3.2l-0.2-1.1l1.1,0.1l1.9-0.5l2-3.9l0.2-1.9l2.1-2.2l1.3-0.8l0.9-1.3
      l0.2-1.9l1.3-2.5l0.4-1.5l1-1.1l0.1-1.8l1.2-1l3.7,0.5l0.6-0.1l3.5-7.4l1.7-0.5l1.4-1l3.4-0.9l1.4-1.1l0.2-1.8l0.6-0.6l0.6,1.6
      L686,49l1.1-0.8v-1.9l1.1-0.8l1.9,2h6.1l0.5-1.5l1-1.1l1.3-0.2l0.4,0.1l1.2,0.8l1,1.1l1.4,0.9h1.9l1.4,0.8l1.8,2.3l3.2,1l3.7,5.2
      l1.1,1l0.7,1.2l0.4,1.8l-0.5,3.8l2,2l1.4,0.6h2l0.6,1.2l-1.2,1.3l0.2,1.2l4.9,5.1l6.3-0.4l4.5-3.9l0.4,1.1l-1.2,1.1l-1.4,0.8
      l-1,1.1l1.1,1l0.3,0.8l-2.9,1.4l-0.7,1.5l0.5,1.3l1.6,1.1l1.5,0.5l2.9,2.1l-1.5,1.1l-2,0.7l-1.1,1.6l-2.3,0.5l-1.2,1.6l-4.1,1.8
      l-2.5,0.2l-2,0.7l-1.8,1.2l-4.8,0.5v2.7l1.1,2l2.3,0.7l1.4,1.6l0.2,2.5l3,6.8l2.3,1.6l1.6,2.1l2.3,0.5l1.4-1.2v2l1.6,1.8l7,1.8
      l5.4,4.6l5.2,0.4l4.6-2.3l2.5-0.2l1.1-1.8l0.7-2.1l-4.6-4.5v-4.8l-0.5-2.1l2.3-0.5l2.9-3l2.5,0.5l1.8,1.2l0.9,0.2l1.8-2.9l1.4-1.4
      l0.9-2.1l0-1.2l1.8,0l1.6-0.6h1.9l-0.6,0.6l-0.8,1.3l-0.4,1.6l1,1.2h1.6l1.3-1.2l1.4-0.8l2.9,0.9l4.4,0.1l1.4,0.6l1.5-0.6l5.9,1.3
      h1.9l3.4-1.2l1-1l1.7-0.4l1.8,0.1v1.9l-1.2,0.8l-0.8,1.2l0.6,0.9l1,0.9l0.6,1.2L803,109l-1.5,1l-1.5,4.5l1.3,3l4.2,4.2l0.2,0.6
      l-1.4,1l0.6,0.8v5.7l0.4,1.4l4.9,4l4,0.4l1.7-1.1l0.8-0.8l1.8-0.1l1.5,0.3l0.9,1l1.4,0.5l1.2,2.2l1.1-1l1.4-0.1l1.9,2l-0.1,1.5
      l-0.7,1.4l-1.9,0.2l-0.8,1.3l-2.8,1.3l-0.8,1.1l-1.8,0.1l-0.8,1.5L817.3,147.5L817.3,147.5L817.3,147.5z"/>
  </g>
  <g title="桃園市" id="taoyuan">
    <path d="M671.4,248.9l-0.5,1.2l-1.6,0.5l-3.5,0.3l-1.3,0.8l-1.8,2.4L662,259l-2.3,3l-0.4,1.5l-0.6,1.3
      l-0.4,1.5l-3.4,5.3l-0.3,1.6h-1.8l-1.4,0.6l-1.1,0.9l-1.5,1.9l-0.8,0.5l-1.8,0.4l-3.7-0.5l-1.5-0.9l-1.3-1.9H639l-0.8-1l-0.5-1.5
      l-1.4-0.5v-3.9l0.8-1.4l0.4-1.8l-1.5-0.8l-1.6-0.3l-1.3-0.8l-0.1-3.8l1-1l0.1-1.9l0.5-1.5l-0.3-1.9l-1.6-0.8h-1.9l-0.6-1.3
      l-0.3-1.8l-0.8-1.3l-1.1-0.9l-2.3-2.9l-2.9-1.4l-3,1l-1.4-0.5v-3.8l1.1-1.1l0.6-1.3v-3.8l-2.1-0.4l0.1-4.4l0.5-1.4l0.9-1.3l0.4-1.6
      l-1.3-1.3l-0.6-1.5l-1.8-0.4l-1.3-1.3l-1.4-0.5l-1.8-0.1l-0.5-0.8h-0.3l-1.6-0.9l-1-1.3l-1.5-0.9l-0.4-0.6l-0.1-1.1l-0.9-2.4
      l-1.5-0.6l-2.1-0.4v-1.9l-1.5-0.5l-3.9,1.8h-1.6l-5.7-4.5l-1.4-0.6l-1.1-1.1l-2-0.8v-1.4l-1.9-0.3l-1.8-0.6l-3.5-2.8l-0.6-0.9
      l-3-1.9l0.4-1.6l1.8-2.8l-0.5-5.6l-1.4-1l-11.7-1l-0.8-1.5l-0.3-1.9l1-0.9l-1.4-0.8l-1.3,0.9l-1.6,0.6l-1.9,0.1l-1.6-1.3l-2-0.6
      h-1.9l-1.3-1.4l-3-1.1l0.1-3.7l0.6-1.5l-1.1-3l-0.1-1.8l-1.8-0.1l-2.1-2.1l-3.7-0.3l-4.3,1.3l-2.3-1.5l-4.3,2.5l-3.1-0.8l0.5-0.8
      l1.2-1l3-4l0.6-1.4l1.3-1.3l1.2-0.8l1.8-1.9v-3.7l-0.9-1.2l0.1-1.8l3-3.8l1-2.9l1.1-0.9l2.4-3.5l4.7-4.1l3.9-1.8l1.6-0.2l1.2-1.2
      l1.5-0.5l2.5-1.6l1.2-1.4l1.7-1l1.8-0.5l2.8-2.2l1.5-0.6h2l12.1-3.2l1.5-1.3l3.5-1.6l3.7-3.7l1.7-1l2.1-0.1l8.3-3.4h1.9l1-1
      l3.5-0.8l1.5,0.5l2.5,2l2.4,0.1l0.8,1.9l1.2,1.4l1.2,0.4l1.2,1.6l5,0.5l9.3,4.5l-0.5,3.6l2,1.8l5.2,1.2h5.4l1.2,0.9l1.8,2.1
      l0.5,2.3v0.7l-0.9,1.8l0.4,2.3l1.2,1.4l2.3,1.6l-0.9,1.6l-2,1.2l-2,0.7l2.1,1.6l0.2,2.1l-1.6,1.1l-2.1,0.7l-2,3.6l-2.9,2l-11.2-0.4
      l-1.8,0.9l-0.5,2.1l1.1,2l-0.5,5l-1.4,1.4l-0.9,2l1.2,2.3l2.1,0.2l1.6,1.2l-2.1,1.4l-1.2,1.4l-0.9,2l0.9,4.3l4.5,2.7l3.4,5.2
      l-1.2,1.6l0.4,2.1l2.7,1.8l-1.6,1.2l-4.6,0.9l-0.2,2.1l2.5,0.4l1.6-1.2l1.6,1.6l1.8,1.1l2.9,0.7l2.7-0.2l2-1.1l5.4-0.4l1.2,0.7
      l0.4,0.9l1.4,1.4l0.7,2l2.9,2.9l1.6,0.9l1.6,1.8l0.2,2.5l0.9,2.5l2.3,0.7v2.7l-1.6,1.1l-1.1,1.6l-3.2,2.7l-1.1,2l0.4,2.3l0.9,1.8
      v1.1l2,5l3.2,3l-0.5,2l2.5,1.1l0.2,1.8l2.5,0.7l1.4,2l2,1.4l0.5,5L671.4,248.9L671.4,248.9L671.4,248.9z"/>
  </g>
  <g title="新竹縣" id="hsinchu">
    <path d="M623.5,325.5l-1.7,0.9l-0.6,1.4l-1.3,0.8l-5.8-0.1l-1.3,0.6l-5.7-0.1l-0.9-1l-0.1-2.1l0.3-1.3
      l-0.6-0.8V320l-0.9-1.3l-1.5-0.9l-1.1-1.3l-0.6-1.5l-2.1-2.4l-0.6-1.5l-1.5-0.8l-0.8-1.1l-1.6-0.9l-0.5-1.3l0.3-2.9l-1.1-1.5
      l-3.7-0.3l-1.5,0.5l-2.7,1.8h-4.3l-1.4,0.8h-1.9l-1.3-1.1l-7.6-0.4l-1.4,0.5l-1.5-0.4h-1.9l-2.1,1.9l-3.4,1.4l-3.4-0.8h-5.9v-2.1
      l-0.9-1.9v-2l0.8-1.5l2.1-2.3l0.5-1.5l-0.1-1.9l-1.5-2.9l-2.7-2.1l2-3.3l1.1-4.2l1.1-1.4l0.1-1.8l0.9-1.3l0.4-1.6l-1.4-0.8
      l-1.1-3.4l-1.9-0.5l-1.4-3.2l-0.9-0.4l-2,0.5l-1.5,0.9l-2.4-1.9l-0.9-1.5l-1.6-0.4l-1.3-0.9l-2-3.2l-1.8-0.3l-1.8-0.8l-0.1-1.8
      l-1.1-1.3l-1.8-0.3l-3.3-2.1l-0.9-1.5l-1.4-1.4l-1.6-2.9l-0.3-1.8l-2-0.8l0.4-1.3l0.9-1.4l0.3-1.6l1-1.3l-1.4-0.8l0.9-1.1l-3.5,0.3
      l-1.3,1l-1.5,0.6l-0.9-1.1H511l-1.5-0.8l-1-1.2l2.5-3.1l0.4-3.7l1.3-2.9l1.6-0.4l1-1.5l1.5-0.6l1.1-0.9l0.5-1.5l0.9-1.4l5.4-1.3
      l-0.5-1.4l1.1-1.8l1.5-0.5h1.9l7.1,7.2l1.5-1v-3.8l-3.3-11.1l-1.9-2.6l-3.4-2.9l-3.7-0.4l-0.1-0.6l-0.5-1l-1.4-0.9h-1.9l-7.7-1.9
      l-1.9-2.7l-0.5-1.4l-1.6-1.3l0.6-1.5l2-2.5l0.5-1.8l1-0.9l0.6-3.4l5.6-6.9l-0.8-1.5l-0.4-1.9l5.5-7.9l3.1,0.8l4.3-2.5l2.3,1.5
      l4.3-1.3l3.7,0.3l2.1,2.1l1.8,0.1l0.1,1.8l1.1,3l-0.6,1.5l-0.1,3.7l3,1.1l1.3,1.4h1.9l2,0.6l1.6,1.3l1.9-0.1l1.6-0.6l1.3-0.9
      l1.4,0.8l-1,0.9l0.3,1.9l0.8,1.5l11.7,1l1.4,1l0.5,5.6l-1.8,2.8l-0.4,1.6l3,1.9l0.6,0.9l3.5,2.8l1.8,0.6l1.9,0.3v1.4l2,0.8l8.2,6.3
      h1.6l3.9-1.8l1.5,0.5v1.9l2.1,0.4l1.5,0.6l0.9,2.4l0.1,1.1l0.4,0.6l1.5,0.9l1,1.3l1.6,0.9h0.3l0.5,0.8l1.8,0.1l1.4,0.5l1.3,1.3
      l1.8,0.4l0.6,1.5l1.3,1.3l-0.4,1.6l-0.9,1.3l-0.5,1.4l-0.1,4.4l2.1,0.4v3.8l-0.6,1.3l-1.1,1.1v3.8l1.4,0.5l3-1l2.9,1.4l2.3,2.9
      l1.1,0.9l0.8,1.3l0.3,1.8l0.6,1.3h1.9l1.6,0.8l0.3,1.9l-0.5,1.5l-0.1,1.9l-1,1l0.1,3.8l1.3,0.8l1.6,0.3l1.5,0.8l-0.4,1.8l-0.8,1.4
      v3.9l1.4,0.5l0.5,1.5l0.8,1h0.9l1.3,1.9l1.5,0.9l3.7,0.5l2.6-0.7l1.4,1l0.6,1.4l0.1,1.8l0.5,1.4l-1.3,1.1l-0.5,0.3l-0.5,0.8
      l-1.3,0.8l-1.4,1.5l-0.4,1.6l0.6,1.3l0.1,7.3l-0.8,1.8l-1.1,1.4l-1.4,1l-1.6,2.5l-1.8,1.4l-0.5,1.3l-1.4-0.1l-3.4,4.9l-1.3,0.8
      l-1.9-0.4l-1.4,2.8l-1.4,0.8L628,318l-0.9,1.1l-1.5,1.3l-1.3,2.5l-0.1,1.8L623.5,325.5"/>
  </g>
  <g title="新竹市" id="hsinchu-city">
    <path d="M493.6,229l0.2,1.1l1.3,0.6h2l1.4-0.5l1.1-0.9l1.4,0.5l1.9,2.3l0.5,1.6v1.5l4.9,1.3l0.7-0.5
      l2-2.4l0.4-3.7l1.3-2.9l1.6-0.4l1-1.5l1.5-0.6l1.1-0.9l0.5-1.5l0.9-1.4l5.4-1.3l-0.5-1.4l1.1-1.8l1.5-0.5h1.9l7.1,7.2l1.5-1v-3.8
      l-3.3-11.1l-1.9-2.6l-3.4-2.9l-3.7-0.4l-0.1-0.6l-0.5-1l-1.4-0.9h-1.9l-7.7-1.9l-1.9-2.7l-0.5-1.4l-1.6-1.2l-1,0.8l-1.5,0.6l-5.1,7
      l-1.2,1.1l0.6,3l-0.2,1l0.5,0.5l1.9,0.1l0.2,1.6l-0.8,1.5l-0.4,3.5l-0.8,1.1l-0.1,3.9l-1.9,2.5l-0.5,1.5l1.4,1.1l-0.8,1l-0.6,1.8
      l-2.3,2.2l-1.8,1L493.6,229L493.6,229L493.6,229z"/>
  </g>
  <g title="苗栗縣" id="miaoli">
    <path d="M509.5,237.9l1.5,0.8h2.9l0.9,1.1l1.5-0.6l1.3-1l3.5-0.3l-0.9,1.1l1.4,0.8l-1,1.3l-0.3,1.6
      l-0.9,1.4l-0.4,1.3l2,0.8l0.3,1.8l1.6,2.9l1.4,1.4l0.9,1.5l3.3,2.1l1.8,0.3l1.1,1.3l0.1,1.8l1.8,0.8l1.8,0.3l2,3.2l1.3,0.9l1.6,0.4
      l0.9,1.5l2.4,1.9l1.5-0.9l2-0.5l0.9,0.4l1.4,3.2l1.9,0.5l1.1,3.4l1.4,0.8l-0.4,1.6l-0.9,1.3l-0.1,1.8l-1.1,1.4l-1.1,4.2l-2,3.3
      l2.7,2.1l1.5,2.9l0.1,1.9l-0.5,1.5l-2.1,2.3l-0.8,1.5v2l0.9,1.9v2.1h5.9l3.4,0.8l3.4-1.4l2.1-1.9h1.9l1.5,0.4l1.4-0.5l7.6,0.4
      l1.3,1.1h1.9l1.4-0.8h4.3l2.7-1.8l1.5-0.5l3.7,0.3l1.1,1.5l-0.3,2.9l0.5,1.3l1.6,0.9l0.8,1.1l1.5,0.8l0.6,1.5l2.1,2.4l0.6,1.5
      l1.1,1.3l1.5,0.9l0.9,1.3v3.8l0.6,0.8l-0.3,1.3l0.1,3.9l-1.8,0.9l-2,1.6l-1.4,1.8l-0.2,2.5l-3.4,5.5v2.7l-2.1,0.5h-2.7l-1.8-2.5
      l0.9-1.4l-2.7-1.1l-2,0.9l-0.7,2.1l-1.2,1.8l-2.3,0.7l-4.8,6.8l-2,0.7h-5.4l-1.8,1.2l-0.7,2l-0.7,0.2l-2,2.1l-0.4,2.5l-2.1,0.5
      l-3.4,2.9l-2.1,0.5l-5-0.5l-4.5,1.6l-5.4,6.8l-2.7,0.2l-2-1.1l-3.2-3l-0.4-2.5l-1.8-1.2l-2.7-3.9l-1.6,0.4h-2.7l-2-0.9l-5-0.4
      l-6.1-2.1l-4.8-0.2l-2.1,0.9l-1.4,1.4v2.7l0.9,2l0.4,5l-0.9,2l-1.8,1.4l-2.1,0.7l-10.4-0.4l-2,0.9h-5.4l-5.2-1.6l-3.2-3l-1.1-2
      L472,367l-11.2-2.1l-3.4-2.9l-2.5-0.7l-1.4-2l-3.6-2l-1.4-1.6l-2.1-0.7l-1.2-1.6l-0.9-0.4l-3-0.4l-1.2-1.8l-3.2-2.1l-7.5-9.6h-0.4
      l-0.9-1.8l-4.5-1.8l-2.3-3.8l-0.4-2l-3.8-4.7l1.6-0.7l3.8-3.2l3-3.9l0.6-1.4l5.2-6.8l1.2-3.2l1.4-1.9l9.5-28.2l2.2-2.9l0.1-0.8
      l1.2-1.2l3.2-0.8l2.7,2.3l1.1,1.7l0.4-1.5l-0.4-2l2.7-1.6h1.4l1,0.2l-0.1-1.7l-1.7-0.8l-1.5-1.1l1-0.9l0.6-3.3l3-5.3l1.7-0.2l2-2.4
      l5.6-0.2l4.4-2h2.4l3.9,1.4l1,1l2.2,0.5l1.3-0.8l-0.8-1l-1.9-0.2l-1.5-0.7l-0.8-1.6l-1.8-0.2l0.1-1.8l1-1.1l1.5-0.8l5.5-4.7
      l0.5-1.6l0.9-1.2l0.4-1.5v-1.9l1.2-2.8l1.3-1.1l0.9-1.3l1.9-0.8l0.5,1l1.3,0.6h2l1.4-0.5l1.1-0.9l1.4,0.5l1.9,2.3l0.5,1.6v1.5
      l4,1.1L509.5,237.9L509.5,237.9L509.5,237.9z"/>
  </g>
  <g title="南投縣" id="nantou">
    <path d="M419.4,538.4l5.1,10.9l-0.5,7.1l-2.8-0.5l-0.8,3l0.3,7.3l1.5,2.5l0.3,3.5l-1.5,6.1l-0.8,1.3
      l-0.3,0.3l-2,5.8l0.3,7.3l4.8-3.5l0.8,3l1.5,2.8l2.3,1.5l3.5,0.3l3-0.5l0.8-3l3.3,0.8l2.8-0.5l1-2.8l3.3,0.5l2.3,2l0.1,3l6.5-0.7
      l11.6,4.3h3.8l3.3,0.8l1.3,2.5l0.3,8.6l-0.8,3.3l-2.8,2.3v3.8l1.5,2.5l2.3,2l0.5,7.3l2.5,5.8l2,2.3l11.4,1.3l5.6-2.3l11.1,1l2.8,1
      l11.9,0.8l6.3,2.3h0.5l2.5,1.8l1.3,6.3l1,0.5l1.3-1.3l3-0.8l3-2l3-0.8l0.8-3.5l-1-3l-2.3-1.8l1.8-2l0.8-3.3l2-2.3l2.3-1.5l0.8-3
      l2.3-1.5l3.5,0.3l2.8-1.3h3.8l1.3-0.5l2.5,1.5l3,0.8l4.8-4v-7.6l0.8-3l2.3-1.5l-2.3-6.3l2.5-1.3l3.8,1l2.8-1.3l1.5-2.3l3.3-1.5
      l4.5-0.3l3.8-8.3l1.3-10.4l1.3-2.5v-3.8l2-5.6v-9.3l-2.5-2.3l-0.3-3.5l0.8-2.8l-8.1-5.8l0.3-3.5l3.3-0.8l3-2.3v-1.5l-0.8-3l0.5-3.5
      l2.3-1.5l1-3.3l0.3-3.8l1-3l2-2.5l0.3-3.8l2.3-2.3l3-0.8l-0.5-5.6l-1.8-3l4.3-3.8l-0.3-3l-1-3.3v-3.8l0.8-2v-7.6l-2-2.5l-1-0.3
      v-3.5l6.1-12.9l0.3-7.3l2.3-2.3l2.5-1.3l1.5-2.5l2.5-1.3l2-3.5l0.8-2.5l0.3-3.5l-1.5-2.3l-3.5-0.5l-1-2.8l-1.8-2l-3.8-1.3l-0.8-1
      l-0.3-6.3l2.8-6.6l3-0.8l2-1.8l2.8-1.3l3.8-3.8h3.8l1.5-2.3l-1.7-5.6l-1.4-0.8l-4.8-1.1l-1.2-1.2l-4.8-0.5l-1.1,1.6l-5.2-0.4
      l-2.1-0.7l-1.1-1.6l-3.2-2.3h-0.9l-2.3,3.8l-0.5,2.1l-1.2,2.1l-2,0.7l-2.5-0.9l-1.6-1.1l-4.1,1.8h-2.7l-1.8,1.1l-0.9,1.8l-4.3-1.2
      l-1.2-1.4l-2-0.9l-1.6,1.1l-1.2,1.6l-2.5,0.2l-0.7,2l-1.2,2l-1.4,1.2l-10.5,0.2l-1.8,0.9l-0.5,2.1l-1.2,2l-1.8,1.1l-1.2,1.6l-2,1.2
      l-1.2,2l-3.9,2h-4.1l-1.6,1.1l-4.3-0.5l-2.5,0.5l-1.4,1.4l-2,6.3l-2.7,2.7l-1.8,1.1l-5.2,0.2l-2.3-0.5l-1.4-1.2l-2.3,0.4l-6.1,5.2
      l-2.5,0.5l-2.5-0.4l-2.9-1.4l-0.4-0.5l-1.6-1.4l-1.1,4.3l-0.9,1.8l-1.4,1.2l-0.7,1.6l-1.1-0.5v-5.4l-1.6-1.4l-4.6-0.9l-1.1-1.6
      l-2.3-0.7l-1.6,1.1l-3.8,7.5l0.4,6.6l-1.2,1.6l-4.5,1.2l-3.2,3.2l-3.8,9.8l-6.4,7l-4.3,1.4l-2.1,0.2l-1.8-1.6l-2.5-0.5h-5.4
      l-2.1,0.5l-9-3.3l-3.9,2.3h-7.6l-0.3,3.8l0.5,0.3l1.8,2l1.3,2.8l-0.3,3.5l-1.3,2.5l-2,1.8l-1.5,2.3l1,5.6l-0.5,2.3l0.5,2.8
      l-1.3,9.6l-1.3,2.5l-0.5,3.3l-1.5,2.5l1.8,2l-1.3,2.5l-0.3,2.3l7.3,7.8l2.5,1.3h3.3l2.5-1.3l2,0.5v3.3l-1.3,2.5l-2,1.8l-5.8,1.8
      L419.4,538.4L419.4,538.4L419.4,538.4z"/>
  </g>
  <g title="臺中市" id="taichung">
    <path d="M417.1,325.1l-1.8,0.4l-0.2,2.3l-9.1,12.6l-1.9,4.7l0.2,3.8l-0.1,0.5l-1.2,1.6l-0.5,1.4l-2.8,1.2
      l-1,1.8l-4.7,3.2l0.1,1.4l0.5,1.2l0.1,2.1l-0.9,1l-2.4-0.8l2.8,1.3l0.5,1.2l-1,1.4l-0.9,0.8l-2.2-2.9l-0.9,0.5l-1.1,1.2l-2.2,1.2
      l0.1,1.8l-0.8,1.3l-2.8,1.6l-0.9,1.4l4.2,5.7l-0.1,1.6l-0.9,1.3l-0.4,1.5l-1-1.9l0.2-1.6l-1.5,1.6l-1.5-0.1l-0.4-1.9l0.9-1.8
      l-0.1-1.8l-1.2,1.2l-2.2,6.4l1.6,1.3l0.6,1.4l0.6,0.5l0.1-0.2l1.8,1.1v1.9l-0.6,1.5l-3.9,5.7l-1.2,0.9l-1.5,3.8l0.1,2l4.4,2
      l2.2,3.4l0.3,2.3l1.1,2.7l0.4,2.7l3.2,3.4l-1.2,1.6l-0.2,2.5l1.2,1.6l1.8,1.2h0.5l1.4,2.3l2,0.9l1.6,1.2l2.2,0.5l1.9,1.1l10.2,2.3
      h2.5l1.6,1.2l0.3,0.9v5.4l1.6,2.1l1.8,1.4v2.8l-0.9,2l1.4,7l2.5,0.2l1.6,1.4l3.6-0.4l1.1-0.7l5.7,4.8l8.9,3.2l2.2-0.6h5.3l2.5,0.6
      l1.8,1.6l2.2-0.2l4.3-1.4l6.4-6.9l3.8-9.8l3.2-3.2l4.4-1.2l1.2-1.6l-0.3-6.6l3.8-7.5l1.6-1.1l2.3,0.7l1.1,1.6l4.7,0.9l1.6,1.4v5.3
      l1.1,0.6l0.7-1.6l1.4-1.2l0.9-1.8l1.1-4.3l1.6,1.4l0.4,0.5l2.8,1.4l2.5,0.4l2.5-0.5l6.1-5.2l2.3-0.3l1.4,1.2l2.3,0.5l5.2-0.2
      l1.8-1.1l2.7-2.7l2-6.2l1.4-1.4l2.5-0.5l4.3,0.5l1.6-1.1h4.1l3.9-2l1.2-2l2-1.2l1.2-1.6l1.8-1.1l1.2-2l0.6-2.2l1.8-0.9l10.5-0.2
      l1.4-1.2l1.2-2l0.7-2l2.5-0.2l1.2-1.6l1.6-1.1l2,0.9l1.2,1.4l4.3,1.2l0.9-1.8l1.8-1.1h2.7l4.1-1.8l1.6,1.1l2.5,0.9l2-0.7l1.2-2.1
      l0.5-2.2l2.3-3.8h0.9l3.2,2.3l1.1,1.6l4.7,1.1h2.7l1.1-1.6l4.8,0.5l1.2,1.2l6.4,1.8l1.8-1.4l0.9-1.8l-0.2-2.5l3.8-1.6l1.1-1.6
      l2.7,0.2l1.4-1.2l0.6-2.3l-0.9-4.7l1.2-2l2.3-0.9l1.6-1.1l1.8-4.1l1.4-1.6h2.7l1.8-1.2l1.4-1.8l2.2-4.3l0.7-7.3l0.9-2l2.3-1.2
      l1.2-1.4l-1.2-2.1l-1.6-1.1l-1.1-1.8l-2.2-1.1l-7.1,0.9l-1.8,0.9l-2.3,0.3l-1.6,1.2l-1.2,1.6l-2.7,0.4l-1.8-0.9l-4.1-7h-4.8l-2-0.7
      l-2.7-2.7l0.9-7.2l-0.5-2.1l-0.9-1.8l-1.2-1.4l-0.4-0.2l-1.7,0.9l-0.7,1.4l-1.2,0.8l-5.8-0.1l-1.2,0.7l-6.5-0.2l-0.1,0.8l-1.8,0.9
      l-2,1.6l-1.4,1.8l-0.2,2.5l-3.4,5.5v2.7l-2.2,0.6h-2.7l-1.8-2.5l0.9-1.4l-2.7-1.1l-2,0.9l-0.7,2.2l-1.2,1.8l-2.3,0.7l-4.8,6.8
      l-2,0.7h-5.3l-1.8,1.2l-0.7,2l-0.7,0.2l-2,2.2l-0.4,2.5l-2.1,0.5l-3.4,2.8l-2.1,0.6l-5-0.6l-4.5,1.6l-5.4,6.8l-2.7,0.2l-2-1.1
      l-3.2-3l-0.4-2.5l-1.8-1.2l-2.7-3.9l-1.6,0.3h-2.7l-1.9-0.9l-5-0.3l-6.1-2.2l-4.8-0.2l-2.2,0.9l-1.4,1.4v2.7l0.9,2l0.4,5l-0.9,1.9
      l-1.8,1.4l-2.2,0.7l-10.3-0.3l-2,0.9h-5.3l-5.2-1.6l-3.2-3l-1.1-2L472,367l-11.2-2.2l-3.4-2.8l-2.5-0.7l-1.4-2l-3.6-2l-1.4-1.6
      l-2.1-0.7l-1.2-1.6l-0.9-0.4l-3-0.3l-1.2-1.8l-3.2-2.2l-7.5-9.7h-0.3l-0.9-1.8l-4.5-1.8l-2.3-3.8l-0.4-2L417.1,325.1z"/>
  </g>
  <g title="彰化縣" id="changhua">
    <path d="M306,523l6.3,2.3l3.8,0.3l5.8,1.8l16.2-0.8l2.8-1l14.6,0.3l2.5-1.3l5.1,2.8l0.8,1.3l4.3,0.5
      l8.1,4l6.3,1.5l7.3-0.3l3-0.8l30.6,5.1l5.8-1.8l2-1.8l1.3-2.5v-3.3l-2-0.5l-2.5,1.3h-3.3l-2.5-1.3l-7.3-7.8l0.3-2.3l1.3-2.5l-1.8-2
      l1.5-2.5l0.5-3.3l1.3-2.5l1.3-9.6l-0.5-2.8l0.5-2.3l-1-5.6l1.5-2.3l2-1.8l1.3-2.5l0.3-3.5l-1.3-2.8l-1.8-2l-0.5-0.3l0.3-3.8h7.6
      l4-2.3l-5.8-4.8l-1.1,0.7l-3.6,0.4l-1.6-1.4l-2.5-0.2l-1.4-7l0.9-2v-2.9l-1.8-1.4l-1.6-2.1v-5.4l-0.4-0.9l-1.6-1.2h-2.5l-7.5-2.1
      l-2.7-0.2l-2-1.1l-2.1-0.5l-1.6-1.2l-2-0.9l-1.4-2.3h-0.5l-1.8-1.2l-1.2-1.6l0.2-2.5l1.2-1.6l-3.2-3.4l-0.4-2.7l-1.1-2.7l-0.4-2.3
      l-2.1-3.4l-4.4-2l-1.5,0.4l0.5,1.5l0.7,0.9l-1.5,0.8l-3.2,4.2l0,0.1l-2.7,4.3l-1.2,0.8l-5.6,0.1l0.2,1.5l1.5,1.3l-0.1,3.7l-1,0.9
      l-0.6,1.4l-0.4,1.8l-0.9,1.2l-0.1,3.8l-0.1,0.4l-1,1.5v1.9l0.6,1.2l-0.2,1.9l-1.4,1.2l-3,1.2l-0.8,1.8l-2.3,2.4l-0.2,3.5l-0.6,1.4
      l-2.7,0.5l-1.4,1.1l-1.6,0.2l-2.4,1.8l-1.4,0.5l-0.4,1.6l-4.1,3l-0.8,3v2.2L335,467l-0.2,1.6l-1.3,0.9l-0.6,3.3l-2.4,0.2l-1.9-0.4
      l-1.4,1.2l-1,4.9l0.4,1.2h3.8v0.9l-4.8,7.2l-0.2-0.1l-0.4,2l-0.9,1.1l-0.4,1.5l-1,1l-1.3,4.8l-2.1,2.4l-0.5,1.8l-1.9,2.5l-0.6,1.5
      l-0.9,1l-0.2,2.2l-1.2,0.7l-1.1,1l-1.4,0.7l-0.9,1.3l-1.4,0.8v1.4l0.9,1.3l-0.7,0.5h-1.9l-0.9,1.2l-0.3,3.7l-0.4,1"/>
  </g>
  <g title="雲林縣" id="yunlin">
    <path d="M447.5,596.5v-3l-2.3-2l-3.3-0.5l-1,2.8l-2.8,0.5l-3.3-0.8l-0.8,3l-3,0.5l-3.5-0.3l-2.3-1.5
      l-1.5-2.8l-0.8-3l-4.8,3.5l-0.3-7.3l2-5.8l0.3-0.3l1.8-4l0.5-3.3l-0.3-3.5l-1.5-2.5l-0.3-7.3l0.8-3l2.8,0.5l0.5-7.1l-5.1-10.9
      l-20.7-3l-3.8-1.5l-2-0.3l-10.4,1l-14.4-5.6l-4.3-0.5l-0.8-1.3l-5.1-2.8l-2.5,1.3l-14.6-0.3l-7.6,2l-11.4-0.3l-5.8-1.8l-3.8-0.3
      l-6.4-2.2l-3.9,0.1l-1.2-0.7l-1.4-0.2l-0.5,1.5l-1.8,0.1l-6.1,10.8l-0.1,0.7l-2.2-0.1l-1.3,0.8l-2,2.4l-0.6,1.6l-0.2,3.8l-1,1.2
      l-0.2,1.6l1.7,2.4l-0.2,1.8l-1.7,2.4l-3.2,0.1l-0.4,1.5v1.9l-2.2,1.2v2.4l-0.1-0.1l-0.5,0.2l-0.4,1.6v1.9l0.5,1.4l1.9,0.1l1.4,2
      l-2.3,2.8l-1.4,2.8l-0.4,1.5l-1.9,3.2l-1,3.5l-1.3,1.9l-2.6,7.4l0.2,7.5l-0.4,1.5l0.1,1.4l1.8,0.9l-0.1,1.7l-1.5,0.6l-2.2,1.6
      l0.5,2.2l1.1,0.6l1.5,0.2l0.1,1.9l-1,1.2v3.8l1.2,0.9l2,2.8l1.4,1.1l-1.2,0.8l-0.9,1.7l-1.8,0.1l-0.5-1.4l-1-0.9l-1.5,0.9l-2.2,2.5
      l3.9,0.1l5.8,2.3h3.8l1.5,2.3h3.8l2.8-1.3l6.3,2.3l3.8,0.5l2.3-1.8l1.8-2.3l0.3-3.5l-0.8-3l2-2h3.3v3.3l3.5,1.3l1-2.8l4.8-2.8
      l0.8-3.5l1.8-2l6.3-2.5l2.8,1.5l1.8-6.1l1.5-2.5l4,0.3l2.5-1.3l1-3l5.8-1.8l1-2.8l2.3-1.8l1.8-2.3l2.8,1.3l2.3,1.8l2.8,1l5.8-2.3
      h7.6l5.3-2.8l5.1,1.3l1.5,5.1l4.3,3.5l1.5,2.3l2.5,1.5l3.5,0.3l2.3,1.8l3,1l13.9,1.5l2.8-1.5l1.8-2l5.6,0.3l-0.5,3.8l2,3.3l2-2
      l3.3-0.8l2,2l3-0.3l2.8-1l3.5,0.3l2.8,1.5l3.5,0.5l1.3-2.5v-3.5l-0.8-3l-1.5-2.3L447.5,596.5L447.5,596.5L447.5,596.5z"/>
  </g>
  <g title="嘉義縣" id="chiayi">
    <path d="M510.3,642.2l-2.8-1l-11.1-1l-5.6,2.3l-11.4-1.3l-2-2.3l-2.5-5.8l-0.5-7.3l-2.3-2l-1.5-2.5
      v-3.8l2.8-2.3l0.8-3.3l-0.3-8.6l-1.3-2.5l-3.3-0.8h-3.8l-11.6-4.3l-6.4,0.6l-1.6,2.4l1.5,2.3l0.8,3v3.5l-1.3,2.5l-3.5-0.5l-2.8-1.5
      l-3.5-0.3l-2.8,1l-3,0.3l-2-2l-3.3,0.8l-2,2l-2-3.3l0.5-3.8l-5.6-0.3l-1.8,2l-2.8,1.5l-13.9-1.5l-3-1l-2.3-1.8l-3.5-0.3l-2.5-1.5
      l-1.5-2.3l-4.3-3.5l-1.5-5.1l-5.1-1.3l-5.3,2.8h-7.6l-5.8,2.3l-2.8-1l-2.3-1.8l-2.8-1.3l-1.8,2.3l-2.3,1.8l-1,2.8l-5.8,1.8l-1,3
      l-2.5,1.3l-4-0.3l-1.5,2.5l-1.8,6.1l-2.8-1.5l-6.3,2.5l-1.8,2l-0.8,3.5l-4.8,2.8l-1,2.8l-3.5-1.3v-3.3h-3.3l-2,2l0.8,3l-0.3,3.5
      l-1.8,2.3l-2.3,1.8l-3.8-0.5l-6.3-2.3l-2.8,1.3H280l-1.5-2.3h-3.8l-5.8-2.3l-3.9-0.1l-1.6,0.9l-0.4,9.1l0.9,1.5l1.8,0.6l5.7-0.4
      l1.4,0.6v1.9l-0.8,1.4l-0.5,1.8l-0.9,1.1v2l-0.8,1.5l0.4,2l1.6,0.9h1.7l-2.2,4.2l-0.1,1.8l1.5,0.4v1.7l-1,3.2l0.1,1.9l0.8,1.5
      l1.2,1l1.4-1.2l0.6,0.9l0.2,3.5l-1,1.7l-1.3,5l-3.7,1.5l-1.4-0.1l-3,6.9l-0.2,5.1l0.4,1.5h3.8l1.6,0.6l0.2,1.7v0l2.7,1.8l3.8,0.8
      l1,3l2.3,2l2.5,0.3v-2.8l1.3,2l4.5,1.3l3-1.3l3.3-4.8l0.8-3l9.6-4.8l0.5-1.8l-1-2.8l2-2l2.5,1.5l1.8-1.5l1.3-2.5l0.5-3.3l7.1-0.5
      l-0.3-3l6.3,0.3l1.5-2.5l0.3-0.3l6.1-3.5h7.6l5.3-2.5l3.3-0.5l2,2l3.3-0.3l2.3-1.5l0.3-0.5l3.8,1l1.8,2.3l3.3,1l2.5,2l3,0.8
      l2.3-1.3l2,1.8l-1.5,2.3l-2.5,1.8v3.3l7.8,0.5l0.3,3.3l-2.5,1.8l1,2.3l3.8,1.3l3.5,0.3l1,2.8l-0.3,3l-2.3,1.8l-1.5,2.8l0.3,3.5
      l1,2.8l-1.3,2.5l0.5,2.5l2,3.3l0.8,2.5l-3.3,4.8l1,2.5l6.1,1.8l4.3,3l3.3,0.3l3-0.8l3.8-5.6l3.5,2.5l3,1l6.3-1.3l2.5,1.3l2-1.8
      v-3.8l-2-2.5l-1.3-2.8l1.3-11.6l7.8,0.3l2.3,2l8.1,0.3l1.3-6.6l6.6-0.5l1-2.8l5.1-2.5l2.3-5.8l7.1-0.5l4-4l1.5-2.5l2-2h3.8l2.8-1
      l0.3-3.5l1.8-2l1-2.8l1.8-1.5l1.8-4.3l2.3-2.5l3.3-0.5l2.3-1.8l2.8-1.3l1.5-2.5l4.3-3.5l6.3-1.3l2.3-1.5l6.6-1l2.5-1.3l1.2-0.3
      L510.3,642.2z M377.7,645.5l-0.8,2.5l-4.5-1.5l-2-1.8l-4.5,3.5l-1.3,2.5l-2.5,1.5l-2.5-1.3l-2-2.8l-1-2.8l-10.1-1.5l2-4.5l-3-3
      l-0.5-3.3h0l0,0v0l4.8-1.3l3,0.8l7.1-5.1l3-0.8l2.5,1.3l1.8,2.5l2,1.8l2.8-1l2.3,1.5l1.3,2.5l3,0.8l0.8,3l2,2L377.7,645.5z"/>
  </g>
  <g title="嘉義市" id="chiayi-city">
    <polygon points="378.4,636.2 375.4,635.4 374.1,632.9 371.9,631.4 369.1,632.4 367.1,630.6
      365.3,628.1 362.8,626.9 359.7,627.6 352.7,632.7 349.6,631.9 344.8,633.2 345.3,636.5 348.4,639.5 346.4,644 356.5,645.5
      357.5,648.3 359.5,651.1 362,652.4 364.5,650.8 365.8,648.3 370.3,644.8 372.4,646.6 376.9,648.1 377.7,645.5 381.2,641.3
      379.2,639.2"/>
  </g>
  <g title="臺南市" id="tainan">
    <path d="M359.1,660.9l-0.2,0.5l-2.3,1.5l-3.3,0.2l-2-2l-3.3,0.5l-5.3,2.5h-7.6l-6,3.5l-0.3,0.2l-1.5,2.5
      l-6.3-0.3l0.2,3l-7.1,0.5l-0.5,3.3l-1.2,2.5l-1.8,1.5l-2.5-1.5l-2,2l1,2.8l-0.5,1.8l-9.6,4.8l-0.8,3l-3.3,4.8l-3,1.2l-4.6-1.2
      l-1.2-2v2.8l-2.5-0.2l-2.3-2l-1-3l-3.8-0.8l-2.8-1.8l-0.8-1l-7.2-1.3l-3.9,6.8l0.6,1.3l1,0.1l-0.6,1.9l-1.3,0.9l-0.2,1.8l2.8,1.7
      l1.2,0.2l1.6-0.5l-2.2,3.2l-3.4,1.2l-0.9,1.3l0.2,1.8l0.8,1.5l-2.5,2.4l-0.4,1.7l-0.7,1.4l-2.4-0.6l-0.6,1.3L252,722l0.1,3.8
      l-4.9,9.2l-0.5,3.5l-1.5,2.5l1.1-1.4l1.5-0.6l1.5-0.1l0.6,8l1.2,0.9l1.5,0.6l-0.9,5.3l-3.3,1.4h-0.2l-3.5,1.2l-3.2,2.9l-3.3,1.5
      l-0.2,3.5l0.2,0.5l0.2,3.7l0.8,0.9h1.9l-0.1,5.9l0.8,2.4l2.1,2.5l0.3,0.8l1.9-0.7l1.3-0.9l1-1.1l0.3,1.6l-0.2,1.8l4.7,2.8l0.9,0.2
      l1.8,1.6l3.2,1.7l3.9,4.2l1.5,0.8l5.3,4.9l0.1,0.5l3.4,3.3l2.4,3.7l1.6-0.4l2.3-2.1l1.4-0.8l-1,2.2l-0.9,1.1v1.3l3.3,0.9l-1.7,1.4
      l-1.9-0.1l-1-1.4l-0.9-0.5l0.8,1.9l1.2,1.5l0.5,1.7l-0.5,9.6l-0.5,1.4l1,2l1.3-0.8l2-2l1.5-0.6l2.7-4.9l-2.6,4.9l8.5,3.1l2.5-1.3
      l1.8,1l3.8,4h3.5l0.5-0.8l2.8,1.2l2,0.2l1.5-2l17.9,4.1l3.8-0.2l6.3-5.8l1.8-2.5l3-1l3.5-0.2l2.5,1.5h3l1.5-6.3l1.5-0.8l1-1.3
      l0.5-3.3l3.8-8.8l3-0.8l1-3l0.2-3.5l5.6-0.2l0.2-3.5l9.1-6.6l3.5-5.6l10.4-7.1l2.2-2.5l3.8-2.3l0.5-3l0.2-0.2l1.2-0.8l0.5-3.5
      l1.3-2.5l0.2-3.5l5.6-6.8l1.8-0.2l8.1-10.9l0.2-3.8l4-5.1l0.8-3l-2.2-1.8l-0.3-0.5l-1,0.8l-2.5-1.2l-6.3,1.2l-3-1l-3.5-2.5
      l-3.8,5.6l-3,0.8l-3.3-0.2l-4.3-3l-6.1-1.8l-1-2.5l3.3-4.8l-0.8-2.5l-2-3.3l-0.5-2.5l1.3-2.5l-1-2.8l-0.2-3.5l1.5-2.8l2.3-1.8
      l0.2-3l-1-2.8l-3.5-0.2l-3.8-1.2l-1-2.3l2.5-1.8l-0.2-3.3l-7.8-0.5v-3.3l2.5-1.8l1.5-2.3l-2-1.8l-2.2,1.3l-3-0.8l-2.5-2l-3.3-1
      l-1.8-2.3L359.1,660.9z"/>
  </g>
  <g title="高雄市" id="kaohsiung">
    <path d="M334.8,956l6.8,5.6l1.8,0.8l2.8,2.5l5.4,1.6l0.2-0.6l-0.9-14.5l0.9-2.9l0.9-1.1l1.8,0.4l2.5-2.2
      l2-5.8l0.3-3.5l1.8-6.1v-3.8l-3.5-4.3l0.3-18.2l2-6.1l1.8-2.3l1.8-6.8l-0.3-4.5l0.8-1.3l-0.3-15.2l1.3-2.8l0.5-3.3l1.3-2.5l1.5-9.8
      l2.5-1.3l1.8,0.8l4.5,0.3l1.5,2.3h3.8l2.8-1.3l6.1,1.5l3.5-0.3l2.3-1.8l2.5-3.3l0.3-1.3l4-3.3l1.3-0.5l6.6-4.5l3.5-0.3l2.8-2l3-0.8
      l3,1.3l1.8,2v3.8l2.8,1.3h2.8l1-1l3-0.8l3.3-4.8l3-0.8h3.8l8.6,5.3l6.3,7.6l2.8,1.5l2.8-1.5l1.8-2.3l5.1-2.5l1.5-2.3h3.3l1,0.3
      l2.5,1.3l0.3,1.3l2.3-0.8l4.5,3l0.7-6.8l-2.9-3.9l-5.4-0.4l-2.9-8.6l0.4-5l-1.4-1.4l-1.8-3.9v-5.4l1.1-4.3l10-12.9l0.7-1.8v-3.9
      l6.4-4.6l-1.4-4.3l-2.5-2.5l1.8-4.3l3.6-3.9l-1.1-22.5l3.2-2.1l2.1-5l3.6-1.8l-1.1-3.9v-3.9l1.4-3.9l-0.4-1.8l-1.1-0.7l2.9-3.6
      l3.6-1.4l6.8-5.7l5-6.4h3.9l7.9-2.1l2.9-2.9l8.5-3.6l0.8-3l-0.3-3.5l-15.4-10.6l-3-0.8l1-3.5l1.8-2.5l3.3-1l-0.5-2.3l1.5-2.3
      l3.3-0.5l1.5-2.3v-7.1l-2.3-1.5l-0.6-1.7l-0.9-0.3l-1.3-6.3l-2.5-1.8h-0.5l-9.9-2.8l-1,0.3l-2.5,1.3l-6.6,1l-2.3,1.5l-6.3,1.3
      l-4.3,3.5l-1.5,2.5l-2.8,1.3l-2.3,1.8l-3.3,0.5l-2.3,2.5l-1.8,4.3l-1.8,1.5l-1,2.8l-1.8,2L477,674l-2.8,1h-3.8l-2,2l-1.5,2.5l-4,4
      l-7.1,0.5l-2.3,5.8l-5.1,2.5l-1,2.8l-6.6,0.5l-1.3,6.6l-8.1-0.3l-2.3-2l-7.8-0.3l-1.3,11.6l1.3,2.8l2,2.5v3.8l-1.1,1.1l0,0l0.3,0.4
      l2.3,1.8l-0.8,3l-4,5.1l-0.3,3.8l-8.1,10.9l-1.8,0.3l-5.6,6.8l-0.3,3.5l-1.3,2.5l-0.5,3.5l-1.3,0.8l-0.3,0.3l-0.5,3l-16.4,11.9
      l-3.5,5.6l-9.1,6.6l-0.3,3.5l-5.6,0.3l-0.3,3.5l-1,3l-3,0.8l-3.8,8.8l-0.5,3.3l-1,1.3l-1.5,0.8l-1.5,6.3h-3l-2.5-1.5l-3.5,0.3l-3,1
      l-8.1,8.3l-3.8,0.3l-10.1-3.3l-7.8-0.8l-1.5,2l-2-0.3l-2.8-1.3l-0.5,0.8h-3.5l-3.8-4l-1.8-1l-2.5,1.3l-8.4-3.2l-1.7,0.7l-3.2,3
      l1.2,1.4l0.8,4.2l3,6.3v1.9h0.7l0.2-1.6l0.6-1.3l1.9-0.1l2.1,5.1l-0.2,1.6l-1.9,0.2l-1.4-0.9l-0.8-1.2l-0.4,0.9l0.1,1.8l0.8,2.2
      l0.1,1.8l1.8,3.4v1.4l8.9,21.1l0.2,1.8l1.5,3.4l1.4,1.8l3.1,6.6l1.7,3.8l4.5,5.6l-2.1,2.5l-3.9-3.9l2.5,6.1l-4,3.9l-0.2,4.2
      l-0.5,1.6l0.5,1.8l2.8,2.5l0.9,1.4v1.9l0.9,1l-0.1,1.9l-1.1,0.8l0.8,1.1l1.5,0.8l1.2,3.4l2.7,2.6l0.6,1.4l1.3,1.8l1.1,0.8l1,1.1
      l0.7,1.4l1,1l2.8,4.5l-2.7,0.5l-0.5,0.7v0.6l1.6,0.4l3.3-0.1l0.6,0.1l3.3,3.5l1.4,0.8l2.3,2.5l0.6,1.4l3.9,3.3L334.8,956"/>
  </g>
  <g title="屏東縣" id="pingtung">
    <path d="M335.1,1008.7l-3.5,6.2l1,0.9l1.2,0.4l2.7-0.8l0.5-1.1l3-3.2l2.9-1.8l0.4-1l-0.3-1.1l-1.1-0.5
      l-0.4-0.9l-0.9-0.7l-1.2,0.1l-2.1,1l-0.7,0.8l-0.9,0.4l-0.5,0.9L335.1,1008.7L335.1,1008.7L335.1,1008.7z"/>
    <path d="M496.7,1043.3l-3,1l-2,1.8l-3.3,1.3l-2.8-4.8l-3-0.8h-4l-1.3-2.8l-2.8-1l-2.3-1.5l-4.3,0.3
      l-0.5-2.3l0.8-3.3l1.8-1l-2.8-1l-1-2.5l-4.8-2.8l1.8-2.3l2.5-1.3l-0.3-7.6l-2.5-1.5l-1.3-2.5h-2.8l-3.8-4l-3.5-1.8l4.8-14.6l6.6-1
      l1.3-2.8l-0.8-3.3l-2.8-2l-2.5-5.3l-4-2.5l-0.3-3l-1-3l4.3-3.3v-3.8l-4-8.1l-0.5-4.3l-1.8-1l-1-3l0.5-3.5l2.8-5.1l-1.3-2.8
      l1.5-2.3l1-2.8l-0.3-3.5l1-2.5l2-2l0.8-3l1.8-2l0.3-1l4-5.6l0.3-3.5l1.8-2.3l0.3-2.5l3.3,0.5l3.5-1.8l6.1-1.3l3-6.3l11.1-0.5
      l2-2.3l0.3-3.5l1.8-2l0.5-9.9l2-2l-1-2.3v-3.8l-1-3.3l-2.5-1.3l-3.3-0.3l-2.8-2.5l-0.8-1.5l-1-8.3l-3.8-2.5l-2.3,0.8l-0.3-1.3
      l-2.5-1.3l-1-0.3h-3.3l-1.5,2.3l-5.1,2.5l-1.8,2.3l-2.8,1.5l-2.8-1.5L454,840l-8.6-5.3h-3.8l-3,0.8l-3.3,4.8l-3,0.8l-1,1h-2.8
      l-2.8-1.3v-3.8l-1.8-2l-3-1.3l-3,0.8l-2.8,2l-3.5,0.3l-6.6,4.5l-1.3,0.5l-4,3.3l-0.3,1.3l-2.5,3.3l-2.3,1.8l-3.5,0.3l-6.1-1.5
      l-2.8,1.3h-3.8l-1.5-2.3l-4.5-0.3l-1.8-0.8l-2.5,1.3l-1.5,9.8l-1.3,2.5l-0.5,3.3l-1.3,2.8l0.3,15.2l-0.8,1.3l0.3,4.5l-1.8,6.8
      l-1.8,2.3l-2,6.1l-0.3,18.2l3.5,4.3l-2.8,16.4l-1.3,2.8l-2.5,2.2l-0.2,1.8l-0.8,1.1l-0.1,3.8l-0.5,1.3l0.8,1.8l0.1,9.1l2.9,0.2
      l2,1.9l-0.1,1.8l-0.9,0.2l1.8,2.4l5,3.7l0.9,1.2l1.4,0.6l0.6,0.1l-0.2,0l0.2,0.4l3,1.3l1.2,1.2l1.5,1l2.9,1l1.1,1l1.5,0.8l1,0.9
      l1.7,0.8l1.1,1l9.6,5.2l1.1,1.4l1.7,0.5l10.8,8.7l0.9,1.3l2,5.4l-0.4,0.8l5.4,3.7l5.8,7.3l0.5,1.5v4.8l1.8,2.9l0.8,3.4l-0.1,1.8
      l-0.5,1.5l0.1,1.5l4,3.4l0.9,1.2l1.3,1l0.8,1.2l1,3.5l3.3,3.4l-0.9,1.8v1.9l0.4,1.8l1.2,1.2l1.6,4.9l0.1,5.6l1,3.3v1.9l0.7,1.2
      l0.5,2.2l1.2,1.1l0.8,1.2l0.3,1.8l1.4,1.4l0.2,3.6l0.8,1.2l-0.1,1.9l-0.8,1.2l-5.2,1.5l0.2,1.7l1.8,0.4l0.5,1.3l-0.1,0.6l0.4,1.6
      l0.6,1.3l-0.6,1.2l-0.9,1l-2.5,1.5l-0.2,1.6l1,1.2l-3.8,3.5v3.7l1.5,0.9v1.9l1.2,1.1l-0.4,1.5l3.9,3.4l0.4,1.4l1.3,1.3l0.8,1.2
      l0.5,2.5l-0.9,3l0.1,5.9l-1.1,2.7l0.9,1.1l1.8,0.2l0.9,3.3l1,1.7l3.9,0.4l1.5-2.4l1.1-0.9l0.2-1.7l-0.8-3.2l0.6-1.4l1.4-0.6l2-2.2
      l1.3-0.6h1.9l0.5,1.4l4.2,2.9l0.5,0.1l2.3-0.1l1.6,0.4l0.6,1.3l1.5,0.4l1.4,0.9l1.9,0.6l1.9,0.1l3.8,3.8l0.1,1.8l1.9,0.6l0.9,0.8
      l0.6,1.2l0.1,1.8l2.7,2.5h1.9l1.3-0.9l-0.2-1.7l-1-0.9l-0.4-1.6l0.1-1.8l-0.5-1.4l-4.9-7.5v-7.3l0.5-1.4l-0.6-1.4l0.5-3l1-0.4
      l0.1-1.7l3.4-0.5l1.7-0.6l0.6-1.2l1.5-0.4l1.3-1l0.5-1.5l0.1-1.8l0.9-1l1.8-0.1l0.4-3.4l3.7-2.4l0.4-1.4l-2-2.5l-0.1-9.3l-1.2-1.7
      v-1.9l2.7-4.5l1-1.1v-1.5l-1.5-0.6l-2-2.2l-0.6-1.6l-0.2-4.8l0.2-0.7l0.9-0.1l0.5-0.6l0.7-0.5l0.3-0.7l-0.9-1.4l0.1-3.7l-0.8-1.3
      l0.2-0.6l0.5-0.6l0.3-0.6l0.2-0.8l-0.2-0.8v-1.9l-0.3-1l-0.4-0.6l0.1-1.1l-0.6-0.8l-0.3-0.8l0.2-1.6l1.4-1.2l0.4-0.6l0.2-0.7
      l0.1-1.8l-0.2-0.8v-0.7l0.2-0.5l-0.9-1l0.8-3.3l1-1.1l0.5-1.4l-1-1.2l-0.2-0.9"/>
  </g>
  <g title="臺東縣" id="taitung">
    <polygon points="683.2,916.4 681.5,914.6 681.3,912.4 684.1,908.3 684.1,905.7 683.6,903.3 682,902.1 679.3,902.3
      677,901.2 671.8,902.1 669.8,901.9 669.8,904.6 672.2,906.2 672.9,908.7 672.9,911.4 674,912.6 676.5,913 679,916 681.5,916.7"/>
    <polygon points="709.1,1113.2 707.5,1111.6 705.6,1111 701.3,1107.3 700.6,1105.3 700.7,1102.8 703.2,1102.6
      703.1,1100.5 701.6,1099.1 701.3,1096.7 702.5,1095.3 701.5,1093.7 691.6,1093.9 689.7,1094.6 684.3,1094.6 682.7,1093.5
      681.1,1093.9 681.1,1095.7 683.1,1096.9 683.1,1104.8 684.1,1106.4 686.6,1107.3 691.6,1113.5 697,1113.5 699.1,1115
      699.7,1116.6 699.7,1116.6 701.1,1118.5 702.7,1120 705.4,1120 707.9,1120.5 709.1,1120.1 709.8,1118.2 711.1,1116.6
      711.5,1114.2"/>
    <polygon points="671.2,677.9 671.3,672.2 673.1,665.2 674.5,664.3 674.5,662.4 676.7,655.6 676.7,655.6 673.5,654.6
      671.2,652.8 668.2,652.1 664.4,652.1 661.7,653.1 657.4,656.6 650,669.7 649.8,670 650.3,674.5 652.3,676.8 655.6,678.6
      655.6,678.8 643.5,697.3 643,706.1 641.2,708.4 638.9,709.9 637.7,712.4 636.1,718.5 633.9,720.2 633.9,724 632.4,726.8
      632.1,729.6 629.1,735.4 624.8,738.9 623.8,741.7 624.5,745.2 624.5,756.6 623.5,759.4 613.7,760.9 611.1,762.7 609.1,762.4
      608.9,758.9 607.1,755.6 605.1,753.8 600.5,753.6 599.5,752.8 597.8,750.5 594.7,749.5 592.2,748 592.2,745.2 588.4,739.9
      588.4,735.6 587.2,732.9 585.4,730.6 580.3,727.3 575,729.8 574.5,731.1 573.5,731.1 570.7,729.8 569.5,727.3 565.9,723.3
      562.4,723 557.1,719.7 552.8,719.7 551.5,717.2 550.8,716.5 547,707.4 547.2,703.6 543.5,698.8 542.5,696.3 534.2,699.8
      531.3,702.7 523.5,704.8 519.5,704.8 514.5,711.3 507.7,717 504.2,718.4 501.3,722 502.4,722.7 502.7,724.5 501.3,728.4
      501.3,732.3 502.4,736.3 498.8,738 496.7,743 493.5,745.2 494.5,767.7 491,771.6 489.2,775.9 491.7,778.4 493.1,782.7
      486.7,787.3 486.7,791.3 486,793 476,805.9 474.9,810.2 474.9,815.5 476.7,819.5 478.1,820.9 477.7,825.9 480.6,834.5 486,834.8
      488.8,838.8 488.1,845.5 487.7,846.5 487.7,850.3 488.4,853.3 489.2,854.8 491.9,857.4 495.2,857.6 497.8,858.9 498.8,862.2
      498.8,866 499.8,868.2 497.8,870.2 497.2,880.1 495.5,882.1 495.2,885.7 493.2,887.9 482.1,888.4 479.1,894.7 473,896
      469.5,897.8 466.2,897.3 465.9,899.8 464.2,902.1 463.9,905.6 459.9,911.2 459.6,912.2 457.8,914.2 457.1,917.2 455.1,919.2
      454.1,921.8 454.3,925.3 453.3,928.1 451.8,930.4 453,933.1 450.3,938.2 449.8,941.7 450.8,944.7 452.5,945.8 453.1,950.1
      457.1,958.1 457.1,961.9 452.8,965.2 453.8,968.2 454.1,971.3 458.1,973.8 460.6,979.1 463.4,981.1 464.2,984.4 462.9,987.2
      456.3,988.2 451.5,1002.8 455.1,1004.6 458.9,1008.6 461.6,1008.6 462.9,1011.2 465.4,1012.7 465.7,1020.3 463.2,1021.5
      461.4,1023.8 466.2,1026.6 467.2,1029.1 470,1030.1 468.2,1031.1 467.4,1034.4 467.9,1036.7 472.2,1036.4 474.5,1037.9
      477.3,1038.9 478.6,1041.7 482.6,1041.7 485.6,1042.5 488.4,1047.3 491.7,1046 493.7,1044.2 497.2,1043.1 497.1,1038.4
      496.2,1035.3 496.2,1027.5 495,1025.8 494.7,1020.4 495.3,1019 495.3,1015.5 498.2,1006.4 500.8,1003.8 501.3,998.8 502.1,997.5
      505.7,987.4 506.6,986.3 507.1,984.9 509,982.4 509.4,980.6 510.4,979.3 510.6,975.8 511.5,974.7 512,971.1 513.3,969.5
      513.1,967.4 515.3,963.2 515.3,959.4 516.2,958.5 516.2,956.6 519.1,949.8 519.1,947.3 520.3,946.5 520.3,944.6 521,943
      521.1,941.2 522,940 522.2,938.3 523,937.1 524.6,936.2 525.5,934.9 529.1,931.7 531,928.9 531.1,927.5 531.8,926.1 532,920.8
      532.2,920.2 532.1,918.5 532.8,917 533,915.2 533.9,914.1 534.1,912.4 535.2,911.1 536.1,908 537.8,905.4 538.9,904.3 543,902.6
      544.1,901.6 545.9,901.4 546.9,900.2 547.2,898.6 549.9,897.4 550.9,896.4 553.3,895 554.2,893.9 556,893.7 557,892.7
      558.9,892.5 560.2,891.8 561.2,891 562.8,890.6 565.4,889.1 569.2,884.5 570.7,883.9 571.9,883.1 572.6,882 575.1,880
      575.9,878.7 577,877.7 579.8,876.6 582,874.6 583.2,866.6 584.6,865.2 586.3,865 588.4,863.1 588.9,861.4 587.8,858.7
      588.1,856.9 587.1,855.7 585.9,855.1 585.2,853.8 586,852.6 587.9,852.6 587.5,851.3 585.7,849.3 585.1,848 585.4,846.4
      586.1,845.2 591.9,840.5 595.3,840 596.7,839.4 599.9,840.2 600,837.2 602.4,834.7 603.9,834.3 605.1,833.3 605.7,830.1
      607.8,825.7 610,823.7 613.4,816.2 613.6,813.1 614.3,812.8 615.2,811.3 616.3,810.3 618.1,810.2 618.6,808.8 621.9,805.3
      621.9,803.5 622.4,801.9 623.1,801.1 624.4,796 625.4,794.4 626.4,791.5 627.4,790.3 628.5,789.6 628.8,787.9 629.9,786.3
      630.8,780.6 631.8,779.2 633.2,774.3 635.2,771.4 635.2,769.5 636,768.3 636,766.3 637.4,765.6 638.3,764.4 642,764.3
      643.3,763.7 645.5,759.5 646.7,758.5 650.4,758.4 651.3,755.5 648.2,752.3 648.1,746.9 649.5,745.5 649.8,741.9 649.4,740.1
      647.5,736.3 647.2,728.4 650.1,726.3 650,724.9 649,723.9 650.1,723.4 651,724.8 652.4,725.1 653.9,723.9 654.7,722.7
      654.2,721.3 653.3,720.1 655.7,710.4 656.7,709.2 657.1,705.1 658.2,704 658.3,702.3 659,700.9 660.3,699.7 661.1,698.4
      662.3,695.3 663.5,694.3 665,693.9 666.9,693.9 668.4,692.9 669.6,687.3 669.4,681.8"/>
  </g>
  <g title="花蓮縣" id="hualien">
    <path d="M676.8,655.5l-3.4-0.9l-2.3-1.8l-3-0.8h-3.8l-2.8,1l-4.3,3.5l-7.3,13.1l-0.3,0.3l0.5,4.5l2,2.3
      l3.3,1.8v0.3l-12.1,18.4l-0.5,8.8l-1.8,2.3l-2.3,1.5l-1.3,2.5l-1.5,6.1l-2.3,1.8v3.8l-1.5,2.8l-0.3,2.8l-3,5.8l-4.3,3.5l-1,2.8
      l0.8,3.5v11.4l-1,2.8l-9.8,1.5l-2.5,1.8l-2-0.3l-0.3-3.5l-1.8-3.3l-2-1.8l-4.5-0.3l-1-0.8l-1.8-2.3l-3-1l-2.5-1.5v-2.8l-3.8-5.3
      v-4.3l-1.3-2.8l-1.8-2.3l-5.1-3.3l-5.3,2.5l-0.5,1.3h-1l-2.8-1.3l-1.3-2.5l-3.5-4l-3.5-0.3l-5.3-3.3h-4.3l-1.3-2.5l-0.8-0.8
      l-3.8-9.1l0.3-3.8l-3.8-4.8l-0.8-2.5l0.8-3l-0.3-3.5l-15.4-10.6l-3-0.8l1-3.5l1.8-2.5l3.3-1l-0.5-2.3l1.5-2.3l3.3-0.5l1.5-2.3v-7.1
      l-2.3-1.5l-0.5-1.6l1.3-1.1l3-0.8l3-2l3-0.8l0.8-3.5l-1-3l-2.3-1.8l1.8-2l0.8-3.3l2-2.3l2.3-1.5l0.8-3l2.3-1.5l3.5,0.3l2.8-1.3h3.8
      l1.3-0.5l2.5,1.5l3,0.8l4.8-4v-7.6l0.8-3l2.3-1.5l-2.3-6.3l2.5-1.3l3.8,1l2.8-1.3l1.5-2.3l3.3-1.5l4.5-0.3l3.8-8.3l1.3-10.4
      l1.3-2.5v-3.8l2-5.6v-9.3l-2.5-2.3l-0.3-3.5l0.8-2.8l-8.1-5.8l0.3-3.5l3.3-0.8l3-2.3v-1.5l-0.8-3l0.5-3.5l2.3-1.5l1-3.3l0.3-3.8
      l1-3l2-2.5l0.3-3.8l2.3-2.3l3-0.8l-0.5-5.6l-1.8-3l4.3-3.8l-0.3-3l-1-3.3v-3.8l0.8-2v-7.6l-2-2.5l-1-0.3v-3.5l6.1-12.9l0.3-7.3
      l2.3-2.3l2.5-1.3l1.5-2.5l2.5-1.3l2-3.5l0.8-2.5l0.3-3.5l-1.5-2.3l-3.5-0.5l-1-2.8l-1.8-2l-3.8-1.3l-0.8-1l-0.3-6.3l2.8-6.6l3-0.8
      l2-1.8l2.8-1.3l3.8-3.8h3.8l1.5-2.3l-1.8-5.6l2-1.5l0.9-1.8l-0.2-2.5l3.8-1.6l1.1-1.6l2.7,0.2l1.4-1.2l0.5-2.3l-0.9-4.6l1.2-2
      l2.3-0.9l1.6-1.1l1.8-4.1l1.4-1.6h2.7l1.8-1.2l1.4-1.8l2.1-4.3l0.7-7.3l0.9-2l2.3-1.2l1.3-1.5l12,7.7h8.1l3,1.3l4,0.5l8.1,5.6
      l8.3,1l3-0.8l-3-2.5l0.8-3l3.3-0.8l2.3-1.5l2.3,1l2-1.8l-0.8-1.5l-2-1.8l0.3-3.3l2-0.8l4.3,3.8l4.8,1.5l4.5,3.5l9.1,3.8l7.3,0.5
      l8.1,4l4.2,0.5l-0.1,1.3l-0.6,1.2l-1.5,0.7l-0.9,1.5l-2.2,2l-2.7,3.9l-0.1,5.6l-1.2,0.9l-1.1,2.7l-1.5,0.6l-0.8,1.9l-0.2,0.1
      l-3.5,0.4l-3,5.4l-1.2,1l-1.7,0.2l-3.3,2.9l-1.5,0.4l-0.9,1.1l-1.2,0.8l-3.9,5.3l-1,3.3v5.7l2.4,4.3l-0.8,0.8l-0.5,5.2l-0.2,0.5
      l-0.4-0.2l-1.4,2.4l-1.4,0.5l-3.2,3.7l-1.5,0.8l-4.3,4.7l-0.6,1.4l0.1,11.9l0.6,1.2l0.5,3.3l7.2,7.5v1.9l-1,3.4l-1.4-0.4l-0.4,1.7
      l0.7,1.2l-1.5,0.5l-1.1,2.5h1.6l-0.8,3l-1.9,0.1l-0.8-1.1H714l-1,0.9l-0.6,1.7l-1.1,1.1l-1,3l1.4,3l0.1,5.6l-1,3.4l-0.1,5.3
      l-0.5,1.7L710,508l-0.6,1.2l-0.4,3.4l-1.9,4.3v1.9l-0.6,1.4l-0.2,1.6l-1.9,3.9l-0.3,1.8l-3.3,4.8l-0.6,2.1V545l0.5,1.4l-1.4,6.4
      l-3.5,5.4l-1.7,1.1v1.8l-0.6,1.4l-0.1,1.8l0.9,0.6l0.8,2.5l-0.9,1.1l-0.2,1.7l-0.6,1.2l-0.9,6.9l-0.9,1l0.1,1.2l0.8,1.2l-1,1.1
      l-1.5,0.6l-0.5,3.4l-1.3,1.5l-0.1,12.8l-0.9,1.8l-0.1,3.9l-0.9,3l-1.4,1.2l-0.4,1.7l-1.6,2.4l-0.2,1.9l-1.2,3.3l-0.1,3.7l-0.9,1.4
      l-0.2,2.1l0.5,0.4l0.6,1.7v3.8l1.7,2.5l-0.1,3.7l-0.5,1.5l-1,1.1l-1.5,0.6l-1.8,0.1l0.4,0.5l1.7,1l-0.8,1.5v1.9l-0.9,3.3v1.9
      l-0.6,1.5L676.8,655.5L676.8,655.5L676.8,655.5z"/>
  </g>
  <g title="宜蘭縣" id="yilan">
    <path d="M808,191.5l1.5,0.9l1.3-0.1l0.3-1.6l1.8-0.1l1.8,0.3l0.5,1.6l1.6,1l-0.6,1l-1.6,0.6l-3.7-0.1
      l-2-1.9L808,191.5L808,191.5L808,191.5z"/>
    <path d="M671.3,248.9l-0.5,1.2l-1.6,0.5l-3.5,0.3l-1.3,0.8l-1.8,2.4L662,259l-2.3,3l-0.4,1.5l-0.6,1.3
      l-0.4,1.5l-3.4,5.3l-0.3,1.6h-1.8l-1.4,0.6l-1.1,0.9l-0.4,0.7l-0.9,0.9l0.1,0.6l1.1,0.8l0.6,1.4l0.1,1.8l0.5,1.4l-1.3,1.1
      l-0.5,0.3l-0.5,0.8l-1.3,0.8l-1.4,1.5l-0.4,1.6l0.6,1.3l0.1,7.3l-0.8,1.8l-1.1,1.4l-1.4,1l-1.6,2.5l-1.8,1.4l-0.5,1.3l-1.4-0.1
      l-3.4,4.9l-1.3,0.8l-1.9-0.4l-1.4,2.8l-1.4,0.8L628,318l-0.9,1.1l-1.5,1.3l-1.3,2.5l0.1,1.6l-0.7,1l1.4,1.7l0.9,1.8l0.5,2.1
      l-0.9,7.1l2.7,2.7l2,0.7h4.8l4.1,7l1.8,0.9l2.7-0.4l1.2-1.6l1.6-1.2l2.3-0.4l1.8-0.9l7.1-0.9l2.1,1.1l1.1,1.8l1.6,1.1l1.3,2.1
      l12,7.7h8.1l3,1.3l4,0.5l8.1,5.6l8.3,1l3-0.8l-3-2.5l0.8-3l3.3-0.8l2.3-1.5l2.3,1l2-1.8l-0.8-1.5l-2-1.8l0.3-3.3l2-0.8l4.3,3.8
      l4.8,1.5l4.5,3.5l9.1,3.8l7.3,0.5l8.1,4l4.1,0.5l-0.2-4l-0.8-1.2l0.1-3.7l2.5-4.8l0.4-1.5l1.5-2.5l0.5-10.9l0.5-1.6l0.9-1.2v-2.1
      l1-3.5l1.8-2.4l0.5-1.8l2.7-1.6l1-1.2l1.3-2.6l0.1-1.8l4.5-4.3l4.6-1.9h1.9l0.5-0.2l-1.4-0.8l-1-1l-0.8-1.2v-1.9l-2.5-1.5
      l-0.5-4.9l1.8-1.1l0.9-1.3l3.3-0.6l2.8-1.8l1.7-0.4l0.5-1.7l-0.1-1.5l-0.6-1.8l-0.1-1.8l-0.8-1.1l-0.3-3.5l0.8-1.1l-0.9-1.4
      l0.7-1.5h1.9l-0.2-1.4l-1-0.9l-0.2-0.5l1.5-0.7l-1.2,0.3l-1.8-0.7l-0.1-1.2l-2.4-2.2l0.4-1.6l1.8-0.1l1.5,0.9l0.2,1.8l1.8-0.1
      l1.5-0.8l-3.7-3.7l-3-1l-0.4-0.8l-0.5-0.2l-1.6-2.2l-0.1-1.3L779,255l-0.1-3.8l-0.8-1.1l0.4-0.6v-2l-0.5-1.7l0.5-10.2l-2.9-3.2
      l-0.9-1.5v-1.7l-1.4-1.6l-0.6-3.4l0.1-1.8l-1-1l0.5-14l0.7-2.2v-1.9l1.2-2.8l-0.2-3.7l1-3l0.1-1.8l2.4-5.2l0.2-1.8l1.3-2.2
      l1.5-0.8l1-2.9l0.9-1.1l1.4-0.5l3.8-4.4l1.3-0.6l3.2-3.7l0.2-3.5l0.5-1.5l2.8-1.6l1.5-0.4l1.5-2.2l1.2-0.9l1.2-0.6l2.5-3.5
      l0.3-1.9l1.8-1l1.8-0.2l1.2-0.5l1.1-1.1l1.7-0.2l1.2-0.8l1.9-0.1l2.7-1.2l-3.6-0.7l-2.5,0.2l-6.2,2.3l-5.4,0.4l-6.8,4.8l-1.4,1.6
      h-2.9l-2.3-0.9l-5.2-0.4l-1.8,1.4l-0.5,2.1l-0.9,1.8l4.1,5.9l-1.4,4.3l-5.9,3.9l-5.4-0.4l-2.3,0.4l-3,2.5l-2.5,3.2l0.5,2.3
      l-0.2,2.1l-1.4,1.8l-0.2,2.1l0.2,1.2l-2.5,0.5l-1.8,0.9l-7.5-1.1l-2.3,0.4l-2,1.1l-2.7,3.2l-4.8,2.6l-0.5,1.7l-1.8,1.6l-1.1,1.8
      l-2.1,0.7l-0.7,2l-1.6-1.8l-3.6,1.8l-10.5,1.2l1.4,1.2l-0.5,2.1l-2.3,1.8l-2.5-0.7l-1.8,1.1l-2.1,0.5l-0.9,2l0.2,2.5l-0.4,2.3
      l-2,1.6l0.4,2.5l1.4,1.4l0.7,2.1v2.7l-1.4,4.3l-2.3,0.4l-1.6,1.2l-1.1,1.6l-2.1,0.7l-2.7,0.2l-5.9,3.2l-0.5,2.5l-5.2,3.4l-4.6,0.7
      l-2.3,1.4L671.3,248.9L671.3,248.9L671.3,248.9z"/>
  </g>
  `,
  pathData: [
    {
      title: '澎湖',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <polygon fill="#00000000" stroke-width="0" points="10,500 10,750 200,750 200,500"/>
      <path d="M115.9,584.2l0.7-2.5l2-0.7l-0.9,1.4l-0.5,2.3L115.9,584.2
        C115.9,584.2,115.9,584.2,115.9,584.2z"/>
      <path d="M124.7,577.3l2.3-0.4l-0.5,1.8L124.7,577.3C124.7,577.3,124.7,577.3,124.7,577.3z"/>
      <path d="M82.2,624.9l-0.2,2.5l2.1-0.9L82.2,624.9L82.2,624.9z"/>
      <path d="M85.9,631.2l-4.3,2.3l-1.6-0.7l0.4,2.3l5.4-0.4l4.1-2.5l-0.4-1.2l-2.5-0.4L85.9,631.2
        L85.9,631.2z"/>
      <path d="M79,585.8l-0.6,1.2l-0.2,0.8l0.8,0.9l-1.5,1.4l0.4,1.1l1.1,0.5l0.8,0.7l0.4,0.8l-0.9,0.4
        l-0.7,2.1l0.4,1.2l-1.2,0.4h-1.3l-1.1,0.7l0.4,1l1.4,0.3l1.8,2.3v1.3l-0.5,1.1l-1,0.7l-0.7,0.9l-1,0.7l-1,0.3l-1.8-1.6l-1.2,0.4
        l-0.9,0.7l-0.6,1l-1.5,1.3l-0.6,0.9l0.9,0.5h1.3l0.6-0.8h4l0.7-0.6l0.9-0.4l2.6,0.4l1.2,0.5h1.3l1.2-0.5l-0.4-1.2l-0.7-0.9l-0.4-1
        l-0.1-1.2l1.5-1.4l-1.2-2.9l3.7-3.3l-0.4-0.9l-1-0.7l-0.2-1.2l0.6-2.1l-0.5-1.2l-0.6-0.8l-0.3-1.1l0.4-1l1-0.6h2.7l2.3-0.6l1-0.6
        l0.2-1.3l-0.7-0.6l-1.1,1l-1.1,0.3l-1.2-0.6l-2.1-2.4l0.3-1.2l-0.4-1l-1.1,0.1l-0.8,0.7l-2.2,4.1L79,585.8L79,585.8L79,585.8z"/>
      <path d="M93.8,575.4l-1,1l0.1,1.2l3,2.1l0.6,1.1l0.6-0.6l0.9-2.1l0.9-0.6l2.8,0.1l0.5,1.2v1.3l0.7,0.8
        l0.4,1l2.2,2.3l0.4,1.2l0.9,0.2l0.8-1l0.2-1.3l2.2-1.2l1.3-0.2l-0.1-1.2l-1.2-1.2l-0.5-0.8l0.8-1.4l-1.4-0.1l-1.7-3.1l-0.7-0.2
        l-0.8,0.5l-2.1-0.6l-0.3-1.1l-1.3-0.2l-1.2,0.5l-0.5,1.1l-1.3,0.3l-2.3-0.3l-1.1-0.5l-0.3-0.6L93.8,575.4L93.8,575.4L93.8,575.4z"/>
      <path id="path2478" d="M106,586.8l-1.4,1.9l1.8,1.7h1.3l1-0.6l0.1-1.2l1.7-2.1l-0.8-0.5h-1.3l-1.2,0.3L106,586.8
        L106,586.8z"/>
      <path d="M109.3,590.8l-1.2,0.5l-0.4,1l0.4,1.1l1.1,0.5h1.3l1.2-0.3l0.4-1.2l-0.6-0.9l-1.2-0.6
        L109.3,590.8C109.3,590.8,109.3,590.8,109.3,590.8z"/>
      <path id="path2482" d="M102.3,599.6l-2.9,2.5l-1.2,0.4h-1.3l0.6,1.2v1.3l-0.3,1.2l-1.8,1.5l-0.2,1.3l1,0.5l2.4-1.1
        l1-0.7l1.4,0.1l0.9,0.6l0.8,0.9l-2.7,2.9l-1.3,0.2L96,612l1.5,1.7l2.1,0.9l1.6-0.2l1.2-0.7l4,0.1l0.8,1.1l0.4,1.2l-1.3,2.1
        l-0.2,0.7l1.2,0.8l-0.1,1l-1.1,1.1l-1.2-0.1l-0.8-0.7l-1.1-0.5l-1.3,0.2l-1.6,3.4l-1.5,1.6l0.6,1.1l2.6,0.3l1.4-0.2l1.8-0.6
        l1.5-0.1l1.6,1.8l1.2-0.3l0.2-1.2l1.1-1l0.1-1.2l0.4-1.2l-1.1-0.6l-0.2-1l0.9-1l0.6-1.2l4.5-3.5l1.2-0.2l2.2-2.8l0.4-1.1l1-0.8
        l6.2-0.2l1.9-0.9l0.8,0.6l0.3,1.3l0.6,1.2l1.1,0.7l1.2-0.3l1-0.5h1.4l0.5-0.8l-3.1-3.3l-0.8-2.9l0.4-1.1v-1.3l-0.5-1.2l-2.4-2.6
        l-0.6-2.1l-0.7,0.8l-0.4,2.5l-1.2-0.2l-0.6-1.2l-0.8-0.9l0.4-0.8l0.7-0.5l-1.7-1.6l-1.2-0.4l-0.8,0.8l-1.7,3.1l-1-0.2l-0.9,0.3
        l-1.5,1.5l-0.3,1.1l-0.7,0.2l-0.1-4l1-2.1l-1.5,0.2l-0.8,0.5l-5.2,0.9l-0.8,0.6l0.1,1.2l0.7,1v1.3l-0.5,0.9l-1.2,0.4l-0.8,1v2.8
        l-0.7,0.8l-1.3-0.6l-1-0.9l0.2-1.8l-1.4-0.2l-0.2-1.2l0.7-1.2l0.1-0.8L102.3,599.6L102.3,599.6L102.3,599.6z"/>
      <path d="M92,612.4l-0.5,1.3l-1,0.4l-0.4,1.2l0.1,2.6l1.5,0.7l0.4,0.7l0.9,0.8l0.4-0.9l-0.7-0.9l1.2-1.1
        l-0.7-0.8l-0.3-2.9l-0.9-0.7"/>
      <path d="M94.3,620.5v1.4l4.4,0.1l0.5-0.8l-0.7-0.9l-1.1-0.8l-0.5-0.8l-0.3,1L94.3,620.5L94.3,620.5
        L94.3,620.5z"/>
      <path d="M109.4,549l-1.9,3.2l0.4,1.6l1.3,0.5l1.1-1.8l1.3-1l1.6,0.3l1.3-0.6l1.1-1.1l-4.2-2.9l-1,0.1
        L109.4,549L109.4,549L109.4,549z"/>
      <path d="M22.3,658.5l-1.4,3.2l1.4,0.9l1.6-0.4l1.4-0.9V660l-2.3-1.8L22.3,658.5
        C22.3,658.5,22.3,658.5,22.3,658.5z"/>
      <path d="M73.8,663.5l0.9,1.9v8.7l-0.4,1.6l-1.5,0.6l0.9,1.3l5.6,0.1l0.4-0.9l-0.6-1.4l0.6-1.3l1.6-0.5
        l1.3,1.4l1.3,0.9l1.5-0.5l0.9-1h1.9l1.1-0.8l-0.5-1.6l-0.8-1.3l-1.6,0.6l-2,2.3l-4.5-2.7l-0.5-1.4v-3.8l-1,0.3l-1.9-0.1
        L73.8,663.5L73.8,663.5L73.8,663.5z"/>
      <path d="M83.8,703.9l0.3,1.9l1.6,0.1l-1-1.4L83.8,703.9C83.8,703.9,83.8,703.9,83.8,703.9z"/>
      <path d="M87.3,706.7l-0.5,2l-1,1.4l1.6-0.5l1.8-2.3L87.3,706.7L87.3,706.7z"/>
      <path d="M126.9,708.2l-0.9,1.9l0.8,1.1v1.4l1.1,1.1l0.8-0.1v-1.9l1.3-1.4l0.6-1.3l-1-0.9l-1.9-0.1
        L126.9,708.2L126.9,708.2L126.9,708.2z"/>
      <path d="M55.3,721l-3.2,1.1l-0.4,1.5l0.4,1.8l1.1,0.9v1.9l1.8,2.4l0.6,0.3l0.1-1.8l1.5-1l1.5-0.4
        l0.4-3.7l1.3-1.1l-2.5-2L55.3,721L55.3,721L55.3,721z"/>
      `
    },
    {
      title: '基隆市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M768.4,99.7l0.1-1.2l-1.1-1.1l0.5-3.4l-1.8,0.1l-2.4,1.5l-2.2-2l-1.9-0.2l-0.9-0.6v-2.1l-1.6-0.4
        l-0.8,1.4l-1.5,0.4l-0.4-1.5h-1.3l0.5,1.5l1.4,2l-1.8,2.2l-2.8,2l-1.2,0.1l2.3-2.2l-0.8-1.4v-3.2l-1.5-0.1l-1.4-0.8l-1.8-0.2l-2-2
        h-3.9l-1.1-0.8l0.3-1.4l-0.4-0.9l-1.8,1.2l-2,0.7l-1.1,1.6l-2.3,0.5l-1.2,1.6l-4.1,1.8l-2.5,0.2l-2,0.7l-1.8,1.2l-4.8,0.5v2.7
        l1.1,2l2.3,0.7l1.4,1.6l0.2,2.5l3,6.8l2.3,1.6l1.6,2.1l2.3,0.5l1.4-1.2v2l1.6,1.8l7,1.8l5.4,4.6l5.2,0.4l4.6-2.3l2.5-0.2l1.1-1.8
        l0.7-2.1l-4.6-4.5v-4.8l-0.5-2.1l2.3-0.5l2.9-3l2.5,0.5l1.8,1.2l0.9,0.2l1.8-2.9l1.4-1.4L768.4,99.7L768.4,99.7L768.4,99.7z"/>
      `
    },
    {
      title: '新北市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M817.3,147.5l-3.7-0.7l-2.5,0.2l-6.2,2.3l-5.4,0.4l-6.8,4.8l-1.4,1.6h-2.9l-2.3-0.9l-5.2-0.4
        l-1.8,1.4l-0.5,2.1l-0.9,1.8l4.1,5.9l-1.4,4.3l-5.9,3.9l-5.4-0.4l-2.3,0.4l-3,2.5l-2.5,3.2l0.5,2.3l-0.2,2.1l-1.4,1.8l-0.2,2.1
        l0.2,1.2l-2.5,0.5l-1.8,0.9l-7.5-1.1l-2.3,0.4l-2,1.1l-2.7,3.2l-4.8,2.6l-0.5,1.7l-1.8,1.6l-1.1,1.8l-2.1,0.7l-0.7,2l-1.6-1.8
        l-3.6,1.8l-10.5,1.2l1.4,1.2l-0.5,2.1l-2.3,1.8l-2.5-0.7l-1.8,1.1l-2.1,0.5l-0.9,2l0.2,2.5l-0.4,2.3l-2,1.6l0.4,2.5l1.4,1.4
        l0.7,2.1v2.7l-1.4,4.3l-2.3,0.4l-1.6,1.2l-1.1,1.6l-2.1,0.7l-2.7,0.2l-5.9,3.2l-0.5,2.5l-5.2,3.4l-4.6,0.7l-2.3,1.4l-4.8-0.3
        l-1.5-0.6l-0.5-5l-2-1.4l-1.4-2l-2.5-0.7l-0.2-1.8l-2.5-1.1l0.5-2l-3.2-3l-2-5v-1.1l-0.9-1.8l-0.4-2.3l1.1-2l3.2-2.7l1.1-1.6
        l1.6-1.1v-2.7l-2.3-0.7l-0.9-2.5l-0.2-2.5l-1.6-1.8l-1.6-0.9l-2.9-2.9l-0.7-2l-1.4-1.4l-0.4-0.9l-1.2-0.7l-5.4,0.4l-2,1.1l-2.7,0.2
        l-2.9-0.7l-1.8-1.1l-1.6-1.6l-1.6,1.2l-2.5-0.4l0.2-2.1l4.6-0.9l1.6-1.2l-2.5-1.6l-0.2-0.2l-0.4-2.1l1.2-1.6l-3.4-5.2l-4.5-2.7
        l-0.9-4.3l0.9-2l1.2-1.4l2.1-1.4l-1.6-1.2l-2.1-0.2l-1.2-2.3l0.9-2l1.4-1.4l0.5-5l-1.1-2l0.5-2.1l1.8-0.9l11.2,0.4l2.9-2l2-3.6
        l2.1-0.7l1.6-1.1l-0.2-2.1l-2.1-1.6l2-0.7l2-1.2l0.9-1.6l-2.3-1.6l-1.2-1.4l-0.4-2.3l0.9-1.8v-0.7l-0.5-2.3l-1.8-2.1l-1.2-0.9h-5.4
        l-5.2-1.2l-2-1.8l0.5-3.6l-9.3-4.5l-5-0.5l-1.2-1.6l-1.2-0.4l-1.8-2.5l1-1.4l2.4-2.3l12-0.1l1.3-0.9l5.2-1.6l4.2-2.8l1.5-0.4
        l2.8-1.7l1.6-0.5l3.7-3.5l0.6-2.4l1.1-1h1.9l5.8,3.8l2.2,2.3l0.7,1.8l0.1,1.8l2,2.7l0.2,1.6l0.8,1.7l0.2,1.6l0.9-0.8l-0.2-7.6
        l-2.2-4.4l-1-1.2l-1.4-1l-1.5-2.6l-3.5-1.7l-1.9-0.1l-3.4-3.2l-0.2-1.1l1.1,0.1l1.9-0.5l2-3.9l0.2-1.9l2.1-2.2l1.3-0.8l0.9-1.3
        l0.2-1.9l1.3-2.5l0.4-1.5l1-1.1l0.1-1.8l1.2-1l3.7,0.5l0.6-0.1l3.5-7.4l1.7-0.5l1.4-1l3.4-0.9l1.4-1.1l0.2-1.8l0.6-0.6l0.6,1.6
        L686,49l1.1-0.8v-1.9l1.1-0.8l1.9,2h6.1l0.5-1.5l1-1.1l1.3-0.2l0.4,0.1l1.2,0.8l1,1.1l1.4,0.9h1.9l1.4,0.8l1.8,2.3l3.2,1l3.7,5.2
        l1.1,1l0.7,1.2l0.4,1.8l-0.5,3.8l2,2l1.4,0.6h2l0.6,1.2l-1.2,1.3l0.2,1.2l4.9,5.1l6.3-0.4l4.5-3.9l0.4,1.1l-1.2,1.1l-1.4,0.8
        l-1,1.1l1.1,1l0.3,0.8l-2.9,1.4l-0.7,1.5l0.5,1.3l1.6,1.1l1.5,0.5l2.9,2.1l-1.5,1.1l-2,0.7l-1.1,1.6l-2.3,0.5l-1.2,1.6l-4.1,1.8
        l-2.5,0.2l-2,0.7l-1.8,1.2l-4.8,0.5v2.7l1.1,2l2.3,0.7l1.4,1.6l0.2,2.5l3,6.8l2.3,1.6l1.6,2.1l2.3,0.5l1.4-1.2v2l1.6,1.8l7,1.8
        l5.4,4.6l5.2,0.4l4.6-2.3l2.5-0.2l1.1-1.8l0.7-2.1l-4.6-4.5v-4.8l-0.5-2.1l2.3-0.5l2.9-3l2.5,0.5l1.8,1.2l0.9,0.2l1.8-2.9l1.4-1.4
        l0.9-2.1l0-1.2l1.8,0l1.6-0.6h1.9l-0.6,0.6l-0.8,1.3l-0.4,1.6l1,1.2h1.6l1.3-1.2l1.4-0.8l2.9,0.9l4.4,0.1l1.4,0.6l1.5-0.6l5.9,1.3
        h1.9l3.4-1.2l1-1l1.7-0.4l1.8,0.1v1.9l-1.2,0.8l-0.8,1.2l0.6,0.9l1,0.9l0.6,1.2L803,109l-1.5,1l-1.5,4.5l1.3,3l4.2,4.2l0.2,0.6
        l-1.4,1l0.6,0.8v5.7l0.4,1.4l4.9,4l4,0.4l1.7-1.1l0.8-0.8l1.8-0.1l1.5,0.3l0.9,1l1.4,0.5l1.2,2.2l1.1-1l1.4-0.1l1.9,2l-0.1,1.5
        l-0.7,1.4l-1.9,0.2l-0.8,1.3l-2.8,1.3l-0.8,1.1l-1.8,0.1l-0.8,1.5L817.3,147.5L817.3,147.5L817.3,147.5z"/>
      `
    },
    {
      title: '臺北市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path fill="#fff" d="M671.6,94.5l-1.2,2.7l-1.2,1.2l-0.4,0.7l-1.2,1.4v2.7l-1.8,1.1l-0.4,2.3l0.7,2.3l1.8,1.2
        l5.7,0.7l3.6,3.4l1.4,2.7l0.2,8.2l-2.7,3.7l-1.8,1.6l-0.9,2l0.5,5.2l1.1,2.1l2.1-0.2l2-1.1l1.2-1.8l1.2-0.2l2.9,1.2l1.6,1.2l0.7,2
        v2.7l1.2,2.1l2.3,1.6l2.5,0.2l1.1,4.3l3.8,2.5l10,1.1l2.1-0.9h2.9l0.2-1.8l-4.8-2.3l-0.4-2.5l0.2-2.1l-1.2-4.1v-2.3l1.2-2l1.8-1.1
        l5,0.4l1.6-1.1l2.1-0.5l6.8,0.4l0.5-1.4l-1.6-0.7l-2.3,0.7l-3.9-1.8l-4.8-3.6l-0.9-2l1.2-1.6l-0.4-2l-1.4-1.4l1.1-1.2l2.3-4.8
        l-0.5-4.6l-7.7-6.6l0.5-2.3l1.4-1.6l-0.5-1.8l-2.9-2.5l-1.1-1.6l-0.2-5.2l-2.9-2.9V84l0.7-2v-2.7l-4.6,0.4l-2-3.6l-1.4-1.6
        l-2.5,3.9l-2,0.9l-0.9,2l-3.8,2.1l-0.9,2.3l-1.6,1.1l-2.5,0.2l-2,0.9l-3,3.6l-0.9,2.3L671.6,94.5L671.6,94.5L671.6,94.5z"/>
      <path  d="M671.6,94.5l-1.2,2.7l-1.2,1.2l-0.4,0.7l-1.2,1.4v2.7l-1.8,1.1l-0.4,2.3l0.7,2.3l1.8,1.2
        l5.7,0.7l3.6,3.4l1.4,2.7l0.2,8.2l-2.7,3.7l-1.8,1.6l-0.9,2l0.5,5.2l1.1,2.1l2.1-0.2l2-1.1l1.2-1.8l1.2-0.2l2.9,1.2l1.6,1.2l0.7,2
        v2.7l1.2,2.1l2.3,1.6l2.5,0.2l1.1,4.3l3.8,2.5l10,1.1l2.1-0.9h2.9l0.2-1.8l-4.8-2.3l-0.4-2.5l0.2-2.1l-1.2-4.1v-2.3l1.2-2l1.8-1.1
        l5,0.4l1.6-1.1l2.1-0.5l6.8,0.4l0.5-1.4l-1.6-0.7l-2.3,0.7l-3.9-1.8l-4.8-3.6l-0.9-2l1.2-1.6l-0.4-2l-1.4-1.4l1.1-1.2l2.3-4.8
        l-0.5-4.6l-7.7-6.6l0.5-2.3l1.4-1.6l-0.5-1.8l-2.9-2.5l-1.1-1.6l-0.2-5.2l-2.9-2.9V84l0.7-2v-2.7l-4.6,0.4l-2-3.6l-1.4-1.6
        l-2.5,3.9l-2,0.9l-0.9,2l-3.8,2.1l-0.9,2.3l-1.6,1.1l-2.5,0.2l-2,0.9l-3,3.6l-0.9,2.3L671.6,94.5L671.6,94.5L671.6,94.5z"/>
      `
    },
    {
      title: '桃園市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M671.4,248.9l-0.5,1.2l-1.6,0.5l-3.5,0.3l-1.3,0.8l-1.8,2.4L662,259l-2.3,3l-0.4,1.5l-0.6,1.3
        l-0.4,1.5l-3.4,5.3l-0.3,1.6h-1.8l-1.4,0.6l-1.1,0.9l-1.5,1.9l-0.8,0.5l-1.8,0.4l-3.7-0.5l-1.5-0.9l-1.3-1.9H639l-0.8-1l-0.5-1.5
        l-1.4-0.5v-3.9l0.8-1.4l0.4-1.8l-1.5-0.8l-1.6-0.3l-1.3-0.8l-0.1-3.8l1-1l0.1-1.9l0.5-1.5l-0.3-1.9l-1.6-0.8h-1.9l-0.6-1.3
        l-0.3-1.8l-0.8-1.3l-1.1-0.9l-2.3-2.9l-2.9-1.4l-3,1l-1.4-0.5v-3.8l1.1-1.1l0.6-1.3v-3.8l-2.1-0.4l0.1-4.4l0.5-1.4l0.9-1.3l0.4-1.6
        l-1.3-1.3l-0.6-1.5l-1.8-0.4l-1.3-1.3l-1.4-0.5l-1.8-0.1l-0.5-0.8h-0.3l-1.6-0.9l-1-1.3l-1.5-0.9l-0.4-0.6l-0.1-1.1l-0.9-2.4
        l-1.5-0.6l-2.1-0.4v-1.9l-1.5-0.5l-3.9,1.8h-1.6l-5.7-4.5l-1.4-0.6l-1.1-1.1l-2-0.8v-1.4l-1.9-0.3l-1.8-0.6l-3.5-2.8l-0.6-0.9
        l-3-1.9l0.4-1.6l1.8-2.8l-0.5-5.6l-1.4-1l-11.7-1l-0.8-1.5l-0.3-1.9l1-0.9l-1.4-0.8l-1.3,0.9l-1.6,0.6l-1.9,0.1l-1.6-1.3l-2-0.6
        h-1.9l-1.3-1.4l-3-1.1l0.1-3.7l0.6-1.5l-1.1-3l-0.1-1.8l-1.8-0.1l-2.1-2.1l-3.7-0.3l-4.3,1.3l-2.3-1.5l-4.3,2.5l-3.1-0.8l0.5-0.8
        l1.2-1l3-4l0.6-1.4l1.3-1.3l1.2-0.8l1.8-1.9v-3.7l-0.9-1.2l0.1-1.8l3-3.8l1-2.9l1.1-0.9l2.4-3.5l4.7-4.1l3.9-1.8l1.6-0.2l1.2-1.2
        l1.5-0.5l2.5-1.6l1.2-1.4l1.7-1l1.8-0.5l2.8-2.2l1.5-0.6h2l12.1-3.2l1.5-1.3l3.5-1.6l3.7-3.7l1.7-1l2.1-0.1l8.3-3.4h1.9l1-1
        l3.5-0.8l1.5,0.5l2.5,2l2.4,0.1l0.8,1.9l1.2,1.4l1.2,0.4l1.2,1.6l5,0.5l9.3,4.5l-0.5,3.6l2,1.8l5.2,1.2h5.4l1.2,0.9l1.8,2.1
        l0.5,2.3v0.7l-0.9,1.8l0.4,2.3l1.2,1.4l2.3,1.6l-0.9,1.6l-2,1.2l-2,0.7l2.1,1.6l0.2,2.1l-1.6,1.1l-2.1,0.7l-2,3.6l-2.9,2l-11.2-0.4
        l-1.8,0.9l-0.5,2.1l1.1,2l-0.5,5l-1.4,1.4l-0.9,2l1.2,2.3l2.1,0.2l1.6,1.2l-2.1,1.4l-1.2,1.4l-0.9,2l0.9,4.3l4.5,2.7l3.4,5.2
        l-1.2,1.6l0.4,2.1l2.7,1.8l-1.6,1.2l-4.6,0.9l-0.2,2.1l2.5,0.4l1.6-1.2l1.6,1.6l1.8,1.1l2.9,0.7l2.7-0.2l2-1.1l5.4-0.4l1.2,0.7
        l0.4,0.9l1.4,1.4l0.7,2l2.9,2.9l1.6,0.9l1.6,1.8l0.2,2.5l0.9,2.5l2.3,0.7v2.7l-1.6,1.1l-1.1,1.6l-3.2,2.7l-1.1,2l0.4,2.3l0.9,1.8
        v1.1l2,5l3.2,3l-0.5,2l2.5,1.1l0.2,1.8l2.5,0.7l1.4,2l2,1.4l0.5,5L671.4,248.9L671.4,248.9L671.4,248.9z"/>
      `
    },
    {
      title: '新竹縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M623.5,325.5l-1.7,0.9l-0.6,1.4l-1.3,0.8l-5.8-0.1l-1.3,0.6l-5.7-0.1l-0.9-1l-0.1-2.1l0.3-1.3
        l-0.6-0.8V320l-0.9-1.3l-1.5-0.9l-1.1-1.3l-0.6-1.5l-2.1-2.4l-0.6-1.5l-1.5-0.8l-0.8-1.1l-1.6-0.9l-0.5-1.3l0.3-2.9l-1.1-1.5
        l-3.7-0.3l-1.5,0.5l-2.7,1.8h-4.3l-1.4,0.8h-1.9l-1.3-1.1l-7.6-0.4l-1.4,0.5l-1.5-0.4h-1.9l-2.1,1.9l-3.4,1.4l-3.4-0.8h-5.9v-2.1
        l-0.9-1.9v-2l0.8-1.5l2.1-2.3l0.5-1.5l-0.1-1.9l-1.5-2.9l-2.7-2.1l2-3.3l1.1-4.2l1.1-1.4l0.1-1.8l0.9-1.3l0.4-1.6l-1.4-0.8
        l-1.1-3.4l-1.9-0.5l-1.4-3.2l-0.9-0.4l-2,0.5l-1.5,0.9l-2.4-1.9l-0.9-1.5l-1.6-0.4l-1.3-0.9l-2-3.2l-1.8-0.3l-1.8-0.8l-0.1-1.8
        l-1.1-1.3l-1.8-0.3l-3.3-2.1l-0.9-1.5l-1.4-1.4l-1.6-2.9l-0.3-1.8l-2-0.8l0.4-1.3l0.9-1.4l0.3-1.6l1-1.3l-1.4-0.8l0.9-1.1l-3.5,0.3
        l-1.3,1l-1.5,0.6l-0.9-1.1H511l-1.5-0.8l-1-1.2l2.5-3.1l0.4-3.7l1.3-2.9l1.6-0.4l1-1.5l1.5-0.6l1.1-0.9l0.5-1.5l0.9-1.4l5.4-1.3
        l-0.5-1.4l1.1-1.8l1.5-0.5h1.9l7.1,7.2l1.5-1v-3.8l-3.3-11.1l-1.9-2.6l-3.4-2.9l-3.7-0.4l-0.1-0.6l-0.5-1l-1.4-0.9h-1.9l-7.7-1.9
        l-1.9-2.7l-0.5-1.4l-1.6-1.3l0.6-1.5l2-2.5l0.5-1.8l1-0.9l0.6-3.4l5.6-6.9l-0.8-1.5l-0.4-1.9l5.5-7.9l3.1,0.8l4.3-2.5l2.3,1.5
        l4.3-1.3l3.7,0.3l2.1,2.1l1.8,0.1l0.1,1.8l1.1,3l-0.6,1.5l-0.1,3.7l3,1.1l1.3,1.4h1.9l2,0.6l1.6,1.3l1.9-0.1l1.6-0.6l1.3-0.9
        l1.4,0.8l-1,0.9l0.3,1.9l0.8,1.5l11.7,1l1.4,1l0.5,5.6l-1.8,2.8l-0.4,1.6l3,1.9l0.6,0.9l3.5,2.8l1.8,0.6l1.9,0.3v1.4l2,0.8l8.2,6.3
        h1.6l3.9-1.8l1.5,0.5v1.9l2.1,0.4l1.5,0.6l0.9,2.4l0.1,1.1l0.4,0.6l1.5,0.9l1,1.3l1.6,0.9h0.3l0.5,0.8l1.8,0.1l1.4,0.5l1.3,1.3
        l1.8,0.4l0.6,1.5l1.3,1.3l-0.4,1.6l-0.9,1.3l-0.5,1.4l-0.1,4.4l2.1,0.4v3.8l-0.6,1.3l-1.1,1.1v3.8l1.4,0.5l3-1l2.9,1.4l2.3,2.9
        l1.1,0.9l0.8,1.3l0.3,1.8l0.6,1.3h1.9l1.6,0.8l0.3,1.9l-0.5,1.5l-0.1,1.9l-1,1l0.1,3.8l1.3,0.8l1.6,0.3l1.5,0.8l-0.4,1.8l-0.8,1.4
        v3.9l1.4,0.5l0.5,1.5l0.8,1h0.9l1.3,1.9l1.5,0.9l3.7,0.5l2.6-0.7l1.4,1l0.6,1.4l0.1,1.8l0.5,1.4l-1.3,1.1l-0.5,0.3l-0.5,0.8
        l-1.3,0.8l-1.4,1.5l-0.4,1.6l0.6,1.3l0.1,7.3l-0.8,1.8l-1.1,1.4l-1.4,1l-1.6,2.5l-1.8,1.4l-0.5,1.3l-1.4-0.1l-3.4,4.9l-1.3,0.8
        l-1.9-0.4l-1.4,2.8l-1.4,0.8L628,318l-0.9,1.1l-1.5,1.3l-1.3,2.5l-0.1,1.8L623.5,325.5"/>
      `
    },
    {
      title: '新竹市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M493.6,229l0.2,1.1l1.3,0.6h2l1.4-0.5l1.1-0.9l1.4,0.5l1.9,2.3l0.5,1.6v1.5l4.9,1.3l0.7-0.5
        l2-2.4l0.4-3.7l1.3-2.9l1.6-0.4l1-1.5l1.5-0.6l1.1-0.9l0.5-1.5l0.9-1.4l5.4-1.3l-0.5-1.4l1.1-1.8l1.5-0.5h1.9l7.1,7.2l1.5-1v-3.8
        l-3.3-11.1l-1.9-2.6l-3.4-2.9l-3.7-0.4l-0.1-0.6l-0.5-1l-1.4-0.9h-1.9l-7.7-1.9l-1.9-2.7l-0.5-1.4l-1.6-1.2l-1,0.8l-1.5,0.6l-5.1,7
        l-1.2,1.1l0.6,3l-0.2,1l0.5,0.5l1.9,0.1l0.2,1.6l-0.8,1.5l-0.4,3.5l-0.8,1.1l-0.1,3.9l-1.9,2.5l-0.5,1.5l1.4,1.1l-0.8,1l-0.6,1.8
        l-2.3,2.2l-1.8,1L493.6,229L493.6,229L493.6,229z"/>
      `
    },
    {
      title: '苗栗縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M509.5,237.9l1.5,0.8h2.9l0.9,1.1l1.5-0.6l1.3-1l3.5-0.3l-0.9,1.1l1.4,0.8l-1,1.3l-0.3,1.6
        l-0.9,1.4l-0.4,1.3l2,0.8l0.3,1.8l1.6,2.9l1.4,1.4l0.9,1.5l3.3,2.1l1.8,0.3l1.1,1.3l0.1,1.8l1.8,0.8l1.8,0.3l2,3.2l1.3,0.9l1.6,0.4
        l0.9,1.5l2.4,1.9l1.5-0.9l2-0.5l0.9,0.4l1.4,3.2l1.9,0.5l1.1,3.4l1.4,0.8l-0.4,1.6l-0.9,1.3l-0.1,1.8l-1.1,1.4l-1.1,4.2l-2,3.3
        l2.7,2.1l1.5,2.9l0.1,1.9l-0.5,1.5l-2.1,2.3l-0.8,1.5v2l0.9,1.9v2.1h5.9l3.4,0.8l3.4-1.4l2.1-1.9h1.9l1.5,0.4l1.4-0.5l7.6,0.4
        l1.3,1.1h1.9l1.4-0.8h4.3l2.7-1.8l1.5-0.5l3.7,0.3l1.1,1.5l-0.3,2.9l0.5,1.3l1.6,0.9l0.8,1.1l1.5,0.8l0.6,1.5l2.1,2.4l0.6,1.5
        l1.1,1.3l1.5,0.9l0.9,1.3v3.8l0.6,0.8l-0.3,1.3l0.1,3.9l-1.8,0.9l-2,1.6l-1.4,1.8l-0.2,2.5l-3.4,5.5v2.7l-2.1,0.5h-2.7l-1.8-2.5
        l0.9-1.4l-2.7-1.1l-2,0.9l-0.7,2.1l-1.2,1.8l-2.3,0.7l-4.8,6.8l-2,0.7h-5.4l-1.8,1.2l-0.7,2l-0.7,0.2l-2,2.1l-0.4,2.5l-2.1,0.5
        l-3.4,2.9l-2.1,0.5l-5-0.5l-4.5,1.6l-5.4,6.8l-2.7,0.2l-2-1.1l-3.2-3l-0.4-2.5l-1.8-1.2l-2.7-3.9l-1.6,0.4h-2.7l-2-0.9l-5-0.4
        l-6.1-2.1l-4.8-0.2l-2.1,0.9l-1.4,1.4v2.7l0.9,2l0.4,5l-0.9,2l-1.8,1.4l-2.1,0.7l-10.4-0.4l-2,0.9h-5.4l-5.2-1.6l-3.2-3l-1.1-2
        L472,367l-11.2-2.1l-3.4-2.9l-2.5-0.7l-1.4-2l-3.6-2l-1.4-1.6l-2.1-0.7l-1.2-1.6l-0.9-0.4l-3-0.4l-1.2-1.8l-3.2-2.1l-7.5-9.6h-0.4
        l-0.9-1.8l-4.5-1.8l-2.3-3.8l-0.4-2l-3.8-4.7l1.6-0.7l3.8-3.2l3-3.9l0.6-1.4l5.2-6.8l1.2-3.2l1.4-1.9l9.5-28.2l2.2-2.9l0.1-0.8
        l1.2-1.2l3.2-0.8l2.7,2.3l1.1,1.7l0.4-1.5l-0.4-2l2.7-1.6h1.4l1,0.2l-0.1-1.7l-1.7-0.8l-1.5-1.1l1-0.9l0.6-3.3l3-5.3l1.7-0.2l2-2.4
        l5.6-0.2l4.4-2h2.4l3.9,1.4l1,1l2.2,0.5l1.3-0.8l-0.8-1l-1.9-0.2l-1.5-0.7l-0.8-1.6l-1.8-0.2l0.1-1.8l1-1.1l1.5-0.8l5.5-4.7
        l0.5-1.6l0.9-1.2l0.4-1.5v-1.9l1.2-2.8l1.3-1.1l0.9-1.3l1.9-0.8l0.5,1l1.3,0.6h2l1.4-0.5l1.1-0.9l1.4,0.5l1.9,2.3l0.5,1.6v1.5
        l4,1.1L509.5,237.9L509.5,237.9L509.5,237.9z"/>
      `
    },
    {
      title: '南投縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M419.4,538.4l5.1,10.9l-0.5,7.1l-2.8-0.5l-0.8,3l0.3,7.3l1.5,2.5l0.3,3.5l-1.5,6.1l-0.8,1.3
        l-0.3,0.3l-2,5.8l0.3,7.3l4.8-3.5l0.8,3l1.5,2.8l2.3,1.5l3.5,0.3l3-0.5l0.8-3l3.3,0.8l2.8-0.5l1-2.8l3.3,0.5l2.3,2l0.1,3l6.5-0.7
        l11.6,4.3h3.8l3.3,0.8l1.3,2.5l0.3,8.6l-0.8,3.3l-2.8,2.3v3.8l1.5,2.5l2.3,2l0.5,7.3l2.5,5.8l2,2.3l11.4,1.3l5.6-2.3l11.1,1l2.8,1
        l11.9,0.8l6.3,2.3h0.5l2.5,1.8l1.3,6.3l1,0.5l1.3-1.3l3-0.8l3-2l3-0.8l0.8-3.5l-1-3l-2.3-1.8l1.8-2l0.8-3.3l2-2.3l2.3-1.5l0.8-3
        l2.3-1.5l3.5,0.3l2.8-1.3h3.8l1.3-0.5l2.5,1.5l3,0.8l4.8-4v-7.6l0.8-3l2.3-1.5l-2.3-6.3l2.5-1.3l3.8,1l2.8-1.3l1.5-2.3l3.3-1.5
        l4.5-0.3l3.8-8.3l1.3-10.4l1.3-2.5v-3.8l2-5.6v-9.3l-2.5-2.3l-0.3-3.5l0.8-2.8l-8.1-5.8l0.3-3.5l3.3-0.8l3-2.3v-1.5l-0.8-3l0.5-3.5
        l2.3-1.5l1-3.3l0.3-3.8l1-3l2-2.5l0.3-3.8l2.3-2.3l3-0.8l-0.5-5.6l-1.8-3l4.3-3.8l-0.3-3l-1-3.3v-3.8l0.8-2v-7.6l-2-2.5l-1-0.3
        v-3.5l6.1-12.9l0.3-7.3l2.3-2.3l2.5-1.3l1.5-2.5l2.5-1.3l2-3.5l0.8-2.5l0.3-3.5l-1.5-2.3l-3.5-0.5l-1-2.8l-1.8-2l-3.8-1.3l-0.8-1
        l-0.3-6.3l2.8-6.6l3-0.8l2-1.8l2.8-1.3l3.8-3.8h3.8l1.5-2.3l-1.7-5.6l-1.4-0.8l-4.8-1.1l-1.2-1.2l-4.8-0.5l-1.1,1.6l-5.2-0.4
        l-2.1-0.7l-1.1-1.6l-3.2-2.3h-0.9l-2.3,3.8l-0.5,2.1l-1.2,2.1l-2,0.7l-2.5-0.9l-1.6-1.1l-4.1,1.8h-2.7l-1.8,1.1l-0.9,1.8l-4.3-1.2
        l-1.2-1.4l-2-0.9l-1.6,1.1l-1.2,1.6l-2.5,0.2l-0.7,2l-1.2,2l-1.4,1.2l-10.5,0.2l-1.8,0.9l-0.5,2.1l-1.2,2l-1.8,1.1l-1.2,1.6l-2,1.2
        l-1.2,2l-3.9,2h-4.1l-1.6,1.1l-4.3-0.5l-2.5,0.5l-1.4,1.4l-2,6.3l-2.7,2.7l-1.8,1.1l-5.2,0.2l-2.3-0.5l-1.4-1.2l-2.3,0.4l-6.1,5.2
        l-2.5,0.5l-2.5-0.4l-2.9-1.4l-0.4-0.5l-1.6-1.4l-1.1,4.3l-0.9,1.8l-1.4,1.2l-0.7,1.6l-1.1-0.5v-5.4l-1.6-1.4l-4.6-0.9l-1.1-1.6
        l-2.3-0.7l-1.6,1.1l-3.8,7.5l0.4,6.6l-1.2,1.6l-4.5,1.2l-3.2,3.2l-3.8,9.8l-6.4,7l-4.3,1.4l-2.1,0.2l-1.8-1.6l-2.5-0.5h-5.4
        l-2.1,0.5l-9-3.3l-3.9,2.3h-7.6l-0.3,3.8l0.5,0.3l1.8,2l1.3,2.8l-0.3,3.5l-1.3,2.5l-2,1.8l-1.5,2.3l1,5.6l-0.5,2.3l0.5,2.8
        l-1.3,9.6l-1.3,2.5l-0.5,3.3l-1.5,2.5l1.8,2l-1.3,2.5l-0.3,2.3l7.3,7.8l2.5,1.3h3.3l2.5-1.3l2,0.5v3.3l-1.3,2.5l-2,1.8l-5.8,1.8
        L419.4,538.4L419.4,538.4L419.4,538.4z"/>
      `
    },
    {
      title: '臺中市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M417.1,325.1l-1.8,0.4l-0.2,2.3l-9.1,12.6l-1.9,4.7l0.2,3.8l-0.1,0.5l-1.2,1.6l-0.5,1.4l-2.8,1.2
        l-1,1.8l-4.7,3.2l0.1,1.4l0.5,1.2l0.1,2.1l-0.9,1l-2.4-0.8l2.8,1.3l0.5,1.2l-1,1.4l-0.9,0.8l-2.2-2.9l-0.9,0.5l-1.1,1.2l-2.2,1.2
        l0.1,1.8l-0.8,1.3l-2.8,1.6l-0.9,1.4l4.2,5.7l-0.1,1.6l-0.9,1.3l-0.4,1.5l-1-1.9l0.2-1.6l-1.5,1.6l-1.5-0.1l-0.4-1.9l0.9-1.8
        l-0.1-1.8l-1.2,1.2l-2.2,6.4l1.6,1.3l0.6,1.4l0.6,0.5l0.1-0.2l1.8,1.1v1.9l-0.6,1.5l-3.9,5.7l-1.2,0.9l-1.5,3.8l0.1,2l4.4,2
        l2.2,3.4l0.3,2.3l1.1,2.7l0.4,2.7l3.2,3.4l-1.2,1.6l-0.2,2.5l1.2,1.6l1.8,1.2h0.5l1.4,2.3l2,0.9l1.6,1.2l2.2,0.5l1.9,1.1l10.2,2.3
        h2.5l1.6,1.2l0.3,0.9v5.4l1.6,2.1l1.8,1.4v2.8l-0.9,2l1.4,7l2.5,0.2l1.6,1.4l3.6-0.4l1.1-0.7l5.7,4.8l8.9,3.2l2.2-0.6h5.3l2.5,0.6
        l1.8,1.6l2.2-0.2l4.3-1.4l6.4-6.9l3.8-9.8l3.2-3.2l4.4-1.2l1.2-1.6l-0.3-6.6l3.8-7.5l1.6-1.1l2.3,0.7l1.1,1.6l4.7,0.9l1.6,1.4v5.3
        l1.1,0.6l0.7-1.6l1.4-1.2l0.9-1.8l1.1-4.3l1.6,1.4l0.4,0.5l2.8,1.4l2.5,0.4l2.5-0.5l6.1-5.2l2.3-0.3l1.4,1.2l2.3,0.5l5.2-0.2
        l1.8-1.1l2.7-2.7l2-6.2l1.4-1.4l2.5-0.5l4.3,0.5l1.6-1.1h4.1l3.9-2l1.2-2l2-1.2l1.2-1.6l1.8-1.1l1.2-2l0.6-2.2l1.8-0.9l10.5-0.2
        l1.4-1.2l1.2-2l0.7-2l2.5-0.2l1.2-1.6l1.6-1.1l2,0.9l1.2,1.4l4.3,1.2l0.9-1.8l1.8-1.1h2.7l4.1-1.8l1.6,1.1l2.5,0.9l2-0.7l1.2-2.1
        l0.5-2.2l2.3-3.8h0.9l3.2,2.3l1.1,1.6l4.7,1.1h2.7l1.1-1.6l4.8,0.5l1.2,1.2l6.4,1.8l1.8-1.4l0.9-1.8l-0.2-2.5l3.8-1.6l1.1-1.6
        l2.7,0.2l1.4-1.2l0.6-2.3l-0.9-4.7l1.2-2l2.3-0.9l1.6-1.1l1.8-4.1l1.4-1.6h2.7l1.8-1.2l1.4-1.8l2.2-4.3l0.7-7.3l0.9-2l2.3-1.2
        l1.2-1.4l-1.2-2.1l-1.6-1.1l-1.1-1.8l-2.2-1.1l-7.1,0.9l-1.8,0.9l-2.3,0.3l-1.6,1.2l-1.2,1.6l-2.7,0.4l-1.8-0.9l-4.1-7h-4.8l-2-0.7
        l-2.7-2.7l0.9-7.2l-0.5-2.1l-0.9-1.8l-1.2-1.4l-0.4-0.2l-1.7,0.9l-0.7,1.4l-1.2,0.8l-5.8-0.1l-1.2,0.7l-6.5-0.2l-0.1,0.8l-1.8,0.9
        l-2,1.6l-1.4,1.8l-0.2,2.5l-3.4,5.5v2.7l-2.2,0.6h-2.7l-1.8-2.5l0.9-1.4l-2.7-1.1l-2,0.9l-0.7,2.2l-1.2,1.8l-2.3,0.7l-4.8,6.8
        l-2,0.7h-5.3l-1.8,1.2l-0.7,2l-0.7,0.2l-2,2.2l-0.4,2.5l-2.1,0.5l-3.4,2.8l-2.1,0.6l-5-0.6l-4.5,1.6l-5.4,6.8l-2.7,0.2l-2-1.1
        l-3.2-3l-0.4-2.5l-1.8-1.2l-2.7-3.9l-1.6,0.3h-2.7l-1.9-0.9l-5-0.3l-6.1-2.2l-4.8-0.2l-2.2,0.9l-1.4,1.4v2.7l0.9,2l0.4,5l-0.9,1.9
        l-1.8,1.4l-2.2,0.7l-10.3-0.3l-2,0.9h-5.3l-5.2-1.6l-3.2-3l-1.1-2L472,367l-11.2-2.2l-3.4-2.8l-2.5-0.7l-1.4-2l-3.6-2l-1.4-1.6
        l-2.1-0.7l-1.2-1.6l-0.9-0.4l-3-0.3l-1.2-1.8l-3.2-2.2l-7.5-9.7h-0.3l-0.9-1.8l-4.5-1.8l-2.3-3.8l-0.4-2L417.1,325.1z"/>
      `
    },
    {
      title: '彰化縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M306,523l6.3,2.3l3.8,0.3l5.8,1.8l16.2-0.8l2.8-1l14.6,0.3l2.5-1.3l5.1,2.8l0.8,1.3l4.3,0.5
        l8.1,4l6.3,1.5l7.3-0.3l3-0.8l30.6,5.1l5.8-1.8l2-1.8l1.3-2.5v-3.3l-2-0.5l-2.5,1.3h-3.3l-2.5-1.3l-7.3-7.8l0.3-2.3l1.3-2.5l-1.8-2
        l1.5-2.5l0.5-3.3l1.3-2.5l1.3-9.6l-0.5-2.8l0.5-2.3l-1-5.6l1.5-2.3l2-1.8l1.3-2.5l0.3-3.5l-1.3-2.8l-1.8-2l-0.5-0.3l0.3-3.8h7.6
        l4-2.3l-5.8-4.8l-1.1,0.7l-3.6,0.4l-1.6-1.4l-2.5-0.2l-1.4-7l0.9-2v-2.9l-1.8-1.4l-1.6-2.1v-5.4l-0.4-0.9l-1.6-1.2h-2.5l-7.5-2.1
        l-2.7-0.2l-2-1.1l-2.1-0.5l-1.6-1.2l-2-0.9l-1.4-2.3h-0.5l-1.8-1.2l-1.2-1.6l0.2-2.5l1.2-1.6l-3.2-3.4l-0.4-2.7l-1.1-2.7l-0.4-2.3
        l-2.1-3.4l-4.4-2l-1.5,0.4l0.5,1.5l0.7,0.9l-1.5,0.8l-3.2,4.2l0,0.1l-2.7,4.3l-1.2,0.8l-5.6,0.1l0.2,1.5l1.5,1.3l-0.1,3.7l-1,0.9
        l-0.6,1.4l-0.4,1.8l-0.9,1.2l-0.1,3.8l-0.1,0.4l-1,1.5v1.9l0.6,1.2l-0.2,1.9l-1.4,1.2l-3,1.2l-0.8,1.8l-2.3,2.4l-0.2,3.5l-0.6,1.4
        l-2.7,0.5l-1.4,1.1l-1.6,0.2l-2.4,1.8l-1.4,0.5l-0.4,1.6l-4.1,3l-0.8,3v2.2L335,467l-0.2,1.6l-1.3,0.9l-0.6,3.3l-2.4,0.2l-1.9-0.4
        l-1.4,1.2l-1,4.9l0.4,1.2h3.8v0.9l-4.8,7.2l-0.2-0.1l-0.4,2l-0.9,1.1l-0.4,1.5l-1,1l-1.3,4.8l-2.1,2.4l-0.5,1.8l-1.9,2.5l-0.6,1.5
        l-0.9,1l-0.2,2.2l-1.2,0.7l-1.1,1l-1.4,0.7l-0.9,1.3l-1.4,0.8v1.4l0.9,1.3l-0.7,0.5h-1.9l-0.9,1.2l-0.3,3.7l-0.4,1"/>
      `
    },
    {
      title: '雲林縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M447.5,596.5v-3l-2.3-2l-3.3-0.5l-1,2.8l-2.8,0.5l-3.3-0.8l-0.8,3l-3,0.5l-3.5-0.3l-2.3-1.5
        l-1.5-2.8l-0.8-3l-4.8,3.5l-0.3-7.3l2-5.8l0.3-0.3l1.8-4l0.5-3.3l-0.3-3.5l-1.5-2.5l-0.3-7.3l0.8-3l2.8,0.5l0.5-7.1l-5.1-10.9
        l-20.7-3l-3.8-1.5l-2-0.3l-10.4,1l-14.4-5.6l-4.3-0.5l-0.8-1.3l-5.1-2.8l-2.5,1.3l-14.6-0.3l-7.6,2l-11.4-0.3l-5.8-1.8l-3.8-0.3
        l-6.4-2.2l-3.9,0.1l-1.2-0.7l-1.4-0.2l-0.5,1.5l-1.8,0.1l-6.1,10.8l-0.1,0.7l-2.2-0.1l-1.3,0.8l-2,2.4l-0.6,1.6l-0.2,3.8l-1,1.2
        l-0.2,1.6l1.7,2.4l-0.2,1.8l-1.7,2.4l-3.2,0.1l-0.4,1.5v1.9l-2.2,1.2v2.4l-0.1-0.1l-0.5,0.2l-0.4,1.6v1.9l0.5,1.4l1.9,0.1l1.4,2
        l-2.3,2.8l-1.4,2.8l-0.4,1.5l-1.9,3.2l-1,3.5l-1.3,1.9l-2.6,7.4l0.2,7.5l-0.4,1.5l0.1,1.4l1.8,0.9l-0.1,1.7l-1.5,0.6l-2.2,1.6
        l0.5,2.2l1.1,0.6l1.5,0.2l0.1,1.9l-1,1.2v3.8l1.2,0.9l2,2.8l1.4,1.1l-1.2,0.8l-0.9,1.7l-1.8,0.1l-0.5-1.4l-1-0.9l-1.5,0.9l-2.2,2.5
        l3.9,0.1l5.8,2.3h3.8l1.5,2.3h3.8l2.8-1.3l6.3,2.3l3.8,0.5l2.3-1.8l1.8-2.3l0.3-3.5l-0.8-3l2-2h3.3v3.3l3.5,1.3l1-2.8l4.8-2.8
        l0.8-3.5l1.8-2l6.3-2.5l2.8,1.5l1.8-6.1l1.5-2.5l4,0.3l2.5-1.3l1-3l5.8-1.8l1-2.8l2.3-1.8l1.8-2.3l2.8,1.3l2.3,1.8l2.8,1l5.8-2.3
        h7.6l5.3-2.8l5.1,1.3l1.5,5.1l4.3,3.5l1.5,2.3l2.5,1.5l3.5,0.3l2.3,1.8l3,1l13.9,1.5l2.8-1.5l1.8-2l5.6,0.3l-0.5,3.8l2,3.3l2-2
        l3.3-0.8l2,2l3-0.3l2.8-1l3.5,0.3l2.8,1.5l3.5,0.5l1.3-2.5v-3.5l-0.8-3l-1.5-2.3L447.5,596.5L447.5,596.5L447.5,596.5z"/>
      `
    },
    {
      title: '嘉義縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `

      `
    },
    {
      title: '嘉義市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M378.4,636.2 375.4,635.4 374.1,632.9 371.9,631.4 369.1,632.4 367.1,630.6
        365.3,628.1 362.8,626.9 359.7,627.6 352.7,632.7 349.6,631.9 344.8,633.2 345.3,636.5 348.4,639.5 346.4,644 356.5,645.5
        357.5,648.3 359.5,651.1 362,652.4 364.5,650.8 365.8,648.3 370.3,644.8 372.4,646.6 376.9,648.1 377.7,645.5 381.2,641.3
        379.2,639.2"/>
      <path d="M510.3,642.2l-2.8-1l-11.1-1l-5.6,2.3l-11.4-1.3l-2-2.3l-2.5-5.8l-0.5-7.3l-2.3-2l-1.5-2.5
        v-3.8l2.8-2.3l0.8-3.3l-0.3-8.6l-1.3-2.5l-3.3-0.8h-3.8l-11.6-4.3l-6.4,0.6l-1.6,2.4l1.5,2.3l0.8,3v3.5l-1.3,2.5l-3.5-0.5l-2.8-1.5
        l-3.5-0.3l-2.8,1l-3,0.3l-2-2l-3.3,0.8l-2,2l-2-3.3l0.5-3.8l-5.6-0.3l-1.8,2l-2.8,1.5l-13.9-1.5l-3-1l-2.3-1.8l-3.5-0.3l-2.5-1.5
        l-1.5-2.3l-4.3-3.5l-1.5-5.1l-5.1-1.3l-5.3,2.8h-7.6l-5.8,2.3l-2.8-1l-2.3-1.8l-2.8-1.3l-1.8,2.3l-2.3,1.8l-1,2.8l-5.8,1.8l-1,3
        l-2.5,1.3l-4-0.3l-1.5,2.5l-1.8,6.1l-2.8-1.5l-6.3,2.5l-1.8,2l-0.8,3.5l-4.8,2.8l-1,2.8l-3.5-1.3v-3.3h-3.3l-2,2l0.8,3l-0.3,3.5
        l-1.8,2.3l-2.3,1.8l-3.8-0.5l-6.3-2.3l-2.8,1.3H280l-1.5-2.3h-3.8l-5.8-2.3l-3.9-0.1l-1.6,0.9l-0.4,9.1l0.9,1.5l1.8,0.6l5.7-0.4
        l1.4,0.6v1.9l-0.8,1.4l-0.5,1.8l-0.9,1.1v2l-0.8,1.5l0.4,2l1.6,0.9h1.7l-2.2,4.2l-0.1,1.8l1.5,0.4v1.7l-1,3.2l0.1,1.9l0.8,1.5
        l1.2,1l1.4-1.2l0.6,0.9l0.2,3.5l-1,1.7l-1.3,5l-3.7,1.5l-1.4-0.1l-3,6.9l-0.2,5.1l0.4,1.5h3.8l1.6,0.6l0.2,1.7v0l2.7,1.8l3.8,0.8
        l1,3l2.3,2l2.5,0.3v-2.8l1.3,2l4.5,1.3l3-1.3l3.3-4.8l0.8-3l9.6-4.8l0.5-1.8l-1-2.8l2-2l2.5,1.5l1.8-1.5l1.3-2.5l0.5-3.3l7.1-0.5
        l-0.3-3l6.3,0.3l1.5-2.5l0.3-0.3l6.1-3.5h7.6l5.3-2.5l3.3-0.5l2,2l3.3-0.3l2.3-1.5l0.3-0.5l3.8,1l1.8,2.3l3.3,1l2.5,2l3,0.8
        l2.3-1.3l2,1.8l-1.5,2.3l-2.5,1.8v3.3l7.8,0.5l0.3,3.3l-2.5,1.8l1,2.3l3.8,1.3l3.5,0.3l1,2.8l-0.3,3l-2.3,1.8l-1.5,2.8l0.3,3.5
        l1,2.8l-1.3,2.5l0.5,2.5l2,3.3l0.8,2.5l-3.3,4.8l1,2.5l6.1,1.8l4.3,3l3.3,0.3l3-0.8l3.8-5.6l3.5,2.5l3,1l6.3-1.3l2.5,1.3l2-1.8
        v-3.8l-2-2.5l-1.3-2.8l1.3-11.6l7.8,0.3l2.3,2l8.1,0.3l1.3-6.6l6.6-0.5l1-2.8l5.1-2.5l2.3-5.8l7.1-0.5l4-4l1.5-2.5l2-2h3.8l2.8-1
        l0.3-3.5l1.8-2l1-2.8l1.8-1.5l1.8-4.3l2.3-2.5l3.3-0.5l2.3-1.8l2.8-1.3l1.5-2.5l4.3-3.5l6.3-1.3l2.3-1.5l6.6-1l2.5-1.3l1.2-0.3
        L510.3,642.2z M377.7,645.5l-0.8,2.5l-4.5-1.5l-2-1.8l-4.5,3.5l-1.3,2.5l-2.5,1.5l-2.5-1.3l-2-2.8l-1-2.8l-10.1-1.5l2-4.5l-3-3
        l-0.5-3.3h0l0,0v0l4.8-1.3l3,0.8l7.1-5.1l3-0.8l2.5,1.3l1.8,2.5l2,1.8l2.8-1l2.3,1.5l1.3,2.5l3,0.8l0.8,3l2,2L377.7,645.5z"/>
      `
    },
    {
      title: '臺南市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M359.1,660.9l-0.2,0.5l-2.3,1.5l-3.3,0.2l-2-2l-3.3,0.5l-5.3,2.5h-7.6l-6,3.5l-0.3,0.2l-1.5,2.5
        l-6.3-0.3l0.2,3l-7.1,0.5l-0.5,3.3l-1.2,2.5l-1.8,1.5l-2.5-1.5l-2,2l1,2.8l-0.5,1.8l-9.6,4.8l-0.8,3l-3.3,4.8l-3,1.2l-4.6-1.2
        l-1.2-2v2.8l-2.5-0.2l-2.3-2l-1-3l-3.8-0.8l-2.8-1.8l-0.8-1l-7.2-1.3l-3.9,6.8l0.6,1.3l1,0.1l-0.6,1.9l-1.3,0.9l-0.2,1.8l2.8,1.7
        l1.2,0.2l1.6-0.5l-2.2,3.2l-3.4,1.2l-0.9,1.3l0.2,1.8l0.8,1.5l-2.5,2.4l-0.4,1.7l-0.7,1.4l-2.4-0.6l-0.6,1.3L252,722l0.1,3.8
        l-4.9,9.2l-0.5,3.5l-1.5,2.5l1.1-1.4l1.5-0.6l1.5-0.1l0.6,8l1.2,0.9l1.5,0.6l-0.9,5.3l-3.3,1.4h-0.2l-3.5,1.2l-3.2,2.9l-3.3,1.5
        l-0.2,3.5l0.2,0.5l0.2,3.7l0.8,0.9h1.9l-0.1,5.9l0.8,2.4l2.1,2.5l0.3,0.8l1.9-0.7l1.3-0.9l1-1.1l0.3,1.6l-0.2,1.8l4.7,2.8l0.9,0.2
        l1.8,1.6l3.2,1.7l3.9,4.2l1.5,0.8l5.3,4.9l0.1,0.5l3.4,3.3l2.4,3.7l1.6-0.4l2.3-2.1l1.4-0.8l-1,2.2l-0.9,1.1v1.3l3.3,0.9l-1.7,1.4
        l-1.9-0.1l-1-1.4l-0.9-0.5l0.8,1.9l1.2,1.5l0.5,1.7l-0.5,9.6l-0.5,1.4l1,2l1.3-0.8l2-2l1.5-0.6l2.7-4.9l-2.6,4.9l8.5,3.1l2.5-1.3
        l1.8,1l3.8,4h3.5l0.5-0.8l2.8,1.2l2,0.2l1.5-2l17.9,4.1l3.8-0.2l6.3-5.8l1.8-2.5l3-1l3.5-0.2l2.5,1.5h3l1.5-6.3l1.5-0.8l1-1.3
        l0.5-3.3l3.8-8.8l3-0.8l1-3l0.2-3.5l5.6-0.2l0.2-3.5l9.1-6.6l3.5-5.6l10.4-7.1l2.2-2.5l3.8-2.3l0.5-3l0.2-0.2l1.2-0.8l0.5-3.5
        l1.3-2.5l0.2-3.5l5.6-6.8l1.8-0.2l8.1-10.9l0.2-3.8l4-5.1l0.8-3l-2.2-1.8l-0.3-0.5l-1,0.8l-2.5-1.2l-6.3,1.2l-3-1l-3.5-2.5
        l-3.8,5.6l-3,0.8l-3.3-0.2l-4.3-3l-6.1-1.8l-1-2.5l3.3-4.8l-0.8-2.5l-2-3.3l-0.5-2.5l1.3-2.5l-1-2.8l-0.2-3.5l1.5-2.8l2.3-1.8
        l0.2-3l-1-2.8l-3.5-0.2l-3.8-1.2l-1-2.3l2.5-1.8l-0.2-3.3l-7.8-0.5v-3.3l2.5-1.8l1.5-2.3l-2-1.8l-2.2,1.3l-3-0.8l-2.5-2l-3.3-1
        l-1.8-2.3L359.1,660.9z"/>
      `
    },
    {
      title: '高雄市',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M334.8,956l6.8,5.6l1.8,0.8l2.8,2.5l5.4,1.6l0.2-0.6l-0.9-14.5l0.9-2.9l0.9-1.1l1.8,0.4l2.5-2.2
        l2-5.8l0.3-3.5l1.8-6.1v-3.8l-3.5-4.3l0.3-18.2l2-6.1l1.8-2.3l1.8-6.8l-0.3-4.5l0.8-1.3l-0.3-15.2l1.3-2.8l0.5-3.3l1.3-2.5l1.5-9.8
        l2.5-1.3l1.8,0.8l4.5,0.3l1.5,2.3h3.8l2.8-1.3l6.1,1.5l3.5-0.3l2.3-1.8l2.5-3.3l0.3-1.3l4-3.3l1.3-0.5l6.6-4.5l3.5-0.3l2.8-2l3-0.8
        l3,1.3l1.8,2v3.8l2.8,1.3h2.8l1-1l3-0.8l3.3-4.8l3-0.8h3.8l8.6,5.3l6.3,7.6l2.8,1.5l2.8-1.5l1.8-2.3l5.1-2.5l1.5-2.3h3.3l1,0.3
        l2.5,1.3l0.3,1.3l2.3-0.8l4.5,3l0.7-6.8l-2.9-3.9l-5.4-0.4l-2.9-8.6l0.4-5l-1.4-1.4l-1.8-3.9v-5.4l1.1-4.3l10-12.9l0.7-1.8v-3.9
        l6.4-4.6l-1.4-4.3l-2.5-2.5l1.8-4.3l3.6-3.9l-1.1-22.5l3.2-2.1l2.1-5l3.6-1.8l-1.1-3.9v-3.9l1.4-3.9l-0.4-1.8l-1.1-0.7l2.9-3.6
        l3.6-1.4l6.8-5.7l5-6.4h3.9l7.9-2.1l2.9-2.9l8.5-3.6l0.8-3l-0.3-3.5l-15.4-10.6l-3-0.8l1-3.5l1.8-2.5l3.3-1l-0.5-2.3l1.5-2.3
        l3.3-0.5l1.5-2.3v-7.1l-2.3-1.5l-0.6-1.7l-0.9-0.3l-1.3-6.3l-2.5-1.8h-0.5l-9.9-2.8l-1,0.3l-2.5,1.3l-6.6,1l-2.3,1.5l-6.3,1.3
        l-4.3,3.5l-1.5,2.5l-2.8,1.3l-2.3,1.8l-3.3,0.5l-2.3,2.5l-1.8,4.3l-1.8,1.5l-1,2.8l-1.8,2L477,674l-2.8,1h-3.8l-2,2l-1.5,2.5l-4,4
        l-7.1,0.5l-2.3,5.8l-5.1,2.5l-1,2.8l-6.6,0.5l-1.3,6.6l-8.1-0.3l-2.3-2l-7.8-0.3l-1.3,11.6l1.3,2.8l2,2.5v3.8l-1.1,1.1l0,0l0.3,0.4
        l2.3,1.8l-0.8,3l-4,5.1l-0.3,3.8l-8.1,10.9l-1.8,0.3l-5.6,6.8l-0.3,3.5l-1.3,2.5l-0.5,3.5l-1.3,0.8l-0.3,0.3l-0.5,3l-16.4,11.9
        l-3.5,5.6l-9.1,6.6l-0.3,3.5l-5.6,0.3l-0.3,3.5l-1,3l-3,0.8l-3.8,8.8l-0.5,3.3l-1,1.3l-1.5,0.8l-1.5,6.3h-3l-2.5-1.5l-3.5,0.3l-3,1
        l-8.1,8.3l-3.8,0.3l-10.1-3.3l-7.8-0.8l-1.5,2l-2-0.3l-2.8-1.3l-0.5,0.8h-3.5l-3.8-4l-1.8-1l-2.5,1.3l-8.4-3.2l-1.7,0.7l-3.2,3
        l1.2,1.4l0.8,4.2l3,6.3v1.9h0.7l0.2-1.6l0.6-1.3l1.9-0.1l2.1,5.1l-0.2,1.6l-1.9,0.2l-1.4-0.9l-0.8-1.2l-0.4,0.9l0.1,1.8l0.8,2.2
        l0.1,1.8l1.8,3.4v1.4l8.9,21.1l0.2,1.8l1.5,3.4l1.4,1.8l3.1,6.6l1.7,3.8l4.5,5.6l-2.1,2.5l-3.9-3.9l2.5,6.1l-4,3.9l-0.2,4.2
        l-0.5,1.6l0.5,1.8l2.8,2.5l0.9,1.4v1.9l0.9,1l-0.1,1.9l-1.1,0.8l0.8,1.1l1.5,0.8l1.2,3.4l2.7,2.6l0.6,1.4l1.3,1.8l1.1,0.8l1,1.1
        l0.7,1.4l1,1l2.8,4.5l-2.7,0.5l-0.5,0.7v0.6l1.6,0.4l3.3-0.1l0.6,0.1l3.3,3.5l1.4,0.8l2.3,2.5l0.6,1.4l3.9,3.3L334.8,956"/>
      `
    },
    {
      title: '屏東縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M335.1,1008.7l-3.5,6.2l1,0.9l1.2,0.4l2.7-0.8l0.5-1.1l3-3.2l2.9-1.8l0.4-1l-0.3-1.1l-1.1-0.5
        l-0.4-0.9l-0.9-0.7l-1.2,0.1l-2.1,1l-0.7,0.8l-0.9,0.4l-0.5,0.9L335.1,1008.7L335.1,1008.7L335.1,1008.7z"/>
      <path d="M496.7,1043.3l-3,1l-2,1.8l-3.3,1.3l-2.8-4.8l-3-0.8h-4l-1.3-2.8l-2.8-1l-2.3-1.5l-4.3,0.3
        l-0.5-2.3l0.8-3.3l1.8-1l-2.8-1l-1-2.5l-4.8-2.8l1.8-2.3l2.5-1.3l-0.3-7.6l-2.5-1.5l-1.3-2.5h-2.8l-3.8-4l-3.5-1.8l4.8-14.6l6.6-1
        l1.3-2.8l-0.8-3.3l-2.8-2l-2.5-5.3l-4-2.5l-0.3-3l-1-3l4.3-3.3v-3.8l-4-8.1l-0.5-4.3l-1.8-1l-1-3l0.5-3.5l2.8-5.1l-1.3-2.8
        l1.5-2.3l1-2.8l-0.3-3.5l1-2.5l2-2l0.8-3l1.8-2l0.3-1l4-5.6l0.3-3.5l1.8-2.3l0.3-2.5l3.3,0.5l3.5-1.8l6.1-1.3l3-6.3l11.1-0.5
        l2-2.3l0.3-3.5l1.8-2l0.5-9.9l2-2l-1-2.3v-3.8l-1-3.3l-2.5-1.3l-3.3-0.3l-2.8-2.5l-0.8-1.5l-1-8.3l-3.8-2.5l-2.3,0.8l-0.3-1.3
        l-2.5-1.3l-1-0.3h-3.3l-1.5,2.3l-5.1,2.5l-1.8,2.3l-2.8,1.5l-2.8-1.5L454,840l-8.6-5.3h-3.8l-3,0.8l-3.3,4.8l-3,0.8l-1,1h-2.8
        l-2.8-1.3v-3.8l-1.8-2l-3-1.3l-3,0.8l-2.8,2l-3.5,0.3l-6.6,4.5l-1.3,0.5l-4,3.3l-0.3,1.3l-2.5,3.3l-2.3,1.8l-3.5,0.3l-6.1-1.5
        l-2.8,1.3h-3.8l-1.5-2.3l-4.5-0.3l-1.8-0.8l-2.5,1.3l-1.5,9.8l-1.3,2.5l-0.5,3.3l-1.3,2.8l0.3,15.2l-0.8,1.3l0.3,4.5l-1.8,6.8
        l-1.8,2.3l-2,6.1l-0.3,18.2l3.5,4.3l-2.8,16.4l-1.3,2.8l-2.5,2.2l-0.2,1.8l-0.8,1.1l-0.1,3.8l-0.5,1.3l0.8,1.8l0.1,9.1l2.9,0.2
        l2,1.9l-0.1,1.8l-0.9,0.2l1.8,2.4l5,3.7l0.9,1.2l1.4,0.6l0.6,0.1l-0.2,0l0.2,0.4l3,1.3l1.2,1.2l1.5,1l2.9,1l1.1,1l1.5,0.8l1,0.9
        l1.7,0.8l1.1,1l9.6,5.2l1.1,1.4l1.7,0.5l10.8,8.7l0.9,1.3l2,5.4l-0.4,0.8l5.4,3.7l5.8,7.3l0.5,1.5v4.8l1.8,2.9l0.8,3.4l-0.1,1.8
        l-0.5,1.5l0.1,1.5l4,3.4l0.9,1.2l1.3,1l0.8,1.2l1,3.5l3.3,3.4l-0.9,1.8v1.9l0.4,1.8l1.2,1.2l1.6,4.9l0.1,5.6l1,3.3v1.9l0.7,1.2
        l0.5,2.2l1.2,1.1l0.8,1.2l0.3,1.8l1.4,1.4l0.2,3.6l0.8,1.2l-0.1,1.9l-0.8,1.2l-5.2,1.5l0.2,1.7l1.8,0.4l0.5,1.3l-0.1,0.6l0.4,1.6
        l0.6,1.3l-0.6,1.2l-0.9,1l-2.5,1.5l-0.2,1.6l1,1.2l-3.8,3.5v3.7l1.5,0.9v1.9l1.2,1.1l-0.4,1.5l3.9,3.4l0.4,1.4l1.3,1.3l0.8,1.2
        l0.5,2.5l-0.9,3l0.1,5.9l-1.1,2.7l0.9,1.1l1.8,0.2l0.9,3.3l1,1.7l3.9,0.4l1.5-2.4l1.1-0.9l0.2-1.7l-0.8-3.2l0.6-1.4l1.4-0.6l2-2.2
        l1.3-0.6h1.9l0.5,1.4l4.2,2.9l0.5,0.1l2.3-0.1l1.6,0.4l0.6,1.3l1.5,0.4l1.4,0.9l1.9,0.6l1.9,0.1l3.8,3.8l0.1,1.8l1.9,0.6l0.9,0.8
        l0.6,1.2l0.1,1.8l2.7,2.5h1.9l1.3-0.9l-0.2-1.7l-1-0.9l-0.4-1.6l0.1-1.8l-0.5-1.4l-4.9-7.5v-7.3l0.5-1.4l-0.6-1.4l0.5-3l1-0.4
        l0.1-1.7l3.4-0.5l1.7-0.6l0.6-1.2l1.5-0.4l1.3-1l0.5-1.5l0.1-1.8l0.9-1l1.8-0.1l0.4-3.4l3.7-2.4l0.4-1.4l-2-2.5l-0.1-9.3l-1.2-1.7
        v-1.9l2.7-4.5l1-1.1v-1.5l-1.5-0.6l-2-2.2l-0.6-1.6l-0.2-4.8l0.2-0.7l0.9-0.1l0.5-0.6l0.7-0.5l0.3-0.7l-0.9-1.4l0.1-3.7l-0.8-1.3
        l0.2-0.6l0.5-0.6l0.3-0.6l0.2-0.8l-0.2-0.8v-1.9l-0.3-1l-0.4-0.6l0.1-1.1l-0.6-0.8l-0.3-0.8l0.2-1.6l1.4-1.2l0.4-0.6l0.2-0.7
        l0.1-1.8l-0.2-0.8v-0.7l0.2-0.5l-0.9-1l0.8-3.3l1-1.1l0.5-1.4l-1-1.2l-0.2-0.9"/>
      `
    },
    {
      title: '臺東縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M683.2,916.4 681.5,914.6 681.3,912.4 684.1,908.3 684.1,905.7 683.6,903.3 682,902.1 679.3,902.3
        677,901.2 671.8,902.1 669.8,901.9 669.8,904.6 672.2,906.2 672.9,908.7 672.9,911.4 674,912.6 676.5,913 679,916 681.5,916.7"/>
      <path d="M709.1,1113.2 707.5,1111.6 705.6,1111 701.3,1107.3 700.6,1105.3 700.7,1102.8 703.2,1102.6
        703.1,1100.5 701.6,1099.1 701.3,1096.7 702.5,1095.3 701.5,1093.7 691.6,1093.9 689.7,1094.6 684.3,1094.6 682.7,1093.5
        681.1,1093.9 681.1,1095.7 683.1,1096.9 683.1,1104.8 684.1,1106.4 686.6,1107.3 691.6,1113.5 697,1113.5 699.1,1115
        699.7,1116.6 699.7,1116.6 701.1,1118.5 702.7,1120 705.4,1120 707.9,1120.5 709.1,1120.1 709.8,1118.2 711.1,1116.6
        711.5,1114.2"/>
      <path d="M671.2,677.9 671.3,672.2 673.1,665.2 674.5,664.3 674.5,662.4 676.7,655.6 676.7,655.6 673.5,654.6
        671.2,652.8 668.2,652.1 664.4,652.1 661.7,653.1 657.4,656.6 650,669.7 649.8,670 650.3,674.5 652.3,676.8 655.6,678.6
        655.6,678.8 643.5,697.3 643,706.1 641.2,708.4 638.9,709.9 637.7,712.4 636.1,718.5 633.9,720.2 633.9,724 632.4,726.8
        632.1,729.6 629.1,735.4 624.8,738.9 623.8,741.7 624.5,745.2 624.5,756.6 623.5,759.4 613.7,760.9 611.1,762.7 609.1,762.4
        608.9,758.9 607.1,755.6 605.1,753.8 600.5,753.6 599.5,752.8 597.8,750.5 594.7,749.5 592.2,748 592.2,745.2 588.4,739.9
        588.4,735.6 587.2,732.9 585.4,730.6 580.3,727.3 575,729.8 574.5,731.1 573.5,731.1 570.7,729.8 569.5,727.3 565.9,723.3
        562.4,723 557.1,719.7 552.8,719.7 551.5,717.2 550.8,716.5 547,707.4 547.2,703.6 543.5,698.8 542.5,696.3 534.2,699.8
        531.3,702.7 523.5,704.8 519.5,704.8 514.5,711.3 507.7,717 504.2,718.4 501.3,722 502.4,722.7 502.7,724.5 501.3,728.4
        501.3,732.3 502.4,736.3 498.8,738 496.7,743 493.5,745.2 494.5,767.7 491,771.6 489.2,775.9 491.7,778.4 493.1,782.7
        486.7,787.3 486.7,791.3 486,793 476,805.9 474.9,810.2 474.9,815.5 476.7,819.5 478.1,820.9 477.7,825.9 480.6,834.5 486,834.8
        488.8,838.8 488.1,845.5 487.7,846.5 487.7,850.3 488.4,853.3 489.2,854.8 491.9,857.4 495.2,857.6 497.8,858.9 498.8,862.2
        498.8,866 499.8,868.2 497.8,870.2 497.2,880.1 495.5,882.1 495.2,885.7 493.2,887.9 482.1,888.4 479.1,894.7 473,896
        469.5,897.8 466.2,897.3 465.9,899.8 464.2,902.1 463.9,905.6 459.9,911.2 459.6,912.2 457.8,914.2 457.1,917.2 455.1,919.2
        454.1,921.8 454.3,925.3 453.3,928.1 451.8,930.4 453,933.1 450.3,938.2 449.8,941.7 450.8,944.7 452.5,945.8 453.1,950.1
        457.1,958.1 457.1,961.9 452.8,965.2 453.8,968.2 454.1,971.3 458.1,973.8 460.6,979.1 463.4,981.1 464.2,984.4 462.9,987.2
        456.3,988.2 451.5,1002.8 455.1,1004.6 458.9,1008.6 461.6,1008.6 462.9,1011.2 465.4,1012.7 465.7,1020.3 463.2,1021.5
        461.4,1023.8 466.2,1026.6 467.2,1029.1 470,1030.1 468.2,1031.1 467.4,1034.4 467.9,1036.7 472.2,1036.4 474.5,1037.9
        477.3,1038.9 478.6,1041.7 482.6,1041.7 485.6,1042.5 488.4,1047.3 491.7,1046 493.7,1044.2 497.2,1043.1 497.1,1038.4
        496.2,1035.3 496.2,1027.5 495,1025.8 494.7,1020.4 495.3,1019 495.3,1015.5 498.2,1006.4 500.8,1003.8 501.3,998.8 502.1,997.5
        505.7,987.4 506.6,986.3 507.1,984.9 509,982.4 509.4,980.6 510.4,979.3 510.6,975.8 511.5,974.7 512,971.1 513.3,969.5
        513.1,967.4 515.3,963.2 515.3,959.4 516.2,958.5 516.2,956.6 519.1,949.8 519.1,947.3 520.3,946.5 520.3,944.6 521,943
        521.1,941.2 522,940 522.2,938.3 523,937.1 524.6,936.2 525.5,934.9 529.1,931.7 531,928.9 531.1,927.5 531.8,926.1 532,920.8
        532.2,920.2 532.1,918.5 532.8,917 533,915.2 533.9,914.1 534.1,912.4 535.2,911.1 536.1,908 537.8,905.4 538.9,904.3 543,902.6
        544.1,901.6 545.9,901.4 546.9,900.2 547.2,898.6 549.9,897.4 550.9,896.4 553.3,895 554.2,893.9 556,893.7 557,892.7
        558.9,892.5 560.2,891.8 561.2,891 562.8,890.6 565.4,889.1 569.2,884.5 570.7,883.9 571.9,883.1 572.6,882 575.1,880
        575.9,878.7 577,877.7 579.8,876.6 582,874.6 583.2,866.6 584.6,865.2 586.3,865 588.4,863.1 588.9,861.4 587.8,858.7
        588.1,856.9 587.1,855.7 585.9,855.1 585.2,853.8 586,852.6 587.9,852.6 587.5,851.3 585.7,849.3 585.1,848 585.4,846.4
        586.1,845.2 591.9,840.5 595.3,840 596.7,839.4 599.9,840.2 600,837.2 602.4,834.7 603.9,834.3 605.1,833.3 605.7,830.1
        607.8,825.7 610,823.7 613.4,816.2 613.6,813.1 614.3,812.8 615.2,811.3 616.3,810.3 618.1,810.2 618.6,808.8 621.9,805.3
        621.9,803.5 622.4,801.9 623.1,801.1 624.4,796 625.4,794.4 626.4,791.5 627.4,790.3 628.5,789.6 628.8,787.9 629.9,786.3
        630.8,780.6 631.8,779.2 633.2,774.3 635.2,771.4 635.2,769.5 636,768.3 636,766.3 637.4,765.6 638.3,764.4 642,764.3
        643.3,763.7 645.5,759.5 646.7,758.5 650.4,758.4 651.3,755.5 648.2,752.3 648.1,746.9 649.5,745.5 649.8,741.9 649.4,740.1
        647.5,736.3 647.2,728.4 650.1,726.3 650,724.9 649,723.9 650.1,723.4 651,724.8 652.4,725.1 653.9,723.9 654.7,722.7
        654.2,721.3 653.3,720.1 655.7,710.4 656.7,709.2 657.1,705.1 658.2,704 658.3,702.3 659,700.9 660.3,699.7 661.1,698.4
        662.3,695.3 663.5,694.3 665,693.9 666.9,693.9 668.4,692.9 669.6,687.3 669.4,681.8"/>
      `
    },
    {
      title: '花蓮縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M676.8,655.5l-3.4-0.9l-2.3-1.8l-3-0.8h-3.8l-2.8,1l-4.3,3.5l-7.3,13.1l-0.3,0.3l0.5,4.5l2,2.3
        l3.3,1.8v0.3l-12.1,18.4l-0.5,8.8l-1.8,2.3l-2.3,1.5l-1.3,2.5l-1.5,6.1l-2.3,1.8v3.8l-1.5,2.8l-0.3,2.8l-3,5.8l-4.3,3.5l-1,2.8
        l0.8,3.5v11.4l-1,2.8l-9.8,1.5l-2.5,1.8l-2-0.3l-0.3-3.5l-1.8-3.3l-2-1.8l-4.5-0.3l-1-0.8l-1.8-2.3l-3-1l-2.5-1.5v-2.8l-3.8-5.3
        v-4.3l-1.3-2.8l-1.8-2.3l-5.1-3.3l-5.3,2.5l-0.5,1.3h-1l-2.8-1.3l-1.3-2.5l-3.5-4l-3.5-0.3l-5.3-3.3h-4.3l-1.3-2.5l-0.8-0.8
        l-3.8-9.1l0.3-3.8l-3.8-4.8l-0.8-2.5l0.8-3l-0.3-3.5l-15.4-10.6l-3-0.8l1-3.5l1.8-2.5l3.3-1l-0.5-2.3l1.5-2.3l3.3-0.5l1.5-2.3v-7.1
        l-2.3-1.5l-0.5-1.6l1.3-1.1l3-0.8l3-2l3-0.8l0.8-3.5l-1-3l-2.3-1.8l1.8-2l0.8-3.3l2-2.3l2.3-1.5l0.8-3l2.3-1.5l3.5,0.3l2.8-1.3h3.8
        l1.3-0.5l2.5,1.5l3,0.8l4.8-4v-7.6l0.8-3l2.3-1.5l-2.3-6.3l2.5-1.3l3.8,1l2.8-1.3l1.5-2.3l3.3-1.5l4.5-0.3l3.8-8.3l1.3-10.4
        l1.3-2.5v-3.8l2-5.6v-9.3l-2.5-2.3l-0.3-3.5l0.8-2.8l-8.1-5.8l0.3-3.5l3.3-0.8l3-2.3v-1.5l-0.8-3l0.5-3.5l2.3-1.5l1-3.3l0.3-3.8
        l1-3l2-2.5l0.3-3.8l2.3-2.3l3-0.8l-0.5-5.6l-1.8-3l4.3-3.8l-0.3-3l-1-3.3v-3.8l0.8-2v-7.6l-2-2.5l-1-0.3v-3.5l6.1-12.9l0.3-7.3
        l2.3-2.3l2.5-1.3l1.5-2.5l2.5-1.3l2-3.5l0.8-2.5l0.3-3.5l-1.5-2.3l-3.5-0.5l-1-2.8l-1.8-2l-3.8-1.3l-0.8-1l-0.3-6.3l2.8-6.6l3-0.8
        l2-1.8l2.8-1.3l3.8-3.8h3.8l1.5-2.3l-1.8-5.6l2-1.5l0.9-1.8l-0.2-2.5l3.8-1.6l1.1-1.6l2.7,0.2l1.4-1.2l0.5-2.3l-0.9-4.6l1.2-2
        l2.3-0.9l1.6-1.1l1.8-4.1l1.4-1.6h2.7l1.8-1.2l1.4-1.8l2.1-4.3l0.7-7.3l0.9-2l2.3-1.2l1.3-1.5l12,7.7h8.1l3,1.3l4,0.5l8.1,5.6
        l8.3,1l3-0.8l-3-2.5l0.8-3l3.3-0.8l2.3-1.5l2.3,1l2-1.8l-0.8-1.5l-2-1.8l0.3-3.3l2-0.8l4.3,3.8l4.8,1.5l4.5,3.5l9.1,3.8l7.3,0.5
        l8.1,4l4.2,0.5l-0.1,1.3l-0.6,1.2l-1.5,0.7l-0.9,1.5l-2.2,2l-2.7,3.9l-0.1,5.6l-1.2,0.9l-1.1,2.7l-1.5,0.6l-0.8,1.9l-0.2,0.1
        l-3.5,0.4l-3,5.4l-1.2,1l-1.7,0.2l-3.3,2.9l-1.5,0.4l-0.9,1.1l-1.2,0.8l-3.9,5.3l-1,3.3v5.7l2.4,4.3l-0.8,0.8l-0.5,5.2l-0.2,0.5
        l-0.4-0.2l-1.4,2.4l-1.4,0.5l-3.2,3.7l-1.5,0.8l-4.3,4.7l-0.6,1.4l0.1,11.9l0.6,1.2l0.5,3.3l7.2,7.5v1.9l-1,3.4l-1.4-0.4l-0.4,1.7
        l0.7,1.2l-1.5,0.5l-1.1,2.5h1.6l-0.8,3l-1.9,0.1l-0.8-1.1H714l-1,0.9l-0.6,1.7l-1.1,1.1l-1,3l1.4,3l0.1,5.6l-1,3.4l-0.1,5.3
        l-0.5,1.7L710,508l-0.6,1.2l-0.4,3.4l-1.9,4.3v1.9l-0.6,1.4l-0.2,1.6l-1.9,3.9l-0.3,1.8l-3.3,4.8l-0.6,2.1V545l0.5,1.4l-1.4,6.4
        l-3.5,5.4l-1.7,1.1v1.8l-0.6,1.4l-0.1,1.8l0.9,0.6l0.8,2.5l-0.9,1.1l-0.2,1.7l-0.6,1.2l-0.9,6.9l-0.9,1l0.1,1.2l0.8,1.2l-1,1.1
        l-1.5,0.6l-0.5,3.4l-1.3,1.5l-0.1,12.8l-0.9,1.8l-0.1,3.9l-0.9,3l-1.4,1.2l-0.4,1.7l-1.6,2.4l-0.2,1.9l-1.2,3.3l-0.1,3.7l-0.9,1.4
        l-0.2,2.1l0.5,0.4l0.6,1.7v3.8l1.7,2.5l-0.1,3.7l-0.5,1.5l-1,1.1l-1.5,0.6l-1.8,0.1l0.4,0.5l1.7,1l-0.8,1.5v1.9l-0.9,3.3v1.9
        l-0.6,1.5L676.8,655.5L676.8,655.5L676.8,655.5z"/>
      `
    },
    {
      title: '宜蘭縣',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M808,191.5l1.5,0.9l1.3-0.1l0.3-1.6l1.8-0.1l1.8,0.3l0.5,1.6l1.6,1l-0.6,1l-1.6,0.6l-3.7-0.1
        l-2-1.9L808,191.5L808,191.5L808,191.5z"/>
      <path d="M671.3,248.9l-0.5,1.2l-1.6,0.5l-3.5,0.3l-1.3,0.8l-1.8,2.4L662,259l-2.3,3l-0.4,1.5l-0.6,1.3
        l-0.4,1.5l-3.4,5.3l-0.3,1.6h-1.8l-1.4,0.6l-1.1,0.9l-0.4,0.7l-0.9,0.9l0.1,0.6l1.1,0.8l0.6,1.4l0.1,1.8l0.5,1.4l-1.3,1.1
        l-0.5,0.3l-0.5,0.8l-1.3,0.8l-1.4,1.5l-0.4,1.6l0.6,1.3l0.1,7.3l-0.8,1.8l-1.1,1.4l-1.4,1l-1.6,2.5l-1.8,1.4l-0.5,1.3l-1.4-0.1
        l-3.4,4.9l-1.3,0.8l-1.9-0.4l-1.4,2.8l-1.4,0.8L628,318l-0.9,1.1l-1.5,1.3l-1.3,2.5l0.1,1.6l-0.7,1l1.4,1.7l0.9,1.8l0.5,2.1
        l-0.9,7.1l2.7,2.7l2,0.7h4.8l4.1,7l1.8,0.9l2.7-0.4l1.2-1.6l1.6-1.2l2.3-0.4l1.8-0.9l7.1-0.9l2.1,1.1l1.1,1.8l1.6,1.1l1.3,2.1
        l12,7.7h8.1l3,1.3l4,0.5l8.1,5.6l8.3,1l3-0.8l-3-2.5l0.8-3l3.3-0.8l2.3-1.5l2.3,1l2-1.8l-0.8-1.5l-2-1.8l0.3-3.3l2-0.8l4.3,3.8
        l4.8,1.5l4.5,3.5l9.1,3.8l7.3,0.5l8.1,4l4.1,0.5l-0.2-4l-0.8-1.2l0.1-3.7l2.5-4.8l0.4-1.5l1.5-2.5l0.5-10.9l0.5-1.6l0.9-1.2v-2.1
        l1-3.5l1.8-2.4l0.5-1.8l2.7-1.6l1-1.2l1.3-2.6l0.1-1.8l4.5-4.3l4.6-1.9h1.9l0.5-0.2l-1.4-0.8l-1-1l-0.8-1.2v-1.9l-2.5-1.5
        l-0.5-4.9l1.8-1.1l0.9-1.3l3.3-0.6l2.8-1.8l1.7-0.4l0.5-1.7l-0.1-1.5l-0.6-1.8l-0.1-1.8l-0.8-1.1l-0.3-3.5l0.8-1.1l-0.9-1.4
        l0.7-1.5h1.9l-0.2-1.4l-1-0.9l-0.2-0.5l1.5-0.7l-1.2,0.3l-1.8-0.7l-0.1-1.2l-2.4-2.2l0.4-1.6l1.8-0.1l1.5,0.9l0.2,1.8l1.8-0.1
        l1.5-0.8l-3.7-3.7l-3-1l-0.4-0.8l-0.5-0.2l-1.6-2.2l-0.1-1.3L779,255l-0.1-3.8l-0.8-1.1l0.4-0.6v-2l-0.5-1.7l0.5-10.2l-2.9-3.2
        l-0.9-1.5v-1.7l-1.4-1.6l-0.6-3.4l0.1-1.8l-1-1l0.5-14l0.7-2.2v-1.9l1.2-2.8l-0.2-3.7l1-3l0.1-1.8l2.4-5.2l0.2-1.8l1.3-2.2
        l1.5-0.8l1-2.9l0.9-1.1l1.4-0.5l3.8-4.4l1.3-0.6l3.2-3.7l0.2-3.5l0.5-1.5l2.8-1.6l1.5-0.4l1.5-2.2l1.2-0.9l1.2-0.6l2.5-3.5
        l0.3-1.9l1.8-1l1.8-0.2l1.2-0.5l1.1-1.1l1.7-0.2l1.2-0.8l1.9-0.1l2.7-1.2l-3.6-0.7l-2.5,0.2l-6.2,2.3l-5.4,0.4l-6.8,4.8l-1.4,1.6
        h-2.9l-2.3-0.9l-5.2-0.4l-1.8,1.4l-0.5,2.1l-0.9,1.8l4.1,5.9l-1.4,4.3l-5.9,3.9l-5.4-0.4l-2.3,0.4l-3,2.5l-2.5,3.2l0.5,2.3
        l-0.2,2.1l-1.4,1.8l-0.2,2.1l0.2,1.2l-2.5,0.5l-1.8,0.9l-7.5-1.1l-2.3,0.4l-2,1.1l-2.7,3.2l-4.8,2.6l-0.5,1.7l-1.8,1.6l-1.1,1.8
        l-2.1,0.7l-0.7,2l-1.6-1.8l-3.6,1.8l-10.5,1.2l1.4,1.2l-0.5,2.1l-2.3,1.8l-2.5-0.7l-1.8,1.1l-2.1,0.5l-0.9,2l0.2,2.5l-0.4,2.3
        l-2,1.6l0.4,2.5l1.4,1.4l0.7,2.1v2.7l-1.4,4.3l-2.3,0.4l-1.6,1.2l-1.1,1.6l-2.1,0.7l-2.7,0.2l-5.9,3.2l-0.5,2.5l-5.2,3.4l-4.6,0.7
        l-2.3,1.4L671.3,248.9L671.3,248.9L671.3,248.9z"/>
      `
    }
  ]
}
