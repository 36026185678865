<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px)")
    div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="d-flex justify-content-between position-relative pt-xl-3")
        div(class="d-flex")
          h4(class="font-weight-bold text-left") 我的帳戶
      div(class="mt-4 pt-3 text-black")
        div(class="green-dot2") 僅密碼、電子信箱為可異動資料
        div
          span 如其他資料有錯誤或異動，請與總公司聯繫
          span(v-if="SystemRights.loaded") {{ SystemRights.data[0].telphone }}。
      div(class="mt-4 text-dark")
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 管理編號
          div(class="col-12 col-md-10") {{ UserData.manage_id }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 負責人
          div(class="col-12 col-md-10") {{ UserData.company_owner }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 姓名/公司名稱
          div(class="col-12 col-md-10") {{ UserData.name }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 配偶
          div(class="col-12 col-md-10") {{ UserData.spouse_name }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 住宅電話
          div(class="col-12 col-md-10") {{ UserData.home_phone }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 行動電話1 (簡訊發送以此電話為主)
          div(class="col-12 col-md-10") {{ UserData.mobile_phone01 }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 行動電話2
          div(class="col-12 col-md-10") {{ UserData.mobile_phone02 }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 手機條碼
          div(class="col-12 col-md-10") {{ UserData.barcode01 }}
        div(class="mt-3 form-group row align-items-center")
          div(class="col-12 col-md-2 green-dot") 修改密碼
          router-link(to="/member/change")
            button(class="mt-2 mt-md-0 px-3 py-2 btn btn-outline-success") 修改密碼
        div(class="mt-3 form-group row align-items-center")
          div(class="col-12 col-md-2 green-dot") 電子信箱
          div(class="col-12 col-md-5 p-0 my-2")
            InputBox(class="" type="text" placeholder="此為日後寄送訂單訊息的聯絡信箱，請填寫正確" :model="model" handle="email" @input="handleCDB($event, model)")
            ErrorMessage(class="text-left mt-0" :model="model" handle="email")
        div(class="mt-3 form-group row align-items-center")
          div(class="col-12 col-md-2 green-dot") 確認電子信箱
          div(class="col-12 col-md-5 p-0 mr-4 my-2")
            InputBox(class="" type="text" placeholder="請再次輸入您的電子信箱" :model="model" handle="confirm_email" @input="handleCDB($event, model)")
            ErrorMessage(class="text-left mt-0" :model="model" handle="confirm_email")
          //- button(class="mt-2 mt-md-0 px-3 py-2 btn btn-outline-success" @click="submit") 送出儲存
          ButtonBox(class="my-2" :model="model" @click="submit") 送出儲存
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 戶籍地址
          div(class="col-12 col-md-10") {{ UserData.residence_address }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 通訊地址
          div(class="col-12 col-md-10") {{ UserData.mailing_address }}
        div(class="mt-3 form-group row")
          div(class="col-12 col-md-2") 有效期限
          div(class="col-12 col-md-10") {{ dayFormat(UserData.expiry_day,'YYYY-MM-DD') }}
</template>

<script>
import { mapState } from 'vuex'
import { updateUser } from '@/api'
import { UserModel } from '@/models'

export default {
  name: 'Account',
  data () {
    return {
      model: new UserModel(),
      submitDisabled: false
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
    }
  },
  mounted () {
    this.model.email = this.UserData.email
  },
  methods: {
    handleCDB (e, model) {
      this.model.validate({
        email: () => {
          // eslint-disable-next-line no-control-regex
          if (this.model.email.match(/[^\x00-\xff]/g)) {
            return { inclusion: { message: '^請再次確認電子信箱是否相同 (限半形，中英文並一定要有@才是正確的 MAIL 格式)' } }
          }
          return {
            email: {
              email: true,
              message: '^請填寫正確的電子信箱格式'
            }
          }
        },
        confirm_email: () => {
          // eslint-disable-next-line no-control-regex
          if (this.model.confirm_email.match(/[^\x00-\xff]/g)) {
            return { inclusion: { message: '^請再次確認電子信箱是否相同 (限半形，中英文並一定要有@才是正確的 MAIL 格式)' } }
          }
          return {
            email: {
              email: true,
              message: '^請填寫正確的電子信箱格式'
            }
          }
        }
      })
    },
    async submit () {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate({
        email: () => {
          if (this.email === '') {
            return { inclusion: { message: '^請填寫電子信箱' } }
          } else if (this.email !== this.confirm_email) {
            return { inclusion: { message: '^電子信箱不一致' } }
            // eslint-disable-next-line no-control-regex
          } else if (this.model.email.match(/[^\x00-\xff]/g)) {
            return { inclusion: { message: '^請再次確認電子信箱是否相同 (限半形，中英文並一定要有@才是正確的 MAIL 格式)' } }
          }
          return {
            email: {
              email: true,
              message: '^請填寫正確的電子信箱格式'
            }
          }
        },
        confirm_email: {
          presence: {
            allowEmpty: false,
            message: '^請再次確認電子信箱是否相同 (限半形，中英文並一定要有@才是正確的 MAIL 格式)'
          },
          equality: {
            attribute: 'email',
            message: '^請再次確認電子信箱是否相同 (限半形，中英文並一定要有@才是正確的 MAIL 格式)'
          }
        }
      })
      if (errorMessage.length) {
        return
      }
      await this.$nextTick()
      this.model.loaded = false
      this.submitDisabled = true
      const data = {
        email: this.model.email
      }
      updateUser(data).then(() => {
        this.model.loaded = true
        this.submitDisabled = false
        this.UserData.set({
          email: this.model.email
        })
      }).catch(() => {
        this.model.loaded = true
        this.submitDisabled = false
      })
    }
  },
  computed: {
    ...mapState({
      SystemRights: state => state.model.SystemRights,
      UserData: state => state.model.UserData
    })
  }
}
</script>

<style lang="scss" scoped>
.green-dot{
  position: relative;
  &::before{
    content:"";
    position: absolute;
    width: 8px;
    height: 8px;
    color:#56870F;
    background:#56870F;
    border-radius: 50%;
    left:0px;
    top:8px;
  }
}
.green-dot2{
  position: relative;
  &::before{
    content:"";
    position: absolute;
    width: 8px;
    height: 8px;
    color:#56870F;
    background:#56870F;
    border-radius: 50%;
    left:-15px;
    top:8px;
  }
}
</style>
