export function mountedCheck (dom, reject) {
  return new Promise(resolve => {
    let i = 0
    const start = () => {
      i++
      if (!dom) {
        if (i > 60) {
          return console.warn('mountedCheck not dom.')
        }
        return requestAnimationFrame(start)
      }
      if (dom.offsetWidth === 0 || dom.offsetHeight === 0) {
        if (reject) reject()
        requestAnimationFrame(start)
      } else {
        resolve()
      }
    }
    start()
  })
}
