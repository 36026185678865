import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
*/
export default class GeneralAddressModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.name = entity.name || ''
    this.phone = entity.phone || ''
    this.postal_code = entity.postal_code || ''
    this.city = entity.city || ''
    this.district = entity.district || ''
    this.address = entity.address || ''
  }

  toDatabase (addObject) {
    return {
      name: this.name,
      phone: this.phone,
      postal_code: this.postal_code,
      city: this.city,
      district: this.district,
      address: this.address,
      ...addObject
    }
  }

  rules () {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^請填寫收件人'
        },
        words: {
          test: /[\u4e00-\u9fa5]|\s|[a-z]|[A-Z]/,
          message: '^僅能輸入中英文 (限半形，中英文) '
        }
      },
      phone: {
        presence: {
          allowEmpty: false,
          message: '^請填寫收貨人電話'
        },
        words: {
          test: /[0-9]/,
          message: '^僅能輸入數字 (限半形，數字，9-10 碼) '
        },
        length: {
          maximum: 10,
          tooLong: '^聯絡電話最多為10碼'
        }
        // phone: {
        //   message: '^填寫的電話號碼格式不正確'
        // }
      },
      postal_code: {
        presence: {
          allowEmpty: false,
          message: '^請填寫郵遞區號'
        },
        length: {
          minimum: 3,
          tooShort: '^郵遞區號最少3碼',
          maximum: 6,
          tooLong: '^郵遞區號最多6碼'
        },
        words: {
          test: /[0-9]/,
          message: '^僅能輸入數字'
        }
      },
      city: {
        presence: {
          allowEmpty: false,
          message: '^請選擇寫城市'
        }
      },
      district: {
        presence: {
          allowEmpty: false,
          message: '^請選擇地區'
        }
      },
      address: () => {
        console.log('address', this.address)
        if (this.postal_code === '' && this.city === '' && this.district === '' && this.address === '') {
          return { inclusion: { message: '^請確實填寫收貨地址' } }
          // eslint-disable-next-line no-control-regex
        } else if (this.address === '') {
          return { inclusion: { message: '^請確實填寫收貨地址' } }
          // eslint-disable-next-line no-control-regex
        } else if (!this.address.match(/^(\w|[\u4E00-\u9FA5()-])*$/)) {
          return { inclusion: { message: '^請確實填寫收貨地址，勿填入特殊符號 (限半形，中英文及數字還有括弧)\n' } }
        }
        if (this.errors) {
          if (this.errors.postal_code) {
            return { inclusion: { message: `^${this.errors.postal_code}` } }
          }
          if (this.errors.city) {
            return { inclusion: { message: `^${this.errors.city}` } }
          }
          if (this.errors.district) {
            return { inclusion: { message: `^${this.errors.district}` } }
          }
        }
        return {
          presence: {
            allowEmpty: false,
            message: '^請確實填寫收貨地址'
          }
          // words: {
          //   test: /[\u4E00-\u9FA5]|[a-zA-Z]|[0-9]|[、]|[_]|\(|\)/,
          //   message: '^僅能輸入中文、英文、數字，符號僅能 (、)'
          // }
        }
      }
    }
  }
}
