import Validate from 'validate.js'

Validate.validators.phone = (value, options) => {
  const length = value.length
  if (
    value.split('').every(s => /[0-9]/.test(s) && length === 10)
  ) {
    return undefined
  }
  return options.message
}
// 重新訂製規則
