<template lang="pug">
  div(class="lighbox position-relative p-4")
    div(class="rounded-lg" style="background: #ffffff;")
      div(class="px-3 py-4")
        <button class="close_button close" type="button" @click="dialogClose">
        <span>&times;</span>
        </button>
        div(class="" style="background: #ffffff;" )
          div(class="px-5 py-4")
            div(style="height: 640px; overflow-y: auto;" v-html="message.content")
        div(class="my-2 text-center")
          button(class="btn btn-success" type="button" @click="dialogClose" ) 關閉
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'HomeLightbox',
  data () {
    return {
      message: {
        content: 'aaa'
      },
      checkbox: false
    }
  },
  props: {
    attrs: Object
  },
  created () {
    this.model = this.attrs.model
    this.checkbox = this.attrs.checkbox
    this.message.content = this.attrs.content
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Seometas: state => state.model.Seometas,
      SystemImages: state => state.model.SystemImages,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData,
      Search: state => state.global.search
    })
  },
  methods: {
    dialogClose () {
      const check = () => {
        this.$dialog.close()
        this.checkbox = true
      }
      check()
    }
  }
}
</script>
<style>
 .lighbox iframe {
   margin: auto;
 }
 .z-index-10{
   z-index: 10;
 }
</style>
