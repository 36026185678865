export default {
  mount: '.icon-prod-hand',
  attrs: {
    viewBox: '0 0 450 230',
    fill: 'currentColor'
  },
  path: `
  <path d="M92.3,225.7c-11.6,0-21-2.4-27.8-7c-0.7-0.5-1.1-1.2-1.1-2.1v-11.8c-3.5-3.2-7.2-7.3-11.1-12.3
    c0,0,0-0.1-0.1-0.1c-11.9-16.8-16.5-44.1-17.3-53.2c-0.5-5.5-0.2-10.7,0.8-15.6L9.6,79.4c-6.3-8.8-7.7-16.2-4.1-21.9
    c2-3.2,4.8-5,8-5.5c4.1-0.5,8.9,1.3,12.8,5c1.1,1,4.2,3.9,28.8,40.8L43.6,55.1c-3.4-11.2-5-18.8-5-23.1c0-9.8,7.2-14.1,14.4-14.1
    c6.6,0,11.8,4.4,15.2,13.2c0,0,0,0.1,0.1,0.2l17.2,53.5c-0.2-55,1.1-65.7,2.2-68.8c1.7-5,4.9-8.6,8.8-10.3
    c3.6-1.5,7.7-1.3,11.9,0.7c6.5,3.1,9.8,11.3,9.7,24.4v54.8c7.6-21.1,12.4-33.7,14.6-38.4c1.2-2.6,2.4-5.5,3.6-8.3
    c2.7-6.5,5-12.1,7.9-14.8c0,0,0.1-0.1,0.1-0.1c3.5-3,12.2-4,17,0.8c3.2,3.2,3.9,9.1,2.3,17.9c0,0.1,0,0.1,0,0.2l-19.2,72.2
    c1.6,4.9,2.8,9.5,3.6,13.9c3.3-1.7,7.8-3.8,13.7-6.3c5.1-8.9,12-14.5,20.7-16.6c9.2-2.2,17.8,0.9,25.6,9.2c0.5,0.5,0.7,1.1,0.7,1.8
    s-0.3,1.3-0.8,1.7l-28.5,26.2c-12.8,17.7-20.7,28.1-24.2,31.9c-3.4,3.7-12.7,11.2-28.4,23.1v16.5c0,1-0.6,1.9-1.5,2.3
    C114.8,223.4,103.7,225.7,92.3,225.7z M68.4,215.3c5.9,3.6,13.9,5.4,23.9,5.4c10.2,0,20-1.9,29.3-5.7v-16.1c0-0.8,0.4-1.5,1-2
    c15.8-11.9,25.5-19.7,28.8-23.2c3.3-3.5,11.3-14.2,24-31.6c0.1-0.1,0.2-0.3,0.3-0.4l26.8-24.6c-6-5.7-12.2-7.6-19-6
    c-7.6,1.8-13.5,6.7-18,14.9c-0.3,0.5-0.7,0.9-1.2,1.1c-8.4,3.5-14.1,6.2-17,8.1c-0.7,0.5-1.6,0.5-2.4,0.2c-0.8-0.4-1.3-1.1-1.4-2
    c-0.6-5.3-2-11.2-4.1-17.3c-0.2-0.5-0.2-1-0.1-1.5l19.4-72.9c1.7-9.1,0.1-12.3-0.9-13.4c-2.7-2.7-8.3-2.1-10.1-0.6
    c-2.1,2.1-4.4,7.7-6.7,13.1c-1.2,2.8-2.4,5.8-3.7,8.5c-2.6,5.4-9,22.7-19.2,51.5c-0.4,1.1-1.6,1.8-2.8,1.6
    c-1.2-0.2-2.1-1.2-2.1-2.5V30.8c0.1-11-2.2-17.6-6.9-19.8c-2.9-1.4-5.5-1.6-7.8-0.6c-2.6,1.1-4.8,3.7-6,7.3
    c-1.1,3.2-2.3,20.3-1.8,83.4c0,1.2-0.9,2.3-2.1,2.5c-1.2,0.2-2.4-0.5-2.8-1.7L63.5,32.9c-2.6-6.7-6.1-10-10.5-10
    c-8.7,0-9.4,7-9.4,9.1c0,3.8,1.6,11.1,4.8,21.7l15.3,57.5c0.3,1.2-0.3,2.4-1.4,2.9c-1.1,0.5-2.4,0.1-3.1-0.9
    C32.8,73.2,24.8,62.5,22.8,60.7c-2.7-2.6-6.1-4-8.7-3.7c-1.8,0.2-3.3,1.3-4.4,3.2c-2.4,3.8-1,9.3,4,16.4c0,0.1,0.1,0.1,0.1,0.2
    l26.8,45.2c0.3,0.6,0.4,1.2,0.3,1.8c-1.1,4.7-1.4,9.7-0.9,15.1c0.7,8.1,5.2,34.9,16.4,50.7c4,5.1,7.8,9.3,11.3,12.4
    c0.5,0.5,0.8,1.2,0.8,1.9V215.3z"/>
  <path d="M356.7,225.7c-11.5,0-22.5-2.3-32.8-6.8c-0.9-0.4-1.5-1.3-1.5-2.3v-16.5c-15.7-11.8-25-19.4-28.4-23.1
    c-3.4-3.7-11.4-14.1-24.2-31.9L241.3,119c-0.5-0.5-0.8-1.1-0.8-1.7s0.2-1.3,0.7-1.8c7.8-8.3,16.4-11.4,25.6-9.2
    c8.7,2.1,15.7,7.7,20.7,16.6c5.9,2.5,10.5,4.5,13.7,6.3c0.7-4.4,1.9-9.1,3.6-13.9l-19.2-72.2c0-0.1,0-0.1,0-0.2
    c-1.6-8.9-0.9-14.7,2.3-17.9c4.8-4.9,13.5-3.9,17-0.8c0,0,0.1,0.1,0.1,0.1c2.9,2.8,5.2,8.4,7.9,14.8c1.2,2.8,2.3,5.7,3.6,8.3
    c2.2,4.7,7,17.3,14.6,38.4V30.8c-0.1-13,3.2-21.2,9.7-24.3c4.2-2,8.3-2.2,11.9-0.7c4,1.6,7.1,5.3,8.8,10.3
    c1.1,3.1,2.4,13.8,2.2,68.8l17.2-53.5c0-0.1,0-0.1,0.1-0.2c3.4-8.8,8.6-13.2,15.2-13.2c7.2,0,14.4,4.4,14.4,14.1
    c0,4.3-1.6,11.9-5,23.2L394,97.9C418.6,60.9,421.7,58,422.8,57c3.9-3.6,8.6-5.5,12.8-5c3.3,0.4,6.1,2.3,8,5.5
    c3.6,5.7,2.2,13.1-4.1,21.9l-26.2,44.2c1,4.9,1.3,10.1,0.8,15.6c-0.8,9.1-5.4,36.4-17.3,53.2c0,0,0,0.1-0.1,0.1
    c-3.9,5-7.6,9.1-11.1,12.3v11.8c0,0.8-0.4,1.6-1.1,2.1C377.7,223.4,368.4,225.7,356.7,225.7z M327.5,215c9.2,3.8,19.1,5.7,29.3,5.7
    c10,0,18-1.8,23.9-5.4v-11.6c0-0.7,0.3-1.4,0.8-1.9c3.5-3.1,7.3-7.3,11.3-12.4c11.2-15.8,15.7-42.5,16.4-50.7
    c0.5-5.3,0.1-10.4-0.9-15.1c-0.1-0.6,0-1.3,0.3-1.8l26.8-45.2c0-0.1,0.1-0.1,0.1-0.2c5.1-7.1,6.4-12.6,4-16.4
    c-1.2-1.9-2.6-2.9-4.4-3.2c-2.6-0.3-6,1.1-8.7,3.7c-2,1.9-10,12.6-36.4,52.7c-0.7,1-2,1.4-3.1,0.9c-1.1-0.5-1.7-1.7-1.4-2.9
    l15.3-57.4c3.2-10.7,4.8-18,4.8-21.8c0-2.1-0.7-9.1-9.4-9.1c-4.4,0-7.9,3.3-10.5,10l-22.1,68.9c-0.4,1.2-1.6,1.9-2.8,1.7
    c-1.2-0.2-2.1-1.3-2.1-2.5c0.5-63.1-0.7-80.2-1.8-83.4c-1.3-3.6-3.4-6.2-6-7.3c-2.3-1-4.9-0.7-7.8,0.6c-4.6,2.2-6.9,8.9-6.9,19.8
    V100c0,1.2-0.9,2.3-2.1,2.5c-1.2,0.2-2.4-0.5-2.8-1.6c-10.2-28.8-16.7-46.1-19.2-51.5c-1.3-2.7-2.5-5.7-3.7-8.5
    c-2.2-5.4-4.5-11-6.7-13.1c-1.8-1.5-7.4-2.1-10.1,0.6c-1.1,1.1-2.6,4.3-0.9,13.4l19.4,72.9c0.1,0.5,0.1,1-0.1,1.5
    c-2.1,6.2-3.5,12-4.1,17.3c-0.1,0.9-0.6,1.6-1.4,2c-0.8,0.4-1.7,0.3-2.4-0.2c-2.9-1.9-8.7-4.6-17-8.1c-0.5-0.2-1-0.6-1.2-1.1
    c-4.5-8.2-10.3-13.1-18-14.9c-6.7-1.6-13,0.3-19,6l26.8,24.6c0.1,0.1,0.2,0.2,0.3,0.4c12.6,17.4,20.7,28.1,24,31.6
    c3.2,3.5,12.9,11.3,28.8,23.2c0.6,0.5,1,1.2,1,2V215z"/>
  `,
  pathData: [
    {
      id: 'R1',
      title: '右大拇指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path d="M282.3,160.4c0.5,0,1-0.2,1.5-0.5c1.1-0.8,1.3-2.4,0.5-3.5c-3-4.1-6.6-8.9-10.5-14.4c-0.1-0.1-0.2-0.3-0.3-0.4
        l-26.8-24.6c6-5.7,12.2-7.6,19-6c7.6,1.8,13.5,6.7,18,14.9c0.3,0.5,0.7,0.9,1.2,1.1c1.2,0.5,2.3,0.9,3.3,1.4c1.3,0.5,2.7,0,3.3-1.3
        c0.5-1.3,0-2.7-1.3-3.3c-0.8-0.4-1.7-0.7-2.6-1.1c-5.1-8.9-12-14.5-20.7-16.6c-9.2-2.2-17.8,0.9-25.6,9.2c-0.5,0.5-0.7,1.1-0.7,1.8
        s0.3,1.3,0.8,1.7l28.5,26.2c3.9,5.4,7.4,10.2,10.4,14.2C280.7,160.1,281.5,160.4,282.3,160.4z"/>
      `,
      hoverPath: `
      <path d="M290.3,170.4l12.4-38.5l-15.8-6.6c0,0-9.6-14.7-15.6-15.6c-6-1-15.9-1.2-19.5,1 c-3.5,2.2-8,6.2-8,6.2s23.4,23.2,25.7,25.2C271.7,144,290.3,170.4,290.3,170.4z"/>
      `
    },
    {
      id: 'R2',
      title: '右手食指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M303.7,103.9c0.2,0,0.4,0,0.6-0.1c1.3-0.4,2.1-1.7,1.8-3.1l-15.7-59c-1.7-9.1-0.1-12.3,0.9-13.4
        c2.7-2.7,8.3-2.1,10.1-0.6c2.1,2.1,4.4,7.7,6.7,13.1c1.2,2.8,2.4,5.8,3.7,8.5c2.1,4.4,6.7,16.5,13.8,36.1c0.5,1.3,1.9,2,3.2,1.5
        c1.3-0.5,2-1.9,1.5-3.2c-7.2-20.1-11.8-32-13.9-36.6c-1.2-2.6-2.4-5.5-3.6-8.3c-2.7-6.5-5-12.1-7.9-14.8c0,0-0.1-0.1-0.1-0.1
        c-3.5-3-12.2-4-17,0.8c-3.2,3.2-3.9,9.1-2.3,17.9c0,0.1,0,0.1,0,0.2l15.7,59.1C301.6,103.1,302.6,103.9,303.7,103.9z"/>
      `,
      hoverPath: `
      <path d="M307.6,115l24.8-18.5l-23.7-62.8c0,0-3.6-10.8-12.2-9.7S286.3,33,287,36.8S307.6,115,307.6,115z"/>
      `
    },
    {
      id: 'R3',
      title: '右手中指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M333.4,85.3c1.4,0,2.5-1.1,2.5-2.5v-52c-0.1-11,2.2-17.6,6.9-19.8c2.9-1.4,5.5-1.6,7.8-0.6
        c2.6,1.1,4.8,3.7,6,7.3c0.9,2.7,2.1,15.7,2,62.5c0,1.4,1.1,2.5,2.5,2.5c0,0,0,0,0,0c1.4,0,2.5-1.1,2.5-2.5
        c0.1-51.5-1.2-61.3-2.2-64.2c-1.7-5-4.9-8.6-8.8-10.3c-3.6-1.5-7.7-1.3-11.9,0.7c-6.6,3.2-9.8,11.1-9.7,24.4v52
        C330.9,84.2,332.1,85.3,333.4,85.3z"/>
      `,
      hoverPath: `
      <path d="M332.3,96.5l29.4,2l-2-77.2c0,0-1.4-14-10.2-14.4c-8.8-0.4-13.9,5.8-14.2,8.2 S332.3,96.5,332.3,96.5z"/>
      `
    },
    {
      id: 'R4',
      title: '右無名指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M393.9,91.1c1.1,0,2.1-0.7,2.4-1.9l9.1-34.1c3.4-11.2,5-18.8,5-23.1c0-9.8-7.2-14.1-14.4-14.1
        c-6.6,0-11.8,4.4-15.2,13.2c0,0,0,0.1-0.1,0.2L365.1,80c-0.4,1.3,0.3,2.7,1.6,3.1c1.3,0.4,2.7-0.3,3.1-1.6l15.6-48.6
        c2.6-6.7,6.1-10,10.5-10c8.7,0,9.4,7,9.4,9.1c0,3.8-1.6,11.1-4.8,21.7l-9.1,34.2c-0.4,1.3,0.4,2.7,1.8,3.1
        C393.5,91,393.7,91.1,393.9,91.1z"/>
      `,
      hoverPath: `
      <path d="M389,107.5l18.8-71c0,0,2-11.5-4.6-13.7c-6.6-2.2-14.6-3.8-19.7,8s-21.7,67.7-21.7,67.7L389,107.5z"/>
      `
    },
    {
      id: 'R5',
      title: '右手小指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M420.2,109.6c0.9,0,1.7-0.4,2.2-1.2l17.1-28.9c6.3-8.8,7.7-16.2,4.1-21.9c-2-3.2-4.8-5-8-5.5
        c-4.1-0.5-8.9,1.3-12.8,5c-1.6,1.5-5.9,6.7-24.1,33.8c-0.8,1.1-0.5,2.7,0.7,3.5c1.1,0.8,2.7,0.5,3.5-0.7
        c16.5-24.6,21.8-31.5,23.3-33c2.7-2.6,6.1-4,8.7-3.7c1.8,0.2,3.3,1.3,4.4,3.2c2.4,3.8,1,9.3-4,16.4c0,0.1-0.1,0.1-0.1,0.2l-17.2,29
        c-0.7,1.2-0.3,2.7,0.9,3.4C419.3,109.5,419.7,109.6,420.2,109.6z"/>
      `,
      hoverPath: `
      <path d="M411.3,123l26.5-45.6c0,0,4.4-9.7,5.1-14.8c0.7-5.1-2.1-5.9-4.9-6.8c-2.8-0.9-9.6-2.3-12.1,1.1 c-2.4,3.4-37.1,53.5-37.1,53.5L411.3,123z"/>
      `
    },
    {
      id: 'L1',
      title: '左大拇指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M166.7,160.4c-0.5,0-1-0.2-1.5-0.5c-1.1-0.8-1.3-2.4-0.5-3.5c3-4.1,6.6-8.9,10.5-14.4c0.1-0.1,0.2-0.3,0.3-0.4
        l26.8-24.6c-6-5.7-12.2-7.6-19-6c-7.6,1.8-13.5,6.7-18,14.9c-0.3,0.5-0.7,0.9-1.2,1.1c-1.2,0.5-2.3,0.9-3.3,1.4
        c-1.3,0.5-2.7,0-3.3-1.3c-0.5-1.3,0-2.7,1.3-3.3c0.8-0.4,1.7-0.7,2.6-1.1c5.1-8.9,12-14.5,20.7-16.6c9.2-2.2,17.8,0.9,25.6,9.2
        c0.5,0.5,0.7,1.1,0.7,1.8s-0.3,1.3-0.8,1.7l-28.5,26.2c-3.9,5.4-7.4,10.2-10.4,14.2C168.3,160.1,167.5,160.4,166.7,160.4z"/>
      `,
      hoverPath: `
      <path d="M158.7,170.4l-12.4-38.5l15.8-6.6c0,0,9.6-14.7,15.6-15.6c6-1,15.9-1.2,19.5,1c3.5,2.2,8,6.2,8,6.2 s-23.4,23.2-25.7,25.2C177.2,144,158.7,170.4,158.7,170.4z"/>
      `
    },
    {
      id: 'L2',
      title: '左手食指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M145.3,103.9c-0.2,0-0.4,0-0.6-0.1c-1.3-0.4-2.1-1.7-1.8-3.1l15.7-59c1.7-9.1,0.1-12.3-0.9-13.4
        c-2.7-2.7-8.3-2.1-10.1-0.6c-2.1,2.1-4.4,7.7-6.7,13.1c-1.2,2.8-2.4,5.8-3.7,8.5c-2.1,4.4-6.7,16.5-13.8,36.1
        c-0.5,1.3-1.9,2-3.2,1.5c-1.3-0.5-2-1.9-1.5-3.2c7.2-20.1,11.8-32,13.9-36.6c1.2-2.6,2.4-5.5,3.6-8.3c2.7-6.5,5-12.1,7.9-14.8
        c0,0,0.1-0.1,0.1-0.1c3.5-3,12.2-4,17,0.8c3.2,3.2,3.9,9.1,2.3,17.9c0,0.1,0,0.1,0,0.2L147.7,102
        C147.4,103.1,146.4,103.9,145.3,103.9z"/>
      `,
      hoverPath: `
      <path d="M141.4,115l-24.8-18.5l23.7-62.8c0,0,3.6-10.8,12.2-9.7c8.6,1.1,10.2,9.1,9.5,12.8 S141.4,115,141.4,115z"/>
      `
    },
    {
      id: 'L3',
      title: '左手中指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M115.6,85.3c-1.4,0-2.5-1.1-2.5-2.5v-52c0.1-11-2.2-17.6-6.9-19.8c-2.9-1.4-5.5-1.6-7.8-0.6
        c-2.6,1.1-4.8,3.7-6,7.3c-0.9,2.7-2.1,15.7-2,62.5c0,1.4-1.1,2.5-2.5,2.5c0,0,0,0,0,0c-1.4,0-2.5-1.1-2.5-2.5
        c-0.1-51.5,1.2-61.3,2.2-64.2c1.7-5,4.9-8.6,8.8-10.3c3.6-1.5,7.7-1.3,11.9,0.7c6.6,3.2,9.8,11.1,9.7,24.4v52
        C118.1,84.2,116.9,85.3,115.6,85.3z"/>
      `,
      hoverPath: `
      <path d="M116.6,96.5l-29.4,2l2-77.2c0,0,1.4-14,10.2-14.4s13.9,5.8,14.2,8.2S116.6,96.5,116.6,96.5z"/>
      `
    },
    {
      id: 'L4',
      title: '左無名指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M55.1,91.1c-1.1,0-2.1-0.7-2.4-1.9l-9.1-34.1c-3.4-11.2-5-18.8-5-23.1c0-9.8,7.2-14.1,14.4-14.1
        c6.6,0,11.8,4.4,15.2,13.2c0,0,0,0.1,0.1,0.2L83.9,80c0.4,1.3-0.3,2.7-1.6,3.1c-1.3,0.4-2.7-0.3-3.1-1.6L63.5,32.9
        c-2.6-6.7-6.1-10-10.5-10c-8.7,0-9.4,7-9.4,9.1c0,3.8,1.6,11.1,4.8,21.7l9.1,34.2c0.4,1.3-0.4,2.7-1.8,3.1
        C55.5,91,55.3,91.1,55.1,91.1z"/>
      `,
      hoverPath: `
      <path class="st0" d="M60,107.5l-18.8-71c0,0-2-11.5,4.6-13.7s14.6-3.8,19.7,8s21.7,67.7,21.7,67.7L60,107.5z"/>
      `
    },
    {
      id: 'L5',
      title: '左手小指',
      attrs: {
        fill: 'currentColor'
      },
      path: `
      <path class="st1" d="M28.9,109.6c-0.9,0-1.7-0.4-2.2-1.2L9.6,79.4c-6.3-8.8-7.7-16.2-4.1-21.9c2-3.2,4.8-5,8-5.5
        c4.1-0.5,8.9,1.3,12.8,5c1.6,1.5,5.9,6.7,24.1,33.8c0.8,1.1,0.5,2.7-0.7,3.5c-1.1,0.8-2.7,0.5-3.5-0.7C29.6,69,24.3,62.1,22.8,60.7
        c-2.7-2.6-6.1-4-8.7-3.7c-1.8,0.2-3.3,1.3-4.4,3.2c-2.4,3.8-1,9.3,4,16.4c0,0.1,0.1,0.1,0.1,0.2l17.2,29c0.7,1.2,0.3,2.7-0.9,3.4
        C29.7,109.5,29.3,109.6,28.9,109.6z"/>
      `,
      hoverPath: `
      <path d="M37.7,123L11.1,77.5c0,0-4.4-9.7-5.1-14.8s2.1-5.9,4.9-6.8C13.7,55,20.5,53.6,23,57 c2.4,3.4,37.1,53.5,37.1,53.5L37.7,123z"/>
      `
    }
  ]
}
