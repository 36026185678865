<template lang="pug">
  div#product-list
    div(class="overflow-hidden bg-white mx-n3")
      Slick(class="benner" v-bind="bennerSlickSettings" v-if="isProductCarousel.length")
        div(class="px-sm-1 " v-for="item in isProductCarousel")
          div(class="position-relative banner-item" :class="{'cursor-pointer': item.url}" @click="linkUrl(item.url)")
            div(class="position-fill bg-cover" :style="{backgroundImage: `url(${item.image || defaultImage})`}")
    div(class="bg-white top-fix mb-2")
      Marquee(:message="marqueesContents")
    div(class="d-flex")
      div(class="col-auto d-none d-lg-block" style="width: 260px;" v-if="!Search.keyword")
        div(class="")
          ul(class="list-unstyled")
            li(class="py-1" v-for="(category, index) in Categories.data" :key="category.id || 'all'")
              router-link(class="d-flex align-items-center justify-content-between text-dark flex-grow-1" to="/product/list" v-if="category.id === -1")
                span 全部商品
                span(class="text-secondary opacity-6")
              div(class="d-flex align-items-center justify-content-between flex-grow-1" v-else-if="category.series && category.series.length")
                router-link(class="text-dark" :to="{query: { category_id: category.id }}") {{ category.name }}
                button(class="btn btn-icon text-dark"  @click="slideToggle(index)")
                  Icon(class="rotate-90" pattern="ArrowRight" size="24")
              a(class="d-flex align-items-center justify-content-between text-dark flex-grow-1" v-else)
                span {{ category.name }}
                span(class="text-secondary opacity-6") {{ category.products_count }}
              div(ref="panel")
                ul(class="list-unstyled py-1" v-if="category.series && category.series.length")
                  li(class="pl-3 py-1")
                    router-link(class="d-flex justify-content-between text-dark" :to="{query: { category_id: category.id }}")
                      span 全部
                      span(class="text-secondary opacity-6")
                  li(class="pl-3 py-1" v-for="serie in category.series" :key="serie.id")
                    router-link(class="d-flex justify-content-between text-dark" :to="{query: { series_id: serie.id, category_id: serie.category_id }}")
                      span {{ serie.name }}
                      span(class="text-secondary opacity-6")
      div(class="col")
        div(class="d-flex flex-lg-row flex-column justify-content-center justify-content-lg-between align-items-center")
          div()
            div(v-if="Search.keyword")
              span(class="mr-1") 找到
              span(class="mr-1") {{ ProductsList.data.length }}
              span(class="mr-1") 件
              span(class="text-success font-weight-bold mr-1") {{ this.Search.keyword }}
              span 商品
          div(class="col d-flex d-lg-none justify-content-center py-1" v-if="!Search.keyword")
            select(class="w-100 custom-select py-2 rounded max-w-240" @change="changeCategory" v-model="selectedSeries")
              option(:value="0" key="0" :selected="0 === selectedSeries") 全部
              option(v-for="item in selectcategory" :value="item.id" :key="item.id" :selected="item.id === selectedSeries") {{ item.name }}
          div(class="col d-flex justify-content-center justify-content-lg-end py-1")
            select(class="w-100 custom-select py-2 rounded max-w-240" v-model="selectedSort" @change="changeSort")
              option(value="0" :selected="'0' === selectedSort") 預設排序
              //option(value="1" :selected="'1' === selectedSort") 依最新商品排序
              option(value="2" :selected="'2' === selectedSort") 依價格排序：低至高
              option(value="3" :selected="'3' === selectedSort") 依價格排序：高至低
        div(class="row")
          div(class="col-6 col-md-4 col-lg-3 col-2_5 d-flex justify-content-center my-3 text-center" v-for="model in ProductsList.data")
            div(class="my-4 w-100")
              router-link(class="hover-block position-relative cursor-pointer" tag="div" :to="'/product/' + model.id")
                div(class="mb-2")
                  div(class="p-100 position-relative")
                    div(class="position-fill d-flex flex-center")
                      img(class="overflow-mix-image" v-if="model.images.length" :src="model.images[0].image")
                      img(class="overflow-mix-image" v-else :src="defaultImage")
                      div(v-if="model.quantity <= 0 && !isLuckyProduct(model.product_number) && UserData.loginStatus" class="position-absolute d-inline-block text-white px-1" style="width:100%; height:100%; background-size: contain; background-repeat:no-repeat;" :style="{backgroundImage: `url(${soldOutImage})`}")
                div(class="hover-show position-fill")
                  div(class="d-flex align-items-end justify-content-center h-100 pb-2" style="overflow: hidden; position:relative;")
                    div(class="d-flex transform-button" @click.stop v-if="UserData.loginStatus")
                        button(class="btn-cart btn-icon cursor-pointer focus-none mx-2" title="加入購物車" :class="{'d-none': inShopCart(model.id)}" @click="clickShopcart(model.id)" v-show="model.quantity > 0" v-if="!isLuckyProduct(model.product_number)")
                          Icon(pattern="ShoppingCart" size="30")
                        button(class="btn-favorite btn-icon cursor-pointer focus-none mx-2" title="加入我的最愛" :class="{'d-none': inFavorites(model.id)}" @click="clickFavorites(model.id)" v-if="!isLuckyProduct(model.product_number)")
                          Icon(pattern="Favorite" size="30")
              div(class="mb-1 w-100 text-dark text-truncate") {{ model.name }}
              div(class="w-100 text-dark suggested-price") 建議售價 NT$ {{ commaFormat(model.price) }}
              div(v-if="inShopCart(model.id)")
                div(style="border-bottom:2px white solid;color:#db0000;") 已加入購物車
    div(class="d-flex justify-content-center py-4")
      div(class="d-flex justify-content-center mb-4")
        PaginationList(v-bind="paginationSettings")
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { deleteShopcartById, getProducts, getProductsByKeyword } from '../../../api'
import { ListModel } from '../../../library/proto'
import { ProductsModel } from '../../../models'
import * as Icon from '../../../components/icon'
import defaultImage from '@/assets/images/default.png'
import soldOutImage from '@/assets/svg/sold_out.svg'
import { Toast } from './../../../utils/helper'

export default {
  name: 'ProductList',
  data () {
    return {
      bennerSlickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }]
      },
      ProductsList: new ListModel({
        model: ProductsModel
      }),
      sidebar: [],
      selectedSeries: 0,
      selectedSort: 0,
      animationGroup: [],
      sortData: [
        {
          id: 0
        },
        {
          id: 1,
          order_by: 'updated_at',
          sort: 'asc'
        },
        {
          id: 2,
          order_by: 'price',
          sort: 'asc'
        },
        {
          id: 3,
          order_by: 'price',
          sort: 'desc'
        }
      ],
      defaultImage,
      soldOutImage
    }
  },
  created () {
    // console.log('created')
    this.$store.dispatch('getListModel', {
      name: 'ProductCarousels'
    })
    this.$store.dispatch('getListModel', {
      name: 'ProductMarquees'
    })
    this.$store.dispatch('getListModel', {
      name: 'Products'
    })
    this.$store.dispatch('getListModel', {
      name: 'ShopCarts'
    })

    // console.log('UserData', this.UserData.loginStatus)

    this.getProducts(this.$route.query).then(list => {
      this.cacheModel(list)
    })
    this.productsStatusHandler()
    window.scrollTo({ top: 600 })
  },
  mounted () {
    this.$store.commit('setSearch', {
      page: 1,
      series_id: null,
      category_id: null,
      order_by: null,
      sort: null,
      keyword: null,
      ...this.$route.query
    })
    if (this.Search.keyword) {
      return
    }
    this.$store.dispatch('getListModel', {
      name: 'Categories'
    }).then(list => {
      list.data[0].set({
        name: '全部商品'
      })
      // 綁定動畫使用 DOM
      this.animationGroup = list.data.map((item, index) => {
        return {
          index
        }
      })
      this.setAnimationGroup(this.animationGroup)
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          this.animationGroup.forEach((o, i) => {
            const element = this.$refs.panel[i]
            o.element = element
          })
        })
      })
    })
    // console.log('this.ShopCarts:', this.ShopCarts.data)
  },
  computed: {
    ...mapState({
      Search: state => state.global.search,
      Carousels: state => state.model.ProductCarousels,
      Marquees: state => state.model.ProductMarquees,
      StoreProducts: state => state.model.Products,
      Categories: state => state.model.Categories,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData
    }),
    isProductCarousel () {
      return this.Carousels.data.filter(p => p.is_product_use)
    },
    marqueesContents () {
      return this.Marquees.data.filter(p => p.is_product_use).map(p => {
        return p.content
      })
    },
    paginationSettings () {
      // console.log('paginationSettings', Number(this.Search.page))
      const arrow = JSON.parse(JSON.stringify(Icon.ArrowRight))
      const arrowAttrs = Object.keys(arrow.attrs).map(key => ` ${key}="${arrow.attrs[key]}"`).join('')
      return {
        // forcePage: Number(this.Search.page),
        value: Number(this.Search.page),
        pageCount: this.ProductsList.last_page,
        prevText: `<div><svg${arrowAttrs}><g transform="rotate(180 12 12)">${arrow.path}</g></svg></div>`,
        nextText: `<div><svg${arrowAttrs}>${arrow.path}</svg></div>`,
        containerClass: 'pagination',
        pageClass: 'pagination-item',
        prevClass: 'pagination-prev',
        nextClass: 'pagination-next',
        clickHandler: this.changePage
      }
    },
    selectcategory () {
      return this.Categories.data.reduce((acc, item) => acc.concat(item.series), [])
    },
    showCartPicHandler () {
      console.log('啟動computed')
      console.log(this.ShopCarts.data[0])
      return this.ShopCarts.data[0]
    }
  },
  watch: {
    $route (to) {
      this.$store.commit('setSearch', {
        page: 1,
        series_id: null,
        category_id: null,
        order_by: null,
        sort: null,
        keyword: null,
        ...to.query
      })
      this.getProducts(to.query).then(list => {
        this.cacheModel(list)
      })
      window.scrollTo({ top: 600 })
    }
  },
  methods: {
    ...mapMutations(['setAnimationGroup']),
    getProducts (query) {
      return new Promise(resolve => {
        const queryString = this.searchQuery(query)
        if (query.keyword) {
          getProductsByKeyword(queryString).then(res => {
            this.ProductsList = new ListModel({
              model: ProductsModel,
              ...res.data
            })
            resolve(this.ProductsList)
          })
        } else {
          getProducts(queryString).then(res => {
            this.ProductsList = new ListModel({
              model: ProductsModel,
              ...res.data
            })
            resolve(this.ProductsList)
          })
        }
      })
    },
    cacheModel (resultList) {
      this.StoreProducts.setPages(resultList)
      resultList.data.forEach(model => {
        const cache = this.StoreProducts.data.find(p => Number(p.id) === Number(model.id))
        if (cache) {
          cache.set(new ProductsModel(model))
        } else {
          this.StoreProducts.data.push(new ProductsModel(model))
        }
      })
    },
    linkUrl (url) {
      if (url) {
        window.open(url)
      }
    },
    searchQuery (query = {}) {
      const search = {}
      if (query.page) search.page = query.page
      if (query.series_id) search.series_id = query.series_id
      if (query.category_id) search.category_id = query.category_id
      if (query.order_by) search.order_by = query.order_by
      if (query.sort) search.sort = query.sort
      if (query.keyword) search.keyword = query.keyword
      return search
    },
    changePage (page) {
      this.Search.page = page
      this.routerLink()
    },
    changeCategory () {
      const selectcategory = this.selectedSeries === 0 ? {} : this.selectcategory.find(p => p.id === this.selectedSeries)
      if (!selectcategory) {
        return
      }
      this.Search.category_id = selectcategory.category_id || null
      this.Search.series_id = selectcategory.id || null
      this.Search.page = 1
      this.routerLink()
    },
    changeSort () {
      const selectedSort = this.sortData.find(p => Number(p.id) === Number(this.selectedSort))
      if (!selectedSort) {
        return
      }
      this.Search.order_by = selectedSort.order_by || null
      this.Search.sort = selectedSort.sort || null
      this.routerLink()
    },
    routerLink () {
      this.$router.push({
        query: this.searchQuery(this.Search)
      })
    },
    slideToggle (index) {
      this.$store.dispatch('toggleAnimationTarget', {
        index,
        animation: {
          type: 'slide'
        }
      })
    },
    inFavorites (id) {
      // console.log(this.Favorites.data.map(p => p.id).includes(id))
      return this.Favorites.data.map(p => p.id).includes(id)
    },
    inShopCart (id) {
      return this.ShopCarts.data.map(p => p.product_id).includes(id)
    },
    clickFavorites (id) {
      if (!this.UserData.loginStatus) {
        this.$store.commit('setLoginPage', this.$route.path)
        this.$router.replace('/member/login')
        return
      }
      if (this.Favorites.data.map(p => Number(p.id)).includes(Number(id))) {
        Toast.fire({
          icon: 'success',
          title: '已加入我的最愛！'
        })
        // this.$store.dispatch('removeFavorites', id)
      } else {
        this.$store.dispatch('addFavorites', id)
        Toast.fire({
          icon: 'success',
          title: '已加入我的最愛！'
        })
      }
    },
    clickShopcart (id) {
      // console.log('clickshopcart', this.ProductsList.data)

      const model = this.ProductsList.data.filter(p => p.id === id)
      // console.log('model', model)
      if (!this.UserData.loginStatus) {
        this.$store.commit('setLoginPage', this.$route.path)
        this.$router.replace('/member/login')
        return
      }
      if (this.inShopCart(id)) {
        this.$store.dispatch('removeShopcartByProdId', id)
      } else {
        this.$store.dispatch('addShopcart', id).then(data => {
          // console.log(this.inShopCart(id))
          // console.log('addShopcart', data)
          if (data !== '') {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty('out_of_stock')) {
              if (data.out_of_stock === 1) {
                model[0].quantity = 0
                // console.log(model[0])
                deleteShopcartById(data.id)
                Toast.fire({
                  icon: 'error',
                  title: '商品庫存不足'
                })
                return
              }
            }

            Toast.fire({
              icon: 'success',
              title: '成功加入購物車'
            })
          }
        })
      }
    },
    productsStatusHandler () {
      // console.log('productsStatusHandler', this.ShopCarts)
      this.ShopCarts.data.forEach(item => {
        // console.log('狀態', item.products.status)
        // console.log('商品名稱：', item.products.name)
        if (item.products.status === 0) {
          // console.log('進入0')
          // console.log(item.products.id)
          this.$store.dispatch('removeShopcartByProdId', item.products.id)
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .suggested-price{
    font-size: .9em;
  }
  .text-truncate {
    line-height: 1.7;
  }
  .custom-select {
    text-align-last: center;
    font-size: 14px;
    max-width: 340px;
  }
  .hover-block{
    .hover-show{
      box-shadow: 0 0 0px rgb(0 0 0 / 0%);
      transition: all .3s ease-in-out;
      .transform-button{
        overflow: hidden;
        bottom: -120%;
        position: absolute;
        transition: all .3s ease-in-out;
      }
      .btn-favorite{
        width: 44px;
        height: 43px;
        background-color: rgba(165,38,49,0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #fff;
        transition: all .3s ease-in-out;
        &:hover {
          background-color: rgba(165,38,49,1);
        }
      }
      .btn-cart{
        width: 44px;
        height: 43px;
        background-color: rgba(96, 157, 33 ,0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #fff;
        transition: all .7s ease-in-out;
        &:hover {
          background-color: rgba(96, 157, 33,1);
        }
      }
    }
    &:hover{
      .hover-show{
        display: block;
        box-shadow: 0 0 5px rgb(0 0 0 / 20%);
      }
      .transform-button{
        bottom: 5px;
      }
    }
  }
  .overflow-mix-image {
    max-width: 100%;
    max-height: 100%;
  }
  #product-list {
    font-size: 14px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .max-w-240 {
    max-width: 240px;
  }
  // #btn-favorite {
  //   position: absolute;
  // }
  @media (min-width: 1200px) {
    .col-2_5 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
</style>
