<template>
  <button v-on="$listeners" :class="{'is-invalid': error}" @click="click" :disabled="this.state === 'loading'" type="button">
    <span ref="loadingBox" class="loading-box" :class="{'animation': this.state === 'success','loading': this.state === 'loading'}">
      <span class="checkline-left"></span>
      <span ref="loadingRight" class="checkline-right"></span>
    </span>
    <span>
      <slot v-if="this.state === 'static' && $slots.icon" name="icon"></slot>
      <slot v-if="this.state === 'error' && $slots.icon" name="icon"></slot>
    </span>
    <span class="slot-box">
      <slot v-if="this.state === 'success' && $slots.success" name="success"></slot>
      <slot v-else-if="this.state === 'error' && $slots.error" name="error"></slot>
      <slot v-else-if="this.state === 'loading' && $slots.loading" name="loading"></slot>
      <slot v-else name="default"></slot>
    </span>
  </button>
</template>

<script>
import { DataModel } from '../../../library/proto'

/**
 * 注意事項： model 請務必使用 set 方法來進行檔案覆蓋，否則物件位址跑掉導致偵測異常
 */
export default {
  name: 'ButtonBox',
  props: {
    model: {
      type: DataModel,
      default: () => new DataModel()
    }
  },
  data () {
    return {
      submit: false,
      state: 'static'
    }
  },
  watch: {
    'model.loaded': {
      handler (loaded) {
        if (loaded) {
          if (this.submit) {
            this.submit = false
            this.animation()
          }
        } else {
          this.state = 'loading'
        }
      }
    },
    'model.errors': {
      handler (errors) {
        if (errors && Object.keys(errors).length && Object.keys(errors).some(k => errors[k])) {
          this.state = 'error'
        } else {
          this.state = 'static'
        }
      }
    }
  },
  computed: {
    error () {
      return this.state === 'error'
    }
  },
  methods: {
    animation () {
      const loadingBox = this.$refs.loadingBox
      const loadingRight = this.$refs.loadingRight
      requestAnimationFrame(() => {
        const loadingRightLine = () => {
          loadingRight.removeEventListener('transitionend', loadingRightLine)
          setTimeout(() => {
            this.state = 'static'
          }, 1500)
        }
        const endLoad = () => {
          requestAnimationFrame(() => {
            if (this.error) {
              this.state = 'error'
              return
            }
            this.state = 'success'
            loadingBox.removeEventListener('transitionend', endLoad)
            loadingRight.addEventListener('transitionend', loadingRightLine)
          })
        }
        loadingBox.addEventListener('animationiteration', endLoad)
      })
    },
    click () {
      this.submit = true
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: none;
    overflow: visible;
    padding: 0.375rem 1.5rem;
    color: #fff;
    background-color: #56870F;
    border-color: #56870F;
    > span {
      pointer-events: none;
    }
    &:not(:disabled):not(.disabled){
      cursor: pointer;
    }
    &:focus{
      outline: 0;
      background-color: #56870F;
      border-color: #39590a;
      box-shadow: 0 0 0 0.2rem rgb(111 153 51 / 50%);
    }
    &:active{
      background-color: #39590a;
      border-color: #314e09;
    }
    &:hover{
      background-color: #009140;
      border-color: #39590a;
    }
    // &.is-invalid{
    //   border-color: #A30000;
    //   background-color: #A30000;
    // }
    &:disabled{
      background-color: #56870F;
      opacity: 0.65;
    }
  }
  .loading-box{
    position: relative;
    transform: rotate(0deg);
    width: 0;
    height: 1.5rem;
    font-size: 1rem;
    margin: 0;
    border: 0 solid transparent;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.2s;
    .checkline-left{
      position: absolute;
      bottom: 43%;
      left: 20%;
      height: 12%;
      width: 32%;
      background-color: #009140;
      transform-origin: left;
      transform: rotate(45deg) scale(0,1);
      transition: all 0.2s 0.1s;
      border-radius: 99rem;
    }
    .checkline-right{
      position: absolute;
      top: 68%;
      left: 38%;
      height: 12%;
      width: 60%;
      background-color: #009140;
      transform-origin: left;
      transform: rotate(-45deg) scale(0,1);
      transition: all 0.2s 0.2s;
      border-radius: 99rem;
    }
    &.loading{
      margin: 0 0.25rem;
      width: 1.5rem;
      border-width: 0.25rem;
      border-color: transparent #fff #fff #fff;
      animation: rotate 1s linear infinite;
    }
    &.animation{
      margin: 0 0.25rem;
      width: 1.5rem;
      border: 0 solid #fff;
      background-color: #ffffff;
      .checkline-left{
        transform: rotate(45deg) scale(1);
      }
      .checkline-right{
        transform: rotate(-45deg) scale(1);
      }
    }
  }
  .slot-box {
    display: flex;
    align-items: center;
  }
  @keyframes rotate {
    from { transform: rotate(0deg);}
    to { transform: rotate(360deg);}
  }
</style>
