<template lang="pug">
  #natalchart-editor(class="col")
    div(class="row")
      div(class="col-auto form-title") 使用者
      div(class="col")
        InputBox(class="" type="text" placeholder="請輸入姓名" :model="model" handle="fortune_name" @change="change" :watch="true")
        ErrorMessage(class="mt-0" :model="model" handle="fortune_name")
        div(v-if="SystemRights.loaded" class=" mt-2" style="color: #AE0000;") 如特殊姓名、交節氣，請洽東震客服人員 電話 {{ SystemRights.data[0].telphone }}
    div(class="row")
      div(class="col-auto form-title") 性別
      div(class="col")
        div(class="d-flex mx-n1 radio-group")
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.man" :class="{'is-invalid': hasError(model, 'fortune_gender')}" v-model="model.fortune_gender" :value="'M'" @change="change")
            label(class="custom-control-label" :for="uuid.man") 男
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.woman" :class="{'is-invalid': hasError(model, 'fortune_gender')}" v-model="model.fortune_gender" :value="'F'" @change="change")
            label(class="custom-control-label" :for="uuid.woman") 女
        ErrorMessage(class="mt-0" :model="model" handle="fortune_gender")
    div(class="row")
      div(class="col-auto form-title") 曆別
      div(class="col")
        div(class="d-flex mx-n1 radio-group")
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" value="國曆" :id="uuid.zhyear" :class="{'is-invalid': hasError(model, 'fortune_calendar')}" v-model="model.fortune_calendar" @change="change")
            label(class="custom-control-label px-0 text-center" :for="uuid.zhyear") 國曆
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.lunar" value="農曆" :class="{'is-invalid': hasError(model, 'fortune_calendar')}" v-model="model.fortune_calendar" @change="change")
            label(class="custom-control-label px-0 text-center" :for="uuid.lunar") 農曆
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.solar" value="西元" :class="{'is-invalid': hasError(model, 'fortune_calendar')}" v-model="model.fortune_calendar" @change="change")
            label(class="custom-control-label px-0 text-center" :for="uuid.solar") 西元
        ErrorMessage(class="mt-0" :model="model" handle="fortune_calendar")
    div(class="row")
      div(class="col-auto form-title") 出生日期
      div(class="col")
        div(class="row align-items-center mx-n1 py-1")
          div(class="col-12 col-lg px-1 my-1" style="flex-grow: 2;")
            div(class="d-flex align-items-center mx-n1")
              div(class="col-auto px-1" v-if="model.fortune_calendar === '國曆' || model.fortune_calendar === '農曆'") 民國
              div(class="col px-1")
                select(class="custom-select" :class="{'is-invalid': hasError(model, 'fortune_year')}" v-model="model.fortune_year" @change="change"  v-if="model.fortune_calendar === '國曆' || model.fortune_calendar === '農曆'")
                  option(disabled value="") 請選擇年份
                  option(v-for="i in 127" :value="convYear(i + 1)") {{ i + 1 }}
                select(class="custom-select" :class="{'is-invalid': hasError(model, 'fortune_year')}" v-model="model.fortune_year" @change="change" v-else)
                  option(disabled value="") 請選擇年份
                  option(v-for="i in 127" :value="convYear(i + 1912)") {{ i + 1912 }}
              div(class="col-auto px-1") 年
          div(class="col-12 col-lg px-1 my-1" style="flex-grow: 3;")
            div(class="d-flex align-items-center mx-n1")
              div(class="col px-1")
                select(class="custom-select" :class="{'is-invalid': hasError(model, 'fortune_month')}" v-model="model.fortune_month" @change="change")
                  option(disabled value="") 月份
                  option(v-for="i in 12" :value="convMonthAndDay(i)") {{ i }}
              div(class="col-auto px-1") 月
              div(class="col px-1")
                select(class="custom-select" :class="{'is-invalid': hasError(model, 'fortune_day')}" v-model="model.fortune_day" @change="change")
                  option(disabled value="") 日期
                  option(v-for="i in mathDayCount" :value="convMonthAndDay(i)") {{ i }}
              div(class="col-auto px-1") 日
        ErrorMessage(class="mt-0" :model="model" handle="fortune_birth_at")
    div(class="row" v-if="model.fortune_calendar === '農曆'")
      div(class="col-auto form-title") 閏月
      div(class="col")
        div(class="d-flex mx-n1 radio-group")
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.leap" v-model="model.fortune_is_leap" :value="1" @change="change")
            label(class="custom-control-label" :for="uuid.leap") 是
          div(class="col custom-control custom-radio my-2")
            input(type="radio" class="custom-control-input" :id="uuid.unleap" v-model="model.fortune_is_leap" :value="0" @change="change")
            label(class="custom-control-label" :for="uuid.unleap") 否
    div(class="row")
      div(class="col-auto form-title") 出生時辰
      div(class="col")
        div(class="row mx-n1")
          div(class="col px-1" style="max-width: 225px;" )
            select(class="custom-select mt-2" :model="model" handle="hour" @change="selectChangeHourHandler" v-model="hour" style="height:38px;" :disabled="model.lucky")
              option(disabled value="") 時
              option(v-for="hour in hours" :value="hour"  name="hour") {{hour.toString().padStart(2, '0')}}
            select(class="custom-select mt-2" :model="model" handle="min" @change="selectChangeTimeHandler" v-model="min" style="height:38px;" :disabled="model.lucky")
              option(disabled value="") 分
              option(v-for="min in mins" :value="min"  name="min") {{min.toString().padStart(2, '0')}}
          div(class="col-auto px-1" )
            select(class="custom-select mt-2" :model="model" handle="fortune_time" @change="selectChangeHandler" v-model="selectTime" style="height:38px;" :disabled="model.lucky")
              option(disabled value="") 請選擇時辰
              option(value="11.5" name="selectTime") 晚子
              option(value="0" name="selectTime") 早子
              option(value="1" name="selectTime") 丑時
              option(value="2" name="selectTime") 寅時
              option(value="3" name="selectTime") 卯時
              option(value="4" name="selectTime") 辰時
              option(value="5" name="selectTime") 巳時
              option(value="6" name="selectTime") 午時
              option(value="7" name="selectTime") 未時
              option(value="8" name="selectTime") 申時
              option(value="9" name="selectTime") 酉時
              option(value="10" name="selectTime") 戌時
              option(value="11" name="selectTime") 亥時
          div(class="col-auto col-lg mt-3 ")
            div(class="form-check")
              input(type="checkbox" class="form-check-input" :id="uuid.lucky" v-model="model.lucky" :value="model.lucky" @change="changeLucky")
              label(class="form-check-label text-left fs-16" :for="uuid.lucky")
                span(class="form-check-box")
                span 吉時
        ErrorMessage(class="mt-0" :model="model" handle="fortune_time")
    ErrorMessage(class="mt-0" :model="model" handle="error_message")
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { NatalchartModel } from '../../models'
import solarLunar from 'solarlunar'

export default {
  name: 'NatalchartEditor',
  props: {
    model: {
      type: NatalchartModel,
      default: new NatalchartModel()
    }
  },
  created () {
    console.log('created', this.model)
    // this.selectTime = this.model.fortune_hour
    this.handlerTime()
    for (let i = 0; i < 60; i++) {
      this.mins.push(i)
    }
  },
  beforeUpdate () {
    // this.selectTime = this.model.fortune_china_hour
    // console.log('beforeUpdate', this.model.fortune_china_hour)
    this.handlerTime()
  },
  data () {
    return {
      uuid: {
        man: uuidv4(),
        woman: uuidv4(),
        zhyear: uuidv4(),
        lunar: uuidv4(),
        solar: uuidv4(),
        leap: uuidv4(),
        unleap: uuidv4(),
        lucky: uuidv4()
      },
      selectTime: 1,
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      mins: [],
      hour: 0,
      min: 0
    }
  },
  computed: {
    ...mapState({
      SystemRights: state => state.model.SystemRights
    }),
    mathDayCount () {
      const model = this.model
      const calendar = model.fortune_calendar
      const year = Number(model.fortune_year)
      const month = Number(model.fortune_month)
      if (calendar === '國曆' && year % 4 === 1 && month === 2) {
        return 29
      }
      if (calendar === '西元' && year % 4 === 0 && month === 2) {
        return 29
      }
      if (calendar !== '農曆' && month === 2) {
        return 28
      }
      if (calendar !== '農曆' && [1, 3, 5, 7, 8, 10, 12].includes(month)) {
        return 31
      }
      return 30
    }
  },
  methods: {
    handlerTime () {
      // console.log('handlerTime')
      if (this.model.fortune_hour === '') {
        this.hour = 0
      }
      if (parseInt(this.model.fortune_minute) !== 0) {
        this.min = parseInt(this.model.fortune_minute)
      } else {
        this.min = 0
      }

      if (this.model.fortune_hour !== '0') {
        this.hour = parseInt(this.model.fortune_hour)
      } else {
        this.hour = 0
      }
      switch (this.model.fortune_china_hour) {
        case '早子':
          // console.log('早子')
          this.selectTime = '0'
          this.selectTimeHandler()
          break
        /* eslint-disable */
        case '丑時':
          // console.log('丑時')
          this.selectTime = '1'
          break
        case '寅時':
          // console.log('寅時')
          this.selectTime = '2'
          this.selectTimeHandler()
          break
        case '卯時':
          // console.log('卯時')
          this.selectTime = '3'
          this.selectTimeHandler()
          break
        case '辰時':
          // console.log('辰時')
          this.selectTime = '4'
          this.selectTimeHandler()
          break
        case '巳時':
          // console.log('巳時')
          this.selectTime = '5'
          this.selectTimeHandler()
          break
        case '午時':
          // console.log('午時')
          this.selectTime = '6'
          this.selectTimeHandler()
          break
        case '未時':
          // console.log('未時')
          this.selectTime = '7'
          this.selectTimeHandler()
          break
        case '申時':
          // console.log('申時')
          this.selectTime = '8'
          this.selectTimeHandler()
          break
        case '酉時':
          // console.log('酉時')
          this.selectTime = '9'
          this.selectTimeHandler()
          break
        case '戌時':
          // console.log('戌時')
          this.selectTime = '10'
          this.selectTimeHandler()
          break
        case '亥時':
          // console.log('亥時')
          this.selectTime = '11'
          this.selectTimeHandler()
          break
        case '晚子':
          // console.log('晚子')
          this.selectTime = '11.5'
          this.selectTimeHandler()
          break
        case '吉時':
          // console.log('bef 吉時')
          break
        default:
          // console.log('沒有選到1', this.model.fortune_hour)
          // console.log('沒有選到2', parseInt(this.model.fortune_hour))
          if(parseInt(this.model.fortune_hour) === 23) {
            this.selectTime = (parseInt(this.model.fortune_hour) / 2)
          } else {
            this.selectTime = (Math.round(parseInt(this.model.fortune_hour) / 2));
          }
          this.model.fortune_china_hour = this.selectTime
          this.selectTimeHandler()
          break
      }
    },
    selectTimeHandler () {
      // console.log('selectTimeHandler')
      const model = this.model
      model.lucky = false
      let tmpTime = this.selectTime
      if (tmpTime === '吉' || tmpTime === '') {
        this.hour = tmpTime
        this.min = tmpTime
      } else {
        this.hour = (tmpTime * 2)
        this.min = 0
      }

      if(parseInt(this.model.fortune_minute) !==0 ){
        this.min =parseInt(this.model.fortune_minute)
      } else {
        this.min = 0
      }

      if(this.model.fortune_hour !=='0'){
        this.hour =parseInt(this.model.fortune_hour)
      } else {
        this.hour = 0
      }

      if(this.hour === '' || this.model.fortune_hour === '') {
        this.hour = 0
        this.model.fortune_hour = 0
      }

      if(this.hour === 23) {
        tmpTime = (this.hour / 2)
      } else {
        tmpTime = (Math.round(this.hour / 2));
      }

      tmpTime = tmpTime.toString()
      switch (tmpTime) {
        case '0':
          // console.log('早子')
          model.fortune_time = '00:00'
          model.fortune_china_hour = '早子'
          break
        /* eslint-disable */
        case '1':
          // console.log('丑時')
          model.fortune_time = '02:00'
          model.fortune_china_hour ='丑時'
          break
        case '2':
          // console.log('寅時')
          model.fortune_time = '04:00'
          model.fortune_china_hour = '寅時'
          break
        case '3':
          // console.log('卯時')
          model.fortune_time = '06:00'
          model.fortune_china_hour = '卯時'
          break
        case '4':
          // console.log('辰時')
          model.fortune_time = '08:00'
          model.fortune_china_hour = '辰時'
          break
        case '5':
          // console.log('巳時')
          model.fortune_time = '10:00'
          model.fortune_china_hour = '巳時'
          break
        case '6':
          // console.log('午時')
          model.fortune_time = '12:00'
          model.fortune_china_hour = '午時'
          break
        case '7':
          // console.log('未時')
          model.fortune_time = '14:00'
          model.fortune_china_hour = '未時'
          break
        case '8':
          // console.log('申時')
          model.fortune_time = '16:00'
          model.fortune_china_hour = '申時'
          break
        case '9':
          // console.log('酉時')
          model.fortune_time = '18:00'
          model.fortune_china_hour = '酉時'
          break
        case '10':
          // console.log('戌時')
          model.fortune_time = '20:00'
          model.fortune_china_hour = '戌時'
          break
        case '11':
          // console.log('亥時')
          model.fortune_time = '22:00'
          model.fortune_china_hour = '亥時'
          break
        case '11.5':
          // console.log('晚子')
          model.fortune_time = '23:00'
          model.fortune_china_hour = '晚子'
          break
        default:
      }
      this.fortune_time = this.hour.toString().padStart(2, '0') + ':' + this.min.toString().padStart(2, '0')
      this.change()
    },
    submit (e) {
      if (e instanceof KeyboardEvent && e.key === 'Enter') {
        this.$emit('submit')
      }
    },
    convCC (model) {
      const year = this.model.fortune_year
      const month = this.model.fortune_month
      const day = this.model.fortune_day
      let newyear = parseInt(year)
      if (model.fortune_calendar === '國曆') {
        model.fortune_birth_at = dayjs(`${Number(year) + 1911}/${month}/${day}`).format('YYYY-MM-DD HH:mm:ss')
        if (parseInt(year) > 1911) {
          newyear = parseInt(year) - 1911
        }
        model.fortune_year = this.convYear(newyear)
      }
      if (model.fortune_calendar === '農曆') {
        const solarData = solarLunar.lunar2solar(Number(year) + 1911, Number(month), Number(day))
        model.fortune_birth_at = dayjs(`${solarData.cYear}/${solarData.cMonth}/${solarData.cDay}`).format('YYYY-MM-DD HH:mm:ss')
        if (parseInt(year) > 1911) {
          newyear = parseInt(year) - 1911
        }
        model.fortune_year = this.convYear(newyear)
      }
      if (model.fortune_calendar === '西元') {
        model.fortune_birth_at = dayjs(`${year}/${month}/${day}`).format('YYYY-MM-DD HH:mm:ss')
        if (parseInt(year) < 1911) {
          newyear = Number(year) + 1911
        }
        model.fortune_year = (newyear)
      }
      // console.log('this.model ', this.model)
      // console.log('year ', year)
    },
    convYear (value) {
      if (Number(value) < 10) {
        return '000' + value
      } else if (Number(value) < 100) {
        return '00' + value
      } else if (Number(value) < 1000) {
        return '0' + value
      } else {
        return '' + value
      }
    },
    convMonthAndDay (value) {
      if (Number(value) < 10) {
        return '0' + value
      } else {
        return '' + value
      }
    },
    change (e) {
      this.convCC(this.model)
      this.$emit('change', e)
    },
    changeTime (e) {
      const model = this.model
      model.lucky = false
      model.fortune_hour = dayjs('0000/00/00 ' + model.fortune_time).format('HH')
      model.fortune_minute = dayjs('0000/00/00 ' + model.fortune_time).minute()
      model.fortune_china_hour = this.covChinaTime(model.fortune_hour)
      this.change(e)
    },
    changeLucky (e) {
      // console.log(e.target)
      const model = this.model
      // console.log(model.fortune_time)
      console.log('changeLucky', model.lucky)
      if (model.lucky) {
        model.fortune_time = '--:--'
        model.fortune_hour = '吉'
        model.fortune_minute = '吉'
        model.fortune_china_hour = this.covChinaTime('吉')
        this.fortune_time = ''
        this.change(e)
      } else {
        model.fortune_time = '--:--'
        model.fortune_hour = ''
        model.fortune_minute = ''
        model.fortune_china_hour = ''
        this.fortune_time = ''
        this.change(e)
      }
    },
    selectChangeHandler (e) {
      console.log('selectChangeHandler')
      const model = this.model
      model.lucky = false
      const tmpTime = this.selectTime
      if (tmpTime === '吉' || tmpTime === '') {
        this.hour = tmpTime
        this.min = tmpTime
      } else {
        this.hour = (tmpTime * 2)
        this.min = 0
      }
      // console.log('tmpTime', tmpTime)

      switch (tmpTime) {
        case '0':
          // console.log('早子')
          model.fortune_time = '00:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '00:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '00:00').minute()
          model.fortune_china_hour ='早子'
          break
          /* eslint-disable */
        case '1':
          // console.log('丑時')
          model.fortune_time = '02:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '02:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '02:00').minute()
          model.fortune_china_hour ='丑時'
          break
        case '2':
          // console.log('寅時')
          model.fortune_time = '04:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '04:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '04:00').minute()
          model.fortune_china_hour = '寅時'
          break
        case '3':
          // console.log('卯時')
          model.fortune_time = '06:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '06:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '06:00').minute()
          model.fortune_china_hour = '卯時'
          break
        case '4':
          // console.log('辰時')
          model.fortune_time = '08:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '08:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '08:00').minute()
          model.fortune_china_hour = '辰時'
          break
        case '5':
          // console.log('巳時')
          model.fortune_time = '10:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '10:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '10:00').minute()
          model.fortune_china_hour = '巳時'
          break
        case '6':
          // console.log('午時')
          model.fortune_time = '12:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '12:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '12:00').minute()
          model.fortune_china_hour = '午時'
          break
        case '7':
          // console.log('未時')
          model.fortune_time = '14:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '14:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '14:00').minute()
          model.fortune_china_hour = '未時'
          break
        case '8':
          // console.log('申時')
          model.fortune_time = '16:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '16:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '16:00').minute()
          model.fortune_china_hour = '申時'
          break
        case '9':
          // console.log('酉時')
          model.fortune_time = '18:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '18:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '18:00').minute()
          model.fortune_china_hour = '酉時'
          break
        case '10':
          // console.log('戌時')
          model.fortune_time = '20:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '20:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '20:00').minute()
          model.fortune_china_hour = '戌時'
          break
        case '11':
          // console.log('亥時')
          model.fortune_time = '22:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '22:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '22:00').minute()
          model.fortune_china_hour = '亥時'
          break
        case '11.5':
          // console.log('晚子')
          model.fortune_time = '23:00'
          model.fortune_hour = dayjs('0000/00/00 ' + '23:00').format('HH')
          model.fortune_minute = dayjs('0000/00/00 ' + '23:00').minute()
          model.fortune_china_hour = '晚子'
          break
        default:
          // console.log('沒有選到')
      }
      this.fortune_time = model.fortune_time
      this.change(e)
    },
    selectChangeHourHandler (e) {
      console.log(this.hour)
      if(this.hour === 23) {
        this.selectTime = (this.hour / 2)
      } else {
        this.selectTime = (Math.round(this.hour / 2));
      }
      //this.model.fortune_china_hour = this.selectTime
      this.model.fortune_hour = this.hour
      this.selectTimeHandler()
    },
    selectChangeTimeHandler (e) {
      console.log(this.min)
      this.model.fortune_minute  = this.min
    },
  }
}
</script>
<style lang="scss" scoped>
  #natalchart-editor{
    font-size: 14px;
    .form-title{
      margin-top: 10px;
      width: 86px;
    }
    @media (min-width: 768px) {
      font-size: 16px;
      .form-title{
        width: 94px;
      }
    }
  }
</style>
