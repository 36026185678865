<template lang="pug">
  div
    div(class="d-flex py-3 px-sm-4")
      h4(class="py-4 mb-0 font-weight-bold px-2") 聯絡我們
    div(class="px-sm-4")
      div(class="text-dark px-2" v-html="content")
    div(class="pt-4 mx-auto" style="max-width: 640px;")
      InputBox(class="mb-0" type="text" placeholder="詢問主旨" :model="model" handle="sub" :watch="true")
      ErrorMessage(class="text-left mt-0" :model="model" handle="sub")
      InputBox(class="mt-2" type="text" placeholder="姓名" :model="model" handle="name" :watch="true")
      ErrorMessage(class="text-left mt-0" :model="model" handle="name")
      InputBox(class="mt-2" type="phone" placeholder="聯絡電話" :model="model" handle="phone" :watch="true")
      ErrorMessage(class="text-left mt-0" :model="model" handle="phone")
      InputBox(class="mt-2" type="email" placeholder="電子信箱" :model="model" handle="email" :watch="true")
      ErrorMessage(class="text-left mt-0" :model="model" handle="email")
      textarea(class="form-control mt-2" type="text" placeholder="詢問內容" v-model="model.content" rows="5")
      ErrorMessage(class="text-left mt-0" :model="model" handle="content")
    div(class="py-5 text-center")
      ErrorMessage(class="text-left mt-0" :model="model" handle="error_message")
      //- button(class="btn btn-success w-100 my-3" style="max-width: 300px;" @click="submit") 確定送出
      ButtonBox(style="max-width: 300px;" :model="model" @click="submit" class="w-100")
        template(v-slot:default)
          span(class="ml-2") 確定送出
</template>

<script>
import { getContacts, postMailToTocin } from '../../../api'
import { ContactModel } from '../../../models'

export default {
  data () {
    return {
      model: new ContactModel({
        loaded: true
      }),
      content: ''
    }
  },
  created () {
    if (this.$route.meta && this.$route.meta.content) {
      this.content = this.$route.meta.content
    } else {
      getContacts().then(res => {
        this.content = res.data[0].content
        this.$route.meta.content = this.content
      })
    }
  },
  methods: {
    async submit () {
      const errorMessage = this.model.validate()
      if (errorMessage) {
        return
      }
      await this.$nextTick()
      this.model.loaded = false
      postMailToTocin(
        this.model.toBackend()
      ).then(res => {
        this.model.loaded = true
        setTimeout(() => {
          this.model = new ContactModel({
            loaded: true
          })
        }, 2000)
      }).catch(() => {
        this.model.loaded = true
        this.model.validate({
          error_message: { inclusion: { message: '^送出失敗' } }
        })
      })
    }
  }
}
</script>
<style scoped>
  textarea::placeholder {
    color: #D8D8D8;
  }
</style>
