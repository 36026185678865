import DataModel from '../../library/proto/data-model'
import ActivitieRuleModel from './activitie-rule'
import ProductsModel from './products'

/**
 * @param {Number} id
 * @param {String} title 活動名稱
 * @param {Number} weights 活動權重
 * @param {String} description 活動說明
 * @param {Number} status 上架狀態
 * @param {String} started_at 活動開始時間
 * @param {String} ended_at 活動結束時間
 * @param {String} over_limit_alert
 * @param {String} running_news
 * @param {String} sold_out_alert
 * @param {String} sold_out_news
 * @param {ActivitieRuleModel} ruleable 活動規則
 * @param {Array<ProductsModel>} activity_items 活動已產生的贈品
 * @param {Boolean} errorCondition.iterative 是否超過單筆訂單贈送上限
 * @param {Boolean} errorCondition.quota 是否超過配額
*/

export default class ActivitieModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.title = entity.title || ''
    this.weights = entity.weights || 0
    this.description = entity.description || ''
    this.status = entity.status || 0
    this.started_at = entity.started_at || ''
    this.ended_at = entity.ended_at || ''
    this.over_limit_alert = entity.over_limit_alert || ''
    this.running_news = entity.running_news || ''
    this.sold_out_alert = entity.sold_out_alert || ''
    this.sold_out_news = entity.sold_out_news || ''
    this.ruleable_type = entity.ruleable_type || ''
    this.ruleable = new ActivitieRuleModel(entity.ruleable)
    this.activity_items = entity.activity_items ? entity.activity_items.map(p => new ProductsModel(p)) : []
    this.errorCondition = {
      iterative: false,
      quota: false
    }
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'ruleable') {
        this.ruleable = new ActivitieRuleModel(entity.ruleable)
      } else {
        this[key] = entity[key]
      }
    })
  }
}
