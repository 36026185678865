<template lang="pug">
  div(style="min-height: calc(100vh - 420px);")
    div(class="d-flex justify-content-between position-relative pt-xl-3")
      div(class="d-flex")
        h4(class="col-auto font-weight-bold text-left pr-2") {{ subject }}
        div(class='d-flex flex-column fs-12 text-secondary')
          div(v-if="model.prefix_time") {{ dayFormat(model.prefix_time, 'YYYY年MM月') }}份
          div(v-if="model.index_title") {{ dayFormat(model.index_title, 'YYYY年MM月') }}份
          div(v-if="model.period") {{ model.period }}期
      div(class='d-none d-md-flex align-items-center')
        Icon(class="mr-1" pattern="FormatSize" size="24")
        button(class="btn-30 font-icon rounded-left border border-light border-right-0 bg-white p-0 focus-none" @click="setFontSize('+')")
          Icon(class="" pattern="Add" size="24")
        button(class="btn-30 font-icon rounded-right border border-light bg-white p-0 focus-none" @click="setFontSize('-')")
          Icon(class="" pattern="Remove" size="24")
    template(v-if="model.status ==1")
      div(class="px-md-5")
        div(class="font-weight-bold text-primary text-center py-4 my-xl-3" style="font-size:20px;") {{ model.title }}
        div(class="text-center py-4 my-xl-3" v-if="model.image")
          img(class="img-fluid" :src="model.image")
        div(class="articel-content" :style="{fontSize: global.fontSize + 'px'}" v-html="model.content")
    template(v-else)
      div(class="w-100" v-if="modelName == 'OfficialBulletins'")
        h1(class="text-center" style="padding:200px 0px") {{message}}
      div(class="w-100" v-else-if="modelName == 'NewsArticles'")
        h1(class="text-center" style="padding:200px 0px") {{message}}
      div(class="w-100" v-else)
        h1(class="text-center" style="padding:200px 0px") {{message}}
</template>
<script>
import dayjs from 'dayjs'
import { mapState, mapMutations } from 'vuex'
import { ArticleModel } from '../../library/proto'

export default {
  name: 'ArticleDetail',
  data () {
    return {
      model: new ArticleModel(),
      currentModelId: 0,
      message: 'Loading...'
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      listModel (state) {
        return state.model[this.modelName]
      }
    }),
    modelName () {
      const meta = this.$route.matched[1].meta
      return meta.modelName
    },
    rootPath () {
      const meta = this.$route.matched[1].meta
      return meta.rootPath
    },
    subject () {
      const meta = this.$route.matched[1].meta
      return meta.title
    }
  },
  methods: {
    ...mapMutations(['setFontSize']),
    getModelById () {
      const { id } = this.$route.params
      // console.log('getModelById', this.$route.params)
      // console.log(this.modelName)
      this.$store.dispatch('getModelById', {
        name: this.modelName,
        id: Number(id),
        callback: data => {
          console.log('data', data)
          window.scrollTo({ top: 0 })
          this.model = data
          if (data.status === 0) {
            this.$store.dispatch('getListModel', {
              name: this.modelName,
              callback: list => {
                if (list.data.length > 0) {
                  this.currentModelId = Number(list.data[0].id)
                  // console.log('this.currentModelId', this.currentModelId)
                  // console.log('getListModel', this.rootPath)
                  // console.log('getListModel', this.$route.path)
                  const path = [this.rootPath, this.currentModelId].join('/')
                  this.$router.replace(path)
                } else {
                  if (this.modelName === 'OfficialBulletins') {
                    this.message = '無公司公告'
                  } else if (this.modelName === 'NewsArticles') {
                    this.message = '無特惠活動'
                  } else {
                    this.message = '無訊息'
                  }
                }
              }
            })
          }
        }
      }).catch(() => {
        // this.Products.push({ status: 1 })
        console.log('Do that')
        this.$store.dispatch('getListModel', {
          name: this.modelName,
          callback: list => {
            if (list.data.length > 0) {
              this.currentModelId = Number(list.data[0].id)
              // console.log('this.currentModelId', this.currentModelId)
              // console.log('getListModel', this.rootPath)
              // console.log('getListModel', this.$route.path)
              const path = [this.rootPath, this.currentModelId].join('/')
              this.$router.replace(path)
            } else {
              if (this.modelName === 'OfficialBulletins') {
                this.message = '無公司公告'
              } else if (this.modelName === 'NewsArticles') {
                this.message = '無特惠活動'
              } else {
                this.message = '無訊息'
              }
            }
          }
        })
      })
    },
    dayFormat (time, format) {
      if (time) {
        return dayjs(time).format(format)
      }
      return ''
    }
  },
  watch: {
    $route (to, from) {
      const reg = new RegExp(this.rootPath)
      if (to.path === this.rootPath && reg.test(from.path)) {
        // console.log('$route if')
        this.$router.replace(from.path)
      } else if (from.path !== to.path) {
        // console.log('$route else', from)
        // console.log('$route else', to)

        this.getModelById()
        window.scrollTo({ top: 0 })
      }
    }
  },
  created () {
    // console.log('created')
    this.getModelById()
  }
}
</script>
