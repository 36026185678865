<template lang="pug">
  div(class="stronghold py-3")
    div(class='py-4 px-sm-5')
      h4(class='font-weight-bold mb-3') 全國通訊錄
      p(class='mb-3') 東震傳銷商的提貨中心。在這些地點，您可以隨時獲得充足的貨源而不需要自己大量囤貨；全國營業所更有可以與您共同研討命理、解說產品的老師，協助您拓展事業。
      div(class="row")
        div(class="col-xl-8 py-4")
          div(class="border rounded-lg")
            div(class="d-flex px-3")
              div(class="col-auto d-none d-lg-block p-2" style="width:32px;")
              div(class="col p-2")
                small 營業所
              div(class="col-2 d-none d-lg-block p-2")
                small 電話
              div(class="col-2 d-none d-lg-block p-2")
                small 傳真
              div(class="col-2 d-none d-lg-block p-2")
                small 營業時間
            div(class="px-3 rounded-lg" :class="{'cursor-pointer':item.url}" v-for="(item,index) in serviceBases" :key="item.id" :style="{backgroundColor: item.active ? '#56870F32' : 'transparent'}" @mouseover="mouseoverTable(item)" @mouseout="mouseoutTable(item)" @click="openLink(item.url)")
              div(class="border-top")
              div(class="d-flex flex-wrap py-1 align-items-end")
                div(class="col-auto d-none d-lg-block px-2 align-self-center" style="width:32px;")
                  small(v-if="index && !item.active") {{ index }}
                  span(v-if="item.active")
                    Icon(class="" pattern="Map" size="12")
                div(class="col-12 col-md px-2")
                  small(class="text-primary") {{ item.title }}
                  small(class="d-block") {{ item.postal_code }}{{ item.city }}{{ item.district }}{{ item.address }}
                div(class="col-auto col-md-2 px-2")
                  small(class="d-md-none") TEL：
                  small {{ item.telephone }}
                div(class="col-auto col-md-2 px-2")
                  small(class="d-md-none") FAX：
                  small {{ item.fax }}
                div(class="col-12 col-md-2 px-2")
                  small(class="d-md-none") 營業時間：
                  small {{ item.operate }}
                  span(class="d-none d-md-block")
                  small {{ dayFormat(item.operate_start, 'hh:mm') }}~{{ dayFormat(item.operate_end, 'hh:mm')  }}
        div(class="col-xl-4 d-none d-xl-flex flex-center py-4")
          svg(class="w-100 svg-taiwan" style="color: #97979764;" :viewBox="taiwan.attrs.viewBox" xmlns="http://www.w3.org/2000/svg")
            g(v-bind="item.attrs" :class="{'active': item.active}" v-for="item in pathData" v-html="item.path" :key="item.title" :title="item.title" @mouseover="mouseoverMap(item)" @mouseout="mouseoutMap(item)")
</template>

<script>
import taiwan from '../../../components/icon/pattern/taiwan'
import { getServicesBases } from '../../../api'
import dayjs from 'dayjs'

export default {
  name: 'Stronghold',
  data () {
    return {
      taiwan,
      serviceBases: []
    }
  },
  computed: {
    pathData () {
      return this.taiwan.pathData.map(item => {
        item.serviceBases = this.serviceBases.filter(p => p.city === item.title)
        if (item.serviceBases.length) {
          item.attrs.fill = '#56870F64'
        }
        return item
      })
    }
  },
  created () {
    const success = res => {
      this.serviceBases = res.data.map(item => {
        item.active = false
        const find = this.taiwan.pathData.find(p => p.title === item.city)
        if (find) item.pathData = find
        if (res.request) this.$route.meta.model = res.data
        return item
      })
    }

    // console.log(this.serviceBases)
    if (this.$route.meta.model) {
      success({ data: this.$route.meta.model, statusText: 'Cache' })
    } else {
      getServicesBases().then(success)
    }
  },
  methods: {
    dayFormat (time, format) {
      if (time) {
        return dayjs('0000/00/00 ' + time).format(format)
      }
      return ''
    },
    mouseoverTable (item) {
      item.active = true
      console.log('pathData', item.pathData)
      if (!item.pathData) return
      item.pathData.active = true
    },
    mouseoutTable (item) {
      item.active = false
      if (!item.pathData) return
      if (item.pathData.serviceBases.length) {
        item.pathData.active = false
      }
    },
    mouseoverMap (item) {
      if (item.serviceBases.length) {
        item.serviceBases.forEach(p => {
          p.active = true
        })
        this.$set(item, 'active', true)
      }
    },
    mouseoutMap (item) {
      if (item.serviceBases.length) {
        item.serviceBases.forEach(p => {
          p.active = false
        })
      }
      item.active = false
    },
    openLink (link) {
      if (link) {
        window.open(link)
      }
    }
  }
}
</script>
