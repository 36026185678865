import Vue from 'vue'
import Vuex from 'vuex'
import modelModules from './model'
import optionModules from './option'
import globalModules from './global'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: {
      api: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        headers: {
          'X-Client-Version': process.env.VUE_APP_VERSION
        }
      }
    },
    token: localStorage.getItem('token')
  },
  mutations: {},
  actions: {},
  modules: {
    model: modelModules,
    option: optionModules,
    global: globalModules
  }
})
