<template lang="pug">
  div(class="col d-flex flex-center")
    div(class="text-center")
      div(class="d-flex justify-content-center")
        Icon(class="text-primary" pattern="ReportProblem" size="120")
      h2(class="h4 text-primary font-weight-bold mt-4") 購物功能維護中
      div(class="text-dark") 網頁關閉期間，請至各區營業所出貨，或傳真至各營業所進行傳刷作業
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  computed: {
    ...mapState({
      ShopSystems: state => state.model.ShopSystems
    })
  },
  created () {
    this.getListModel({ name: 'ShopSystems' }).then(list => {
      const system = this.ShopSystems.data.length ? this.ShopSystems.data[0] : false
      const isSystem = dayjs().isAfter(system.started_at) && dayjs().isBefore(system.finished_at)
      // return (isSystem && this.ShopSystems.data[0].status === 0)
      // console.log('shop-close', this.ShopSystems.data[0].status, isSystem)
      if (this.ShopSystems.data[0].status === 1 || (isSystem === false && this.ShopSystems.data[0].status === 0)) {
        this.$router.push('/')
      }
    })
  },
  methods: {
    ...mapActions(['getListModel', 'getModelById', 'removeShopcartByProdId'])
  }
}
</script>
