import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} image 圖片位址
 * @param {String} image_title 圖片說明文字
 * @param {String} image_alt 圖片替代文字
 * @param {String} content1 第一段文字
 * @param {String} content2 第二段文字
 * @param {String} updated_user 更新使用者
*/
export default class IndexBannersModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.image = entity.image || ''
    this.image_alt = entity.image_alt || ''
    this.image_title = entity.image_title || ''
    this.content1 = entity.content1 || ''
    this.content2 = entity.content2 || ''
    this.updated_user = entity.updated_user || ''
  }
}
