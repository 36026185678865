<template lang="pug">
  div()
    div(class="d-flex justify-content-between position-relative py-3")
      div(class="d-flex")
        div(class="col-auto fs-24 font-weight-bold text-left pr-2") 行事曆
      div(class='d-none d-md-flex align-items-center')
        Icon(class="mr-1" pattern="FormatSize" size="24")
        button(class="btn-30 font-icon rounded-left border border-light border-right-0 bg-white p-0 focus-none" @click="setFontSize('+')")
          Icon(class="" pattern="Add" size="24")
        button(class="btn-30 font-icon rounded-right border border-light bg-white p-0 focus-none" @click="setFontSize('-')")
          Icon(class="" pattern="Remove" size="24")
    div(class="row flex-column flex-lg-row justify-content-center")
      div(class="col-12 d-none py-4" :style="{fontSize: global.fontSize + 'px'}" v-if="calendar.information")
        div(class="border border-danger rounded-lg mx-auto rwd-calendar position-relative")
          a(class="slide-btn stretched-link font-weight-bold text-danger fs-18 d-block py-2 position-relative text-center d-block" href="javascript:;" :class="{'active': content.active}" @click="toggleMenu($event,content)") {{ content.name }}
          div(class="slide-panel border-top mx-3" :class="{'action': content.action}")
            div(class="py-3 articel-content" v-html="calendar.information")
          div(class="arrow position-absolute" style="top:10px;")
            Icon(class="text-danger" pattern="ArrowRight" size="24")
      div(class="col-12 col-lg-7")
        div(class="text-primary text-cente")
          div(class="px-3 pt-2 mx-auto w-calendar-view")
            div(class="py-2 d-flex justify-content-between align-items-end")
              div(class="d-flex align-items-end")
                div(class="text-success fs-18 font-weight-bold mb-0") {{ dayFormat(calendar.title, 'YYYY') }}
                div(class="mx-1 text-dark fs-14") 年
                div(class="text-success fs-18 font-weight-bold mb-0") {{ dayFormat(calendar.title, 'MM') }}
                div(class="mx-1 text-dark fs-14") 月
                div(class="vertical-line mb-1 mx-1" style="height: 16px;")
                div(class="mx-1 text-dark") 金元寶標示為納財日
              div(class="d-none")
                button(class="mx-1 btn-course-filter focus-none" :style="{opacity: courseFilter==='all' ? 1 : 0.2}" @click="setCourseFilter('all')")
                  span 全部
                button(class="mx-1 btn-course-filter focus-none" :style="{opacity: courseFilter==='businessData' ? 1 : 0.2}" @click="setCourseFilter('businessData')")
                  span 八字
                button(class="mx-1 btn-course-filter focus-none" :style="{opacity: courseFilter==='characterData' ? 1 : 0.2}" @click="setCourseFilter('characterData')")
                  span 命理
                button(class="mx-1 btn-course-filter focus-none" :style="{opacity: courseFilter==='numerologyData' ? 1 : 0.2}" @click="setCourseFilter('numerologyData')")
                  span 事業
            CalendarView(v-bind="calendarSettings" :show-date="showDate")
              CalendarViewHeader
                dayHeader
            div(class="d-none" v-for="item in courseHandlerFilter" :key="item.key")
              div(class="border-top text-center pb-2 position-relative")
                a(class="slide-btn stretched-link font-weight-bold text-dark position-relative py-2 d-block") {{ item.name }}
                div(v-if="item.data.length" class="slide-panel border-top" :class="{'action': item.action}")
                  div(class="d-flex")
                    div(class="col-week" :class="{'border-left': index!==0}" v-for="(week,index) in item.weeks" :key="week.title")
                      div(class="font-weight-bold fs-18 my-1" :style="{color: week.color}") {{ week.title }}
                      div(class="text-center text-nowrap my-1 text-dark" v-for="(data,i) in week.data")
                        div(class="mx-1" :class="{'border-top': i!==0}")
                        div(class="small py-1") {{ data.location }}
                        div(class="small my-1 row no-gutters")
                          div(class="d-inline-block rounded-sm text-white px-1 ml-1" style="background-color:#80D2F0;") 早
                          div(class="flex-grow-1 text-center mx-1")
                            span(v-if="data.morning_time") {{ dayFormat(`${data.title} ${data.morning_time}`, 'HH:MM') }}
                        div(class="small my-1 row no-gutters")
                          div(class="d-inline-block rounded-sm text-white px-1 ml-1" style="background-color:#64A5BD;") 中
                          div(class="flex-grow-1 text-center mx-1")
                            span(v-if="data.afternoon_time") {{ dayFormat(`${data.title} ${data.afternoon_time}`, 'HH:MM') }}
                        div(class="small my-1 row no-gutters")
                          div(class="d-inline-block rounded-sm text-white px-1 ml-1" style="background-color:#5D6B70;") 晚
                          div(class="flex-grow-1 text-center mx-1")
                            span(v-if="data.evening_time") {{ dayFormat(`${data.title} ${data.evening_time}`, 'HH:MM') }}
      div(class="col-5 d-none" :style="{fontSize: global.fontSize + 'px'}" v-if="calendar.information")
        div(class="border border-danger rounded-lg mx-auto rwd-calendar position-relative")
          div(class="slide-btn stretched-link font-weight-bold text-danger fs-18 d-block py-2 position-relative text-center") {{ content.name }}
          div(class="slide-panel border-top mx-3" :class="{'action': content.action}")
            div(class="py-3 articel-content" v-html="calendar.information")
      div(class="col-12 d-none")
        div(class="border-bottom pt-3")
      div(class="col-12 d-none")
        div(class="" v-for="item in courseHandler" :key="item.key")
          div(v-if="!item.data.length" class="border-bottom text-center py-2 position-relative")
            a(class="slide-btn stretched-link font-weight-bold text-dark position-relative py-2 d-block") {{ item.name }}
          div(v-else class="border-bottom text-center pb-2 position-relative")
            a(class="slide-btn stretched-link font-weight-bold text-dark position-relative py-2 d-block" href="javascript:;" :class="{'active': item.active}" @click="toggleMenu($event,item)") {{ item.name }}
            div(class="slide-panel border-top" :class="{'action': item.action}")
              div(class="d-flex py-2")
                div(class="d-flex align-items-center mx-1")
                  div(class="rounded-circle mx-1" style="background-color:#80D2F0;width:10px;height:10px;")
                  div(class="") 上午
                div(class="d-flex align-items-center mx-1")
                  div(class="rounded-circle mx-1" style="background-color:#64A5BD;width:10px;height:10px;")
                  div(class="") 中午
                div(class="d-flex align-items-center mx-1")
                  div(class="rounded-circle mx-1" style="background-color:#5D6B70;width:10px;height:10px;")
                  div(class="") 晚上
              div(class="d-flex")
                div(class="col-week" :class="{'border-left': index!==0}" v-for="(week,index) in item.weeks" :key="week.title")
                  div(class="font-weight-bold fs-18 my-1" :style="{color: week.color}") {{ week.title }}
                  div(class="text-center text-nowrap my-1" v-for="(data,i) in week.data")
                    div(class="mx-1" :class="{'border-top': i!==0}")
                    div(class="small py-1") {{ data.location }}
                    div(class="small" style="color:#80D2F0;" v-if="data.morning_time") {{ dayFormat(`${data.title} ${data.morning_time}`, 'HH:MM') }}
                    div(class="small" style="color:#64A5BD;" v-if="data.afternoon_time") {{ dayFormat(`${data.title} ${data.afternoon_time}`, 'HH:MM') }}
                    div(class="small" style="color:#5D6B70;" v-if="data.evening_time") {{ dayFormat(`${data.title} ${data.evening_time}`, 'HH:MM') }}
            div(class="arrow position-absolute" style="top: 10px;")
              Icon(class="" pattern="ArrowRight" size="24")
</template>
<script>
import dayjs from 'dayjs'
import { getCalendarsById } from '../../../../api'
import { CalendarModel, CalendarCourseModel } from '../../../../models'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'CalendarDetail',
  data () {
    return {
      calendar: new CalendarModel(),
      showDate: new Date(),
      content: {
        name: '停課通知',
        active: true,
        action: false
      },
      selected: 'businessData',
      calendarDefault: {
        class: 'index-money-theme',
        weekdayNameFormat: 'narrow',
        startingDayOfWeek: 1
      },
      courseFilter: 'all',
      course: [
        {
          key: 'businessData',
          name: '八字課程',
          data: [],
          active: true,
          action: false
        },
        {
          key: 'characterData',
          name: '命理課程',
          data: [],
          active: true,
          action: false
        },
        {
          key: 'numerologyData',
          name: '專業講座',
          data: [],
          active: true,
          action: false
        }
      ],
      weeks: [{
        title: '一',
        color: '#232323'
      }, {
        title: '二',
        color: '#232323'
      }, {
        title: '三',
        color: '#232323'
      }, {
        title: '四',
        color: '#232323'
      }, {
        title: '五',
        color: '#232323'
      }, {
        title: '六',
        color: '#009140'
      }, {
        title: '日',
        color: '#A30000'
      }]
    }
  },
  computed: {
    ...mapState({
      global: state => state.global
    }),
    calendarSettings () {
      return {
        ...this.calendarDefault,
        items: this.calendar.dumpling_day.map(item => {
          return {
            id: item,
            startDate: dayjs(this.calendar.title).format('YYYY/MM/') + item
          }
        })
      }
    },
    courseHandler () {
      return this.course.map(item => {
        return {
          ...item,
          weeks: this.weeks.map((week, index) => {
            return {
              ...week,
              data: item.data.filter(p => p.day_of_week === index + 1)
            }
          })
        }
      })
    },
    courseHandlerFilter () {
      if (this.courseFilter === 'all') {
        return this.courseHandler
      }
      return this.courseHandler.filter(item => item.key === this.courseFilter)
    }
  },
  methods: {
    ...mapMutations(['setFontSize']),
    getCalendar () {
      const { id } = this.$route.params
      return new Promise(resolve => {
        getCalendarsById(id).then(res => {
          this.calendar = res.data.calendar
          this.showDate = new Date(res.data.calendar.title)
          this.course.forEach(p => {
            p.data = res.data[p.key].map(p => new CalendarCourseModel(p))
          })
          this.$route.meta.model.push({
            calendar: this.calendar,
            businessData: this.course.find(p => p.key === 'businessData').data,
            characterData: this.course.find(p => p.key === 'characterData').data,
            numerologyData: this.course.find(p => p.key === 'numerologyData').data
          })
          resolve(res.data)
        })
      }).catch(err => console.error(err))
    },
    toggleMenu (e, item) {
      const el = e.target
      const panel = el.nextSibling
      if (item.action) {
        return
      }
      if (item.active) {
        item.active = false
        item.action = true
        panel.slideUp(() => {
          item.action = false
        })
      } else {
        item.active = true
        item.action = true
        panel.slideDown(() => {
          item.action = false
        })
      }
    },
    setCourseFilter (type) {
      this.courseFilter = type
    }
  },
  watch: {
    $route (to, from) {
      const { id } = this.$route.params
      const modelCache = this.$route.meta.model.find(p => p.calendar.id === id)
      if (modelCache) {
        this.calendar = modelCache
        window.scrollTo({ top: 200 })
      } else {
        this.getCalendar().then(() => {
          window.scrollTo({ top: 200 })
        })
      }
    }
  },
  created () {
    if (!this.$route.meta.model) {
      this.$route.meta.model = []
    }
    this.getCalendar()
  }
}
</script>
<style lang="scss" scoped>
  .arrow{
    right: 15px;
    transform: rotate(-90deg);
    transition: all 0.3s;
  }
  .slide-btn {
    &.active ~ .arrow {
      transform: rotate(90deg);
    }
  }
  .btn-course-filter{
    background-color: transparent;
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    border: 1px solid #56870F;
    text-align: center;
    span{
      font-size: 12px;
      white-space: nowrap;
      transform: scale(0.8333);
      display: block;
    }
  }
  .slide-panel{
    p {
      margin: 0;
    }
  }
</style>
