import Dialog from './index'
import DialogComponent from './component'

const DialogInstall = {
  install (Vue) {
    Vue.prototype.$dialog = Dialog
    Vue.component('Dialog', DialogComponent)
  }
}

export default DialogInstall
