<template lang="pug">
  div()
    ActivitieProdGridItem(v-for="(model, index) in activity_items" :model="model" :key="model.id")
</template>

<script>
import { mapActions } from 'vuex'
import defaultImage from '@/assets/images/default.png'
import { ActivitieModel } from '../../models'
import { createShopcart } from '../../api'
import ActivitieProdGridItem from './ActivitieProdGridItem.vue'

export default {
  props: {
    model: {
      type: [ActivitieModel],
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      defaultImage,
      activity_items: []
    }
  },
  created () {
    console.log('Activities Grid', this.model.activity_items)
    this.activity_items = this.model.activity_items
  },
  computed: {
    isOrderItem () {
      return this.model instanceof ActivitieModel
    }
  },
  methods: {
    ...mapActions(['removeShopcartByProdId']),
    changeData (e, model) {
      createShopcart(
        model.toDatabase()
      ).then(res => {
        model.set(res.data)
        this.change(e)
      })
    }
  },
  components: {
    ActivitieProdGridItem
  }
}
</script>

<style lang="scss" scoped>
  .grid-tr {
    grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 992px) {
      grid-template-columns: 100px 1fr 1fr 1fr;
      .grid-image{
        grid-column: 1;
        grid-row: 1 / 3;
      }
      .grid-text{
        grid-column: 2 / 4;
        grid-row: 1;
      }
      .grid-price{
        display: none;
      }
      .grid-pv{
        display: none;
      }
      .grid-price-pv{
        grid-column: 2 / 4;
        grid-row: 2;
      }
      .grid-quantity{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
      }
      .grid-count-price{
        grid-column: 3 / 5;
        grid-row: 3;
      }
      .gridd-count-pv{
        grid-column: 3 / 5;
        grid-row: 4;
      }
      .grid-delete{
        grid-column: 4 / 5;
        grid-row: 1;
      }
    }
    &.no-delete{
      grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr;
      @media (max-width: 992px) {
        grid-template-columns: 100px 1fr 1fr;
      }
    }
  }
</style>
