<template lang="pug">
  div(class="login")
    div(class="py-5 my-5 mx-auto text-center" style="width: 300px")
      div(class="pt-2")
        h5(class="text-dark font-weight-bold") 會員登入
        p(class="text-dark")
        | 請輸入身分證字號或管理編號<br>
        | 法人請輸入統一編號或管理編號
      div(class="my-2")
        ErrorMessage(class="text-center mt-0" :model="UserData" handle="error_message")
        InputBox(class="mt-2" type="text" placeholder="身分證字號或管理編號" :model="UserData"  handle="username" @keydown="submit")
        ErrorMessage(class="text-left mt-0" :model="UserData" handle="username")
        InputBox(class="mt-2" type="password" placeholder="密碼" :model="UserData" @keyup="updateMessage" handle="password" @keydown ="submit")
        ErrorMessage(class="text-left mt-0" :model="UserData" handle="password")
      div(class="my-2")
        button(class="btn btn-success w-100" type="button" @click="login" :disabled="btnDisabled") 登入
      div(class="my-2 py-2 border-bottom")
        router-link(class="text-dark fs-14" to="forgot") 忘記密碼
      div(class="py-1")
      div(class="my-2")
        router-link(class="btn btn-outline-success w-100"  to="register") 第一次登入東震
</template>
<script>
import dayjs from 'dayjs'
import { mapState, mapActions, mapMutations } from 'vuex'
import { updateUser, getLoginPops } from '@/api'
import Lightbox from '../../dialog/HomeLightbox'
import { Toast } from '@/utils/helper'
updateUser()
export default {
  name: 'Login',
  data () {
    return {
      btnDisabled: false
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      UserData: state => state.model.UserData
    })
  },
  created () {
    if (this.UserData.loginStatus) {
      this.$router.replace('/home')
    }
    this.UserData.validate({})
    // 乙曼法人帳號
    // this.UserData.username = '80643336'
    // this.UserData.password = '123456'
    // 自然人測試帳密
    // this.UserData.username = 'P220327347'
    // this.UserData.password = '5888'
    // 自然人測試帳密
    // this.UserData.username = 'F229561446'
    // this.UserData.password = '123456'
    // 自然人測試帳密
    // this.UserData.username = 'M223023335'
    // this.UserData.password = 'password2'
  },
  methods: {
    ...mapActions(['getMemberModel']),
    ...mapMutations(['toggleMenuBar']),
    updateMessage (e) {
      this.UserData.password = this.UserData.password.replace(/[\uff00-\uffff]/g, '')
      // console.log(this.UserData.password)
    },
    submit (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login () {
      const errorMessage = this.UserData.validate({
        username: {
          presence: {
            allowEmpty: false,
            message: '^請填寫身分證字號或管理編號'
          }
        },
        password: {
          presence: {
            allowEmpty: false,
            message: '^請填寫密碼'
          }
        }
      })
      if (errorMessage.length) {
        return
      }
      this.btnDisabled = true
      this.UserData.login().then(() => {
        this.btnDisabled = false
        this.getMemberModel()
        this.UserData.validate({})
        // console.log('call dialog instance')

        // console.log('login', this.UserData)
        if (this.UserData.credit_audit === 'N' || this.UserData.credit_audit === 'X' || this.UserData.credit_audit === 'Z') {
          Toast.fire({
            icon: 'warning',
            title: '很抱歉,暫停您的網路購物且無法登入，造成不便，敬請見諒',
            text: '如有問題請洽客服專線04-22581569'
          }).then(() => {
            this.toggleMenuBar(false)
            this.$store.commit('logout')
            this.$router.replace('/home')
          })
        } else {
          localStorage.removeItem('name')
          localStorage.removeItem('postal_code')
          localStorage.removeItem('receiver_phone')
          localStorage.removeItem('addresscity')
          localStorage.removeItem('addressdistrict')
          localStorage.removeItem('addressaddress')
          localStorage.removeItem('invoice_accept_type')
          localStorage.removeItem('invoicepostal_code')
          localStorage.removeItem('invoicecity')
          localStorage.removeItem('invoicedistrict')
          localStorage.removeItem('invoiceaddress')
          localStorage.removeItem('currentOfficesInvoiceAddress')
          localStorage.removeItem('sale_territory')
          localStorage.removeItem('payment')
          localStorage.removeItem('installment')
          localStorage.removeItem('total_price_new')

          // 待修正將資料傳遞至 Lightbox 內部
          // 下列不顯示
          // 12/31 之前  當年度加入  不顯示
          // 1/1 ~ 續約截止日   已滿  6000  不顯示
          // this.message = res.data[0] 空的 不顯示
          // 所以 this.message 不為空
          getLoginPops().then(res => {
            const data = res.data[0]
            const todayFormat = dayjs().format()
            const nowDate = new Date()
            const currMonth = nowDate.getMonth() + 1
            const expiryDate = new Date(this.UserData.expiry_day)
            const created = new Date(this.UserData.created_at)
            let show = false
            if (data.status === 1 || (data.status === 2 && data.published_at <= todayFormat && todayFormat <= data.finished_at)) show = true
            if ((currMonth === 11 || currMonth === 12) && created.getFullYear() !== nowDate.getFullYear()) show = true
            if (expiryDate < nowDate) show = true
            if (show) {
              console.log('進入')
              this.$dialog.open(Lightbox, {
                width: '1080px'
              })
            }
            this.$router.push(this.global.loginPage || '/home')
          })
        }
      }).catch(err => {
        console.log(err)
        this.btnDisabled = false
        this.$el.querySelectorAll('input').forEach(el => {
          el.value = ''
        })
        this.UserData.loginError(err)
      })
    }
  }
}
</script>
