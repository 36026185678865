<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 384px)")
    router-view
</template>

<script>
export default {
  name: 'Order',
  data () {
    return {
    }
  },
  created () {
    if (this.$route.path === '/member/order') {
      this.$router.replace('/member/order/list')
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/member/order') {
        this.$router.replace('/member/order/list')
      }
    }
  }
}
</script>

<style lang="scss">
</style>
