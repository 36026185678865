<template lang="pug">
  div#app.overflow-hidden
    Layout(v-if="!error")
    Error(v-else)
    Dialog(v-if="!error")
</template>
<script>
import { plusBrowseCount, getActivities } from './api'
import Layout from './views/Layout.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Error from './views/Error.vue'
import { Toast } from '@/utils/helper'

export default {
  name: 'App',
  data () {
    return {
      count: 0
    }
  },
  methods: {
    ...mapMutations(['toggleMenuBar']),
    ...mapActions(['getListModel', 'getMemberModel']),
    handleDialog (event) {
      console.log('layout')
      console.log(event)
    },
    clearTimer () {
      clearInterval(window.myTimer)
      window.myTimer = null
    },
    setTimer () {
      this.count = 0
      if (!window.myTimer) {
        window.myTimer = window.setInterval(this.cookieTimeout, 1000)
      }
    },
    cookieTimeout () {
      const outTime = 15
      if (this.UserData.id === 0) {
        return
      }
      this.count++
      // console.log('cookieTimeout', this.count)
      if (this.count === outTime * 60) {
        Toast.fire({
          icon: 'warning',
          title: '系統已經15分鐘沒有操作, 自動登出'
        }).then(() => {
          this.logout()
        })
      }
    },
    logout () {
      this.toggleMenuBar(false)
      this.$store.commit('logout')
      this.$router.replace('/home')
    }
  },
  mounted () {
    // 监听鼠标
    document.onmousemove = (event) => {
      const x1 = event.clientX
      const y1 = event.clientY
      if (this.x !== x1 || this.y !== y1) {
        this.count = 0
      }
      this.x = x1
      this.y = y1
    }
    // 监听键盘
    document.onkeydown = () => {
      this.count = 0
    }
    this.setTimer()
    document.oncontextmenu = () => {
      window.event.returnValue = false
      return false
    }
  },
  beforeDestroy () {
  },
  created () {
    this.$store.commit('initModel')
    if (this.UserData.loginStatus) {
      this.UserData.login().then(() => {
        this.getMemberModel()
        if (this.global.loginPage) {
          this.$router.replace(this.global.loginPage)
        }
      })
    }
    getActivities()
    this.getListModel({ name: 'SystemRights' })
    this.getListModel({ name: 'SystemImages' }).then(list => {
      document.querySelectorAll('link').forEach(dom => {
        if (dom.rel === 'icon' && list.data && list.data[3]) {
          dom.href = list.data[3].image
        }
      })
    })
    this.getListModel({ name: 'Seometas' }).then(list => {
      if (list.data[0]) {
        document.querySelector('title').innerHTML = list.data[0].title
      } else {
        document.querySelector('title').innerHTML = '東震股份有限公司'
      }
    })
    this.getListModel({ name: 'Categories' }).then(list => {
      list.data[0].set({
        name: '全部商品'
      })
    })
    plusBrowseCount()
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Categories: state => state.model.Categories,
      UserData: state => state.model.UserData
    }),
    error () {
      return this.$route.path === '/error'
    }
  },
  components: {
    Layout,
    Error
  }
}
</script>
<style lang="scss" src="./scss/main.scss"></style>
