export default {
  mount: '.icon-spin-right',
  attrs: {
    viewBox: '0 0 40 40',
    fill: 'currentColor'
  },
  path: `
    <rect x="0" y="0" width="40" height="40"/>
    <path d="M35,16.8666667 L23.7,16.8666667 L28.2666667,12.1666667 C23.7166667,7.66666667 16.35,7.5 11.8,12 C7.25,16.5166667 7.25,23.8 11.8,28.3166667 C16.35,32.8333333 23.7166667,32.8333333 28.2666667,28.3166667 C30.5333333,26.0833333 31.6666667,23.4666667 31.6666667,20.1666667 L35,20.1666667 C35,23.4666667 33.5333333,27.75 30.6,30.65 C24.75,36.45 15.25,36.45 9.4,30.65 C3.56666667,24.8666667 3.51666667,15.4666667 9.36666667,9.68333333 C15.2166667,3.9 24.6,3.9 30.45,9.68333333 L35,5 L35,16.8666667 Z" fill="#424242"/>
  `
}
