import Vue from 'vue'
import VueRouter from 'vue-router'
import indexTree from './index-tree'
import middleware from './middleware'
import { getNavbars, getAboutsById, getJoinsById, getServicesById } from '../api'
import Second from '../container/multilevel-article-page/Second.vue'
import Third from '../container/multilevel-article-page/Third.vue'
import { MultilevelArticleModel } from '../models'
import store from '../store'

Vue.use(VueRouter)

const covPath = (str) => {
  const a1 = str.split()
  const s1 = a1[0].toLowerCase()
  a1[0] = s1
  return '/' + a1.join() + '/'
}
const covName = (str) => {
  const a1 = str.split()
  const s1 = a1[0].toLowerCase()
  a1[0] = s1
  return a1.join()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: indexTree
})

getNavbars().then((res) => {
  const navbars = res.data.map(p => new MultilevelArticleModel(p))
  router.options.routes.forEach(route => {
    const createChildren = param => {
      const {
        zhName,
        enName,
        component,
        componentChild,
        api
      } = param
      if (route.name === enName) {
        const AboutApiData = navbars.find(p => p.title === zhName)
        if (!AboutApiData || !AboutApiData.web_children) return
        const children = [
          ...AboutApiData.web_children.map(node => {
            return {
              name: `${covName(enName)}-${node.id}`,
              path: `${covPath(enName)}${node.id}`,
              meta: {
                title: node.title,
                articleId: node.id,
                model: node,
                api
              },
              component,
              children: node.web_children && node.web_children.map(_node => {
                return {
                  name: `${covName(enName)}-${node.id}-${_node.id}`,
                  path: `${covPath(enName)}${node.id}/${_node.id}`,
                  meta: {
                    title: _node.title,
                    articleId: _node.id,
                    model: _node,
                    togglePanelBar: !!_node.web_children.length,
                    api
                  },
                  component: componentChild || component
                }
              })
            }
          })
        ]
        children.forEach(child => {
          route.children.push(child)
          router.addRoute(enName, child)
        })
      }
    }
    createChildren({
      zhName: '關於東震',
      enName: 'About',
      component: Second,
      api: getAboutsById
    })
    createChildren({
      zhName: '加入東震',
      enName: 'Join',
      component: Second,
      componentChild: Third,
      api: getJoinsById
    })
    createChildren({
      zhName: '客服中心',
      enName: 'Service',
      component: Second,
      componentChild: Third,
      api: getServicesById
    })
    store.commit('setRouter', router.options.routes)
  })
})

if (localStorage.getItem('token')) {
  router.beforeEach(middleware)
}

export default router
