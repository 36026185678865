<template lang="pug">
  div(class="monthly__item bg-white")
    div(class="d-flex position-fixed monthly__header justify-content-center w-100 py-2")
      div(class="d-flex justify-content-center")
        button(class="header-icon" @click="spinLeft")
          img(:src="SpinLeftIcon")
          //- Icon(class="" pattern="SpinLeft" size="30" style="transform:scaleX(-1)")
        //- button(class="header-icon" v-show="!zooming" @click="zoom")
        //-   img(:src="ZoomInIcon")
          //- Icon(class="" pattern="ZoomIn" size="30")
        button(v-show="zooming" @click="zoom")
          Icon(class="" pattern="ZoomOut" size="30" )
        button(class="header-icon" @click="spinRight")
          img(:src="SpinRightIcon")
          //- Icon(class="" pattern="SpinRight" size="30")
      div
        button(class="position-absolute" style="right:16px" @click="seeThumbnail")
          Icon(class="" pattern="Menu" size="30")
    div(class="position-fixed thumbnail w-100" v-show="thumbnail")
      div(class="d-flex flex-wrap py-5 px-5 " style="")
        div(class="d-flex flex-column align-items-center m-3 cursor-pointer" v-for='(item,index) in books' :key="item.id" @click="thumbnailTagPage(item,index)")
          img(:src="item.image")
          div(class="text-white pt-2") {{ item.page-1 === 0 ? '封面' : item.page -1}}
    div(ref="content" class="d-flex monthly__content justify-content-center")
      div(class="d-none d-xl-flex flex-column")
        div(class="tag-list")
          div(class="tag tag-left rounded py-1 px-2 mb-1 text-white position-relative d-flex justify-content-start" v-if="item.tag_use && page > item.page" v-for="(item, index) in books" :key="item.image" @click="tagPage(item,index)")
            div(class="mr-3") {{ item.page }}
            div {{item.tag_name}}
      #magazine(class="position-relative ml-0" style="z-index:1" @click="turnPage")
        div(v-for="item in books" :key="item.id")
          img(:src="item.image")
      div(class="d-none d-xl-flex flex-column")
        div(class="tag-list")
          div(class="tag tag-right rounded py-1 px-2 mb-1 text-white position-relative d-flex justify-content-end" v-if="item.tag_use && page <= item.page" v-for="(item, index) in books" :key="item.image" @click="tagPage(item,index)")
            div(class="mr-3") {{item.tag_name}}
            div {{ item.page }}
    div(ref="arrows" class="arrows")
      button(class="position-fixed transform-left" @click="prevPage" v-show="page>1")
        Icon(class="" pattern="ArrowRight" size="60")
      button(class="position-fixed transform-right" @click="nextPage" v-show="page < books.length")
        Icon(class="" pattern="ArrowRight" size="60")
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import '@ksedline/turnjs'
import SpinLeftIcon from '@/assets/svg/spin-left.svg'
import SpinRightIcon from '@/assets/svg/spin-right.svg'
import ZoomInIcon from '@/assets/svg/zoom-in.svg'
import { updateEbooksBrowse } from '@/api/sound.js'

const ModelName = 'Ebooks'

export default {
  name: 'MonthlyDetail',
  data () {
    return {
      books: [],
      page: 1,
      thumbnail: false,
      zooming: false,
      deg: 0,
      scale: 1,
      SpinLeftIcon,
      SpinRightIcon,
      ZoomInIcon
    }
  },
  computed: {
    ...mapState({
      listModel: state => state.model[ModelName]
    })
  },
  methods: {
    spinLeft () {
      this.deg -= 90
      this.$refs.content.style.transform = `rotate(${this.deg}deg) scale(${this.scale})`
      this.rotate = `rotate(${this.deg}deg)`
      if (this.deg % 180 === 0) {
        this.$refs.content.style.marginTop = '0px'
        this.$refs.content.style.marginBottom = '0px'
        this.$refs.content.style.width = 'auto'
      } else {
        if ($(window).width() > 768) {
          this.$refs.content.style.marginTop = '150px'
        } else {
          this.$refs.content.style.paddingTop = '0px'
          this.$refs.content.style.marginTop = '0px'
          this.$refs.content.style.width = '500px'
        }
        // this.$refs.content.style.marginBottom = '600px'
      }
    },
    spinRight () {
      this.deg += 90
      this.$refs.content.style.transform = `rotate(${this.deg}deg) scale(${this.scale})`
      if (this.deg % 180 === 0) {
        this.$refs.content.style.marginTop = '0px'
        this.$refs.content.style.marginBottom = '0px'
        this.$refs.content.style.width = 'auto'
      } else {
        if ($(window).width() > 768) {
          this.$refs.content.style.marginTop = '150px'
        } else {
          this.$refs.content.style.paddingTop = '0px'
          this.$refs.content.style.marginTop = '0px'
          this.$refs.content.style.width = '500px'
        }
        // this.$refs.content.style.marginBottom = '600px'
      }
    },
    zoom () {
      if (!this.zooming) {
        this.zooming = true
        if ($(window).width() > 768) {
          this.scale = 2
        } else {
          this.scale = 3
        }
        this.$refs.content.style.transform = `scale(${this.scale}) rotate(${this.deg}deg)`
        if (this.deg % 180 === 0) {
          if ($(window).width() > 768) {
            this.$refs.content.style.width = '2100px'
            this.$refs.content.style.marginTop = '300px'
            this.$refs.content.style.marginBottom = '0px'
            this.$refs.arrows.style.display = 'none'
            this.$refs.arrows.style.zIndex = -1
          } else {
            this.$refs.content.style.width = '1300px'
            this.$refs.content.style.marginTop = '600px'
            this.$refs.arrows.style.zIndex = 1
          }
        } else {
          if ($(window).width() > 768) {
            this.$refs.content.style.marginTop = '600px'
            this.$refs.content.style.marginBottom = '0px'
            this.$refs.arrows.style.zIndex = 1
          } else {
            this.$refs.content.style.marginTop = '450px'
            this.$refs.content.style.width = '1200px'
            this.$refs.arrows.style.zIndex = 1
          }
        }
      } else {
        this.zooming = false
        this.scale = 1
        this.$refs.content.style.transform = `scale(${this.scale}) rotate(${this.deg}deg)`
        this.$refs.arrows.style.display = 'flex'
        this.$refs.arrows.style.zIndex = 1
        if (this.deg % 180 === 0) {
          this.$refs.content.style.marginTop = '0px'
          this.$refs.content.style.marginBottom = '0px'
          this.$refs.content.style.width = 'auto'
        } else {
          if ($(window).width() > 768) {
            this.$refs.content.style.marginTop = '300px'
            this.$refs.content.style.marginBottom = '600px'
            this.$refs.content.style.width = 'auto'
          } else {
            this.$refs.content.style.marginTop = '0px'
            this.$refs.content.style.marginBottom = '0px'
            this.$refs.content.style.width = '500px'
          }
        }
      }
    },
    seeThumbnail () {
      this.thumbnail = !this.thumbnail
    },
    thumbnailTagPage (item, index) {
      this.thumbnail = !this.thumbnail
      this.page = index + 1
      $('#magazine').turn('page', index + 1)
    },
    prevPage () {
      if ($(window).width() > 768) {
        this.page -= 2
      } else {
        this.page -= 1
      }
      if (this.page < 1) {
        this.page = 1
      }
      $('#magazine').turn('page', this.page)
      if (this.page < 1) {
        this.page = 1
      }
    },
    nextPage () {
      if ($(window).width() > 768) {
        this.page += 2
      } else {
        this.page += 1
      }
      if (this.page >= this.books.length) {
        this.page = this.books.length
      }
      $('#magazine').turn('page', this.page)
    },
    turnPage () {
      this.page = $('#magazine').turn('page')
    },
    tagPage (item, index) {
      this.page = item.page + 1
      $('#magazine').turn('page', index + 1)
    }
  },
  mounted () {
    document.documentElement.style.overflow = 'hidden'
    const { id } = this.$route.params
    this.$store.dispatch('getModelById', {
      name: ModelName,
      id: Number(id)
    }).then(item => {
      this.books = item.ebook_images
      updateEbooksBrowse(id, {
        view_from: $(window).width() < 768 ? 'mobile' : 'desktop'
      }).then(() => {
        var winWidth = (window.innerHeight * 0.8) * 1.54
        var winHeight = window.innerHeight * 0.8
        var displayMode = 'double'
        if (window.innerWidth < 1051) {
          winWidth = (window.innerHeight * 0.8) * 0.8
          displayMode = 'single'
        }

        $('#magazine, .tag-list').fadeIn()
        $('#magazine').turn({
          display: displayMode,
          width: winWidth,
          height: winHeight,
          autoCenter: true,
          duration: 1000,
          gradients: true,
          elevation: 50
        })

        $(window).on('resize', function () {
          var winWidth = (window.innerHeight * 0.8) * 1.54
          var winHeight = window.innerHeight * 0.8
          var displayMode = 'double'
          if (window.innerWidth < 1051) {
            winWidth = (window.innerHeight * 0.8) * 0.8
            displayMode = 'single'
          }
          $('#magazine').turn('display', displayMode)
          $('#magazine').turn('size', winWidth, winHeight)
        })
      })
    })
  }
}
</script>

<style lang='scss' scoped>
.monthly__item{
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction:column;
  overflow: hidden;
  .monthly__header{
    top:0;
    z-index: 2;
    background-color: transparent;
    button{
      outline:none;
      border:none;
      background:none;
    }
  }
  .monthly__content{
    min-width: 100vw;
    transition:0.3s;
    padding-top: 60px;
    min-height: 100vh;
    padding-left:6px;
    padding-right: 6px;
    padding-bottom: 60px;
  }
  .tag-list{
    width: 250px;
    display: none;
    margin-top: 3rem;
    .tag{
       background-color: #176636;
       transition:0.3s;
       width: 250px;
       &:nth-of-type(2n){
        background-color: #78181C;
      }
      &:nth-of-type(3n){
        background-color: #C5933A;
      }
      &:nth-of-type(4n){
        background-color: #6F6F6F;
      }
      &:nth-of-type(5n){
        background-color: #01466F;
      }
    }
    .tag-left{
      left:225px;
      &:hover{
        left: 5px;
      }
    }
    .tag-right {
      left:-220px;
      &:hover{
        left: -5px;
      }
    }
  }
}
#magazine{
  display: none;
  overflow: visible;
  margin-top: 3rem;
  img{
    width: 100%;
    height: 100%;
    user-select:none;
    transform:translateZ(0)
  }
}
.arrows{
  display: flex;
  align-items: center;
  z-index: 0;
  position:fixed;
  top:50%;
  button{
    //padding: 124px 163px;
    width: 64px !important;
    height: 70px !important;
    outline:none;
    border:none;
    //border-radius:50%;
    background-position: center center !important;
    background-repeat: no-repeat;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
    z-index: 999;
  }
  .transform-left{
    left:0px;
    transform:rotate(-180deg);
  }
  .transform-right{
    right:0px;
  }
}
.thumbnail{
  top: 48px;
  min-height: calc(100vh - 48px);
  z-index: 2;
  background: rgb(0, 0, 0, 0.7);
  overflow:auto;
  height: 100%;
  > div {
    overflow: auto;
  }
  img{
    width: 80px;
    z-index: 1;
  }
}
@media (max-width:1300px){
  .arrows{
    top:0;
    button{
      padding:0;
      outline:none;
      border:none;
      border-radius:0;
      display: flex;
      justify-content:center;
      width: 50% !important;
      height: 70px !important;
      top: auto;
      bottom: 0;
    }
    .transform-left{
      left:0px;
    }
    .transform-right{
      right:0px;
    }
  }
}
@media (max-width:768px) {
  .monthly__item{
    .monthly__content{
      align-items:center;
      padding-top: 0;
    }
  }
  .arrows{
    z-index: 1;
  }
}
.header-icon{
  img{
    width: 30px;
  }
}
</style>
