import Validate from 'validate.js'

/**
 * @param {RegExp} options.test 允許的特殊字符
 */
const wordsCheck = (value, options) => {
  const regexp = options.test || /[\u4e00-\u9fa5]|\s/
  if (
    String(value).split('').every(str => regexp.test(str))
  ) {
    return true
  }
  return false
}
Validate.validators.words = (value, options) => {
  const valid = wordsCheck(value, options)
  return valid ? undefined : options.message
}
