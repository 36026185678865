import DataModel from '../../library/proto/data-model'
import EbookImagesModel from './ebook-images'

/**
 * @param {Number} id 該Model的辨識索引
*/

export default class EbooksModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.title = entity.title || ''
    this.date = entity.date || ''
    this.published_at = entity.published_at || ''
    this.image = entity.image || ''
    this.sort = entity.sort || 0
    this.view_count_mobile = entity.view_count_mobile || 0
    this.view_count_desktop = entity.view_count_desktop || 0
    this.updated_user = entity.updated_user || ''
    this.ebook_images = entity.ebook_images ? entity.ebook_images.map(p => new EbookImagesModel(p)) : []
  }
}
