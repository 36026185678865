<template lang="pug">
  div(class="login")
    div(class="py-5 my-4 mx-auto text-center" style="width: 300px")
      div(class="d-flex justify-content-center py-3")
        div(class="bg-primary step-block mx-1" v-for="i in [0,1,2,3]" :style="{opacity: step > i ? '1' : '0.2'}")
      h5(class="text-dark font-weight-bold pt-2") 第一次登入
      div(v-if="step === 1")
        p(class="text-dark")
          span 請登入身分證字號或管理編號 <br>
          span 法人請輸入統一編號或管理編號 <br/>
          span(class="text-nowrap") 預設密碼為西元生日 8 碼（例：20160101）
        div(class="my-2")
          ErrorMessage(class="text-center mt-0" :model="UserData" handle="error_message")
          InputBox(class="mt-2" type="text" placeholder="身分證字號或管理編號" :model="UserData" handle="username" @keydown="submit" key="username")
          ErrorMessage(class="text-left mt-0" :model="UserData" handle="username")
          InputBox(class="mt-2" type="password" placeholder="密碼" :model="UserData" handle="password" @keydown="submit" key="password")
          ErrorMessage(class="text-left mt-0" :model="UserData" handle="password")
        div(class="form-group form-check")
          input(type="checkbox" class="form-check-input" id="regulation" v-model="UserData.regulation" value="同意" :class="{'is-invalid': UserData.errors && UserData.errors.regulation && UserData.errors.regulation[0]}")
          label(class="form-check-label text-left fs-14" for="regulation")
            span(class="form-check-box")
            span 我已閱讀並願意遵守
            span(style="color: #009140;text-decoration: none;background-color: transparent;" @click="modalOpen") 會員同意條款
            span ，並同意接收認證碼之簡訊通知
        div(class="my-2")
          button(class="btn btn-success w-100" type="button" @click="toStep2" :disabled="btnDisabled") 下一步
        div(class="my-2")
          router-link(class="text-dark fs-14" to="login") 回到前頁
      div(v-if="step === 2")
        p(class="text-dark")
        | 驗證碼發送至 {{ userPhoneShow }} <br>
        | 若您的手機號碼有誤或未收到驗證碼 <br>
        | 請洽總公司：04-22581569#154 <br>
        div(class="my-2")
          ErrorMessage(class="text-center mt-0" :model="UserData" handle="error_message")
          InputBox(class="mt-2" type="text" placeholder="輸入驗證碼" :model="UserData" handle="otp" @keydown="submit" key="otp")
          ErrorMessage(class="text-left mb-2" :model="UserData" handle="otp" @keydown="submit")
        div(class="my-2")
          button(class="btn btn-success w-100" type="button" @click="toStep3" :disabled="btnDisabled") 送出
        div(class="my-2")
          button(class="btn btn-outline-success w-100" type="button" @click="resendOtp" :disabled="reSendOtpDisabled")
            span 重新發送簡訊驗證碼
            span(v-if="reSendOtpTimer") ({{ reSendOtpTimer }}s)
      div(v-if="step === 3")
        p(class="text-dark")
        | 請修改並牢記您的密碼 <br>
        | 密碼請設定 8 位數以上英數字 <br>
        | 英文大小寫有差別
        div(class="my-2")
          ErrorMessage(class="text-center mt-0" :model="UserData" handle="error_message")
          InputBox(class="mt-2 password_reset" type="password" placeholder="請輸入密碼" :model="UserData" handle="password_reset" @keydown="submit" key="password_reset")
          ErrorMessage(class="text-left mt-0" :model="UserData" handle="password_reset")
          InputBox(class="mt-2" type="password" placeholder="請再次輸入密碼" :model="UserData" handle="password_check" @keydown="submit" key="password_check")
          ErrorMessage(class="text-left mt-0" :model="UserData" handle="password_check")
        div(class="my-2")
          button(class="btn btn-success w-100" type="button" @click="toStep4" :disabled="btnDisabled") 更改密碼
      div(v-if="step === 4")
        p(class="text-dark")
          | 密碼修改成功 <br>
          | 請記住您的密碼
        div(class="my-2")
          router-link(class="btn btn-success w-100" to="/home") 回到首頁
</template>

<script>
import { mapState } from 'vuex'
import {
  getUserLoginFirst,
  getUserOTP,
  getUserResendOTP,
  getUserResetPassword
} from '../../../api'
import { UserModel } from '../../../models'
import { tokenFormat } from '../../../library'
import FirstLoginLightbox from '@/views/dialog/FirstLoginLightbox'
// import Swal from 'sweetalert2'

export default {
  name: 'Register',
  data () {
    return {
      step: 1,
      resetToken: '',
      user: new UserModel(),
      btnDisabled: false,
      reSendOtpDisabled: false,
      reSendOtpTimer: 60
    }
  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    }),
    userPhoneShow () {
      if (this.UserData.mobile_phone01) {
        const userPhone = this.UserData.mobile_phone01.split('-').join('')
        return userPhone.substring(0, 4) + '****' + userPhone.substring(8, 11)
      }
      return ''
    }
  },
  created () {
    if (this.UserData.loginStatus) {
      this.$router.replace('/home')
    }
    this.UserData.validate({})
  },
  methods: {
    submit (e) {
      if (e.keyCode === 13) {
        this['toStep' + (this.step + 1)]()
      }
    },
    async modalOpen () {
      await this.$dialog.open(FirstLoginLightbox, {
        onBackgroundClick: false,
        position: {
          x: 'center',
          y: 'flex-start'
        },
        attrs: {
          model: this.model,
          content: this.content
        },
        width: '1080px'
      })

      await this.$nextTick()
      this.checkbox = true
    },
    // 填寫帳密
    toStep2 () {
      const errorMessage = this.UserData.validate({
        username: {
          presence: {
            allowEmpty: false,
            message: '^請填寫帳號'
          }
        },
        password: {
          presence: {
            allowEmpty: false,
            message: '^請填寫密碼'
          }
        },
        regulation: {
          presence: {
            allowEmpty: false,
            message: '^未同意會員條款'
          }
        }
      })
      if (errorMessage.length) {
        return
      }
      this.btnDisabled = true
      /**
       * req.data = { username: String, password: String }
       * res.data = { phone_number: String }
       */
      getUserLoginFirst({
        username: this.UserData.username,
        password: this.UserData.password
      }).then(res => {
        this.btnDisabled = false
        this.UserData.validate({})
        this.UserData.set({
          mobile_phone01: res.data.phone_number,
          password: '',
          regulation: []
        })
        this.step = 2
        this.onResendOtpTimer(60)
      }).catch(err => {
        this.btnDisabled = false
        this.$el.querySelectorAll('input').forEach(el => {
          el.value = ''
        })
        this.UserData.loginError(err)
      })
    },
    backStep1 () {
      this.UserData.set({
        username: '',
        otp: ''
      })
      this.step = 1
    },
    // OTP 驗證
    toStep3 () {
      const errorMessage = this.UserData.validate({
        otp: {
          presence: {
            allowEmpty: false,
            message: '^請填寫 OTP 驗證碼'
          }
        }
      })
      if (errorMessage.length) {
        return
      }
      this.btnDisabled = true
      /**
       * req.data = { username: String, otp: String }
       * res.data = { reset_token: String }
       */
      getUserOTP({
        username: this.UserData.username,
        otp: this.UserData.otp
      }).then(res => {
        this.btnDisabled = false
        this.UserData.validate({})
        this.resetToken = res.data.reset_token
        this.UserData.set({
          otp: ''
        })
        this.step = 3
      }).catch(err => {
        this.btnDisabled = false
        if (err.response) {
          this.UserData.validate({
            error_message: { inclusion: { message: '^OTP 驗證失敗' } }
          })
        }
      })
    },
    onResendOtpTimer (t) {
      this.reSendOtpDisabled = true
      this.reSendOtpTimer = t
      const timerId = setInterval(() => {
        if (!--this.reSendOtpTimer) {
          this.reSendOtpDisabled = false
          clearTimeout(timerId)
        }
      }, 1000)
    },
    resendOtp () {
      this.onResendOtpTimer(60)
      /**
       * req.data = { identity_card: String }
       */
      getUserResendOTP({
        identity_card: this.UserData.username
      }).then(() => {
        this.UserData.validate({})
        this.reSendOtpTimer(60)
      }).catch(err => {
        if (err.response) {
          this.validate({
            error_message: { inclusion: { message: '^簡訊已重複發送' } }
          })
        }
      })
    },
    // 更改密碼
    toStep4 () {
      const errorMessage = this.UserData.validate({
        password_reset: {
          length: {
            minimum: 8,
            tooShort: '^密碼長度過短，最少 8 碼'
          },
          password: {
            message: '^請填寫包含英文與數字的密碼'
          },
          presence: {
            allowEmpty: false,
            message: '^請填寫密碼'
          }
        },
        password_check: {
          presence: {
            allowEmpty: false,
            message: '^請填寫確認密碼'
          },
          equality: {
            attribute: 'password_reset',
            message: '^密碼與確認密碼必須相同'
          }
        }
      })
      if (errorMessage.length) {
        return
      }
      this.btnDisabled = true
      /**
       * req.data = { username: String, new_password: String, reset_token: String }
       * res.data.token = {
       *   expires_in: Number, (s)
       *   access_token: String,
       *   refresh_token: String,
       *   token_type: String
       * }
       */
      getUserResetPassword({
        username: this.UserData.username,
        new_password: this.UserData.password_reset,
        reset_token: this.resetToken
      }).then(res => {
        this.btnDisabled = false
        this.$store.commit('logout')
        this.UserData.set(res.data)
        const token = tokenFormat(res.data.token)
        localStorage.setItem('token', JSON.stringify(token))
        this.UserData.set({
          loginStatus: true
        })
        this.step = 4
      }).catch(err => {
        this.btnDisabled = false
        if (err.response) {
          this.UserData.validate({
            error_message: { inclusion: { message: '^密碼更新失敗，請洽服務人員' } }
          })
        } else {
          console.warn(err)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .step-block{
    height: 3px;
    width: 16px;
    border-radius: 2px;
  }
</style>
