import ArticleModel from '../../library/proto/article-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {Number} parent_id
 * @param {Array} web_parent
 * @param {Array} web_children
*/
export default class MultilevelArticleModel extends ArticleModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.parent_id = entity.parent_id || 0
    this.banner = entity.banner || ''
    this.banner_title = entity.banner_title || ''
    this.active = entity.active || false
    this.web_children = entity.web_children ? entity.web_children.map(p => new MultilevelArticleModel(p)) : []
  }

  toDatabase (addObject) {
    return {
      ...addObject
    }
  }

  set (entity) {
    const Model = this.constructor
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'web_children') {
        this[key] = entity[key].map(p => new Model(p))
      } else {
        this[key] = entity[key]
      }
    })
  }
}
