/**
 * 行事曆套件
 * https://github.com/richardtallent/vue-simple-calendar
 */
import Vue from 'vue'
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

require('vue-simple-calendar/static/css/default.css')
require('vue-simple-calendar/static/css/holidays-us.css')

Vue.component('CalendarView', CalendarView)
Vue.component('CalendarViewHeader', CalendarViewHeader)
