<template lang="pug">
  div(class=" d-grid grid-tr grid-span fs-lg-16 fs-14" )
    div(class="grid-span line")
    div(class="d-none d-lg-block grid-image px-3 py-2 p-lg-3")
      div(class="p-100 position-relative")
        router-link(class="position-fill bg-contain" :title="model.name" to="'#" v-if="model.images && model.images.length && model.images[0]" :style="{backgroundImage: `url(${model.images[0].image})`}")
    div(class="d-none d-lg-flex align-items-center")
      div(class="")
        div(class="") {{ model.product_number }}
        div(class="") {{ model.name }}
    div(class=" d-lg-flex d-none flex-center")
    div(class=" d-lg-flex d-none flex-center")
    div(class=" d-none d-lg-flex flex-center px-3 px-lg-0")
      div(class="w-100 text-lg-center") {{ model.gift_quantity }}
    div(class="d-none d-lg-flex justify-content-lg-center  align-items-center px-3 my-1") 贈品
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 品號
      div() {{ model.product_number }}
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 商品
      img(:src="model.images[0].image" v-if="model.images && model.images.length && model.images[0]" style="width:100px")
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 名稱
      div() {{ model.name }}
    div(class="d-lg-none two-col px-3 py-2")
      div(class="") 數量
      div() {{ model.gift_quantity }}

</template>

<script>
import defaultImage from '@/assets/images/default.png'
import { ProductsModel } from '../../models'

export default {
  props: {
    model: {
      type: ProductsModel,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultImage
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.grid-tr {
  grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    .two-col{
      display: grid;
      grid-template-columns:  1fr 1fr ;

    }
  }

}
</style>
