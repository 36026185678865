export default {
  mount: '.icon-youtube-native',
  attrs: {
    viewBox: '0 0 120 120',
    fill: 'currentColor'
  },
  path: `
  <g transform="translate(11.000000, 26.000000)">
    <path d="M94.9607955,10.7951613 C93.8585227,6.56612903 90.5517045,3.22741935 86.3630682,2.05887097 C78.8125,0 48.5,0 48.5,0 C48.5,0 18.1875,0 10.5818182,2.05887097 C6.39318182,3.17177419 3.14147727,6.51048387 1.98409091,10.7951613 C0,18.4741935 0,34.5 0,34.5 C0,34.5 0,50.5258065 2.03920455,58.2048387 C3.14147727,62.433871 6.44829545,65.7725806 10.6369318,66.941129 C18.1875,69 48.5,69 48.5,69 C48.5,69 78.8125,69 86.4181818,66.941129 C90.6068182,65.8282258 93.8585227,62.4895161 95.0159091,58.2048387 C97,50.5258065 97,34.5 97,34.5 C97,34.5 97,18.4741935 94.9607955,10.7951613 Z" fill="#FF0000"/>
    <polygon fill="#FFFFFF" points="38 49 64 34.5 38 20"/>
  </g>`
}
