class Bigfate {
  constructor (args) {
    const entity = args || {}
    this.title = entity.title || ''
    this.content = entity.content || ''
  }
}
class Five {
  constructor (args) {
    const entity = args || {}
    this.night = entity.night || ''
    this.morning = entity.morning || ''
  }
}
class Tengod {
  constructor (args) {
    const entity = args || {}
    this.title = entity.title || ''
    this.content = entity.content || ''
  }
}

const JsonParse = (args) => {
  if (args) {
    const parse = typeof args === 'string' ? JSON.parse(args) : args
    return typeof parse === 'string' ? {} : parse
  }
  return {}
}
export default class NatalchartDataModel {
  constructor (args) {
    console.log('NatalchartDataModel')
    console.log(args)
    const entity = JsonParse(args)
    this.AD = entity.AD || ''
    this.CC = entity.CC || ''
    this.LC = entity.LC || ''
    this.bigfate = entity.bigfate ? entity.bigfate.map(p => new Bigfate(p)) : []
    this.five = entity.five ? entity.five.map(p => new Five(p)) : []
    this.gz = entity.gz ? entity.gz.map(p => p) : []
    this.leap = entity.leap || ''
    this.level = entity.level || ''
    this.name = entity.name ? entity.name.map(p => p) : []
    this.nine = entity.nine ? entity.nine.map(p => p) : []
    this.personal = entity.personal ? entity.personal.map(p => p) : []
    this.tengod = entity.tengod ? entity.tengod.map(p => new Tengod(p)) : []
    this.errorstatus = entity.errorstatus || 0
    this.error = entity.error || ''
  }
}
