<template lang="pug">
  div
    div(class="py-5 my-4 mx-auto text-center" style="width: 400px")
      h4(class="py-4 mb-0 font-weight-bold px-2") 聯絡我們
      p(class="text-dark py-3")
        | 我們已收到您的回覆內容，相關部門同仁將儘速處理。 <br>
        | 感謝您對東震的支持與愛護！
      div(class="my-2 mx-auto" style="width: 300px")
        router-link(class="btn btn-success w-100" to="/home") 回到首頁
      div(class="my-2 mx-auto" style="width: 300px")
        router-link(class="btn btn-outline-success w-100" to="/service/contact") 回到聯絡我們
</template>

<script>
export default {
}
</script>
