import IconComponent from './component'

const IconInstall = {
  install (Vue) {
    Vue.component('Icon', IconComponent)
  },
  component: IconComponent
}

export default IconInstall
