<template lang="pug">
  div#member
    div(:style="{backgroundImage: `url(${urlBodyBg})`}")
      router-view
</template>

<script>
import { mapState } from 'vuex'
import urlBodyBg from '@/assets/bg.png'

export default {
  name: 'Member',
  data () {
    return {
      urlBodyBg
    }
  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    })
  },
  created () {
  }
}
</script>
