<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px);")
    div(class="px-sm-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      h3(class="mb-4 font-weight-bold")
      //- div(v-if="isBusiness(UserData)")
        div(class="my-3 border")
          div(class="border-bottom font-weight-bold text-center py-2") 發票寄送地址
          div(class="p-3")
            AddressEditor(:model="InvoiceModel" title="發票寄送地址" @submit="createInvoice")
        div(class="text-center pb-3 border-bottom")
          ButtonBox(:model="InvoiceModel" @click="createInvoice") 送出儲存
      div
        div(class="my-3 border")
          div(class="border-bottom font-weight-bold text-center py-2") 業績地區
          div(class="p-3")
            div(class="col-auto")
              //- select(class="custom-select" style="text-align-last: left;" v-model="InvoiceModel.sale_territory_code")
              select(class="custom-select" style="text-align-last: left;" v-model="userModel.sales_territory_id")
                option(:value="null") 請選擇
                option(v-for="item in filterSalesTerritory" :value="item.id") {{ item.name }}
              ErrorMessage(class="mt-0" :model="userModel" handle="error_message")
            div(class="col")
        div(class="text-center pb-3")
          //- ButtonBox(:model="InvoiceModel" @click="createInvoice") 送出儲存
          ButtonBox(:model="userModel" @click="createInvoice") 送出儲存
      div(class="d-flex align-items-center pt-4 pb-2 border-top" v-if="listModel.data.length")
        div(class="col-auto px-1")
          button(class="btn btn-outline-danger px-4" :disabled="!todolist.length || submitDisabled" @click="deleteList") 刪除
        div(class="form-check mx-3" v-show="mode")
            input(type="checkbox" class="form-check-input" :disabled="!listModel.data.length" :id="uuid.all" v-model="all" :value="true" @change="changeAll")
            label(class="form-check-label text-left fs-16" :for="uuid.all")
              span(class="form-check-box")
              span 全選
      div(class="my-2 border")
        div(class="border-bottom font-weight-bold text-center py-2") 常用收貨人
        div(class="px-3")
          div(class="py-3 row no-gutters border-bottom" v-for="(modelItem, index) in listModel.data" :key="modelItem.id")
            div(class="col form-check mx-3 d-flex" style="pointer-events: auto;"  @click.stop)
              input(type="checkbox" class="form-check-input" :id="modelItem.id" v-model="todolist" :value="modelItem.id" @change="changeTodo")
              label(v-if="mode" class="form-check-label text-left fs-16" :for="modelItem.id")
                span(class="form-check-box" v-show="mode")
                div(class="d-flex flex-column flex-md-row mx-n2 pl-4")
                  span(class="col-auto px-2") {{ modelItem.name }}
                  span(class="col-auto px-2") {{ modelItem.phone }}
                  span(class="col px-2") {{ modelItem.postal_code }} {{ modelItem.city }}{{ modelItem.district }}{{ modelItem.address }}
              label(v-else class="form-check-label text-left fs-16")
                span(class="form-check-box" v-show="mode")
                div(class="d-flex flex-column flex-md-row mx-n2 pl-4")
                  span(class="col-auto px-2") {{ modelItem.name }}
                  span(class="col-auto px-2") {{ modelItem.phone }}
                  span(class="col px-2") {{ modelItem.postal_code }} {{ modelItem.city }}{{ modelItem.district }}{{ modelItem.address }}
            div(class="col-auto px-2")
              button(class="btn btn-icon flex-shrink-0 text-success my-1" @click="editGeneral(index)" v-show="!todolist.length")
                Icon(class="" pattern="Edit" size="24")
            div(class="col-12 p-2" v-if="modelItem.mode === 'edit'")
              div(class="row mx-n2")
                div(class="col-auto px-2 form-title") 姓名
                div(class="col my-2 px-2" style="max-width: 225px;")
                  InputBox(class="" type="text" placeholder="請輸入姓名?" :model="model" handle="name" :watch="true")
                  ErrorMessage(class="mt-0" :model="model" handle="name")
              div(class="row mx-n2")
                div(class="col-auto px-2 form-title") 聯絡電話
                div(class="col my-2 px-2" style="max-width: 225px;")
                  InputBox(class="" type="text" placeholder="請輸入電話" :model="model" handle="phone" :watch="true")
                  ErrorMessage(class="mt-0" :model="model" handle="phone")
              div(class="row flex-column flex-md-row mx-n2")
                AddressEditor(:model="model" title="收貨地址" :title-style="{width: '94px'}" @submit="createGeneral")
                div(class="submitbox col-auto d-flex align-items-center justify-content-end pt-3 pb-2 px-0")
                  div(class="d-flex")
                    div(class="px-1")
                      button(class="btn btn-success px-4 text-nowrap" @click="updateGeneral(index)" :disabled="submitDisabled") 確定
                    div(class="px-1")
                      button(class="btn btn-secondary px-4 text-nowrap text-white" @click="cancelGeneral(index)") 取消
          div(v-if="!editMode")
            div(class="py-2" v-if="listModel.data.length <= 10")
              div(class="row mx-n2")
                div(class="col-auto px-2 form-title") 姓名
                div(class="col my-2 px-2" style="max-width: 225px;")
                  InputBox(class="" type="text" placeholder="請填寫收貨人名" :model="model" handle="name" :watch="true")
                  ErrorMessage(class="mt-0" :model="model" handle="name")
              div(class="row mx-n2")
                div(class="col-auto px-2 form-title") 聯絡電話
                div(class="col my-2 px-2" style="max-width: 225px;")
                  InputBox(class="" type="text" placeholder="請填寫收貨人電話" :model="model" handle="phone" :watch="true")
                  ErrorMessage(class="mt-0" :model="model" handle="phone")
              div(class="row flex-column flex-md-row mx-n2")
                AddressEditor(:model="model" title="請填寫收貨人地址" :title-style="{width: '94px'}" @submit="createGeneral")
                div(class="col-auto d-flex align-items-end justify-content-end p-2" :class="{'mb-4': hasError(model, 'address')}")
                  button(class="btn btn-success px-4" type="button" @click="createGeneral" :disabled="submitDisabled") 新增
            div(class="py-2" v-else)
              div(class="text-center py-2") 已達最大筆數
</template>

<script>
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { getUser, updateUser, createGeneralAddress, updateGeneralAddress, deleteGeneralAddressGroup } from '../../../api' // updateInvoiceAddress getInvoiceAddress
import { GeneralAddressModel, InvoiceAddressModel, UserModel } from '../../../models'
import AddressEditor from '../../../container/address/Index.vue'

export default {
  name: 'Address',
  data () {
    return {
      userModel: new UserModel({
        loaded: true
      }),
      InvoiceModel: new InvoiceAddressModel(),
      model: new GeneralAddressModel(),
      todolist: [],
      all: [],
      uuid: {
        all: uuidv4()
      },
      submitDisabled: false,
      name: '',
      phone: '',
      mode: 'true'
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
      return
    }
    this.$store.dispatch('getListModel', {
      name: 'GeneralAddress'
    })
    this.$store.dispatch('getListModel', {
      name: 'Offices'
    })
    getUser().then(res => {
      this.$store.commit('setModelData', { name: 'UserData', key: 'sales_territory_id', value: res.data.sales_territory_id })
      this.userModel = new UserModel(this.UserData)
    })
    // getInvoiceAddress().then(res => {
    //   if (res.data.length) {
    //     this.InvoiceModel.set(res.data[0])
    //   }
    // })
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Offices: state => state.model.Offices,
      UserData: state => state.model.UserData,
      listModel: state => state.model.GeneralAddress
    }),
    filterSalesTerritory () {
      return this.Offices.data.filter(p => p.is_sales_territory_use)
    },
    filterFortune () {
      return this.Offices.data.filter(p => p.is_fortune_use)
    },
    editMode () {
      return this.listModel.data.some(p => p.mode === 'edit')
    }
  },
  methods: {
    async createInvoice () {
      // const errorMessage = this.InvoiceModel.validate({
      //   sale_territory_code: {
      //     presence: {
      //       allowEmpty: false,
      //       message: '^請選擇業績地區'
      //     }
      //   }
      // })
      // if (errorMessage) {
      //   return
      // }
      // await this.$nextTick()
      // this.InvoiceModel.loaded = false
      // updateInvoiceAddress(
      //   this.InvoiceModel.toDatabase()
      // ).then(res => {
      //   this.InvoiceModel.loaded = true
      // }).catch(err => {
      //   this.InvoiceModel.loaded = true
      //   this.InvoiceModel.validate({
      //     error_message: { inclusion: { message: `^驗證失敗: ${err.response.data.message}` } }
      //   })
      // })
      const errorMessage = this.userModel.validate({
        sales_territory_id: {
          presence: {
            allowEmpty: false,
            message: '^請選擇業績地區'
          }
        }
      })
      if (errorMessage) {
        return
      }
      await this.$nextTick()
      this.userModel.loaded = false
      updateUser({
        sales_territory_id: this.userModel.sales_territory_id
      }).then(() => {
        this.userModel.loaded = true
        getUser().then(res => {
          this.$store.commit('setModelData', { name: 'UserData', key: 'sales_territory_id', value: res.data.sales_territory_id })
        })
      }).catch(err => {
        this.userModel.loaded = true
        this.userModel.validate({
          error_message: { inclusion: { message: `^驗證失敗: ${err.response.data.message}` } }
        })
      })
    },
    createGeneral (e) {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate()
      if (errorMessage) {
        return
      }
      this.submitDisabled = true
      createGeneralAddress(this.model).then(res => {
        this.submitDisabled = false
        this.listModel.data.push(new GeneralAddressModel(res.data))
        this.model = new GeneralAddressModel()
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    updateGeneral (e) {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate()
      if (errorMessage.length) {
        return
      }
      this.submitDisabled = true
      updateGeneralAddress(
        this.model.id,
        this.model.toDatabase()
      ).then(res => {
        this.listModel.data.forEach(p => {
          if (p.mode === 'edit') {
            p.mode = 'static'
          }
        })
        const targetModel = this.listModel.data.find(p => Number(p.id) === Number(this.model.id))
        targetModel.set(this.model)
        this.model = new GeneralAddressModel()
        this.submitDisabled = false
        this.mode = true
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    changeAll () {
      if (this.all.length) {
        this.todolist = this.listModel.data.map(p => p.id)
      } else {
        this.todolist = []
      }
    },
    changeTodo () {
      console.log('todolist:', this.todolist)
      if (this.listModel.data.length === this.todolist.length) {
        this.all.push(true)
      } else {
        this.all = []
      }
    },
    deleteList () {
      console.log('delete')
      deleteGeneralAddressGroup({
        _method: 'delete',
        id: this.todolist
      }).then(res => {
        this.todolist.forEach(item => {
          const index = this.listModel.data.findIndex(model => Number(model.id) === Number(item))
          this.listModel.data.splice(index, 1)
          this.cancelGeneral(index)
        })
        this.all = []
        this.todolist = []
        console.log(this.todolist)
        this.submitDisabled = false
        console.log('this.todolist:', this.todolist)
      }).catch(() => {
        this.all = []
        this.todolist = []
        console.log('??')
        this.submitDisabled = false
      })
    },
    editGeneral (index) {
      this.mode = false
      localStorage.removeItem('deleteCache')
      this.listModel.data[index].mode = 'edit'
      this.model = new GeneralAddressModel(this.listModel.data[index])
    },
    cancelGeneral (index) {
      this.mode = true
      this.listModel.data[index].mode = 'static'
      this.model = new GeneralAddressModel()
    }
  },
  components: {
    AddressEditor
  }
}
</script>
<style lang="scss"scoped>
  .form-title{
    margin-top: 10px;
    width: 94px;
  }
</style>
