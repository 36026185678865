import { ListModel, ArticleModel } from '../library/proto'
import {
  SystemImagesModel,
  SystemRightsModel,
  SeometasModel,
  CalendarModel,
  MarqueesModel,
  CarouselsModel,
  EbooksModel,
  SoundModel,
  ProductsModel,
  NatalchartModel,
  GeneralAddressModel,
  CategoriesModel,
  ShopCartModel,
  OfficesModel,
  PaymentMethodsModel,
  LoveCodes,
  ShoppingRulesModel,
  ContentModel,
  OrderModel,
  ActivitieModel,
  UserModel
} from '../models'
import {
  getShoppingResultViews,
  getShoppingResultViewsById,
  getSystemImages,
  getSeometas,
  getCalendars,
  getIndexCarousels,
  getIndexMarquees,
  getNews,
  getNewsById,
  getOfficialBulletins,
  getOfficialBulletinsById,
  getBossArticles,
  getBossArticlesById,
  getBossEbooks,
  getBossEbooksById,
  getTravels,
  getTravelsById,
  getSystemRights,
  getProducts,
  getProductsById,
  getNatalcharts,
  getNatalchartsById,
  getGeneralAddress,
  getGeneralAddressById,
  getProductsSidebar,
  getShopcart,
  getFavorites,
  getOffices,
  getOfficesById,
  getPaymentMethods,
  getPaymentMethodsById,
  getShopSystems,
  getSettlementDays,
  getLoveCodes,
  getShippingRules,
  getOrders,
  getOrdersById,
  getActivities
} from '../api'

export default {
  state: {
    SystemRights: new ListModel({
      model: SystemRightsModel,
      api: {
        getList: getSystemRights
      },
      loaded: false
    }),
    SystemImages: new ListModel({
      model: SystemImagesModel,
      api: {
        getList: getSystemImages
      },
      loaded: false
    }),
    Seometas: new ListModel({
      model: SeometasModel,
      api: {
        getList: getSeometas
      },
      loaded: false
    }),
    IndexCarousels: new ListModel({
      model: CarouselsModel,
      api: {
        getList: getIndexCarousels
      },
      loaded: false
    }),
    ProductCarousels: new ListModel({
      model: CarouselsModel,
      api: {
        getList: getIndexCarousels
      },
      loaded: false
    }),
    IndexMarquees: new ListModel({
      model: MarqueesModel,
      api: {
        getList: getIndexMarquees
      },
      loaded: false
    }),
    ProductMarquees: new ListModel({
      model: MarqueesModel,
      api: {
        getList: getIndexMarquees
      },
      loaded: false
    }),
    Calendars: new ListModel({
      model: CalendarModel,
      api: {
        getList: getCalendars
      },
      loaded: false
    }),
    Ebooks: new ListModel({
      model: EbooksModel,
      api: {
        getList: getBossEbooks,
        getByid: getBossEbooksById
      },
      loaded: false
    }),
    NewsArticles: new ListModel({
      model: ArticleModel,
      api: {
        getList: getNews,
        getByid: getNewsById
      },
      loaded: false
    }),
    BossArticles: new ListModel({
      model: SoundModel,
      api: {
        getList: getBossArticles,
        getByid: getBossArticlesById
      },
      loaded: false
    }),
    OfficialBulletins: new ListModel({
      model: ArticleModel,
      api: {
        getList: getOfficialBulletins,
        getByid: getOfficialBulletinsById
      },
      loaded: false
    }),
    TravelArticles: new ListModel({
      model: ArticleModel,
      api: {
        getList: getTravels,
        getByid: getTravelsById
      },
      loaded: false
    }),
    Products: new ListModel({
      model: ProductsModel,
      api: {
        getList: getProducts,
        getByid: getProductsById
      },
      loaded: false
    }),
    Natalcharts: new ListModel({
      model: NatalchartModel,
      api: {
        getList: getNatalcharts,
        getByid: getNatalchartsById
      },
      loaded: false
    }),
    GeneralAddress: new ListModel({
      model: GeneralAddressModel,
      api: {
        getList: getGeneralAddress,
        getByid: getGeneralAddressById
      },
      loaded: false
    }),
    Categories: new ListModel({
      model: CategoriesModel,
      api: {
        getList: getProductsSidebar
      },
      loaded: false
    }),
    ShopCarts: new ListModel({
      model: ShopCartModel,
      api: {
        getList: getShopcart
      },
      loaded: false
    }),
    Favorites: new ListModel({
      model: ProductsModel,
      api: {
        getList: getFavorites
      },
      loaded: false
    }),
    Activities: new ListModel({
      model: ActivitieModel,
      api: {
        getList: getActivities
      },
      loaded: false
    }),
    Offices: new ListModel({
      model: OfficesModel,
      api: {
        getList: getOffices,
        getByid: getOfficesById
      }
    }),
    PaymentMethods: new ListModel({
      model: PaymentMethodsModel,
      api: {
        getList: getPaymentMethods,
        getByid: getPaymentMethodsById
      }
    }),
    ShoppingResultViews: new ListModel({
      model: ArticleModel,
      api: {
        getList: getShoppingResultViews,
        getByid: getShoppingResultViewsById
      }
    }),
    LoveCodes: new ListModel({
      model: LoveCodes,
      api: {
        getList: getLoveCodes
      }
    }),
    ShoppingRules: new ListModel({
      model: ShoppingRulesModel,
      api: {
        getList: getShippingRules
      }
    }),
    ShopSystems: new ListModel({
      model: ContentModel,
      api: {
        getList: getShopSystems
      }
    }),
    SettlementDays: new ListModel({
      model: ContentModel,
      api: {
        getList: getSettlementDays
      }
    }),
    Orders: new ListModel({
      model: OrderModel,
      api: {
        getList: getOrders,
        getByid: getOrdersById
      }
    }),
    UserData: new UserModel()
  },
  mutations: {
    initModel (state) {
      state.UserData = new UserModel()
    },
    logout (state) {
      localStorage.clear()
      state.UserData = new UserModel()
      state.ShopCarts.data = []
      state.Favorites.data = []
      state.Natalcharts.data = []
      state.Orders.data = []
    },
    setModel (state, { name, value }) {
      state[name] = value
    },
    setModelData (state, { name, key, value }) {
      if (key) {
        state[name][key] = value
      } else {
        state[name].set(value)
      }
    },
    setListModel (state, { type, name, list }) {
      const ListModel = state[name]
      const Model = type
      ListModel.setPages(list)
      // console.log('bb')
      // if (ListModel.data.length) {
      //   list.data.reduce((d, item) => {
      //     const check = d.some(d => Number(d.id) === Number(item.id))
      //     if (!check && check.id !== undefined) {
      //       d.push(new Model(item))
      //     }
      //     return d
      //   }, ListModel.data)
      // } else {
      ListModel.data = list.data.map(p => new Model(p))
      // }
    },
    setModelById (state, { type, name, data, callback }) {
      // const ListModel = state[name]
      const Model = type
      // const CacheModel = ListModel.data.find(p => Number(p.id) === Number(data.id))
      // if (CacheModel) {
      //   CacheModel.set({ ...data })
      //   if (callback) callback(CacheModel)
      // } else {
      const NewModel = new Model(data)
      // ListModel.data.push(NewModel)
      if (callback) callback(NewModel)
      // }
    }
  },
  actions: {
    async getListModel ({ commit, state }, { api, name, callback, type }) {
      const ListModel = state[name]
      const apiMethod = api || ListModel.api.getList
      const Model = type || state[name].modelType
      ListModel.loaded = false
      const res = await apiMethod()
      // console.log('aa')
      commit('setListModel', {
        list: Array.isArray(res.data) ? { data: res.data } : res.data,
        type: Model,
        name
      })
      ListModel.loaded = true
      if (callback) callback(ListModel)
      return ListModel
    },
    async getModelById ({ commit, state }, { api, name, id, callback, type }) {
      const ListModel = state[name]
      // const CacheModel = ListModel.data.find(p => Number(p.id) === Number(id))
      const apiMethod = api || ListModel.api.getByid
      const ModelType = type || ListModel.modelType
      ListModel.loaded = false
      // if (ListModel.loaded && CacheModel) {
      //   apiMethod(id).then(res => {
      //     ListModel.loaded = true
      //     commit('setModelById', { type: ModelType, name, data: res.data, callback })
      //   })
      //   return CacheModel
      // } else {
      const res = await apiMethod(id)
      ListModel.loaded = true
      commit('setModelById', { type: ModelType, name, data: res.data, callback })
      return new ModelType(res.data)
      // }
    },
    /**
     * 登入時需要的相關 API Model init
     * @returns {Array} [
     *  store.state.model.ShoppingResultViews,
     *  store.state.model.ShopCarts,
     *  store.state.model.Favorites,
     *  store.state.model.Natalcharts,
     *  store.state.model.Orders,
     * ]
     */
    async getMemberModel ({ dispatch }) {
      return await Promise.all([
        dispatch('getListModel', {
          name: 'ShoppingResultViews'
        }),
        dispatch('getListModel', {
          name: 'ShopCarts'
        }),
        dispatch('getListModel', {
          name: 'Favorites'
        }),
        dispatch('getListModel', {
          name: 'Natalcharts'
        }),
        dispatch('getListModel', {
          name: 'Orders'
        })
      ])
    }
  }
}
