<template lang="pug">
  div(style="min-height: calc(100vh - 190px);")
    div(class="position-relative row" v-if="model.banner" style="padding-top: 34%;")
      div(class="position-fill bg-cover" :style="{backgroundImage: `url(${model.banner})`}")
    div(class="px-sm-3 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="")
        div(class="d-flex justify-content-between pt-xl-3")
          h4(class='font-weight-bold mb-0') {{ model.title }}
          div(class='d-none d-md-flex align-items-center')
            Icon(class="mr-1" pattern="FormatSize" size="24")
            button(class="font-icon rounded-left border border-light border-right-0 bg-white p-0 focus-none btn-30" @click="setFontSize('+')")
              Icon(class="" pattern="Add" size="24")
            button(class="font-icon rounded-right border border-light bg-white p-0 focus-none btn-30" @click="setFontSize('-')")
              Icon(class="" pattern="Remove" size="24")
      router-view(v-if="model.web_children && model.web_children.length")
      div(v-else)
        div(class='pt-3 px-xl-5' :style="{fontSize: global.fontSize + 'px'}" v-html="model.content")
</template>

<script>
import { MultilevelArticleModel } from '../../models'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Second',
  data () {
    return {
      model: new MultilevelArticleModel()
    }
  },
  computed: {
    ...mapState({
      global: state => state.global
    }),
    getModelById () {
      return this.$route.meta.api
    }
  },
  methods: {
    ...mapMutations(['setFontSize']),
    getModel () {
      const model = this.$route.meta.model
      if (model && model.content) {
        this.model = model
        return
      }
      this.getModelById(this.$route.meta.articleId).then(res => {
        this.$route.meta.model = this.model = new MultilevelArticleModel(res.data)
      })
    }
  },
  watch: {
    $route (to, from) {
      this.getModel()
    }
  },
  created () {
    this.getModel()
  }
}
</script>
