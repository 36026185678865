<template lang="pug">
  div(class="login")
    div(class="py-5 my-4 mx-auto text-center" style="width: 300px")
      div(class="d-flex justify-content-center py-3")
        div(class="bg-primary step-block mx-1" v-for="i in [0,1,2]" :style="{opacity: step > i ? '1' : '0.2'}")
      h5(class="text-dark font-weight-bold pt-2") 修改密碼
      div(v-if="step === 1")
        div(class="text-dark") 請輸入舊密碼及新密碼
        div(class="mb-2")
          div(class="error-message mb-4")
            div 新密碼請設定 8 位數以上英數字
            div 英文大小寫有差別
          InputBox(class="mt-2" type="password" placeholder="請輸入舊密碼" :model="model" handle="old_password" @keydown="submit" :watch="true")
          ErrorMessage(class="text-left mt-0" :model="model" handle="old_password")
          InputBox(class="mt-2" type="password" placeholder="請輸入新密碼" :model="model" handle="new_password" @keydown="submit" :watch="true")
          ErrorMessage(class="text-left mt-0" :model="model" handle="new_password")
          InputBox(class="mt-2" type="password" placeholder="請再次輸入新密碼" :model="model" handle="confirm_password" @keydown="submit" :watch="true")
          ErrorMessage(class="text-left mt-0" :model="model" handle="confirm_password")
        div(class="my-2")
          button(class="btn btn-success w-100" type="button" @click="toStep2" :disabled="submitDisabled") 下一步
        div(class="my-2")
          router-link(class="text-dark fs-14" to="account") 回到前頁
      div(v-if="step === 2")
        p(class="text-dark")
        | 請輸入驗證碼
        div(class="my-2")
          div
            InputBox(class="mb-2" placeholder="請輸入驗證碼" :model="model" handle="otp" @keydown="submit")
            ErrorMessage(class="text-left mt-0" :model="model" handle="otp")
          button(class="btn btn-outline-success w-100" type="button" @click="resendOtp" :disabled="reSendOtpDisabled")
            span 重新發送簡訊驗證碼
            span(v-if="reSendOtpTimer") ({{ reSendOtpTimer }}s)
        div(class="my-2")
          button(class="btn btn-success w-100" type="button" @click="toStep3" :disabled="submitDisabled") 下一步
        div(class="my-2")
          a(class="text-dark fs-14" href="javascript:;" @click="step = 1") 回到上一步
      div(v-if="step === 3")
        p(class="text-dark")
        | 密碼已修改完成 <br>
        | 下次登入請使用新密碼
        div(class="my-2")
          router-link(to="account")
            button(class="btn btn-success w-100" type="button" @click="toStep2") 回到我的帳戶
</template>

<script>
import { mapState } from 'vuex'
import { UserModel } from '@/models'
import { updateUserChangePassword, getUserForgotPassword, getUserResendOTP, getUserOTP } from '@/api'

export default {
  name: 'Change',
  data () {
    return {
      step: 1,
      submitDisabled: false,
      reSendOtpDisabled: false,
      model: new UserModel(),
      data: {},
      reSendOtpTimer: 0
    }
  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    })
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
    }
    this.UserData.validate({})
  },
  methods: {
    submit (e) {
      if (e.keyCode === 13) {
        this['toStep' + (this.step + 1)]()
      }
    },
    sendOtp () {
      console.log(this.UserData)
      getUserForgotPassword({
        ...this.data,
        identity_card: this.UserData.identity_card
      }).then(() => {
        this.UserData.validate({})
        this.onResendOtpTimer(60)
        this.step = 2
      }).catch(err => {
        if (err.response) {
          this.model.validate({
            old_password: { inclusion: { message: `^${err.response.data.messages}` } }
          })
        }
      })
    },
    onResendOtpTimer (t) {
      this.reSendOtpDisabled = true
      this.reSendOtpTimer = t
      const timerId = setInterval(() => {
        if (!--this.reSendOtpTimer) {
          this.reSendOtpDisabled = false
          clearTimeout(timerId)
        }
      }, 1000)
    },
    resendOtp () {
      this.onResendOtpTimer(60)
      /**
       * req.data = { identity_card: String }
       */
      getUserResendOTP({
        identity_card: this.UserData.identity_card
      }).then(() => {
        this.UserData.validate({})
        this.reSendOtpTimer(60)
      }).catch(err => {
        if (err.response) {
          this.validate({
            error_message: { inclusion: { message: '^簡訊已重複發送' } }
          })
        }
      })
    },
    toStep2 () {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate({
        old_password: {
          presence: {
            allowEmpty: false,
            message: '^請填寫舊密碼'
          }
        },
        new_password: {
          presence: {
            allowEmpty: false,
            message: '^請填寫新密碼'
          },
          length: {
            minimum: 8,
            tooShort: '^密碼長度過短，最少 8 碼'
          },
          password: {
            message: '^請填寫包含英文與數字的密碼'
          }
        },
        confirm_password: {
          presence: {
            allowEmpty: false,
            message: '^請填寫新密碼'
          },
          equality: {
            attribute: 'new_password',
            message: '^密碼與確認密碼必須相同'
          }
        }
      })
      if (errorMessage) {
        return
      }
      this.data = {
        old_password: this.model.old_password,
        new_password: this.model.new_password
      }

      this.sendOtp()
    },
    toStep3 () {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate({
        otp: {
          presence: {
            allowEmpty: false,
            message: '^請填寫驗證碼'
          }
        }
      })
      if (errorMessage) {
        return
      }
      getUserOTP({
        username: this.UserData.identity_card,
        otp: this.model.otp
      }).then(res => {
        this.btnDisabled = false
        this.resetToken = res.data.reset_token
        this.UserData.otp = ''
        updateUserChangePassword(this.data).then(res => {
          this.submitDisabled = false
          this.step = 3
        }).catch(() => {
          this.submitDisabled = false
        })
      }).catch(err => {
        this.btnDisabled = false
        console.log(err)
        console.log(err.response)
        if (err.response) {
          this.model.validate({
            otp: { inclusion: { message: '^OTP驗證失敗' } }
          })
          this.submitDisabled = false
        }
      })
    },
    backStep1 () {
      this.step = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  .step-block{
    height: 3px;
    width: 16px;
    border-radius: 2px;
  }
</style>
