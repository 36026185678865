/**
 * @param {Arguments} args 要過濾的參數
 * @param {Array} types 設定參數的類型
 * @return {Array} 按 types 的位置重新放置 args
 */
export const paramFilter = (args, types) => {
  const arrArgs = [].map.call(args, a => a)
  return types.map(type => {
    const findParam = arrArgs.find(param => {
      if ([Boolean, Number, String, Object].includes(type)) {
        switch (type) {
          case Boolean: return typeof param === 'boolean'
          case Number: return typeof param === 'number'
          case String: return typeof param === 'string'
          case Object: return typeof param === 'object'
          default: return false
        }
      }
      return param instanceof type
    })
    if (findParam !== undefined) {
      arrArgs[arrArgs.indexOf(findParam)] = undefined
    }
    return findParam
  })
}

/**
 * @param {*} value 要檢查的值
 * @return {Boolean}
 */
export const isAllowEmpty = (value) => {
  if (Array.isArray(value) && value.length) {
    return true
  } else if (typeof value === 'string' && value === '') {
    return true
  } else if (typeof value === 'number' && value.length === 0) {
    return true
  } else if (typeof value === 'object' && Object.keys(value).length) {
    return true
  }
  return !!value
}
