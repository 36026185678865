export default function (routes) {
  if (!routes) {
    return
  }
  const allowRoute = [
    'About', 'Join', 'Sound', 'News', 'Product', 'Member', 'Service'
  ]
  const filterNode = routes.filter(node => {
    return allowRoute.includes(node.name)
  })
  return filterNode
}
