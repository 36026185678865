export default {
  name: 'Dialog',
  data () {
    return {
      dialog: this.$dialog
    }
  },
  mounted () {
    this.dialog.vm = this
  },
  computed: {
    positionX () {
      return this.dialog.options.position.x
    },
    positionY () {
      return this.dialog.options.position.y
    },
    maxWidth () {
      return this.dialog.options.width
    }
  },
  render (h) {
    return h('div', {
      class: {
        'dialog ': 1,
        show: this.dialog.show
      },
      style: {
        justifyContent: this.positionX,
        alignItems: this.positionY,
        zIndex: 20
      },
      on: {
        click: () => {
          if (this.dialog.options.onBackgroundClick) {
            this.dialog.options.onBackgroundClick()
          }
        }
      }
    }, [
      // eslint-disable-next-line multiline-ternary
      this.dialog.view ? h(this.dialog.view, {
        style: {
          width: this.maxWidth ? '100%' : 'auto',
          maxWidth: this.maxWidth || 'auto'
        },
        props: {
          attrs: this.dialog.options.attrs
        }
      }) : null
    ])
  }
}
