
import { DataModel } from '../../library/proto'
import OrderItemModel from './order-item'
import ActivitieModel from './activitie'
import UserModel from './user'
import dayjs from 'dayjs'

/**
 * @param {Number} id
 * @param {Array<OrderItemsModel>} order_items
 * @param {Array<ActivitieModel>} activities
 * @param {String} delivery_code 營業所
 * [
 *   { name: '黑貓宅急便', value: '51' }
 *   { name: '營業所取貨', value: 'n', condition: ShopCarts every(is_lucky) }
 * ]
 * @param {String} installment 分期數
 * [
 *   { name: '不分期', value: 0 },
 *   { name: '分3期', value: 3 },
 *   { name: '分6期', value: 6 },
 *   { name: '分9期', value: 9 },
 *   { name: '分12期', value: 12 }
 * ]
 * @param {Number} invoice_accept_type 發票類型
 * [
 *    { name: '同收貨地址', value: 1 },
 *    { name: '另寄新地址', value: 2 },
 *    { name: '營業所領取', value: 3 }
 *    { name: '自然人', value: null }
 * ]
 * @param {Number} carrier_type 發票捐贈方式
 * [
 *    { name: '會員載具', value: 1 },
 *    { name: '手機條碼', value: 2 },
 *    { name: '自然人憑證', value: 3 },
 *    { name: '愛心捐贈', value: 4 }
 *    { name: '法人', value: null }
 * ]
 * @param {String} invoice_address 發票地址
 * @param {String} carrier_id 手機條碼
 * @param {String} love_code 愛心碼
 * @param {String} origin_device 使用裝置 ['browser', 'app']
 * @param {String} payment 付款方式
 * [
 *    { name: '台新信用卡', alias: 'taiShinCard', value: 1 },
 *    { name: '一般信用卡', alias: 'card', value: 2 },
 *    { name: '銀聯', alias: 'unionPay', value: 3 },
 *    { name: 'ATM付款', alias: 'ATM', value: 4 }
 * ]
 * @param {String} postal_code 收件郵遞區號
 * @param {String} receiver 收件人
 * @param {String} receiver_address 收件地址
 * @param {String} receiver_phone 收件人電話
 * @param {String} sale_territory 業績地區
 * @param {String} atm_day 付款日期
 * @param {String} card_number 信用卡號或ATM後5碼
 * @param {Number} shipping_fee
 * @param {Number} total_price 訂單總價格
 * @param {Number} total_pv 訂單總點數
 * @param {Number} terms_agree // 如果同意條款符合條件要帶 1
 * @param {String} uniform_number 統一編號
 * ***************
 * 完成訂單才有的屬性
 * @param {String} order_status 訂單狀態
 * [
 *    { name: '已訂購未付款', value: 1 ,edit: true },
 *    { name: '信用卡處理中', value: 2 ,edit: true },
 *    { name: '已付款完成', value: 3 ,edit: true },
 *    { name: '已付款未出貨', value: 4 },
 *    { name: '備貨中', value: 5 },
 *    { name: '已出貨', value: 6 },
 *    { name: '黑貓配送中', value: 7 },
 *    { name: '已送達', value: 8 },
 *    { name: '取消訂單', value: 9 },
 *    { name: '已退貨', value: 10 },
 *    { name: '信用卡交易失敗', value: 11 },
 *    { name: '錯誤交易', value: 23 }
 * ]
 * @param {String} order_number 訂單編號
 * @param {String} is_uploaded 紀錄是否成功上傳
 * @param {String} paid_at 付款時間
 * @param {String} sale_order_number 銷貨單號
 * @param {String} thirdparty_auth_code 第三方支付權限碼
 * @param {String} thirdparty_payment_number 第三方支付編號
 * @param {String} invoice_number 發票編號
 * @param {Boolean} agree_freebie_none 確認是否被後端更改數量
 */
export default class OrderModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.order_items = this.order_items ? this.order_items.map(p => new OrderItemModel(p)) : []
    this.activities = this.activities ? this.activities.map(p => new ActivitieModel(p)) : []
    this.delivery_code = entity.delivery_code || ''
    this.installment = entity.installment || '0'
    this.invoice_accept_type = entity.invoice_accept_type || ''
    this.love_code = entity.carrier_type === 4 ? entity.love_code || '' : ''
    this.carrier_type = entity.carrier_type || ''
    this.invoice_address = entity.invoice_address || ''
    this.carrier_id = entity.carrier_type === 2 ? entity.carrier_id || '' : ''
    this.origin_device = entity.origin_device || 'browser' // app
    this.uniform_number = entity.uniform_number || ''
    this.payment = entity.payment || ''
    this.postal_code = String(entity.postal_code) || ''
    this.receiver = entity.receiver || ''
    this.receiver_address = entity.receiver_address || ''
    this.receiver_phone = entity.receiver_phone || ''
    this.sale_territory = entity.sale_territory || ''
    this.atm_day = entity.atm || ''
    this.card_number = entity.card_number || ''
    this.shipping_fee = entity.shipping_fee || 0
    this.total_price = entity.total_price || 0
    this.total_pv = entity.total_pv || 0
    this.terms_agree = entity.terms_agree || 0
    this.order_number = entity.order_number || ''
    this.order_status = Number(entity.order_status) || 0
    this.is_uploaded = entity.is_uploaded || 0
    this.paid_at = entity.paid_at || ''
    this.sale_order_number = entity.sale_order_number || ''
    this.thirdparty_auth_code = entity.thirdparty_auth_code || ''
    this.thirdparty_payment_number = entity.thirdparty_payment_number || ''
    this.updated_user = entity.updated_user || ''
    this.invoice_number = entity.invoice_number || ''
    this.agree_freebie_none = entity.agree_freebie_none || false
    this.user = new UserModel(entity.user)
    this.agree_freebie_none_ids = entity.agree_freebie_none_ids || []
    // 特定頁面使用
    this.error_message = ''
  }

  toDatabase (addObject) {
    return {
      order_items: this.order_items.map(item => {
        return {
          product_id: item.products.id,
          product_number: item.products.product_number,
          quantity: item.quantity,
          fortune_name: item.fortune_name || undefined,
          fortune_gender: item.fortune_gender || undefined,
          fortune_birth_at: item.fortune_birth_at || undefined,
          fortune_hour: item.fortune_hour || undefined,
          fortune_minute: item.fortune_minute || undefined,
          fortune_china_hour: item.fortune_china_hour || undefined,
          fortune_is_leap: item.fortune_is_leap || undefined,
          fortune_ring_size: item.fortune_ring_size || undefined,
          fortune_wrist_size: item.fortune_wrist_size || undefined,
          fortune_word: item.fortune_word || undefined,
          fortune_finger: item.fortune_finger || undefined
        }
      }),
      activities: this.activities.filter(item => item.activity_items.length).map(item => {
        return {
          activity_id: item.id,
          activity_items: item.activity_items.map(freebie => {
            return {
              product_id: freebie.id,
              product_number: freebie.product_number,
              quantity: freebie.gift_quantity
            }
          })
        }
      }),
      delivery_code: this.delivery_code,
      installment: this.installment,
      invoice_accept_type: this.invoice_accept_type,
      love_code: this.carrier_type === 4 ? this.love_code || undefined : undefined,
      carrier_type: this.carrier_type || undefined,
      invoice_address: this.invoice_address || undefined,
      carrier_id: this.carrier_type === 2 ? this.carrier_id || undefined : undefined,
      card_number: this.card_number || undefined,
      origin_device: this.origin_device,
      payment: this.payment,
      postal_code: Number(this.postal_code),
      receiver: this.receiver,
      receiver_address: this.receiver_address,
      receiver_phone: this.receiver_phone,
      sale_territory: this.sale_territory,
      atm_day: this.atm_day ? dayjs(this.atm_day).format('YYYY-MM-DD') : undefined,
      shipping_fee: this.shipping_fee,
      total_price: this.total_price + this.shipping_fee,
      total_pv: this.total_pv,
      terms_agree: this.terms_agree,
      agree_freebie_none: false || this.agree_freebie_none,
      agree_freebie_none_ids: this.agree_freebie_none_ids || [],
      ...addObject
    }
  }

  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (key === 'order_items') {
        this[key] = entity[key].map(p => new OrderItemModel(p))
      } else {
        this[key] = entity[key]
      }
    })
  }
}
