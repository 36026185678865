import axios from 'axios'
import { DataModel } from '../library/proto'
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2'

export const LoadingModels = {}
const config = {
  api: {
    baseUrl: process.env.VUE_APP_BASE_URL,
    headers: {
      'X-Client-Version': process.env.VUE_APP_VERSION
    }
  }
}
export const axiosInstance = (param) => {
  const ops = param || {}
  const apiConfig = axios.create({
    baseURL: `${config.api.baseUrl}`,
    headers: config.api.headers,
    ...ops
  })

  const requestSuccess = req => {
    req.url = (() => {
      const url = [req.url]
      if (ops.params && ops.params.length) {
        url.push('/' + ops.params.join('/'))
      }
      if (ops.query) {
        const query = Object.keys(ops.query).map(key => key + '=' + ops.query[key])
        url.push('?' + query.join('&'))
      }
      return url.join('')
    })()

    if (localStorage.getItem('token')) {
      if (ops.model instanceof DataModel) {
        const uuid = uuidv4()
        ops.uuid = uuid
        ops.model.loaded = false
        LoadingModels[uuid] = ops.model
      }
      const token = JSON.parse(localStorage.getItem('token'))
      if (token) {
        req.headers.Authorization = `${token.access_token}`
        req.headers['Authorization-type'] = 'access'
      }
    }
    return req
  }

  const requestError = err => Promise.reject(err)

  const responseSuccess = res => {
    if (ops.uuid && LoadingModels[ops.uuid]) {
      const uuid = ops.uuid
      const model = LoadingModels[uuid]
      res.data = model.set({
        ...res.data,
        loaded: true
      })
      delete LoadingModels[ops.uuid]
    }
    return res
  }

  let warningShown = false

  const responseError = err => {
    if ((err.message === 'Network Error' || err.response.data.message === 'Unauthenticated.') && localStorage.getItem('token')) {
      if (!warningShown) {
        warningShown = true
        Swal.fire({
          html: `
          <strong>
            官網資料重置中，已將您登出；<br />
            請重新登入以繼續使用東震官網。
          </strong>
          `,
          icon: '',
          confirmButtonText: '我已了解',
          confirmButtonColor: '#3085d6',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
            window.location.href = '/member/login'
            warningShown = false
          }
        })
      }
    }

    const hasRedirected = sessionStorage.getItem('hasRedirected') === 'true'

    if (err.response.data.message === '網站關閉維護中' && !hasRedirected) {
      sessionStorage.setItem('hasRedirected', 'true')
      window.location.href = '/error'
    }
    if (ops.uuid && LoadingModels[ops.uuid]) {
      delete LoadingModels[ops.uuid]
    }
    return Promise.reject(err)
  }

  apiConfig.interceptors.request.use(requestSuccess, requestError)
  apiConfig.interceptors.response.use(responseSuccess, responseError)

  return apiConfig
}

export const request = axiosInstance()

// Usage Example
// request.post('/some-endpoint', { data: 'example' })
//   .then(response => {
//     console.log('Success:', response)
//   })
//   .catch(error => {
//     console.error('Error:', error)
//   })
