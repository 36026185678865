import { v4 as uuidv4 } from 'uuid'
const uuid = uuidv4()
export default {
  mount: '.icon-wechat-native',
  attrs: {
    viewBox: '0 0 120 120',
    fill: 'currentColor'
  },
  path: `
  <linearGradient x1="50%" y1="0.271853885%" x2="50%" y2="100%" id="linear-gradient-${uuid}">
    <stop stop-color="#02E36F" offset="0%"/>
    <stop stop-color="#07C160" offset="100%"/>
  </linearGradient>
  <path d="M24.6084125,5.69087953e-14 C11.0730745,5.69087953e-14 0,11.0746705 0,24.6124241 L0,24.6124241 L0,95.3909221 C0,108.927002 11.0730745,120 24.6084125,120 L24.6084125,120 L95.3907507,120 C108.85581,120 119.887052,109.039949 120,95.5992467 L120,95.5992467 L120,24.4049361 C119.887052,10.9617234 108.85581,5.69087953e-14 95.3907507,5.69087953e-14 L95.3907507,5.69087953e-14 L24.6084125,5.69087953e-14 Z" fill="url(#linear-gradient-${uuid})"/>
  <path d="M108.522056,71.4369782 C108.522056,57.9483683 95.4036243,47.0339441 79.2234446,47.0339441 C63.0424288,47.0339441 49.9248335,57.9483683 49.9248335,71.4369782 C49.9248335,84.877017 63.0424288,95.7914412 79.2234446,95.8400122 C82.5661943,95.7914412 85.7943688,95.3146136 88.8333891,94.4321448 C89.058505,94.3718145 89.0400817,94.3745148 89.6297162,94.2587107 C90.2193507,94.1429065 90.7322767,94.4321448 91.177278,94.6667894 L97.505778,98.4211023 C97.7971567,98.5772499 97.9694376,98.6557469 98.2089447,98.6557469 C98.7221207,98.6557469 99.1578407,98.2190029 99.1465003,97.7171686 C99.1578407,97.4398784 99.0693482,97.2194799 99.0006796,96.9871554 C98.9320109,96.7548309 98.1467213,93.8923084 97.7401669,92.0856993 C97.6825997,91.8298874 97.6511266,91.6826331 97.6511266,91.198335 C97.6511266,90.7140368 97.9016961,90.1939366 98.4433336,89.7392537 C104.574651,85.370546 108.522056,78.7575908 108.522056,71.4369782 Z M69.2654682,67.2133761 C67.3085005,67.2133761 65.6288891,65.5329162 65.6288891,63.3452961 C65.6288891,61.3860138 67.3085005,59.7047503 69.2654682,59.7047503 C71.4513279,59.7047503 73.1293335,61.3860138 73.1293335,63.3452961 C73.1293335,65.5329162 71.4513279,67.2133761 69.2654682,67.2133761 M88.719746,67.2133761 C86.7627783,67.2133761 85.0831669,65.5329162 85.0831669,63.3452961 C85.0831669,61.3860138 86.7627783,59.7047503 88.719746,59.7047503 C90.9056057,59.7047503 92.5836114,61.3860138 92.5836114,63.3452961 C92.5836114,65.5329162 90.9056057,67.2133761 88.719746,67.2133761" fill="#FFFFFF"/>
  <path d="M11.2506667,49.3803897 C11.2506667,58.1205603 15.9843175,66.0545393 23.4388889,71.4369782 C23.987974,71.841559 24.2817862,72.3043591 24.2815674,73.0813233 C24.2817862,73.349864 24.2346903,73.6385585 24.1420556,74.0180683 C23.5188879,76.5710882 22.834319,79.4928024 22.7357223,79.8841822 C22.6371255,80.2755621 22.4816983,80.5362848 22.5013334,80.8227605 C22.4816983,81.4723958 23.0049661,81.9959833 23.6732778,81.9959833 C23.9068926,81.9959833 24.1150294,81.9007855 24.3764445,81.7613387 L32.1112779,77.3030921 C32.600995,77.0064532 33.1506919,76.8535668 33.5217313,76.8535668 C33.8927707,76.8535668 34.5120472,76.968884 34.9239445,77.0684476 C38.5099085,77.9859878 42.3817558,78.5596794 46.4090001,78.4763149 C47.0393405,78.5596794 47.6821407,78.542978 48.2841112,78.4763149 C47.5584288,76.2315104 47.1413192,73.8281853 47.1121668,71.4369782 C47.1413192,56.6090876 61.4893885,44.6626079 79.2234446,44.6874986 C79.831345,44.6626079 80.4657863,44.6826495 81.0985558,44.6874986 C78.4521248,30.7378525 63.9226672,20.04982 46.4090001,20.04982 C26.9838074,20.04982 11.2506667,33.1486933 11.2506667,49.3803897 Z M34.4551668,44.6874986 C31.8658072,44.6874986 29.767389,42.5869787 29.767389,39.9946074 C29.767389,37.4030733 31.8658072,35.3017162 34.4551668,35.3017162 C37.0445263,35.3017162 39.1429445,37.4030733 39.1429445,39.9946074 C39.1429445,42.5869787 37.0445263,44.6874986 34.4551668,44.6874986 M57.8940557,44.6874986 C55.3045091,44.6874986 53.2062779,42.5869787 53.2062779,39.9946074 C53.2062779,37.4030733 55.3045091,35.3017162 57.8940557,35.3017162 C60.4827661,35.3017162 62.5818335,37.4030733 62.5818335,39.9946074 C62.5818335,42.5869787 60.4827661,44.6874986 57.8940557,44.6874986" fill="#FFFFFF"/>`
}
