export default {
  mount: '.icon-format-size',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'currentColor'
  },
  path: `
    <path d="M9,4 L9,7 L14,7 L14,19 L17,19 L17,7 L22,7 L22,4 L9,4 Z M3,12 L6,12 L6,19 L9,19 L9,12 L12,12 L12,9 L3,9 L3,12 Z" fill="#000000"/>
  `
}
