import { request } from './request.js'

export const getSystemImages = () => request.get('system-images')
export const getSystemRights = () => request.get('system-rights')
export const getSeometas = () => request.get('seometas')
export const getNavbars = () => request.get('navbars')
export const getIndexCarousels = () => request.get('index-carousels')
export const getIndexMarquees = () => request.get('index-marquees')
export const getMainMoneyDayGet = () => request.get('calendars')
export const getAntifrouds = () => request.get('antifrouds')
export const getIndexBanners = () => request.get('index-banners')
export const getVideoreSources = () => request.get('video-resources')
export const getLoginPops = () => request.get('login-pops')
