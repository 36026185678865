import Validate from 'validate.js'
import '../validate'
import dayjs from 'dayjs'
import { definePropertySet } from '../../library'

/**
 * @param {*} args 若為 JSON 字串則會經過轉型
 * @param {String} created_at 該筆資料建立時間 YYYY/MM/DD HH:MM:ss
 * @param {String} updated_at 該筆資料最後編輯時間 YYYY/MM/DD HH:MM:ss
 * @param {String} deleted_at 該筆資料刪除時間 YYYY/MM/DD HH:MM:ss
 */
export default class DataModel {
  constructor (args) {
    const entity = args || {}
    this.created_at = entity.created_at ? dayjs(entity.created_at).format('YYYY/MM/DD HH:mm:ss') : dayjs().format('YYYY/MM/DD HH:mm:ss')
    this.updated_at = entity.updated_at ? dayjs(entity.updated_at).format('YYYY/MM/DD HH:mm:ss') : dayjs(this.created_at).format('YYYY/MM/DD HH:mm:ss')
    this.deleted_at = entity.deleted_at ? dayjs(entity.deleted_at).format('YYYY/MM/DD HH:mm:ss') : null
    this.errors = entity.errors || {}
    this.loaded = entity.loaded === undefined ? true : entity.loaded
    this.mode = entity.mode || 'static'
    definePropertySet.call(this,
      {
        entity,
        api: entity.api || (() => {}),
        edited: entity.edited || false,
        deleted: !!entity.deleted_at || false,
        element: {}
      },
      {
        enumerable: false,
        writable: true
      }
    )
  }

  /**
   * 取得當前錯誤訊息
   * @param {string} field 指定的 model key
   * @return {object} 取得指定的錯誤訊息
   */
  hasError (field) {
    return this.errors && this.errors[field] ? this.errors[field][0] : null
  }

  /**
   * 進行指定的 model key 的驗證
   * @param {string} field 指定的 model key
   * @return {Object} 取得指定驗證項目的錯誤訊息
   */
  valid (field, rule) {
    const rules = this.rules() || {}
    this.errors[field] = Validate.single(this[field], rule || rules[field]) || []
    return this.errors[field] || null
  }

  /**
   * 進行 model 的驗證
   * @param {Object} setRules 要改變的驗證規則
   * @return {Object} 取得所有錯誤訊息
   */
  validate (setRules) {
    const rules = setRules || this.rules()
    this.errors = Validate(this, rules) || {}
    return Validate(this, rules, { format: 'flat' }) || false
  }

  /**
   * 設定 model property 值
   * @param {*} entity
   */
  set (entity) {
    Object.keys(entity).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      this[key] = entity[key]
    })
  }

  filterSet (entity) {
    Object.keys(this).forEach(key => {
      if (['errors', 'loaded', 'mode'].includes(key)) {
        return
      }
      if (entity[key] !== undefined) {
        this[key] = entity[key]
      }
    })
  }

  rules () {
    return {}
  }
}
