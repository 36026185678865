<template lang="pug">
  div
    div(class="calendar bg-white px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="detail")
        router-view
      div(class="flex-column" v-if="listModel.data.length")
        div(class="h4 font-weight-bold pt-4 pb-2 p-xl-4 border-bottom border-light d-lg-none") 相關訊息
        div(class="py-4 d-lg-block d-none")
        div(class="d-flex")
          div(class="h4 font-weight-bold pt-4 pr-4 d-none d-lg-block") 相關訊息
          div(class="d-flex flex-wrap flex-grow-1")
            Pagination(:model="listModel" type="scroll")
              div(class="list-card col-lg-4 col-xl-3 d-flex flex-column py-md-2" v-for="(item, index) in this.listModel.data")
                div(class="pagination-card p-3 d-flex flex-column position-relative" :class="{'disabled': item.id === currentModelId}")
                  router-link(class="text-primary order-md-2 stretched-link" :to="'/news/calendar/'+ item.id") {{ dayFormat(item.title, 'YYYY年MM月') }}
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

const rootPath = '/news/calendar'

export default {
  name: 'Calendar',
  data () {
    return {
      currentModelId: 0
    }
  },
  computed: {
    ...mapState({
      listModel: state => state.model.Calendars
    })
  },
  methods: {
    dayFormat (time, format) {
      if (time) {
        return dayjs(time).format(format)
      }
      return ''
    }
  },
  watch: {
    $route (to, from) {
      if (to.path === rootPath) {
        this.$router.replace(from.path)
      }
    }
  },
  created () {
    const nowday = dayjs().format('YYYY-MM') + '-01'
    this.$store.dispatch('getListModel', {
      name: 'Calendars'
    }).then(list => {
      this.currentModelId = Number(list.data[0].id)
      // console.log(dayjs().format('YYYY-MM'))
      for (let i = 0; i < list.data.length; i++) {
        const item = list.data[i]
        // console.log(item)
        if (item.title === nowday) {
          this.currentModelId = item.id
          // console.log('this.currentModelId', this.currentModelId)
        }
      }
      const path = [rootPath, this.currentModelId].join('/')
      if (this.$route.path !== path) {
        this.$router.replace(path)
      }
    })
  },
  updated () {
    this.currentModelId = Number(this.$route.params.id)
  }
}
</script>
