<template lang="pug">
  div(class="border")
    div(class="border-bottom text-center py-2") 商品訂購資訊
    div(class="row py-3")
      div(class="col-lg-6")
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 訂
            span 購
            span 時
            span 間
            span ：
          div(class="col py-2") {{ dayFormat(model.created_at, 'YYYY/MM/DD') }} {{ dayFormat(model.created_at, 'HH:mm:ss')}}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 業
            span 績
            span 地
            span 區
            span ：
          div(class="col py-2") {{ convOfficeByCode(model.sale_territory).name }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 姓
            span 名
            span ：
          div(class="col py-2") {{ UserData.name  || UserData.name }}
        div(class="d-flex")
          div(class="col-auto information-title py-2")
            span 付
            span 費
            span 方
            span 式
            span ：
          div(class="col py-2") {{ convPaymentByAlias(model.payment).name }}
        template(v-if="model.payment === 'taiShinCard'")
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 分
              span 期
              span 期
              span 數
              span ：
            div(class="col py-2") {{ model.installment || '不分期' }}
        template(v-if="model.payment === 'ATM'")
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 帳
              span 號
              span 後
              span 五
              span 碼
              span ：
            div(class="col py-2") {{ model.card_number }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 匯
              span 款
              span 日
              span 期
              span ：
            div(class="col py-2") {{ dayFormat(model.atm_day, 'YYYY/MM/DD') }}
      div(class="col-lg-6")
        div(class="d-flex")
          div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 方
              span 式
              span ：
          div(class="col py-2") {{ convDeliveryByCode(model.delivery_code) }}
        template(v-if="model.delivery_code === '51'")
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 人
              span ：
            div(class="col py-2") {{ model.receiver }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 人
              span 電
              span 話
              span ：
            div(class="col py-2") {{ model.receiver_phone }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 地
              span 址
              span ：
            div(class="col py-2") {{ model.postal_code }} {{ model.receiver_address }}
        template(v-else)
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 人
              span ：
            div(class="col py-2") {{ model.receiver }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 收
              span 貨
              span 人
              span 電
              span 話
              span ：
            div(class="col py-2") {{ model.receiver_phone }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 營
              span 業
              span 所
              span 名
              span 稱
              span ：
            div(class="col py-2") {{ model.office_name && model.office_name }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 營
              span 業
              span 所
              span 地
              span 址
              span ：
            div(class="col py-2") {{ model.postal_code }} {{ model.receiver_address }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 營
              span 業
              span 所
              span 電
              span 話
              span ：
            div(class="col py-2") {{ model.office_phone &&  model.office_phone }}
        template(v-if="isBusiness(UserData)")
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 發
              span 票
              span 寄
              span 送
              span 方
              span 式
              span ：
            div(class="col py-2")  {{ convInvoiceAcceptTypeByCode(model.invoice_accept_type) }}
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 發
              span 票
              span 寄
              span 送
              span 地
              span 址
              span ：
            div(class="col py-2") {{ model.invoice_address }}
        template(v-else)
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 發
              span 票
              span 類
              span 型
              span ：
            div(class="col py-2") {{ convCarrierTypeByCode(model.carrier_type) }}
          template(v-if="model.carrier_type === 2")
            div(class="d-flex")
              div(class="col-auto information-title py-2") 手機條碼
              div(class="col py-2") {{ model.carrier_id }}
          template(v-if="model.carrier_type === 4")
            div(class="d-flex")
              div(class="col-auto information-title py-2") 捐贈單位：
              div(class="col py-2") {{ convLoveCodesByCodes(model.love_code).name }}
        template(v-if="model.invoice_number !=''")
          div(class="d-flex")
            div(class="col-auto information-title py-2")
              span 發
              span 票
              span 號
              span 碼
              span ：
            div(class="col py-2") {{ model.invoice_number }}
    div(class="border-top" v-for="item in luckyProducts")
      OrderLuckyView(:model="item")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OrderLuckyView from './OrderLuckyView.vue'
import { DataModel } from '../../library/proto'

export default {
  name: 'OrderView',
  props: {
    model: {
      type: DataModel,
      required: true
    }
  },
  created () {
    this.getListModel({ name: 'LoveCodes' })
    this.getListModel({ name: 'Offices' })
    this.getListModel({ name: 'PaymentMethods' })

    // console.log('model', this.model)
  },
  computed: {
    ...mapState({
      PaymentMethods: state => state.model.PaymentMethods,
      Offices: state => state.model.Offices,
      LoveCodes: state => state.model.LoveCodes,
      UserData: state => state.model.UserData
    }),
    luckyProducts () {
      return this.model.order_items.filter(p => p.is_lucky)
    }
  },
  methods: {
    ...mapActions(['getListModel']),
    convDeliveryByCode (code) {
      switch (String(code)) {
        case '51': return '黑貓宅急便'
        default: return '營業所取貨'
      }
    },
    convPaymentByAlias (alias) {
      switch (String(alias)) {
        case 'taiShinCard': return this.PaymentMethods.data.find(p => Number(p.id) === 1) || {}
        case 'card': return this.PaymentMethods.data.find(p => Number(p.id) === 2) || {}
        case 'unionPay': return this.PaymentMethods.data.find(p => Number(p.id) === 3) || {}
        case 'ATM': return this.PaymentMethods.data.find(p => Number(p.id) === 4) || {}
        default: return {}
      }
    },
    convLoveCodesByCodes (code) {
      const LoveCode = this.LoveCodes.data.find(p => p.love_code === code)
      if (LoveCode) {
        return this.LoveCodes.data.find(p => String(p.love_code) === String(code))
      }
      return {}
    },
    convCarrierTypeByCode (code) {
      switch (Number(code)) {
        case 1: return '會員載具'
        case 2: return '手機條碼'
        case 3: return '自然人憑證'
        case 4: return '捐贈發票'
        default: return ''
      }
    },
    convInvoiceAcceptTypeByCode (code) {
      switch (Number(code)) {
        case 1: return '同收貨地址'
        case 2: return '另寄新地址'
        case 3: return '營業所領取'
        default: return ''
      }
    },
    convOfficeByCode (code) {
      const office = this.Offices.data.find(p => p.code === code)
      if (office) {
        return office
      }
      return {}
    }
  },
  components: {
    OrderLuckyView
  }
}
</script>
