import DataModel from '../../library/proto/data-model'

/**
 * @param {Number} id 該Model的辨識索引
 * @param {Number} calendar_id 隸屬的Model的辨識索引
 * @param {String} title 隸屬的標題文字
 * @param {String} location 上課地區
 * @param {Number} day_of_week 星期
 * @param {String} morning_time 上午時段
 * @param {String} afternoon_time 下午時段
 * @param {String} evening_time 晚上時段
 * @param {Number} status 狀態碼
 * @param {String} updated_user 更新使用者
*/
export default class CalendarCourseModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.calendar_id = entity.calendar_id || 0
    this.title = entity.title || ''
    this.location = entity.location || ''
    this.day_of_week = entity.day_of_week || 0
    this.morning_time = entity.morning_time || ''
    this.afternoon_time = entity.afternoon_time || ''
    this.evening_time = entity.evening_time || ''
    this.status = entity.status || 0
    this.updated_user = entity.updated_user || ''
  }
}
