import DataModel from '../../library/proto/data-model'
import ImageModel from '../../library/proto/image-model'
import ProductsModel from './products'
import NatalchartDataModel from './natalchart-data'

/**
 * @param {Number} id 該Model的辨識索引
*/

export default class OrderItemsModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.product_id = entity.product_id || 0
    this.activity_id = entity.activity_id || 0
    this.product_number = entity.product_number || ''
    this.product_quantity = entity.product_quantity || 1
    this.is_lucky = !!entity.fortune_word
    this.fortune_name = entity.fortune_name || ''
    this.fortune_gender = entity.fortune_gender || ''
    this.fortune_birth_at = entity.fortune_birth_at || ''
    this.fortune_hour = entity.fortune_hour || ''
    this.fortune_minute = entity.fortune_minute || ''
    this.fortune_china_hour = entity.fortune_china_hour || ''
    this.fortune_is_leap = entity.fortune_is_leap || ''
    this.fortune_ring_size = entity.fortune_ring_size || ''
    this.fortune_wrist_size = entity.fortune_wrist_size || ''
    this.fortune_word = entity.fortune_word || ''
    this.fortune_finger = entity.fortune_finger || ''
    this.images = entity.images ? entity.images.map(p => new ImageModel(p)) : []
    this.order_id = entity.order_id || 0
    this.price = entity.price || 0
    this.pv = entity.pv || 0
    this.quantity = entity.quantity || 0
    this.products = new ProductsModel(entity.products)
    this.result = new NatalchartDataModel(entity.result)
    this.activity_fives = entity.activity_fives || ''
    this.activity_fives_change = entity.activity_fives_change || 0
    // 特定頁面使用
    this.error_message = ''
  }
}
