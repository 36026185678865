<template lang="pug">
  div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
    div(class="d-flex justify-content-between position-relative pt-xl-3")
      div(class="d-flex")
        h4(class="font-weight-bold text-left") 購物紀錄
    div(class="d-flex pt-3 pb-2")
      div(class="text-dark") 訂單編號：
      div(class="text-success font-weight-bold") {{ model.order_number }}
    div(class="py-2")
      OrderView(:model="model")
    div(class="d-grid grid-table border" style="grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;")
      div(class="text-center title") 商品
      div(class="text-center title")
      div(class="text-center title") 單價
      div(class="text-center title") 積分
      div(class="text-center title") 數量
      div(class="text-center title") 金額小計
      div(class="text-center title") 積分小計
      ProdGrid(v-for="(model, index) in products" :model="model" :key="model.id")
      div(class="d-grid grid-tr grid-span fs-lg-16 fs-14 no-delete" v-if="model.shipping_fee !=0" )
        div(class="grid-span line")
        div(class="d-none d-lg-flex grid-image px-3 py-2 p-lg-3")
            img(:src="truck" style="width:100px")
        div(class="d-none d-lg-flex grid-text align-items-center")
            div(class="") {{shippingCode}}
        div(class="d-none d-lg-flex grid-price d-lg-flex d-none flex-center")
        div(class="d-none d-lg-flex grid-quantity d-flex flex-center px-3 px-lg-0")
        div(class="d-none d-lg-flex grid-count-price  justify-content-lg-center justify-content-end align-items-center px-3 my-1") 運費
        div(class="d-none d-lg-flex grid-count-pv = justify-content-lg-center justify-content-end align-items-center px-3 my-1")  NT$ {{commaFormat(model.shipping_fee)}}
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 品號
          div() {{ shippingCode }}
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 商品
          img(:src="truck" style="width:100px")
        div(class="d-lg-none two-col px-3 py-2")
          div(class="") 運費
          div() NT$ {{commaFormat(model.shipping_fee)}}
      ProdGrid(v-for="(model, index) in gifts" :model="model" :key="model.id")
      div(class="grid-span line d-none d-lg-block")
      div(class="grid-span")
        div(class="ml-auto px-3 py-2 max-w-lg-250")
          div(class="d-flex py-2 justify-content-between")
            div(class="text-dark totle-count-title")
              span 訂
              span 單
              span 金
              span 額
              span 總
              span 計
            div(class="text-danger font-weight-bold") NT$ {{ commaFormat(model.total_price) }}
          div(class="d-flex py-2 justify-content-between")
            div(class="text-dark totle-count-title")
              span 訂
              span 單
              span 積
              span 分
              span 總
              span 計
            div(class="text-primary font-weight-bold") {{ commaFormat(model.total_pv) }}
</template>

<script>
import truck from '@/assets/images/truck.png'
import OrderView from '../../../../container/order/OrderView.vue'
import ProdGrid from '../../../../container/order/ProdGrid.vue'
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
import { OrderModel, ProductsModel } from '../../../../models'
import { getProductsById, getOrdersById } from '../../../../api'

export default {
  name: 'OrderDetail',
  data () {
    return {
      model: new OrderModel(),
      truck,
      shippingCode: '06004',
      products: [],
      gifts: []
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
      return
    }
    this.$store.dispatch('getListModel', {
      name: 'Orders'
    }).then(() => {
      this.getModelById()
    })
  },
  watch: {
    $route () {
      this.getModelById()
    }
  },
  computed: {
    ...mapState({
      ListModel: state => state.model.Orders,
      UserData: state => state.model.UserData,
      Offices: state => state.model.Offices
    }),
    luckyProducts () {
      return this.model.order_items.filter(p => p.is_lucky)
    }
  },
  methods: {
    ...mapActions(['getListModel', 'getModelById', 'natalchartFormat']),
    getModelById () {
      window.scrollTo({ top: 0 })
      const id = Number(this.$route.params.id)
      const cacheOrders = this.ListModel.data.find(p => Number(p.id) === Number(id))
      if (cacheOrders) { // 快取先更新畫面
        this.model = cacheOrders
      }
      return new Promise(resolve => {
        getOrdersById(id).then(res => {
          this.model.set(res.data)

          const office = this.Offices.data.find(p => String(p.code) === String(this.model.delivery_code))
          if (office) {
            this.model.set({
              office_name: office.name,
              office_phone: office.telephone
            })
          }

          this.model.order_items.forEach(item => {
            if (item.is_lucky) {
              this.natalchartFormat({
                ...item,
                fortune_year: dayjs(item.fortune_birth_at).format('YYYY'),
                fortune_month: dayjs(item.fortune_birth_at).format('MM'),
                fortune_day: dayjs(item.fortune_birth_at).format('DD')
              }).then(data => {
                item.set({ result: data })
              })
            }
            getProductsById(item.product_id).then(res => {
              item.set({ products: new ProductsModel(res.data) })
            })
          })

          if (parseInt(this.model.shipping_fee) === 100) {
            this.shippingCode = '06004'
          } else if (parseInt(this.model.shipping_fee) === 200) {
            this.shippingCode = '0600401'
          } else if (parseInt(this.model.shipping_fee) === 250) {
            this.shippingCode = '0600402'
          }

          this.products = this.model.order_items.filter(p => !p.activity_id)
          this.products.sort((a, b) => a.product_number === b.product_number ? (a.id > b.id ? 1 : -1) : a.product_number - b.product_number > 0 ? 1 : -1)
          // console.log('products', this.products)

          this.gifts = this.model.order_items.filter(p => p.activity_id)
          this.gifts.sort((a, b) => a.product_number === b.product_number ? (a.id > b.id ? 1 : -1) : a.product_number - b.product_number > 0 ? 1 : -1)
          // console.log('gifts', this.gifts)

          resolve(this.model)
        }).catch(err => {
          console.log(err)
          this.$router.replace('/member/order/list')
        })
      })
    }
  },
  components: {
    ProdGrid,
    OrderView
  }
}
</script>

<style lang="scss" scoped>
.grid-table{
  .title {
    padding: 12px 0;
    @media (max-width: 991.5px) {
      display: none;
    }
  }
}
.grid-tr {
  grid-template-columns: 132px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    .two-col{
      display: grid;
      grid-template-columns:  1fr 1fr ;
    }
  }
}
</style>
