<template lang="pug">
  div(:style="{backgroundImage: `url(${urlBodyBg})`}")
    div(class="container" style="min-height: calc(100vh - 190px);")
      div(class='about bg-white px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4')
        div(class='d-flex justify-content-end pb-4 pt-xl-3')
          div(class='d-none d-md-flex align-items-center')
            Icon(class="mr-1" pattern="FormatSize" size="24")
            button(class="font-icon rounded-left border border-light border-right-0 bg-white p-0 focus-none btn-30" @click="setFontSize('+')")
              Icon(class="" pattern="Add" size="24")
            button(class="font-icon rounded-right border border-light bg-white p-0 focus-none btn-30"  @click="setFontSize('-')")
              Icon(class="" pattern="Remove" size="24")
        div(class="about__content" :style="{fontSize: global.fontSize + 'px'}" v-html='antifrouds.content')
</template>

<script>
import urlBodyBg from '@/assets/bg.png'
import { getAntifrouds } from '../../../api'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Scam',
  data () {
    return {
      antifrouds: '',
      urlBodyBg
    }
  },
  computed: {
    ...mapState({
      global: state => state.global
    })
  },
  methods: {
    ...mapMutations(['setFontSize'])
  },
  created () {
    getAntifrouds().then(res => {
      this.antifrouds = res.data[0]
    })
  }
}
</script>
