export default {
  mount: '.icon-pagenext',
  attrs: {
    viewBox: '0 0 40 40',
    fill: 'currentColor'
  },
  path: `
      <g id="icon/pagenext" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-3">
              <circle id="Oval" stroke-opacity="0.2" stroke="#56870F" fill-opacity="0.8" fill="#FFFFFF" cx="20" cy="20" r="19.5"></circle>
              <g id="Group-2" transform="translate(8.000000, 8.000000)">
                  <polygon id="Path" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) " points="0 0 24 0 24 24 0 24"></polygon>
                  <polygon id="Path" fill="#006C00" fill-rule="nonzero" transform="translate(12.295000, 12.000000) rotate(-180.000000) translate(-12.295000, -12.000000) " points="16 16.59 11.42 12 16 7.41 14.59 6 8.59 12 14.59 18"></polygon>
              </g>
          </g>
      </g>
  `
}
