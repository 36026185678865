import classNames from 'classnames'
import { mountedCheck } from '../../library'

export default {
  data () {
    return {
      translateX: 0,
      offsetWidth: 0
    }
  },
  props: {
    message: {
      type: Array,
      default: () => {
        return []
      }
    },
    start: {
      type: Number,
      default: 80
    },
    speed: {
      type: Number,
      default: 60
    },
    delay: {
      type: Number,
      default: 1000
    }
  },
  mounted () {
    const view = this.$refs.view
    let time = 0
    const animation = (t) => {
      const diff = t - time
      time = t
      const mathDistance = this.speed * (diff / 1000) * -1
      this.translateX += (mathDistance || 0)
      if (-this.offsetWidth / 2 > this.translateX) {
        this.translateX = 0
      }
      requestAnimationFrame(animation)
    }
    mountedCheck(view, () => {
      this.offsetWidth = view.offsetWidth
    }).then(() => {
      let count = 0
      view.childNodes.forEach(item => {
        count += item.offsetWidth
        item.style.width = item.offsetWidth + 1 + 'px'
      })
      view.style.width = count + 1 + 'px'
      this.offsetWidth = count
      this.translateX = this.start
      animation()
    })
  },
  computed: {
    messageDouble () {
      return this.message.concat(this.message)
    }
  },
  render (h) {
    return h('div', {
      class: classNames('marquee')
    }, [
      h('div', {
        ref: 'view',
        class: classNames('marquee-view'),
        style: {
          transform: `translateX(${this.translateX}px)`
        }
      }, [
        this.messageDouble.map(msg => {
          return h('div', {
            class: classNames('marquee-item'),
            domProps: {
              innerHTML: msg
            }
          })
        })
      ])
    ])
  }
}
