<template lang="pug">
  div(class="navbar d-flex flex-grow-1")
    ul(class="d-flex justify-content-around flex-grow-1")
      li(class="position-relative" v-for="route in routeRender(getNodeTree)" :key="route.name")
        a(class="p-2 fs-18" href="javascript:;" v-if="route.isRender") {{ route.meta.title }}
        router-link(class="p-2 fs-18" to="/member/login" v-else-if="!isLogin && route.name =='Member'")  {{ route.meta.title }}
        router-link(class="p-2 fs-18" :to="route.path" v-else) {{ route.meta.title }}
        ul(class="shadow" v-if="route.isRender")
          li(class="position-relative" v-for="child in routeRender(route.children)" :key="child.name")
            a(class="p-2" href="javascript:;" v-if="child.isRender") {{ child.meta.title }}
            router-link(class="" :to="child.path" v-else) {{ child.meta.title }}
            ul(class="shadow" v-if="child.isRender")
              li(class="" v-for="_child in routeRender(child.children)" :key="_child.name")
                router-link(class="" :to="_child.path") {{ _child.meta.title }}
</template>
<script>
import { mapState } from 'vuex'
import getNodeTree from '../node-tree'

export default {
  props: {
    routes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      isLogin: false
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      UserData: state => state.model.UserData
    }),
    getNodeTree () {
      return getNodeTree.call(this, this.global.router)
    }
  },
  created () {
    if (this.UserData.id === 0) {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  methods: {
    isRouteRender (route) {
      if (route.name === 'Product') {
        return false
      }
      if (route.name === 'Member' && this.UserData.id === 0) {
        return false
      }
      // console.log(route.children)
      return route.children && route.children.filter(p => !p.meta.hidden).length > 1
    },
    isNextPath (route) {
      return route.children && route.children.length === 1 && !/^:/.test(route.children[0].path)
    },
    routeRender (routes) {
      // console.log('UserData,', this.UserData.id)
      return routes.filter(p => {
        if (!p.meta) {
          return true
        }
        return !p.meta.hidden
      }).map(route => {
        return {
          ...route,
          isRender: this.isRouteRender(route),
          path: this.isNextPath(route) ? route.children[0].path : route.path
        }
      })
    }
  }
}
</script>
