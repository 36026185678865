import ArticleModel from '../../library/proto/article-model'

/**
 * @param {String} period 期刊數
 * @param {String} image 圖片URL
 * @param {Array}  ebook_images 月刊內頁
*/
export default class SoundModel extends ArticleModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.period = entity.period || 0
    this.image = entity.image || 0
    this.ebook_images = entity.ebook_images || []
  }
}
