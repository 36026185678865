<template lang="pug">
  div#home(class="home" :style="{backgroundImage: `url(${urlBodyBg})`}")
    div(class="overflow-hidden bg-white" v-if="isIndexCarousels.length")
      div(class="container")
        div(class="mx-n3")
          Slick(class="benner" v-bind="bennerSlickSettings")
            div(class="px-sm-1 " v-for="item in isIndexCarousels")
              div(class="position-relative banner-item" :class="{'cursor-pointer': item.url}" @click="linkUrl(item.url)")
                div(class="position-fill bg-cover" :style="{backgroundImage: `url(${item.image || defaultImage})`}")
    div(v-if="marqueesContents.length" class="bg-white top-fix border-bottom")
      Marquee(:message="marqueesContents")
    div(class="container justify-content-center pb-4 pt-2 bg-white")
      div(class="row")
        section(class="col-lg-6 pr-lg-4")
          div(class="d-flex flex-between heading-padding")
            div(class="d-flex flex-between")
              Icon(class="icon-cloud-fix mx-2" style="color:#4B94BD;" pattern="Cloud" size="36")
              h4(class="mb-0 font-weight-bold") 總經理專欄
            div(class="")
              router-link(class="btn btn-outline-success rounded-pill fs-14 py-1 px-3" to="/sound/boss") 閱讀更多
          router-link(class="router-link border-top p-3" :to="'/sound/boss/'+item.id" v-for="item in BossArticlesFilter" :key="item.id" :title="item.title")
            span(class="text-truncate d-block w-100") {{ dayFormat(item.prefix_time,'YYYY年MM月份') }} {{ item.period }}期 {{ item.title }}
            small(class="text-secondary") {{ dayFormat(item.prefix_time,'YYYY/MM/DD') }}
        section(class="col-lg-6 pl-lg-4")
          div(class="d-flex flex-between heading-padding")
            div(class="d-flex flex-between")
              Icon(class="icon-cloud-fix mx-2" style="color:#EDBC31;" pattern="Cloud" size="36")
              h4(class="mb-0 font-weight-bold") 公司公告
            div(class="")
              router-link(class="btn btn-outline-success rounded-pill fs-14 py-1 px-3" to="/news/matter") 閱讀更多
          router-link(class="router-link border-top p-3" :to="'/news/matter/'+item.id" v-for="item in OfficialBulletinsFilter" :key="item.id" :title="item.title")
            span(class="text-truncate d-block w-100") {{ item.title }}
            small(class="text-secondary") {{ dayFormat(item.index_title,'YYYY/MM/DD') }}
      section(class="row")
        div(class="col-12 d-flex justify-content-md-center position-relative heading-padding")
          Icon(class="icon-cloud-fix mx-2" style="color:#ADADA6;" pattern="Cloud" size="36")
          h4(class="mb-0 font-weight-bold") 特惠活動
          div(class="position-absolute" style="right: 15px;")
            router-link(class="btn btn-outline-success rounded-pill fs-14 py-1 px-3" to="/news/activity") 閱讀更多
        div(class="w-100")
          Slick(v-bind="newsSlickSettings" v-if="NewsArticlesFilter.length")
            div(class="d-flex" v-for="item in NewsArticlesFilter" :key="item.id")
              div(class="col text-center")
                div(class="w-100 position-relative p-4b3")
                  div(class="position-fill overflow-hidden news-content" v-if="item.image")
                    img(:src="item.image")
                  div(class="position-fill overflow-hidden news-content" v-else)
                    img(:src="defaultImage")
                a(class="text-truncate w-100 text-center py-2 text-dark stretched-link d-block" :href="'/news/activity/'+item.id" :title="item.title") {{ item.title }}
                div(class="text-secondary small") {{ dayFormat(item.prefix_time, 'YYYY-MM-DD') }}
      section(class="row")
        div(class="col-12 d-flex justify-content-md-center heading-padding")
            Icon(class="icon-cloud-fix mx-2" style="color:#EF716D;" pattern="Cloud" size="36")
            h4(class="mb-0 font-weight-bold") 推薦商品
            div(class="position-absolute" style="right: 15px;")
              router-link(class="btn btn-outline-success rounded-pill fs-14 py-1 px-3" to="/product") 前往購物
        div(class="w-100")
          Slick(v-bind="prodSlickSettings" v-if="recommended.data.length")
            div(class="d-flex" v-for="item in recommended.data")
              div(class="col")
                div(class="")
                  div(class="p-100 position-relative")
                    router-link(class="position-fill bg-contain" :title="item.title" :to="'/product/' + item.id" v-if="item.images.length && item.images[0]" :style="{backgroundImage: `url(${item.images[0].image})`}")
                    router-link(class="position-fill bg-contain" :title="item.title" :to="'/product/' + item.id" v-else :style="{backgroundImage: `url(${defaultImage})`}")
                div(class="d-flex flex-column flex-center")
                  span(class="text-truncate w-100 text-center text-dark d-block") {{ item.name }}
                  span(class="text-success") NT$ {{ commaFormat(item.price) }}
      div(class="row")
        div(class="col-xl-5 mb-5")
          div(class="border px-3 pt-2 shadow-sm rounded mx-auto w-calendar-view")
            div(class="d-flex flex-between py-2")
              div(class="d-flex align-items-end")
                div(class="text-success h4 mb-0") {{ currentMonth }}
                div(class="mx-1 fs-14") 月
                div(class="vertical-line mb-1" style="height: 16px;")
                div(class="mx-1 fs-14") 納財日
            CalendarView(v-bind="calendarSettings")
              CalendarViewHeader
                dayHeader
        div(class="col-xl-7" v-if="EbooksFilter&&EbooksFilter.length")
          div(class="d-flex flex-between")
            div(class="d-flex flex-between")
              Icon(class="icon-cloud-fix mx-2" style="color:#ABCA52;" pattern="Cloud" size="36")
              h4(class="font-weight-bold mb-0") 東震月刊
            div(class="")
              router-link(class="btn btn-outline-success rounded-pill fs-14 py-1 px-3" to="/sound/monthly") 閱讀更多
          div(class="row")
            div(class="col-6 col-md-3 d-flex flex-column flex-center" v-for="item in EbooksFilter" :key="item.id")
              div(class="w-100 my-3")
                div(class="p-3by4 position-relative")
                  div(class="position-fill bg-cover" :style="{backgroundImage: `url(${item.image || defaultImage})`}")
              a(class="text-truncate text-center text-dark w-100 stretched-link d-block" :href="'/sound/monthly/'+item.id" :title="item.title") {{ item.title }}
              small(class="text-secondary") {{ dayFormat(item.date, 'YYYY-MM') }}
</template>

<script>
import dayjs from 'dayjs'
import urlBodyBg from '@/assets/bg.png'
import defaultImage from '@/assets/images/default.png'
import { CalendarModel, ProductsModel } from '../../../models'
import { ListModel } from '../../../library/proto'
import { getCalendarsNow, getRecommendedProducts } from '../../../api'
import { mapState } from 'vuex'
// import Lightbox from '../../dialog/HomeLightbox'

export default {
  name: 'Home',
  data () {
    return {
      urlBodyBg,
      defaultImage,
      calendarNow: new CalendarModel(),
      recommended: new ListModel({
        model: ProductsModel
      }),
      calendarDefault: {
        class: 'index-money-theme',
        showDate: new Date(),
        weekdayNameFormat: 'narrow',
        startingDayOfWeek: 1
      },
      bennerSlickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }]
      },
      newsSlickSettings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      },
      prodSlickSettings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }]
      }
    }
  },
  created () {
    sessionStorage.setItem('hasRedirected', 'false')

    this.$store.dispatch('getListModel', {
      name: 'IndexCarousels'
    })
    this.$store.dispatch('getListModel', {
      name: 'IndexMarquees'
    })
    this.$store.dispatch('getListModel', {
      name: 'Ebooks'
    })
    this.$store.dispatch('getListModel', {
      name: 'NewsArticles'
    })
    this.$store.dispatch('getListModel', {
      name: 'BossArticles'
    })
    this.$store.dispatch('getListModel', {
      name: 'OfficialBulletins'
    })
    if (this.$route.meta.calendarNow) {
      this.calendarNow = this.$route.meta.calendarNow
    } else {
      getCalendarsNow().then(res => {
        if (!res.data.message) {
          this.calendarNow = new CalendarModel(res.data.calendar)
          this.$route.meta.calendarNow = this.calendarNow
        }
      })
    }
    if (this.$route.meta.recommended) {
      this.recommended = this.$route.meta.recommended
    } else {
      getRecommendedProducts().then(res => {
        this.recommended.set({
          data: res.data
        })
        this.$route.meta.recommended = this.recommended
      })
    }
  },
  computed: {
    ...mapState({
      Seometas: state => state.model.Seometas,
      SystemImages: state => state.model.SystemImages,
      Carousels: state => state.model.IndexCarousels,
      Marquees: state => state.model.IndexMarquees,
      Ebooks: state => state.model.Ebooks,
      NewsArticles: state => state.model.NewsArticles,
      BossArticles: state => state.model.BossArticles,
      OfficialBulletins: state => state.model.OfficialBulletins,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData
    }),
    calendarSettings () {
      return {
        ...this.calendarDefault,
        items: this.calendarNow.dumpling_day.map(item => {
          return {
            id: item,
            startDate: dayjs().format('YYYY/MM/') + item
          }
        })
      }
    },
    isIndexCarousels () {
      return this.Carousels.data.filter(p => p.is_index_use)
    },
    marqueesContents () {
      return this.Marquees.data.filter(p => p.is_index_use).map(p => {
        return p.content
      })
    },
    EbooksFilter () {
      // 正式版月刊拿掉
      // if (location.host.indexOf('tocin.com.tw') > -1) {
      //   return null
      // }
      return this.Ebooks.data.filter((p, i) => i < 4)
    },
    NewsArticlesFilter () {
      return this.NewsArticles.data.filter((p, i) => i < 8)
    },
    BossArticlesFilter () {
      return this.BossArticles.data.filter((p, i) => i < 3)
    },
    OfficialBulletinsFilter () {
      return this.OfficialBulletins.data.filter((p, i) => i < 3)
    },
    calendar () {
      if (this.calendarNow.dumpling_day.length) {
        return this.calendarNow.dumpling_day.map((day, index) => {
          return {
            id: index + 1,
            startDate: dayjs().format('YYYY/MM/') + day
          }
        })
      }
      return []
    },
    currentMonth () {
      return dayjs().format('MM')
    }
  },
  methods: {
    linkUrl (url) {
      if (url) {
        window.open(url)
      }
    },
    inFavorites (id) {
      return this.Favorites.data.map(p => p.id).includes(id)
    },
    inShopCart (id) {
      return this.ShopCarts.data.map(p => p.product_id).includes(id)
    },
    clickFavorites (id) {
      if (!this.UserData.loginStatus) {
        this.$router.replace('/member/login')
        return
      }
      if (this.Favorites.data.map(p => Number(p.id)).includes(Number(id))) {
        this.$store.dispatch('removeFavorites', id)
      } else {
        this.$store.dispatch('addFavorites', id)
      }
    },
    clickShopcart (id) {
      if (!this.UserData.loginStatus) {
        this.$router.replace('/member/login')
        return
      }
      if (this.ShopCarts.data.map(p => Number(p.product_id)).includes(Number(id))) {
        this.$store.dispatch('removeShopcart', id)
      } else {
        this.$store.dispatch('addShopcart', id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .heading-padding{
    padding-bottom: 40px;
    margin-bottom: 0;
  }
  section {
    padding: 30px 0;
  }
  section[class*="col"]{
    padding: 30px 15px;
  }
  .top-fix{
    position: relative;
    top: -6px;
    margin-bottom: -6px;
  }
  .vertical-line{
    width: 1px;
    background-color: currentColor;
  }
  .router-link{
    display: flex;
    flex-direction: column;
    color: #000;
    &:hover{
      color: #56870F;
      background-color: rgba(0, 145, 64, 0.1);
    }
  }
  .w-calendar-view{
    width: 100%;
    @media (min-width: 425px){
      width: 380px;
    }
    @media (min-width: 576px){
      width: 475px;
    }
  }
</style>
