<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 384px)")
    div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="d-flex justify-content-between position-relative pt-xl-3")
        div(class="d-flex")
          h4(class="font-weight-bold text-left") 活動兌換
      div(class="mt-4 p-2 border border-light text-center text-black") {{ title }}
      div
        div(class="border border-light border-top-0 p-3")
          div(class="d-flex mx-n2 align-items-center border-bottom pb-3 flex-wrap")
            div(class="text-dark px-2") 活動序號
            div(class="px-2 py-2 py-md-0")
              input(class="text-dark rounded-sm px-3 py-2 border border-light serial-number" v-model="serialNumber" placeholder="請輸入序號" autocomplete="off")
            div(class="px-2")
              button(class="px-3 py-2 btn btn-outline-success" @click="submit") 送出儲存
          div(class="pt-3")
            p(class="mb-0" v-text='description')
</template>

<script>
export default {
  name: 'Activity',
  data () {
    return {
      serialNumber: '',
      title: '活動名稱',
      description: '這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！這裡放活動說明，活動說明會出現在這裡喔！'
    }
  },
  methods: {
    submit () {
    }
  }
}
</script>

<style lang="scss" scoped>
.serial-number{
  width: 100%;
  max-width:225px;
}
@media (min-width: 768px){
  .serial-number{
    margin-left: 80px;
    margin-right: 24px;
    &::placeholder{
      color: #D8D8D8
    }
  }
}
</style>
