<template lang="pug">
  div(class="lighbox position-relative p-4 fs-lg-16 fs-14")
    div(class="rounded-lg" style="background: #ffffff;")
      div(class="px-3 px-lg-5 py-4")
        div(class="h5 text-center") 選擇贈品
        div(class="d-flex justify-content-center align-items-center")
          div(class="px-3")
            span 可選擇 {{ model.ruleable.selected_gift }}
          div(class="px-3")
            span 已選擇 {{ model.ruleable.selected_gift - selectCount }}
        div(class="border my-3")
          div(class="d-sm-flex d-none justify-content-between align-items-center")
            div(class="font-weight-bold text-center py-3 w-sm-132") 商品
            div(class="font-weight-bold text-center py-3 d-flex")
              div(class="w-sm-132 w-66") 數量
          div(class="border-top" v-for="item in model.ruleable.freebies")
            div(class="d-flex justify-content-between align-items-center flex-wrap")
              div(class="d-flex align-items-center")
                div(class="px-2 px-sm-3 py-2 p-lg-3 w-sm-132 flex-shrink-0")
                  div(class="p-100 position-relative")
                    div(class="position-fill bg-contain" :title="item.name" v-if="item.images && item.images.length && item.images[0]" :style="{backgroundImage: `url(${item.images[0].image})`}")
                    div(class="position-fill bg-contain" :title="item.name" v-else :style="{backgroundImage: `url(${defaultImage})`}")
                div(class="")
                  div {{ item.product_number }}
                  div {{ item.name }}
              div(class="py-3 flex-shrink-0 d-flex")
                div(class="px-3 w-sm-132")
                  select(class="custom-select" v-if="item.remaining_gift_quota > 0" v-model="item.gift_quantity" @change="change")
                    option(:value="0") 0{{ item.unit }}
                    option(v-for="i in selectOptionCount(item)" :value="i" v-if="!(selectOptionDisabled(item, i))") {{ i }}{{ item.unit }}
                  div(class="text-danger" v-else) 已贈完
        div(class="d-flex justify-content-between align-items-center")
          div(class="px-3 flex-shrink-0")
            button(class="btn btn-success" type="button" @click="close") 確定
</template>

<script>
import { mapState } from 'vuex'
import defaultImage from '@/assets/images/default.png'

export default {
  name: 'GiftProduct',
  data () {
    return {
      defaultImage
    }
  },
  props: {
    attrs: Object
  },
  computed: {
    ...mapState({
      ShopCarts: state => state.model.ShopCarts
    }),
    model () {
      return this.attrs.model
    },
    activityItemTotle () {
      return this.model.activity_items.reduce((a, item) => Number(a) + Number(item.gift_quantity), 0)
    },
    selectCount () {
      return Math.min(this.model.ruleable.selected_gift - this.activityItemTotle, this.model.ruleable.remaining_quota)
    }
  },
  methods: {
    change () {
      console.log('?1')
      this.model.activity_items = this.model.ruleable.freebies.filter(gift => gift.gift_quantity)
    },
    close () {
      this.$dialog.close()
    },
    selectOptionCount (item) {
      return Math.max(Math.min(item.remaining_gift_quota, this.model.ruleable.selected_gift), 0)
    },
    selectOptionDisabled (item, index) {
      return index > Math.max(this.selectCount, item.gift_quantity + this.selectCount)
    }
  }
}
</script>
<style lang="scss" scoped>
  .w-66 {
    width: 66px;
  }
  @media (min-width: 576px){
    .w-sm-132 {
      width: 132px;
    }
  }
</style>
