<template lang="pug">
  header(class="header")
    div(class="bg-primary")
      div(class="container-fluid text-center py-1 px-3 position-relative")
        router-link(class="text-white fs-14 antifrouds stretched-link" to="/scam") 提醒您!!東震公司呼籲傳銷商提高警覺防新型網路詐騙，反詐騙諮詢專線165。
    div(class="desktop-navbar d-none d-md-block")
      div(class="container d-flex justify-content-between")
        div(class="pt-3 d-flex flex-shrink-0")
          router-link(class="logo" to="/home")
            img(v-if="SystemImages.loaded" :src="SystemImages.data[0].image" width="67" height="98")
            Icon(v-else class="btn-icon text-primary" pattern="Logo" size="67,98")
          div(class="mb-2")
            div(class="font-weight-bold mb-4 px-4") 中華民國{{ nowYear }}年・歲次{{ getSolarYearToLunarWord() }}年
            div(class="px-4") {{ indexBanners.content1 }}
            div(class="px-4") {{ indexBanners.content2 }}
        div(class="d-flex d-flex flex-column justify-content-between pt-3 pb-2")
          div(class="d-flex justify-content-end align-items-center flex-wrap")
            //- Icon(class="btn-icon ml-3" pattern="Facebook" size="16")
            //- Icon(class="btn-icon ml-3" pattern="Line" size="16")
            router-link(class="ml-3" to="/member/login" v-if="!UserData.loginStatus") 會員登入
            span(class="ml-3" v-else) {{ UserData.name }}
            a(class="ml-3" v-if="UserData.loginStatus" @click="logout") 登出

            a(class="ml-3" href="http://tocinmail.com.tw/" target="_blank") 業績信箱
            a(class="ml-3" :href="!UserData.loginStatus ? 'http://211.75.213.163/(S(3jk4x345q0idxw5523ilh345))/pv.aspx' : '/member/performance-search'" :target="!UserData.loginStatus ? '_blank' : ''") 業績查詢
            //- router-link(class="ml-3" to="") 新人好禮兌換
          div(class="d-flex justify-content-end")
            router-link(class="d-flex align-items-center link-primary" to="/member/favorite")
              Icon(class="mx-2" pattern="Favorite" size="14")
              span 我的最愛({{ UserData.loginStatus ? mathFavoritesCount : 0 }})
            div(class="vertical-line text-light ml-3 mr-1")
            router-link(class="d-flex align-items-center link-primary" to="/member/shopcart")
              Icon(class="mx-2" pattern="ShoppingCart" size="14")
              span 我的購物車({{ mathShopCartsCount }})
      div(class="horizontal-line bg-light")
      div(class="container d-flex align-items-center")
        a(class="logo my-1" to="/home")
          Icon(class="btn-icon text-primary" pattern="NavBtn" size="40")
        div(class="search-bar d-flex flex-grow-1 align-items-center" v-if="global.searchBarActive")
          div(class="mx-2")
            select(class="custom-select border-0" v-model="searchData.type")
              option(value="prod") 商品查詢
              option(value="buy") 快速購買
          div(class="flex-grow-1")
            input(class="form-control border-0" type="search" v-on:keyup.enter="search()" :placeholder="searchData.type === 'buy' ? '請輸入品號' : '請輸入品號 / 產品關鍵字'" v-model="searchData.text")
          button(class="btn btn-icon text-dark mx-2" @click="search")
            Icon(class="btn-icon text-dark" pattern="Search" size="24")
          button(class="btn btn-icon text-dark" @click="toggleSearchBar(false)")
            Icon(class="btn-icon rotate-45" pattern="Add" size="24")
        DesktopMenu(v-else)
        div(class="d-flex search py-2" v-if="!global.searchBarActive")
          button(class="btn btn-icon text-dark" @click="toggleSearchBar(true)")
            Icon(class="btn-icon text-dark" pattern="Search" size="24")
      div(class="horizontal-line bg-light")
    div(class="mobile-navbar d-md-none d-flex flex-between px-2" style="align-items: flex-start;")
      div(class="d-flex align-items-start")
        div(class="search p-2 d-none d-lg-block")
          Icon(class="btn-icon text-dark" pattern="Search" size="40")
        button(class="btn btn-icon text-primary p-2" @click="toggleMenuBar(true)")
          Icon(class="btn-icon text-primary" pattern="NavBtn" size="24")
      router-link(class="logo p-2" to="/home")
        img(v-if="SystemImages.loaded" :src="SystemImages.data[0].image" width="70" height="90" )
        Icon(v-else class="btn-icon text-primary" pattern="Logo2" size="40")
      div(class="d-flex align-items-start")
        button(class="btn btn-icon text-primary p-2" @click="cart()" style="display: flex;")
          Icon(class="btn-icon text-primary" style='color:white;' pattern="Cart" size="24")
          span() ({{ mathShopCartsCount }})
    div(class="mobile-navbar d-md-none text-center pb-3")
      div(style="font-size: 24px; font-weight: bold")
        | {{ UserData.name }}
      div
        | 管編：{{ UserData.manage_id }}
    div(class="mobile-navbar d-md-none d-flex flex-between px-4")
      div(class="d-flex align-items-start ")
        a(href="javascript:;" v-if="!UserData.loginStatus" style="color: #000000;" @click="linkLoginPage") 會員登入
        a(href="javascript:;" @click="logout" v-if="UserData.loginStatus" style="color: #000000;" ) 登出
      div(class="d-flex align-items-start")
        span |
      div(class="d-flex align-items-start")
        a(class="ml-3" href="http://tocinmail.com.tw/" style="color: #000000;" target="_blank") 業績信箱
      div(class="d-flex align-items-start ")
        span |
      div(class="d-flex align-items-start")
        a(class="ml-3" :href="!UserData.loginStatus ? 'http://211.75.213.163/(S(3jk4x345q0idxw5523ilh345))/pv.aspx' : '/member/performance-search'" :target="!UserData.loginStatus ? '_blank' : ''") 業績查詢
      div(class="d-flex align-items-start")
        span |
      div(class="d-flex align-items-start")
        a(href="javascript:;" style="color: #000000;" @click="linkPage('/member/favorite')" ) 我的最愛
    div(class="mobile-navbar d-md-none d-flex px-4" style="margin-top: 10px;margin-bottom: 10px;")
      div(class="search-bar d-flex align-items-center " )
        div(class="flex-grow-1")
          select(class="custom-select" style="background: #EBE4DC;border: 1px solid #EAE5DF;outline: none;box-shadow: none;color: #3B8B2C;border-radius: 5px 0 0 5px;cursor: pointer;" v-model="searchData.type")
            option(value="prod") 商品查詢
        div(class="flex-grow-1")
          input(class="form-control border-1" style="padding: 0 5px;border: 1px solid #ccc;outline: none;" type="search" v-on:keyup.enter="search()" :placeholder="searchData.type === 'buy' ? '請輸入品號' : '請輸入品號 / 產品關鍵字'" v-model="searchData.text")
        div(class="flex-grow-1")
          button(class="w-100 btn btn-success m-1 max-w-300" style="background: #009141; color:white" @click="search")
            span 查詢
    div(class="mobile-menu bg-primary" :style="{right: global.mobileMenuActive ? '0' : '100%'}")
      div(class="d-flex text-white h-100")
        div(class="flex-grow-1 position-relative")
          div(class="fill-scroll")
            div(class="d-flex py-3 px-3")
              a(class="" href="https://www.facebook.com/tocin1993/" target="_blank")
                Icon(class="btn-icon mx-2" pattern="Facebook" size="24")
              a(class="" href="https://page.line.me/tocin1993?openQrModal=true" target="_blank")
                Icon(class="btn-icon mx-2" pattern="Line" size="24")
              a(class="" href="https://www.youtube.com/channel/UCOat5l-OeZMLWN6fUtGSIeg" target="_blank")
                Icon(class="btn-icon mx-2" pattern="Youtube" size="24")
            ul
              li(class="py-1 px-4")
                a(href="javascript:;" v-if="!UserData.loginStatus" @click="linkLoginPage") 會員登入
                span(v-else) {{ UserData.name }}
                a(class="mx-2" href="javascript:;" v-if="UserData.loginStatus" @click="logout") 登出
              li(class="py-1 px-4")
                a(href="javascript:;" @click="linkPage('/member/favorite')") 我的最愛({{ mathFavoritesCount }})
              li(class="py-1 px-4")
                a(href="javascript:;" @click="linkPage('/member/shopcart')") 我的購物車({{ mathShopCartsCount }})
            div(class="horizontal-line bg-white mx-4")
            MobileMenu()
        div(class="d-flex flex-column" style="background-color: rgb(29, 31, 23);")
          div(class="px-3 bg-white p-3")
            button(class="btn btn-icon text-primary" @click="toggleMenuBar(false)")
              Icon(class="btn-icon rotate-45" pattern="Add" size="36")
          //- div(class="px-3 flex-grow-1 text-center position-relative" style="background-color:#1D1F17;")
          div(class="text-vertical-bottom position-absolute" style="background-color:#1D1F17;")
            span(class="text-vertical-bottom position-absolute")
            span 中華民國{{ nowYear }}年・歲次{{ getSolarYearToLunarWord() }}年
            span(class="opacity-6 ml-3") {{ indexBanners.content1 }} {{ indexBanners.content2 }}
    div(class="fix-menu" :style="{top: global.scrollTopMenuActive && !global.mobileMenuActive ? '0' : '-60px'}")
      div(class="desktop-menu d-none d-md-block bg-primary px-4")
        div(class="d-flex")
          router-link(class="logo" to="/home")
            img(v-if="SystemImages.loaded" :src="SystemImages.data[4].image" width="40" height="40")
            Icon(v-else class="btn-icon text-white" pattern="Logo2" size="40")
          DesktopMenu()
          div(class="d-none d-xl-flex align-items-center flex-shrink-0" style="width: 500px;")
            div(class="d-flex flex-grow-1" v-if="global.searchBarActive")
              div(class="d-flex flex-grow-1")
                div(class="d-flex flex-grow-1 bg-white rounded mx-2")
                  input(class="form-control border-0 bg-transparent h-auto py-0 flex-grow-1 mr-1" v-on:keyup.enter="search()" type="search" :placeholder="searchData.type === 'buy' ? '請輸入品號' : '請輸入品號號 / 產品關鍵字????'" v-model="searchData.text" :class="{'is-invalid': searchData.invalid }")
                  select(class="custom-select border-0 py-0 w-auto h-auto ml-1" v-model="searchData.type")
                    option(value="prod") 商品查詢
                    option(value="buy") 快速購買
              button(class="btn btn-icon" @click="search")
                Icon(class="btn-icon text-white" pattern="Search" size="24")
              button(class="btn btn-icon" @click="toggleSearchBar(false)")
                Icon(class="btn-icon rotate-45 text-white" pattern="Add" size="24")
            div(class="flex-grow-1 d-flex justify-content-end" v-else)
              button(class="btn btn-icon" @click="toggleSearchBar(true)")
                Icon(class="btn-icon text-white" pattern="Search" size="24")
            div(class="vertical-line bg-white mx-1" style="height: 16px;")
            router-link(class="text-white d-flex align-items-center" to="/member/favorite")
              Icon(class="mx-1" pattern="Favorite" size="20")
              span(class="") {{ mathFavoritesCount }}
            div(class="vertical-line bg-white ml-2 mr-1" style="height: 16px;")
            router-link(class="text-white d-flex align-items-center" to="/member/shopcart")
              Icon(class="mx-1" pattern="ShoppingCart" size="20")
              span(class="") {{ mathShopCartsCount }}
</template>
<script>
import solarLunar from 'solarlunar'
import dayjs from 'dayjs'
import { getAntifrouds, getIndexBanners, getProductsByNumber } from '../../../api'
import { IndexBannersModel } from '../../../models'
import { mapState, mapMutations } from 'vuex'
import DesktopMenu from './DesktopMenu.vue'
import MobileMenu from './MobileMenu.vue'
import { Toast } from './../../../utils/helper'

export default {
  name: 'Header',
  data () {
    return {
      antifrouds: '',
      searchData: {
        type: 'prod',
        text: '',
        invalid: false
      },
      indexBanners: new IndexBannersModel()
    }
  },
  created () {
    // document.addEventListener('contextmenu', event => event.preventDefault())
    window.addEventListener('scroll', this.scrollTop)
    getAntifrouds().then(res => {
      this.antifrouds = res.data[0]
    })
    getIndexBanners().then(res => {
      this.indexBanners = new IndexBannersModel(res.data[0])
    })
    this.productsStatusHandler()
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollTop)
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Seometas: state => state.model.Seometas,
      SystemImages: state => state.model.SystemImages,
      Favorites: state => state.model.Favorites,
      ShopCarts: state => state.model.ShopCarts,
      UserData: state => state.model.UserData,
      Search: state => state.global.search,
      Products: state => state.model.Products
    }),
    gzYear () {
      const year = dayjs().format('YYYY/MM/DD')
      const solarLunarData = solarLunar.solar2lunar(
        year.substring(0, 4),
        year.substring(5, 7),
        year.substring(8, 10)
      )
      const { gzYear } = solarLunarData
      return gzYear
    },
    nowYear () {
      return Number(dayjs().format('YYYY')) - 1911
    },
    mathFavoritesCount () {
      return this.Favorites.data.length
    },
    mathShopCartsCount () {
      this.ShopCarts.data.forEach(item => {
        if (item.products.status === 0) {
          // this.$store.dispatch('removeShopcartByProdId', item.products.id)
        }
      })

      // console.log(this.ShopCarts.data)
      return this.ShopCarts.data.reduce((acc, item) => acc + item.product_quantity, 0)
    }
  },
  methods: {
    ...mapMutations(['toggleSearchBar', 'toggleMenuBar', 'scrollTop', 'setModel']),
    logout () {
      this.toggleMenuBar(false)
      this.$store.commit('logout')
      Toast.fire({
        icon: 'success',
        title: '已成功登出'
      }).then(() => {
        window.location.href = '/home'
      })
    },
    linkLoginPage () {
      this.toggleMenuBar(false)
      this.$router.push('/member/login')
    },
    submit (e) {
      console.log('進入submit')
      this.search()
    },
    linkPage (path) {
      this.toggleMenuBar(false)
      this.$router.push(path)
    },
    cart () {
      this.$router.push('/member/shopcart')
    },
    search () {
      console.log('search')
      if (this.searchData.text === '') {
        return
      }
      console.log('search')
      this.toggleSearchBar(false)
      if (this.searchData.type === 'buy') {
        console.log('進入 if buy')
        if (this.isLuckyProduct(this.searchData.text)) {
          console.log('是幸運商品')
          return alert('不可快速購買開運商品')
        }
        getProductsByNumber(this.searchData.text).then(res => {
          console.log('進入getProductsByNumber')
          console.log(res)
          // this.$store.dispatch('addShopcart', res.data.id)
          this.$router.push({
            path: '/product/' + res.data.id,
            query: {
              action: 'buy'
            }
          })
          this.searchData.invalid = false
          this.searchData.text = ''
        }).catch(() => {
          alert('查無商品')
          this.searchData.invalid = true
        })
      } else {
        console.log('進入else')
        this.Search.keyword = this.searchData.text || null
        this.$router.push({
          path: '/product/list',
          query: {
            keyword: this.searchData.text || null
          }
        }).then(() => {
          console.log('else searchData.text清空')
          this.searchData.text = ''
        })
      }
    },
    getSolarYearToLunarWord (solarYear = new Date().getFullYear()) {
      const firstWord = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸']
      const secondWord = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥']
      /* 基準年 甲子年 */
      const baseYear = 4
      const firstWordIndex = (solarYear - baseYear) % 10
      const secondWordIndex = (solarYear - baseYear) % 12
      const word = firstWord[firstWordIndex] + secondWord[secondWordIndex]
      return word
    },
    productsStatusHandler () {
      // console.log(this.ShopCarts)
      this.ShopCarts.data.forEach(item => {
        // console.log('狀態', item.products.status)
        // console.log('商品名稱：', item.products.name)
        if (item.products.status === 0) {
          // console.log('進入0')
          // console.log(item.products.id)
          this.$store.dispatch('removeShopcartByProdId', item.products.id)
        }
      })
    }
  },
  components: {
    DesktopMenu,
    MobileMenu
  }
}
</script>
<style lang="scss" scoped>
  .fill-scroll {
    &::-webkit-scrollbar{
        width: 0.25rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #56870F;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: #D8D8D8;
    }
  }
  .text-vertical-bottom{
    white-space: nowrap;
    transform-origin: right bottom;
    transform: rotate(90deg);
    bottom: 2rem;
    right: 3rem;
    @media (max-height: 700px) {
      font-size: 14px;
    }
    @media (max-height: 640px) {
      font-size: 12px;
    }
  }
</style>
