<template lang="pug">
  div(class="")
    ul(class="py-3 mb-0")
      li(class="py-2 px-4")
        div(class="d-flex flex-between position-relative" @click="toggleMenuBar(false)")
          router-link(class="stretched-link" to="/home") 回到首頁
      li(class="py-2 px-4" v-for="route in routeRender(getNodeTree)" :key="route.name")
        div(class="d-flex flex-between position-relative")
          a(class="stretched-link" href="javascript:;" @click="toggleMenu(route)") {{ route.meta.title }}
          Icon(class="btn-icon text-white arrow" pattern="ArrowRight" size="24" v-if="route.isRender" :class="{'active': route.meta.open}")
        ul(class="py-2" v-if="route.isRender" v-show="route.meta.open")
          li(class="py-2 pl-4" v-for="child in routeRender(route.children)" :key="child.name")
            div(class="d-flex flex-between position-relative")
              a(class="stretched-link" href="javascript:;" @click="toggleMenu(child)") {{ child.meta.title }}
              Icon(class="btn-icon text-white arrow" pattern="ArrowRight" size="24" v-if="child.isRender" :class="{'active': child.meta.open}")
            ul(class="py-2" v-if="child.isRender" v-show="child.meta.open")
              li(class="py-2 pl-4" v-for="_child in routeRender(child.children)" :key="_child.name")
                div(class="d-flex flex-between position-relative")
                  a(class="stretched-link" href="javascript:;" @click="toggleMenu(_child)") {{ _child.meta.title }}
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import getNodeTree from '../node-tree'
import { toScrollAnimation } from '../../../library'

export default {
  props: {
    routes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      Seometas: state => state.Seometas,
      SystemImages: state => state.SystemImages
    }),
    getNodeTree () {
      return getNodeTree.call(this, this.global.router)
    }
  },
  methods: {
    ...mapMutations(['toggleSearchBar', 'toggleMenuBar', 'scrollTop']),
    isRouteRender (route) {
      if (route.name === 'Product') {
        return false
      }
      return route.children && route.children.filter(p => !p.meta.hidden).length > 1
    },
    isNextPath (route) {
      return route.children && route.children.length === 1 && !/^:/.test(route.children[0].path)
    },
    routeRender (routes) {
      return routes.filter(p => {
        if (!p.meta) {
          return true
        }
        return !p.meta.hidden
      }).map(route => {
        return {
          ...route,
          isRender: this.isRouteRender(route),
          path: this.isNextPath(route) ? route.children[0].path : route.path
        }
      })
    },
    toggleMenu (route) {
      if (this.isRouteRender(route)) {
        if (route.meta.open) {
          route.meta.open = false
        } else {
          route.meta.open = true
        }
        this.$forceUpdate()
      } else if (route.component) {
        if (this.$route.path !== route.path) {
          this.$router.push(route.path)
          toScrollAnimation()
          this.toggleMenuBar(false)
        }
      }
    }
  }
}
</script>
