<template lang="pug">
  div
    div(class="py-2")
      div(class="border")
        div(class="border-bottom text-center py-2") 業績地區
        div(class="d-flex")
          div(class="col-auto from-title") 業績地區
          div(class="col")
            select(class="custom-select my-2" style="text-align-last: left;" :class="{'is-invalid': isInvalid}" v-model="model.sale_territory" @change="changeSaleTerritory")
              option(:value="null") 請選擇
              option(v-for="item in filterSalesTerritory" :value="item.code") {{ item.name }}
              ErrorMessage(class="mt-0" :model="model" handle="sale_territory")
            div(v-show="isInvalid" class="mt-0 error-message") 請選擇業績地區
    div(class="py-2")
      div(class="border")
        div(class="border-bottom text-center py-2") 業績人資料
        div(class="py-2")
          div(class="d-flex")
            div(class="col-auto from-title m-0 py-2") 姓名
            div(class="col py-2") {{ UserData.name || UserData.name }}
          div(class="d-flex")
            div(class="col-auto from-title m-0 py-2") 聯絡電話
            div(class="col py-2")  {{ UserData.mobile_phone01 || UserData.mobile_phone02 }}
    div(class="py-2")
      div(class="border")
        div(class="border-bottom text-center py-2") 送貨資料填寫
        div(class="d-flex")
          div(class="col-auto from-title") 送貨方式
          div(class="col")
            div(class="row mx-n1 radio-group")
              template(v-for="item in deliveryData")
                div(class="col-auto custom-control custom-radio my-2" v-if="item.condition()")
                  input(type="radio" class="custom-control-input" :class="{'checked': model.delivery_code !== '51' && item.value !== '51'}" :id="'delivery-' + item.value" v-model="model.delivery_code" :value="item.value" @change="changeDelivery")
                  label(class="custom-control-label" :for="'delivery-' + item.value") {{ item.method }}
        template(v-if="model.delivery_code === '51'")
          div(class="border-top" v-for="item in GeneralAddress.data" :key="item.id")
            div(class="form-radio")
              input(class="form-radio-input" type="radio" name="generalAddress" :id="'address' + item.id" :value="item.id" v-model="currentAddressId" @change="changeGeneralAddress(item.id)")
              label(class="form-radio-label" :for="'address' + item.id")
                div(class="form-radio-box")
                div(class="form-radio-text row flex-column flex-sm-row")
                  span(class="col-sm-2 py-2") {{ item.name }}
                  span(class="col-sm-3 py-2") {{ item.phone }}
                  span(class="col-sm-7 py-2") {{ item.postal_code }} {{ item.city }}{{ item.district }}{{ item.address }}
          div(class="border-top")
            div(class="form-radio")
              input(class="form-radio-input" type="radio" name="generalAddress" :id="'address' + 0" :value="0" v-model="currentAddressId" @change="changeGeneralAddress(0)")
              label(class="form-radio-label" :for="'address' + 0")
                div(class="form-radio-box mt-3")
                div(class="form-radio-text w-100 px-2" v-if="currentAddressId === 0")
                  div(class="pl-4 ml-2 m-sm-0 p-sm-0")
                    div(class="d-flex mx-n2")
                      div(class="flex-shrink-0 pr-2 mt-3 radio-title") 姓名
                      div(class="flex-grow-1 pr-2 my-2" style="max-width: 225px;")
                        InputBox(class="" type="text" placeholder="請輸入姓名" :model="address" handle="name" @input="changeGeneralAddress" :watch="true")
                        ErrorMessage(class="mt-0" :model="address" handle="name")
                    div(class="d-flex mx-n2")
                      div(class="flex-shrink-0 pr-2 mt-3 radio-title") 聯絡電話
                      div(class="flex-grow-1 pr-2 my-2" style="max-width: 225px;")
                        InputBox(class="hideArrows" type="text" placeholder="請輸入電話" :model="address" handle="phone" @input="changeGeneralAddress" :watch="true")
                        ErrorMessage(class="mt-0" :model="address" handle="phone")
                  div(class="mx-n2")
                    AddressEditor(class="" :model="address" title="收貨地址" title-class="ml-4 ml-sm-n2 radio-title" @change="changeGeneralAddress" @input="changeGeneralAddress")
                div(class="form-radio-text w-100 py-3" v-else) 自訂收貨人
            div(class="text-right p-2" v-if="currentAddressId === 0 && GeneralAddress.data.length <= 10")
              ButtonBox(:model="address" @click="createGeneralAddress") 儲存至常用收貨人
        div(class="border-top px-3" v-else)
          div(class="d-flex")
            div(class="flex-shrink-0 pr-2 from-title")
              div(class="") 收件人姓名
            div(class="flex-grow-1 pr-2 my-2" style="max-width: 225px;")
              InputBox(class="" type="text" placeholder="請輸入姓名" :model="model" handle="receiver")
              ErrorMessage(class="mt-0" :model="model" handle="receiver")
          div(class="d-flex")
            div(class="flex-shrink-0 pr-2 from-title")
              div(class="") 收件人電話
            div(class="flex-grow-1 pr-2 my-2" style="max-width: 225px;")
              InputBox(class="" type="text" placeholder="請輸入電話" :model="model" handle="receiver_phone")
              ErrorMessage(class="mt-0" :model="model" handle="receiver_phone")
          div(class="d-flex")
            div(class="flex-shrink-0 pr-2 from-title")
              div(class="mt-1") 營業所
            div(class="flex-grow-1 pr-2 my-2" style="max-width: 225px;")
              select(class="custom-select my-2" style="text-align-last: left;" :class="{'is-invalid': hasError(model, 'delivery_code')}" v-model="model.delivery_code" @change="changeDelivery")
                option(v-for="item in filterFortune" :value="item.code") {{ item.name }}
              ErrorMessage(class="mt-0" :model="model" handle="delivery_code")
    div(class="py-2" v-if="isBusiness(UserData)")
      div(class="border")
        div(class="border-bottom text-center py-2") 發票資料填寫
        div(class="")
          div(class="form-radio")
            input(class="form-radio-input" type="radio" :id="uuid.invoice" :value="1" v-model="model.invoice_accept_type" @change="changeInvoice")
            label(class="form-radio-label" :for="uuid.invoice")
              div(class="form-radio-box")
              div(class="form-radio-text my-2")
                span(class="font-weight-bold") 同收貨地址
                span(class="ml-2") 以郵政掛號寄出（20 個工作天）
          div(class="form-radio")
            input(class="form-radio-input" type="radio" :id="uuid.invoiceNew" :value="2" v-model="model.invoice_accept_type" @change="changeInvoice")
            label(class="form-radio-label" :for="uuid.invoiceNew")
              div(class="form-radio-box")
              div(class="form-radio-text w-100 my-2")
                span(class="font-weight-bold") 另寄新地址
                span(class="ml-2") 以郵政掛號寄出（20 個工作天）
                AddressEditor(class="col" :model="invoice" v-show="model.invoice_accept_type === 2" @change="changeInvoice")
          div(class="form-radio")
            input(class="form-radio-input" type="radio" :id="uuid.invoiceOffice" :value="3" v-model="model.invoice_accept_type" @change="changeInvoice")
            label(class="form-radio-label" :for="uuid.invoiceOffice")
              div(class="form-radio-box")
              div(class="form-radio-text my-2")
                span(class="font-weight-bold") 營業所領取
                span(class="ml-2") 會於20個工作天內寄至營業所
                div(v-show="model.invoice_accept_type === 3")
                  select(class="w-100 custom-select my-2" style="text-align-last: left;" v-model="currentOfficesInvoiceAddress" @change="changeInvoice")
                    option(v-for="item in filterFortune" :value="item.code") {{ item.name }}
                  ErrorMessage(class="mt-0" :model="model" handle="invoice_accept_type")
    div(class="py-2" v-else)
      div(class="border")
        div(class="border-bottom text-center py-2") 發票類型
        div(class="")
          div(class="form-radio")
            input(class="form-radio-input" type="radio" :id="uuid.invoice" :value="1" v-model="model.carrier_type")
            label(class="form-radio-label" :for="uuid.invoice")
              div(class="form-radio-box")
              div(class="form-radio-text my-2")
                span(class="font-weight-bold") 會員載具 <br />
                span(style="font-size: 14px;color:#B51717;") 自然人於東震消費所開立之統一發票，均自動上傳財政部電子發票平台，如有中獎將會寄出紙本發票給您。
          div(class="form-radio" v-if="userPhoneCode.length")
            input(class="form-radio-input" type="radio" :id="uuid.invoiceNew" :value="2" v-model="model.carrier_type")
            label(class="form-radio-label" :for="uuid.invoiceNew")
              div(class="form-radio-box")
              div(class="form-radio-text w-100 my-2")
                span(class="font-weight-bold") 手機條碼 <br />
                span(style="font-size: 14px;color:#B51717;") 提醒您！選擇該手機條碼載具後，載具資料一旦確定即無法更改。
                a(href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC500W/" target="_blank") 如何申請手機載具
                div(v-if="model.carrier_type === 2")
                  select(class="w-100 custom-select my-2" style="text-align-last: left;" v-model="model.carrier_id")
                    option(v-for="item in userPhoneCode" :value="item") {{ item }}
                  ErrorMessage(class="mt-0" :model="model" handle="carrier_id")
          div(class="form-radio")
            input(class="form-radio-input" type="radio" :id="uuid.invoiceOffice" :value="4" v-model="model.carrier_type")
            label(class="form-radio-label" :for="uuid.invoiceOffice")
              div(class="form-radio-box")
              div(class="form-radio-text my-2")
                span(class="font-weight-bold") 捐贈發票 <br />
                span(style="font-size: 14px;color:#B51717;") 提醒您！發票一旦捐贈後，無法再更改發票類型，感謝您的愛心捐贈。
                div(v-if="model.carrier_type === 4")
                  select(class="w-100 custom-select my-2" style="text-align-last: left;" v-model="model.love_code" )
                    option(v-for="item in LoveCodes.data" v-if="item.status == 1" :value="item.love_code") {{ item.name }}
                  ErrorMessage(class="mt-0" :model="model" handle="love_code")
          ErrorMessage(class="ml-3 mt-0" :model="model" handle="carrier_type")
    div(class="pb-5")
      div(class="row mx-n1 py-2 justify-content-end align-items-center flex-column flex-lg-row")
        router-link(class="w-100 btn btn-outline-success m-1 max-w-300 max-w-lg-180" to="/member/shopcart/step/1") 上一步
        button(class="w-100 btn btn-success m-1 max-w-300" @click="nextStep" type="button") 下一步
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import AddressEditor from '../../../container/address/Index.vue'
import { GeneralAddressModel, InvoiceAddressModel } from '../../../models'
import { createGeneralAddress, getDeliveryMethods } from '../../../api' // getInvoiceAddress
import { Toast } from '@/utils/helper'

export default {
  name: 'step2',
  data () {
    return {
      model: this.$parent.model,
      deliveryData: [],
      address: new GeneralAddressModel(),
      currentAddressId: 0,
      currentOfficesInvoiceAddress: '',
      invoice: new InvoiceAddressModel(),
      customerAddress: false,
      uuid: {
        office: uuidv4(),
        home: uuidv4(),
        invoice: uuidv4(),
        invoiceNew: uuidv4(),
        invoiceOffice: uuidv4()
      },
      isInvalid: false
    }
  },
  created () {
    const step = Number(this.$parent.step)
    if (step === 2) {
      this.$parent.step = 2
    } else {
      this.$router.replace('1')
      return
    }
    this.model.sale_territory = null
    console.log('this.UserData.sales_territory', this.UserData.sales_territory)
    if (this.UserData.sales_territory) {
      localStorage.setItem('sale_territory', this.UserData.sales_territory.code)
      this.model.set({
        sale_territory: this.UserData.sales_territory.code,
        delivery_code: '51'
        // invoice_accept_type: this.isBusiness(this.UserData) ? 2 : null
        // carrier_type: this.isBusiness(this.UserData) ? null : 1,
        // receiver: this.UserData.name || this.UserData.web,
        // receiver_phone: this.UserData.home_phone || this.UserData.mobile_phone01 || this.UserData.mobile_phone02
      })
    }
    this.changeDelivery()
    getDeliveryMethods().then(res => {
      // this.deliveryData = res.data
      // 資料順序問題，所以要調整
      this.deliveryData[0] = { // 黑貓宅急便
        ...res.data[1],
        value: '51',
        condition: () => true
      }
      this.deliveryData[1] = { // 營業索取貨
        ...res.data[0],
        value: '',
        condition: () => this.ShopCarts.data.every(p => p.is_lucky)
      }
      if (!this.ShopCarts.data.every(p => p.is_lucky)) this.model.delivery_code = '51'
    })

    this.getListModel({ name: 'GeneralAddress' }).then(res => {
      // console.log('GeneralAddress')
      this.defaultData()
    })
    // console.log('address')
    // console.log('step 2 this.model', this.model)
  },
  beforeUpdate () {
    // console.log('beforeUpdate')
    // this.defaultData()
  },
  updated () {
    // console.log('updated')
  },
  computed: {
    ...mapState({
      ShopCarts: state => state.model.ShopCarts,
      Products: state => state.model.Products,
      Offices: state => state.model.Offices,
      GeneralAddress: state => state.model.GeneralAddress,
      LoveCodes: state => state.model.LoveCodes,
      ShoppingRules: state => state.model.ShoppingRules,
      UserData: state => state.model.UserData
    }),
    filterSalesTerritory () {
      return this.Offices.data.filter(p => p.is_sales_territory_use)
    },
    filterFortune () {
      return this.Offices.data.filter(p => p.is_fortune_use)
    },
    userPhoneCode () { // 把使用者手機條碼整理為為陣列
      return [this.UserData.barcode01, this.UserData.barcode02, this.UserData.barcode03].filter(p => p)
    }
  },
  methods: {
    ...mapActions(['getListModel']),
    defaultData () {
      // console.log('defaultData')
      if (this.GeneralAddress.data !== null) {
        // console.log('this.model.receiver', this.model.receiver)
        // console.log('this.model.receiver_phone', this.model.receiver_phone)
        // console.log('this.currentAddressId', this.currentAddressId)

        if (localStorage.getItem('name') !== null && localStorage.getItem('name') !== '') {
          // console.log('this.currentAddressId 1', localStorage.getItem('name'))

          for (let i = 0; i < this.GeneralAddress.data.length; i++) {
            // console.log('this.GeneralAddress.data[i].phone,', this.model.receiver, this.GeneralAddress.data[i].name)
            const name = localStorage.getItem('name')
            const postalCode = localStorage.getItem('postal_code')
            const receiverPhone = localStorage.getItem('receiver_phone')
            // console.log('name,', name)
            // console.log('receiverPhone,', receiverPhone)
            // console.log('postalCode,', postalCode)
            if (postalCode === this.GeneralAddress.data[i].postal_code &&
              receiverPhone === this.GeneralAddress.data[i].phone &&
              name === this.GeneralAddress.data[i].name) {
              // console.log(this.GeneralAddress.data[i])
              this.currentAddressId = this.GeneralAddress.data[i].id
              // console.log('this.currentAddressId', this.currentAddressId)
              this.changeGeneralAddress(this.currentAddressId)
            }
          }

          if (this.currentAddressId === 0) {
            if (localStorage.getItem('name') !== null && localStorage.getItem('addresscity') !== null) {
              this.address.name = localStorage.getItem('name')
              this.address.postal_code = localStorage.getItem('postal_code')
              this.address.phone = localStorage.getItem('receiver_phone')

              this.address.city = localStorage.getItem('addresscity')
              this.address.district = localStorage.getItem('addressdistrict')
              this.address.address = localStorage.getItem('addressaddress')
            } else {
              this.address.name = this.model.receiver
              this.address.postal_code = this.model.postal_code
              // receiver_address: address.city + address.district + address.address,
              this.address.phone = this.model.receiver_phone

              this.address.city = this.model.addresscity
              this.address.district = this.model.addressdistrict
              this.address.address = this.model.addressaddress
            }

            this.model.set({
              receiver: this.address.name,
              postal_code: this.address.postal_code,
              receiver_address: this.address.city + this.address.district + this.address.address,
              receiver_phone: this.address.phone
            })
            this.model.set({
              addresscity: this.address.city,
              addressdistrict: this.address.district,
              addressaddress: this.address.address
            })
          }
        } else if (this.currentAddressId === 0 && this.customerAddress === false) {
          // console.log('this.currentAddressId 2', this.GeneralAddress.data)
          for (let i = 0; i < this.GeneralAddress.data.length; i++) {
            // console.log('this.currentAddressId 2 i', i)

            if (i === 0) {
              console.log(this.GeneralAddress.data[0])
              this.currentAddressId = this.GeneralAddress.data[0].id
              this.changeGeneralAddress(this.currentAddressId)
            }
          }
        }
      }

      if (this.model.carrier_type !== '' && this.model.carrier_type !== null) {
        // console.log('this.model.carrier_type:::', this.model.carrier_type)
      } else {
        this.model.set({
          carrier_type: 1
        })
      }

      if (localStorage.getItem('invoice_accept_type') !== null) {
        this.currentOfficesInvoiceAddress = localStorage.getItem('currentOfficesInvoiceAddress')
        this.model.set({
          invoice_accept_type: parseInt(localStorage.getItem('invoice_accept_type'))
        })

        if (this.model.invoice_accept_type === 2) {
          this.invoice.postal_code = localStorage.getItem('invoicepostal_code')
          this.invoice.city = localStorage.getItem('invoicecity')
          this.invoice.district = localStorage.getItem('invoicedistrict')
          this.invoice.address = localStorage.getItem('invoiceaddress')
          this.model.invoice_address = this.invoice.postal_code + this.invoice.city + this.invoice.district + this.invoice.address

          this.model.set({
            invoicepostal_code: this.invoice.postal_code,
            invoicecity: this.invoice.city,
            invoicedistrict: this.invoice.district,
            invoiceaddress: this.invoice.address
          })
        }
        this.changeInvoice()
      } else {
        this.model.set({
          invoice_accept_type: 1
        })
      }
    },
    createGeneralAddress () {
      console.log('createGeneralAddress')

      const errorMessage = this.address.validate()
      if (errorMessage) {
        return
      }
      this.address.loaded = true
      createGeneralAddress(
        this.address.toDatabase()
      ).then(res => {
        this.address.loaded = false
        const address = new GeneralAddressModel(res.data)
        this.currentAddressId = address.id
        this.GeneralAddress.data.push(address)
        this.address.set(new GeneralAddressModel())
        this.changeGeneralAddress(this.currentAddressId)
      }).catch(() => {
        this.address.loaded = false
      })
    },
    changeDelivery () {
      if (this.model.delivery_code === '51') {
        this.changeGeneralAddress(this.currentAddressId)
      } else {
        const office = this.Offices.data.find(p => String(p.code) === String(this.model.delivery_code))
        console.log(office)
        if (office) {
          this.model.set({
            postal_code: office.postal_code,
            receiver_address: office.city + office.district + office.address,
            office_name: office.name,
            office_phone: office.telephone
          })
        } else {
          this.model.set({
            postal_code: '',
            receiver_address: '',
            office_name: '',
            office_phone: ''
          })
        }
      }
    },
    changeGeneralAddress (id) {
      // this.currentAddressId = id
      if (id === 0) {
        this.customerAddress = true
      } else {
        this.customerAddress = false
      }
      // console.log('this.id', id)
      const address = Number(id) ? this.GeneralAddress.data.find(p => Number(p.id) === Number(id)) : this.address
      // console.log('this.address', address, address.phone.length)
      if (address.phone.length > 10) {
        this.address.phone = address.phone.substring(0, 9)
        // console.log('this.address', address.phone.substring(0, 10))
        return false
      }

      // console.log('changeGeneralAddress', address)
      localStorage.setItem('name', address.name)
      localStorage.setItem('postal_code', address.postal_code)
      localStorage.setItem('receiver_phone', address.phone)

      localStorage.setItem('addresscity', address.city)
      localStorage.setItem('addressdistrict', address.district)
      localStorage.setItem('addressaddress', address.address)

      this.model.set({
        receiver: address.name,
        postal_code: address.postal_code,
        receiver_address: address.city + address.district + address.address,
        receiver_phone: address.phone
      })
      this.model.set({
        addresscity: address.city,
        addressdistrict: address.district,
        addressaddress: address.address
      })
      // console.log('changeGeneralAddress 2', this.model.invoice_accept_type)
      if (this.isBusiness(this.UserData) && this.model.invoice_accept_type === 1) {
        this.changeInvoice()
      }
    },
    changeInvoice () {
      // console.log('changeInvoice', this.model.invoice_accept_type)
      // console.log(this.UserData)
      if (this.isBusiness(this.UserData)) {
        // console.log('changeInvoice isBusiness', this.model.invoice_accept_type)
        if (this.model.invoice_accept_type !== '' && this.model.invoice_accept_type !== null) {
          localStorage.setItem('invoice_accept_type', this.model.invoice_accept_type)
        }
        if (this.model.invoice_accept_type === 1) {
          this.model.invoice_address = this.model.postal_code + this.model.receiver_address
        } else if (this.model.invoice_accept_type === 2) {
          this.model.invoice_address = this.invoice.postal_code + this.invoice.city + this.invoice.district + this.invoice.address

          localStorage.setItem('invoicepostal_code', this.invoice.postal_code)
          localStorage.setItem('invoicecity', this.invoice.city)
          localStorage.setItem('invoicedistrict', this.invoice.district)
          localStorage.setItem('invoiceaddress', this.invoice.address)
          this.model.set({
            invoicepostal_code: this.invoice.postal_code,
            invoicecity: this.invoice.city,
            invoicedistrict: this.invoice.district,
            invoiceaddress: this.invoice.address
          })
        } else if (this.model.invoice_accept_type === 3) {
          localStorage.setItem('currentOfficesInvoiceAddress', this.currentOfficesInvoiceAddress)
          const office = this.Offices.data.find(p => String(p.code) === String(this.currentOfficesInvoiceAddress))
          // console.log('office', office)
          if (office) {
            this.model.set({
              currentOfficesInvoiceAddress: this.currentOfficesInvoiceAddress
            })
            this.model.invoice_address = office.postal_code + office.city + office.district + office.address
          } else {
            this.model.invoice_address = ''
          }
        }
      } else {
        this.model.invoice_accept_type = null
      }
    },
    mathShippingCost () { // 計算運費
      const isShippingFree = this.model.order_items.some(item => {
        if (item.products.free_shipping_quantity === null) {
          return false
        }
        return item.product_quantity >= item.products.free_shipping_quantity
      })
      const isAbroadShippingFree = this.model.order_items.some(item => {
        if (item.products.abroad_free_shipping_quantity === null) {
          return false
        }
        return item.product_quantity >= item.products.abroad_free_shipping_quantity
      })
      let free
      const zipcode = Number(String(this.model.postal_code).substring(0, 3))
      const shoppingRule = (() => {
        if (zipcode >= 880 && zipcode <= 885) {
          // 澎湖地區 880-885
          free = isAbroadShippingFree
          return this.ShoppingRules.data[1]
        } else if ((zipcode >= 209 && zipcode <= 212) || (zipcode >= 890 && zipcode <= 896)) {
          // 金門地區 209-212 馬祖地區 890-896
          free = isAbroadShippingFree
          return this.ShoppingRules.data[2]
        } else if (zipcode === 290 || zipcode === 817 || zipcode === 819 || zipcode === 929 || zipcode === 952) {
          // 釣魚臺列嶼 290 東沙群島 817 南沙群島 819 琉球鄉 929 蘭嶼鄉 952
          free = isAbroadShippingFree
          return this.ShoppingRules.data[3]
        } else if (zipcode === 951) {
          // 綠島地區 951
          free = isAbroadShippingFree
          return this.ShoppingRules.data[4]
        } else {
          free = isShippingFree
          return this.ShoppingRules.data[0]
        }
      })()
      return this.model.total_price >= shoppingRule.free_shipping || free ? 0 : shoppingRule.shipping_fee
    },
    nextStep () {
      // console.log('nextStep')
      const errorMessage = this.model.validate({
        delivery_code: () => {
          if (this.model.delivery_code === '51' && this.currentAddressId === 0) {
            const addressValidate = this.address.validate()
            if (addressValidate) {
              return { inclusion: { message: '^填寫收件地址' } }
            }
            return {}
          }
          return {
            presence: {
              allowEmpty: false,
              message: '^請選擇營業所'
            }
          }
        },
        receiver: () => {
          if (this.model.delivery_code !== '51') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請輸入姓名'
              }
            }
          }
          return {}
        },
        receiver_phone: () => {
          if (this.model.delivery_code !== '51') {
            // console.log('進入receiver_phone')
            return {
              presence: {
                allowEmpty: false,
                message: '^請輸入電話'
              }
            }
          }
          return {}
        },
        invoice_accept_type: () => {
          if (this.model.invoice_accept_type === 2) {
            const invoiceValidate = this.invoice.validate()
            if (invoiceValidate) {
              return { inclusion: { message: '^請填寫發票地址' } }
            }
          } else if (this.model.invoice_accept_type === 3) {
            if (!this.model.invoice_address) {
              return { inclusion: { message: '^請選擇營業所' } }
            }
          }
          return {}
        },
        carrier_id: () => {
          if (this.model.carrier_type === 2) {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇手機條碼'
              }
            }
          }
          return {}
        },
        love_code: () => {
          if (this.model.carrier_type === 4) {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇愛心碼'
              }
            }
          }
          return {}
        },
        sale_territory: () => {
          // console.log('進入sale_territory')
          // console.log('this.model.sale_territory', this.model.sale_territory)
          if (this.model.sale_territory && this.model.sale_territory !== '') {
            this.isInvalid = false
            // console.log('true')
          } else {
            // console.log('false')
            this.isInvalid = true
            return { message: '^請去選擇業績地區' }
          }
          return {}
        }
      })
      // console.log('errorMessage', errorMessage)
      if (errorMessage) {
        return
      }

      // 01065
      // console.log('next', this.model)
      let hasNotOut = false
      for (let i = 0; i < this.model.order_items.length; i++) {
        if (this.model.order_items[i].products.product_number === '01065') {
          hasNotOut = true
        }
      }
      // console.log('hasNotOut', hasNotOut)
      let notShip = false
      if (hasNotOut) {
        const postalCode = this.model.postal_code
        if (postalCode >= 880 && postalCode <= 885) {
          notShip = true
        } else if ((postalCode >= 890 && postalCode <= 896) || (postalCode >= 89000 && postalCode <= 89699) || (postalCode >= 209 && postalCode <= 212) || (postalCode >= 20900 && postalCode <= 21299)) {
          notShip = true
        } else if (postalCode === 952 || postalCode === 929 || postalCode === 290 || postalCode === 817 || postalCode === 819) {
          notShip = true
        } else if (postalCode === 951) {
          notShip = true
        }
      }

      if (notShip) {
        Toast.fire(
          '聚寶盆為易碎品，離島地區無法提供運送到家服務',
          '請重新填寫寄送地址或購買商品',
          'warning'
        )
        return
      }
      // console.log('nextStep2')
      // console.log('最後:', this.model)
      // console.log('最後:', this.model.sale_territory)
      if (!this.model.sale_territory) {
        // console.log('return')
        return
      }
      this.model.shipping_fee = this.mathShippingCost()
      if (parseInt(this.model.total_price) > 0) {
        this.model.total_price_new = this.model.shipping_fee + this.model.total_price
      }
      this.$parent.step = 3
      this.$router.push('3')
      // console.log('this.model', this.model)
      // console.log('this.model.order_items', this.model.order_items)
    },
    changeSaleTerritory () {
      // console.log('this.model.sale_territory', this.model.sale_territory)
      localStorage.setItem('sale_territory', this.model.sale_territory)
      if (this.model.sale_territory) {
        this.isInvalid = false
      } else {
        this.isInvalid = true
      }
    }
  },
  components: {
    AddressEditor
  }
}
</script>

<style lang="scss" scoped>
  .from-title {
    width: 150px;
    margin-top: 16px;
  }
  .radio-title {
    min-width: 80px;
  }
  .isInvalid{
    border-color: #A30000;
    padding-right: calc(0.75em + 2.3125rem);
  }
</style>
