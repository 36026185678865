
/**
 * 判斷使用者當前是否使用行動設備
 * @return {*} boolen
 */
export const isMobileDevice = function () {
  const mobileDevice = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone'
  ]
  return mobileDevice.some(d => navigator.userAgent.match(d))
}

/**
 * 卷軸滾動動畫
 * @param {*} param 設定參數
 * @param {Number} param.top 滾動 Y 軸
 * @param {Number} param.left 滾動 X 軸
 * @param {Number} param.duration 滾動時間 (ms)
 * @param {Number} param.delay 延遲時間 (ms)
 * @param {Function} param.ease 緩動函數
 */
export const toScrollAnimation = function (param = {}) {
  const toTop = param.top || 0
  const toLeft = param.left || 0
  const duration = param.duration || 400
  const delay = param.delay || 0
  const ease = param.easing || ((e) => e * 1)
  return new Promise(resolve => {
    let timer
    let cacheTime = 0
    const redrawSpeed = 20
    const cacheScrollY = scrollY
    const cacheScrollX = scrollX
    const redraw = () => {
      cacheTime += redrawSpeed
      const top = (toTop - cacheScrollY) * ease(cacheTime / duration) + cacheScrollY
      const left = (toLeft - scrollX) * ease(cacheTime / duration) + cacheScrollX
      scrollTo({ top, left })
      if (cacheTime > duration) {
        clearInterval(timer)
        resolve()
      }
    }
    setTimeout(() => {
      timer = setInterval(redraw, redrawSpeed)
      redraw()
    }, delay)
  })
}
