<template lang="pug">
  div(class="")
    div(class="px-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      div(class="detail")
        router-view
      div(class="d-flex flex-column flex-lg-row" v-if="listModel.data.length")
        div(class="fs-24 font-weight-bold text-left pt-4 pt-lg-5 mt-lg-3 col-auto" style="width:130px") 相關訊息
        div(class="border-bottom pt-4")
        div(class="d-flex flex-wrap pt-lg-5 mt-lg-3 flex-grow-1")
          Pagination(:model="listModel" type="scroll")
            div(class="d-flex flex-column py-md-2 col-lg-4 col-xl-3" v-for="(item, index) in listModel.data")
              div(class="pagination-card p-4 d-flex flex-column position-relative" style="height:120px" :class="{'disabled': item.id === currentModelId}")
                div(class="d-flex flex-column overflow-hidden")
                  router-link(class="order-md-2 mb-2 stretched-link" :to="rootPath +'/'+ item.id") {{ item.title }}
                  small(class="order-md-1 mb-2")
                    span(v-if="item.prefix_time") {{ dayFormat(item.prefix_time, 'YYYY年MM月') }}份
                    span(v-if="item.index_title") {{ dayFormat(item.index_title, 'YYYY年MM月') }}份
                    span(v-if="item.period") {{ item.period }}期
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'ArticleList',
  data () {
    return {
      currentModelId: 0
    }
  },
  computed: {
    ...mapState({
      listModel (state) {
        return state.model[this.modelName]
      }
    }),
    rootPath () {
      const meta = this.$route.matched[1].meta
      return meta.rootPath
    },
    modelName () {
      const meta = this.$route.matched[1].meta
      return meta.modelName
    }
  },
  methods: {
    dayFormat (time, format) {
      if (time) {
        return dayjs(time).format(format)
      }
      return ''
    },
    getListModel (force) {
      this.$store.dispatch('getListModel', {
        name: this.modelName,
        callback: list => {
          if (list.data.length > 0) {
            this.currentModelId = Number(list.data[0].id)
            // console.log('getListModel', list)
            const path = [this.rootPath, this.currentModelId].join('/')
            // console.log('=', this.rootPath)
            // console.log('=', this.$route.path)
            if (force) {
              this.$router.replace(path)
            } else {
              if (this.rootPath === this.$route.path) {
                console.log('=', this.rootPath)
                this.$router.replace(path)
              }
            }
          } else {
            const path = [this.rootPath, 1].join('/')
            if (this.rootPath === this.$route.path) {
              this.$router.replace(path)
            }
          }
        }
      })
    }
  },
  watch: {
    $route (to, from) {
      const reg = new RegExp(this.rootPath)
      if (to.path === this.rootPath && reg.test(from.path)) {
        this.$router.replace(from.path)
      } else if (!Object.prototype.hasOwnProperty.call(from.params, 'id')) {
        this.getListModel(true)
      } else {
        this.getListModel(false)
      }
    }
  },
  created () {
    this.getListModel(false)
  },
  updated () {
    this.currentModelId = Number(this.$route.params.id)
  }
}
</script>
