<template lang="pug">
  div.page-layout
    Header
    div.main
      router-view
    Aside
    Footer
</template>
<script>
import Header from './layout/header/Index.vue'
import Footer from './layout/footer/Index.vue'
import Aside from './layout/Aside.vue'

export default {
  name: 'Layout',
  data () {
    return {
    }
  },
  components: {
    Header,
    Footer,
    Aside
  }
}
</script>
