<template lang="pug">
  div
    div(class="py-2")
      div(class="border")
        div(class="border-bottom text-center py-2") 付款方式
        div(class="d-flex flex-column flex-lg-row" :class="{'border-top': index}" v-for="(item, index) in paymentMethodList" :key="item.id")
          div(class="col-auto py-3" style="width: 200px;")
            div(class="row mx-n1 radio-group h-100 justify-content-lg-center align-items-center")
              div(class="col-auto custom-control custom-radio my-2")
                input(type="radio" class="custom-control-input" :id="'pay-' + convPaymentById(item.id)" :class="{'is-invalid': hasError(model, 'payment')}" v-model="currentPayment" :value="item.id" @change="changePayment")
                label(class="custom-control-label" :for="'pay-' + convPaymentById(item.id)" style="width:120px;") {{ item.name }}
          div(class="col py-3")
            div(class="articel-content" v-html="item.content")
            template(v-if="item.id === 1 && currentPayment === 1")
              div(class="row")
              template(v-for="data in installmentData")
                div(class="col-auto custom-control custom-radio my-2" v-if="data.condition()")
                  input(type="radio" class="custom-control-input" :id="'installment-' + data.value" :class="{'is-invalid': hasError(model, 'installment')}" @change="changeInstallment" v-model="model.installment" :value="data.value")
                  label(class="custom-control-label" :for="'installment-' + data.value" style="width:120px;") {{ data.name }}
              ErrorMessage(class="mt-0" :model="model" handle="installment")
            template(v-if="item.id === 4 && currentPayment === 4")
              div(class="row")
                div(class="col-auto from-title") 匯款帳號末五碼
                div(class="col" style="max-width: 225px;")
                  InputBox(class="my-2" type="text" placeholder="請輸入帳號末五碼" :model="model" handle="card_number" :watch="true" :rules="cardNumberRule")
                  ErrorMessage(class="mt-0" :model="model" handle="card_number")
              div(class="row")
                div(class="col-auto from-title") 匯款日期
                div(class="col")
                  div(class="d-flex flex-wrap mx-n1")
                    div(class="col-auto custom-center custom-radio my-2" v-for="(data, index) in remittanceDays")
                      input(type="radio" class="custom-control-input" :id="'installment-' + index" :class="{'is-invalid': hasError(model, 'atm_day')}" v-model="model.atm_day" :value="data")
                      label(class="custom-control-label" :for="'installment-' + index" style="width:120px;") {{ dayFormat(data, 'YYYY/MM/DD') }}
                  ErrorMessage(class="mt-0" :model="model" handle="atm_day")
    div(class="pb-5")
      ErrorMessage(class="text-center text-lg-right" :model="model" handle="error_message")
      div(class="row mx-n1 py-2 justify-content-end align-items-center flex-column flex-lg-row")
        //router-link(class="" to="/member/shopcart/step/2") 上一步
        button(class="w-100 btn btn-outline-success m-1 max-w-300 max-w-lg-180" @click="preStep" type="button") 上一步
        button(class="w-100 btn btn-success m-1 max-w-300" @click="nextStep" type="button") 下一步
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getRemittanceDays } from '../../../api'
import { v4 as uuidv4 } from 'uuid'

export default {
  data () {
    return {
      model: this.$parent.model,
      installmentData: [
        { name: '不分期', value: 0, condition: () => true },
        { name: '分3期', value: 3, condition: () => this.model.total_price_new >= 3000 },
        { name: '分6期', value: 6, condition: () => this.model.total_price_new >= 10000 },
        { name: '分9期', value: 9, condition: () => this.model.total_price_new >= 25000 },
        { name: '分12期', value: 12, condition: () => this.model.order_items.every(p => this.isLuckyProduct(p.products.product_number)) }
      ],
      currentPayment: 0,
      remittanceDays: [],
      uuid: {
        office: uuidv4()
      },
      cardNumberRule: () => {
        if (this.model.payment === 'ATM') {
          if (this.model.card_number.length > 5) {
            this.model.card_number = this.model.card_number.substring(0, 4)
            // console.log('this.card_number', this.model.card_number)
            return false
          }
          return {
            presence: {
              allowEmpty: false,
              message: '^請填寫匯款帳號後5碼'
            },
            length: {
              minimum: 5,
              tooShort: '^請填寫匯款帳號末5碼',
              maximum: 5,
              tooLong: '^請填寫匯款帳號末5碼'
            },
            format: {
              pattern: '^\\d\\d\\d\\d\\d$',
              flags: 'i',
              message: '^請填寫匯款帳號末5碼'
            }
          }
        }
        return {}
      },
      paymentMethodList: []
    }
  },
  updated () {
    // console.log('remittanceDays', this.remittanceDays)
  },
  created () {
    const step = Number(this.$parent.step)
    if (step === 3) {
      this.$parent.step = 3
    } else {
      this.$router.replace('1')
    }
    this.getListModel({ name: 'PaymentMethods' }).then(res => {
      // eslint-disable-next-line array-callback-return
      this.paymentMethodList = res.data
    })
    getRemittanceDays().then(res => {
      this.remittanceDays = res.data
    })
    if (localStorage.getItem('payment') !== null) {
      // console.log('storage has payment', localStorage.getItem('payment'))
      this.model.payment = localStorage.getItem('payment')
      if (localStorage.getItem('payment') === 'taiShinCard') {
        this.currentPayment = 1
        if (localStorage.getItem('installment') !== null) {
          // console.log('storage  total_price_new', localStorage.getItem('total_price_new'))
          // console.log('storage  this.model.total_price_new', this.model.total_price_new)

          if (localStorage.getItem('total_price_new') !== null && parseInt(localStorage.getItem('total_price_new')) > this.model.total_price_new) {
            // console.log('storage has total_price_new', localStorage.getItem('total_price_new'))
            if (this.model.total_price_new >= 3000) {
              localStorage.setItem('installment', '0')
            } else if (this.model.total_price_new >= 10000) {
              localStorage.setItem('installment', '0')
            } else if (this.model.total_price_new >= 25000) {
              localStorage.setItem('installment', '0')
            }
          }
          this.model.installment = localStorage.getItem('installment')
        }
      } else if (localStorage.getItem('payment') === 'card') {
        this.currentPayment = 2
      } else if (localStorage.getItem('payment') === 'unionPay') {
        this.currentPayment = 3
      } else if (localStorage.getItem('payment') === 'ATM') {
        this.currentPayment = 4
      }
    } else {
      if (this.model.order_items.length && this.model.order_items.every(p => p.is_lucky)) {
        // console.log('found lucky')
        this.model.set({
          payment: 'taiShinCard',
          installment: '12'
        })
        this.currentPayment = 1
        this.changePayment()
      } else {
        // console.log('not found lucky')
      }
    }
    // console.log('step 3 this.OrderModel', this.currentPayment)
    localStorage.setItem('total_price_new', this.model.total_price_new)
  },
  computed: {
    ...mapState({
      PaymentMethods: state => state.model.PaymentMethods
    })
  },
  methods: {
    ...mapActions(['getListModel']),
    convPaymentById (id) {
      // console.log('convPaymentById', id)
      switch (Number(id)) {
        case 1: return 'taiShinCard'
        case 2: return 'card'
        case 3: return 'unionPay'
        case 4: return 'ATM'
        default: return ''
      }
    },
    changeInstallment () {
      localStorage.setItem('installment', this.model.installment)
    },
    changePayment () {
      // console.log('changePayment', this.currentPayment)
      this.model.payment = this.convPaymentById(this.currentPayment)
      // console.log('this.model.payment', this.model.payment)

      if (this.model.payment === 'taiShinCard') {
        this.model.installment = 0
        localStorage.setItem('installment', this.model.installment)
      } else {
        this.model.installment = 0
        localStorage.setItem('installment', this.model.installment)
      }
      // console.log('this.model.installment', this.model.installment)
      localStorage.setItem('payment', this.model.payment)

      if (this.model.payment !== 'ATM') {
        this.model.atm_day = ''
      }
    },
    preStep () {
      this.$parent.step = 2
      this.$router.push('2')
    },
    nextStep () {
      const errorMessage = this.model.validate({
        error_message: () => {
          if (!['taiShinCard', 'unionPay', 'card', 'ATM'].includes(this.model.payment)) {
            return { inclusion: { message: '^請選擇付款方式' } }
          }
          return {}
        },
        installment: () => {
          if (this.model.payment === 'taiShinCard') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇分期期數'
              }
            }
          }
          return {}
        },
        atm_day: () => {
          if (this.model.payment === 'ATM') {
            return {
              presence: {
                allowEmpty: false,
                message: '^請選擇付款日期'
              }
            }
          }
          return {}
        },
        card_number: this.cardNumberRule
      })
      if (errorMessage) {
        return
      }
      console.log(this.model.payment)
      console.log(this.model.atm_day)

      this.$parent.step = 4
      this.$router.push('4')
    }
  }
}
</script>

<style lang="scss" scoped>
  .from-title {
    width: 150px;
    margin-top: 16px;
  }
</style>
