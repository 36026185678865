<template lang="pug">
  div(class="d-flex justify-content-between align-items-center")
    div(class="d-flex align-items-center")
      div(class="px-3 py-2 p-lg-3 w-66 w-sm-132")
        div(class="p-100 position-relative")
          div(class="position-fill bg-contain" :title="model.name" v-if="model.images && model.images.length && model.images[0]" :style="{backgroundImage: `url(${model.images[0].image})`}")
          div(class="position-fill bg-contain" :title="model.name" v-else :style="{backgroundImage: `url(${defaultImage})`}")
      div(class="")
        div {{ model.product_number }}
        div {{ model.name }}
    div(class="p-3 flex-shrink-0")
      span(class="") 數量：
      span(class="") {{ model.gift_quantity }}
</template>

<script>
import { mapState } from 'vuex'
import { ProductsModel } from '../../models'
import defaultImage from '@/assets/images/default.png'

export default {
  name: 'ActivitieProducts',
  props: {
    model: {
      type: ProductsModel,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultImage
    }
  },
  computed: {
    ...mapState({
      ShopCarts: state => state.model.ShopCarts
    })
  }
}
</script>

<style lang="scss" scoped>
  .w-66 {
    width: 66px;
  }
  @media (min-width: 576px){
    .w-sm-132 {
      width: 132px;
    }
  }
</style>
