export default {
  mount: '.icon-map',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'currentColor'
  },
  path: `
    <path d="M20.5,3 L20.34,3.03 L15,5.1 L9,3 L3.36,4.9 C3.15,4.97 3,5.15 3,5.38 L3,20.5 C3,20.78 3.22,21 3.5,21 L3.66,20.97 L9,18.9 L15,21 L20.64,19.1 C20.85,19.03 21,18.85 21,18.62 L21,3.5 C21,3.22 20.78,3 20.5,3 Z M15,19 L9,16.89 L9,5 L15,7.11 L15,19 Z" fill="#424242"/>
  `
}
