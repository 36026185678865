import store from '../store'
import { getUser } from '../api'
// import axios from 'axios';
// import { toScrollAnimation } from '../library'

export default function (to, from, next) {
  getUser().then(() => {
    next()
  }).catch(err => {
    console.log(err)
  })

  window.scrollTo({ top: 0 })
  if (to.meta) {
    if (store.state.Seometas) {
      document.querySelector('title').innerHTML = store.state.Seometas.title + (to.meta.title ? ' - ' + to.meta.title : '')
    } else {
      document.querySelector('title').innerHTML = '東震股份有限公司' + (to.meta.title ? ' - ' + to.meta.title : '')
    }
  }
  next()
}
