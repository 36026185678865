<template lang="pug">
  aside(class="position-fixed aside" style="right:24px;bottom: 24px;z-index: 5;")
    div
      button(class="btn btn-icon focus-none d-flex flex-center rounded-circle" v-if="global.paginationBar && currentPage.index > 0" style="transform: rotate(180deg);" @click="toPage('prev')")
        Icon(class="" pattern="ArrowRight" size="24")
      button(class="btn btn-icon focus-none d-flex flex-center rounded-circle" v-if="global.paginationBar && currentPage.index < currentPage.list.data.length - 1" @click="toPage('next')")
        Icon(class="" pattern="ArrowRight" size="24")
      button(class="btn btn-icon focus-none d-flex flex-center rounded-circle" v-if="global.togglePanelBar" @click="togglePanel(false)")
        Icon(class="" pattern="ViewAgenda" size="24")
      button(class="btn btn-icon focus-none d-flex flex-center rounded-circle" v-if="global.togglePanelBar" @click="togglePanel(true)")
        Icon(class="" pattern="ViewHeadline" size="24")
      button(class="btn btn-icon focus-none d-flex flex-center rounded-circle" v-if="global.scrollTopMenuActive" style="transform: rotate(180deg);" @click="toScroll")
        Icon(class="" pattern="Totop" size="24")
</template>
<script>
// prev
import { mapState, mapMutations, mapActions } from 'vuex'
import { toScrollAnimation } from '../../library'
export default {
  name: 'Aside',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      global: state => state.global,
      model: state => state.model
    }),
    currentPage () {
      const {
        modelName
      } = this.$route.matched[1].meta
      const list = this.model[modelName]
      const { id } = this.$route.params || 0
      const model = list.data.find(p => Number(p.id) === Number(id)) || {}
      const index = list.data.map(p => p.id).indexOf(model.id)
      return { list, id, model, index }
    }
  },
  watch: {
    $route (to, from) {
      this.setFixedBar(to)
    }
  },
  methods: {
    ...mapMutations(['setFixedBar']),
    ...mapActions(['toggleAnimationAll']),
    togglePanel (bool) {
      this.toggleAnimationAll({
        animation: {
          type: 'slide'
        },
        order: bool
      })
    },
    toScroll () {
      toScrollAnimation()
    },
    toPage (to) {
      const { rootPath } = this.$route.matched[1].meta
      const toModel = this.currentPage.list.data.find((p, index) => {
        if (to === 'next') return (this.currentPage.index + 1) === index
        if (to === 'prev') return (this.currentPage.index - 1) === index
        if (!isNaN(Number(to))) return to === index
        return false
      })
      if (toModel) {
        const id = toModel.id
        if (!Number(id) || Number(id) === 'NaN') return
        const path = `${rootPath}/${id}`
        this.$router.push(path)
      }
    }
  },
  created () {
    this.setFixedBar(this.$route)
  }
}
</script>
<style lang="scss" scoped>
  aside{
    .btn.btn-icon{
      width: 36px;
      height: 36px;
      border: 1px solid rgba(86, 135, 15, 0.2);
      margin: 0.25rem 0;
      background-color: #fff;
    }
  }
</style>
