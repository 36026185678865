export default {
  mount: '.icon-view-headline',
  attrs: {
    viewBox: '0 0 24 24',
    fill: 'currentColor'
  },
  path: `
    <path d="M4,15 L20,15 L20,13 L4,13 L4,15 Z M4,19 L20,19 L20,17 L4,17 L4,19 Z M4,11 L20,11 L20,9 L4,9 L4,11 Z M4,5 L4,7 L20,7 L20,5 L4,5 Z" fill="#424242"/>
  `
}
